<template>
  <div id="app">
    <TheNavbar />
    <router-view v-slot="{ Component }">
      <transition name="page" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <TheFooter />
    <CookieConsent />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import TheNavbar from '@/components/layout/TheNavbar.vue'
import TheFooter from '@/components/layout/TheFooter.vue'
import CookieConsent from '@/components/CookieConsent.vue'
import { useSchema } from '@/composables/useSchema'
import { loadSavedLocale } from '@/plugins/i18n'

export default defineComponent({
  name: 'App',
  components: {
    TheNavbar,
    TheFooter,
    CookieConsent
  },
  created() {
    // Otomatik dil algılamasını uygula
    loadSavedLocale()
  },
  metaInfo: {
    title: 'Klinik Akademi | Kartal Soğanlık Diş Kliniği',
    meta: [
      {
        name: 'description',
        content: 'Kartal Soğanlık\'da 20 yıllık deneyimli kadromuzla implant, ortodonti, zirkonyum, diş beyazlatma ve tüm diş tedavileriniz için hizmetinizdeyiz. Yavuz Selim Caddesi üzerinde, modern kliniğimizde uzman diş hekimlerimizle tanışın.'
      },
      {
        name: 'keywords',
        content: 'kartal diş kliniği, orta diş hekimi, kartal implant, orta ortodonti, kartal zirkonyum, diş beyazlatma kartal, yavuz selim diş kliniği, kartal çocuk diş hekimi'
      },
      {
        name: 'geo.placename',
        content: 'Kartal, Orta'
      }
    ]
  },
  setup() {
    const { injectSchema } = useSchema()

    const schema = {
      "@context": "https://schema.org",
      "@type": "Dentist",
      "name": "Klinik Akademi Diş",
      "image": "https://klinikakademi.com/logo.png",
      "url": "https://klinikakademi.com",
      "telephone": "+905539736480",
      "priceRange": "₺₺",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Yavuz Selim Cd. No:15-A",
        "addressLocality": "Kartal",
        "addressRegion": "İstanbul",
        "postalCode": "34880",
        "addressCountry": "TR"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": 40.906927,
        "longitude": 29.188296
      },
      "openingHoursSpecification": [
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
          "opens": "09:00",
          "closes": "19:00"
        },
        {
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "Saturday",
          "opens": "09:00",
          "closes": "17:00"
        }
      ],
      "sameAs": [
        "https://www.facebook.com/klinikakademi",
        "https://www.instagram.com/klinikakademi",
        "https://twitter.com/klinikakademi"
      ]
    }

    injectSchema(schema)
  }
})
</script>

<style lang="scss">
@import '@/assets/styles/_variables';
@import '@/assets/styles/_mixins';

// Reset & Base Styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --text-color: #{$text-color};
  --light-color: #{$light-color};
  --background-color: #{$background-color};
}

body {
  font-family: $font-family-base;
  font-size: 16px;
  line-height: 1.5;
  color: $text-color;
  background-color: $background-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Typography
h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-heading;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

// Links
a {
  color: $primary-color;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: darken($primary-color, 10%);
  }
}

// Page Transitions
.page-enter-active,
.page-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.page-enter-from {
  opacity: 0;
  transform: translateY(20px);
}

.page-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

// Utility Classes
.text-center {
  text-align: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.section-padding {
  @include section-padding;
}

// Responsive Images
img {
  max-width: 100%;
  height: auto;
}

// Buttons
.btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  text-align: center;
  justify-content: center;

  &-primary {
    background: $primary-color;
    color: $light-color;

    &:hover {
      background: darken($primary-color, 10%);
    }
  }

  &-outline {
    border: 2px solid $primary-color;
    color: $primary-color;
    background: transparent;

    &:hover {
      background: $primary-color;
      color: $light-color;
    }
  }
}

// Form Elements
input, textarea, select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid rgba($text-color, 0.1);
  border-radius: $border-radius-md;
  font-family: $font-family-base;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: $primary-color;
    box-shadow: 0 0 0 3px rgba($primary-color, 0.1);
  }
}

// Scrollbar
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $background-color;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 5px;

  &:hover {
    background: darken($primary-color, 10%);
  }
}
</style>

<style>
html, body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  overflow-x: hidden;
}
</style>
