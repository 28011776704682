import { createI18n } from 'vue-i18n'
import messages from '../translations'
import router from '../router'
import slugs from '../translations/slugs'
import trTranslations from '@/translations/tr'
import enTranslations from '@/translations/en'
import deTranslations from '@/translations/de'

export const defaultLocale = 'tr'

// Başlangıç dil değerini localStorage veya tarayıcı dilinden alır
function getInitialLocale() {
  if (typeof window !== 'undefined' && window.localStorage) {
    // Önce localStorage kontrol et
    const savedLocale = localStorage.getItem('locale')
    if (savedLocale && ['tr', 'en', 'de'].includes(savedLocale)) {
      return savedLocale
    }
    
    // Sonra tarayıcı dilini kontrol et
    if (window.navigator) {
      const browserLang = navigator.language || navigator.userLanguage;
      const primaryLang = browserLang.split('-')[0].toLowerCase();
      
      if (primaryLang === 'tr') return 'tr';
      if (primaryLang === 'de') return 'de';
      if (primaryLang === 'en') return 'en';
    }
  }
  
  // Varsayılan
  return defaultLocale;
}

// i18n önce varsayılan dil ile başlat
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: defaultLocale, // Başlangıçta varsayılan dili kullan (döngüsel bağımlılığı önlemek için)
  fallbackLocale: defaultLocale,
  messages: {
    tr: trTranslations,
    en: enTranslations,
    de: deTranslations
  },
  escapeParameter: true,
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false
})

// Başlatma sonrası gerçek dili ayarla
if (typeof window !== 'undefined') {
  const initialLocale = getInitialLocale();
  if (initialLocale !== defaultLocale) {
    i18n.global.locale.value = initialLocale;
  }
}

// Tarayıcı diline göre otomatik dil seçme fonksiyonu
export function detectBrowserLanguage() {
  // Tarayıcı dilini al
  const browserLang = navigator.language || navigator.userLanguage;
  const primaryLang = browserLang.split('-')[0].toLowerCase();
  
  // Desteklenen dilleri kontrol et
  if (primaryLang === 'tr') {
    return 'tr'; // Türkçe
  } else if (primaryLang === 'de') {
    return 'de'; // Almanca
  } else {
    return 'en'; // Diğer tüm diller için İngilizce
  }
}

// Aktif dili döndüren fonksiyon
export function getLocale() {
  return i18n.global.locale.value || defaultLocale
}

export function useI18n() {
  return i18n.global
}

export function setLocale(locale) {
  i18n.global.locale.value = locale
  document.querySelector('html').setAttribute('lang', locale)
  localStorage.setItem('locale', locale)
  
  // Dil değiştirildiğinde URL'yi güncelle
  updateRouteAfterLocaleChange(locale)
}

// Dil değişikliğinden sonra route'u güncelleme
function updateRouteAfterLocaleChange(locale) {
  const currentPath = router.currentRoute.value.path
  
  // Yasal sayfaları kontrol et
  if (currentPath.includes('/yasal/') || 
      currentPath.includes('/legal/') || 
      currentPath.includes('/rechtliches/')) {
    
    // Yasal sayfalar için key'i bul
    let legalKey = null;
    let isLegalPage = false;
    
    // Önce bilinen yasal sayfa keylerini kontrol et
    const legalKeys = ['kvkk', 'privacyPolicy', 'cookiePolicy'];
    
    for (const key of legalKeys) {
      for (const lang in slugs) {
        if (currentPath.includes(`/${slugs[lang].legal}/${slugs[lang][key]}`)) {
          legalKey = key;
          isLegalPage = true;
          break;
        }
      }
      if (legalKey) break;
    }
    
    if (isLegalPage && legalKey) {
      const newPath = `/${slugs[locale].legal}/${slugs[locale][legalKey]}`;
      if (newPath !== currentPath) {
        router.push(newPath);
        return;
      }
    }
  }
  
  // Doğrudan yasal sayfa rotalarını kontrol et (direkt /kvkk gibi)
  const directLegalPaths = {
    'kvkk': 'kvkk',
    'gizlilik-politikasi': 'privacyPolicy',
    'privacy-policy': 'privacyPolicy',
    'datenschutzrichtlinie': 'privacyPolicy',
    'cerez-politikasi': 'cookiePolicy',
    'cookie-policy': 'cookiePolicy',
    'cookie-richtlinie': 'cookiePolicy',
    'personal-data-protection-information': 'kvkk',
    'datenschutz-informationen': 'kvkk'
  };
  
  for (const path in directLegalPaths) {
    if (currentPath === `/${path}`) {
      const legalKey = directLegalPaths[path];
      const newPath = `/${slugs[locale].legal}/${slugs[locale][legalKey]}`;
      if (newPath !== currentPath) {
        router.push(newPath);
        return;
      }
    }
  }
  
  // Tedavi sayfalarını kontrol et
  if (currentPath.includes('/tedaviler/') || 
      currentPath.includes('/treatments/') || 
      currentPath.includes('/behandlungen/')) {
    
    // Şu anki route'u parçalara ayır ve tedavi key'ini bul
    let treatmentKey = null;
    
    // Tüm route'ları kontrol et
    for (const route of router.getRoutes()) {
      if (route.path === currentPath && route.meta && route.meta.treatmentKey) {
        treatmentKey = route.meta.treatmentKey;
        break;
      }
    }
    
    // Eğer treatmentKey bulunamadıysa, mevcut slug'dan eşleşen key'i bulmaya çalış
    if (!treatmentKey) {
      for (const key in slugs['tr']) {
        if (key !== 'treatments') {
          // Her dil için kontrol et
          for (const lang in slugs) {
            const langSlug = slugs[lang][key];
            if (currentPath.includes(langSlug)) {
              treatmentKey = key;
              break;
            }
          }
          if (treatmentKey) break;
        }
      }
    }
    
    // Eğer bir treatmentKey bulunduysa, yeni URL'yi oluştur
    if (treatmentKey) {
      const treatsBase = slugs[locale].treatments;
      const treatSlug = slugs[locale][treatmentKey];
      
      if (treatSlug) {
        const newPath = `/${treatsBase}/${treatSlug}`;
        if (newPath !== currentPath) {
          router.push(newPath);
        }
      }
    }
  } else if (currentPath !== '/') {
    // Ana sayfa değilse ve tedavi sayfası değilse, diğer sayfaların URL'lerini güncelle
    // Mevcut URL'den sayfa keyini bulmaya çalış
    let pageKey = null;
    
    for (const key in slugs['tr']) {
      if (key !== 'treatments' && key !== 'home') {
        for (const lang in slugs) {
          const langSlug = slugs[lang][key];
          if (currentPath === `/${langSlug}`) {
            pageKey = key;
            break;
          }
        }
        if (pageKey) break;
      }
    }
    
    if (pageKey) {
      const newSlug = slugs[locale][pageKey];
      if (newSlug) {
        const newPath = `/${newSlug}`;
        if (newPath !== currentPath) {
          router.push(newPath);
        }
      }
    }
  }
}

export function loadSavedLocale() {
  // Önce localStorage'da kaydedilmiş bir dil var mı kontrol et
  const savedLocale = localStorage.getItem('locale')
  if (savedLocale && messages[savedLocale]) {
    setLocale(savedLocale)
    return savedLocale
  }
  
  // Yoksa tarayıcı diline göre otomatik dil seç
  const detectedLocale = detectBrowserLanguage();
  if (detectedLocale && messages[detectedLocale]) {
    setLocale(detectedLocale)
    return detectedLocale
  }
  
  // Hiçbiri yoksa varsayılan dile geri dön
  return defaultLocale
}

export default {
  install(app) {
    app.use(i18n)
    app.config.globalProperties.$locale = {
      get current() {
        return getLocale()
      },
      set(newLocale) {
        if (messages[newLocale]) {
          setLocale(newLocale)
        }
      }
    }
  }
} 