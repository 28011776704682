export default {
  // Hero Bölümü
  badge: 'Legal Information',
  title: {
    main: 'Cookie',
    highlight: 'Policy'
  },
  description: 'You can learn about the cookies used on our website, their purposes, and how to manage your cookie preferences.',
  
  meta: {
    title: 'Cookie Policy | Klinik Akademi Dental Polyclinic',
    description: 'Detailed information about Klinik Akademi Dental Polyclinic cookie usage policy and how you can manage your cookie settings.'
  },
  
  // İçerik Bölümü
  intro: 'As Klinik Akademi Dental Polyclinic, we have prepared this policy about how we use cookies (cookies) when using our website. This policy explains which cookies we use, how they work, and how you can manage your cookie preferences.',
  
  // Bölüm 1
  section1: {
    title: '1. What Are Cookies?',
    content: 'Cookies are small text files placed on your computer, tablet, or mobile device by websites you visit. Cookies are used to ensure that the website works correctly, improve user experience, analyze the website, and in some cases, provide targeted advertisements.'
  },
  
  // Bölüm 2
  section2: {
    title: '2. Cookies We Use',
    content: 'We use the following types of cookies on our website:',
    type1: {
      title: 'Essential Cookies',
      content: 'These cookies are necessary for the proper functioning of our website and contain system settings. You can block these cookies through your browser, but in this case, some parts of our website may not work properly.'
    },
    type2: {
      title: 'Performance and Analytics Cookies',
      content: 'These cookies collect information about how visitors use our website. For example, they are used to track the most visited pages, the time visitors spend on the site, and error messages they encounter.'
    },
    type3: {
      title: 'Functionality Cookies',
      content: 'These cookies are used to remember choices you make when visiting our website (such as your username, language, or region). These cookies allow us to provide you with a more personalized experience.'
    },
    type4: {
      title: 'Marketing and Targeting Cookies',
      content: 'These cookies are used to track your browsing habits on our website and other websites. Our aim is to show advertisements and content that are relevant to your interests.'
    }
  },
  
  // Bölüm 3
  section3: {
    title: '3. Third-Party Cookies',
    content: 'Our website may include cookies from third-party service providers. These cookies may be used to provide the following services:',
    items: [
      'Website analysis and statistics (Google Analytics, Hotjar, etc.)',
      'Social media features (Facebook, Twitter, Instagram, etc.)',
      'Advertising services (Google AdSense, Facebook Pixel, etc.)'
    ],
    additionalContent: 'These third-party cookies are subject to the privacy policies of the relevant third parties. You can access these policies from the websites of the relevant third parties.'
  },
  
  // Bölüm 4
  section4: {
    title: '4. Cookie Preferences and Control',
    content: 'Most web browsers are automatically set to accept cookies. However, you can block cookies or manage your cookie settings by changing your browser settings.',
    additionalContent: 'You can use the following methods to manage cookies:',
    method1: {
      title: 'Browser Settings',
      content: 'Most browsers offer settings that allow you to block cookies or accept cookies for specific websites.'
    },
    method2: {
      title: 'Cookie Preference Tools',
      content: 'You can update your cookie preferences using the cookie preferences tool offered on our website.'
    },
    method3: {
      title: 'Third-Party Tools',
      content: 'Some third-party websites offer the option to not accept cookies from certain companies.'
    },
    warning: 'If you completely block cookies, please note that some features of our website may not work properly and your user experience may be negatively affected.'
  },
  
  // Bölüm 5
  section5: {
    title: '5. Information on Cookie Usage',
    content: 'When you first visit our website, an information notice about the use of cookies is displayed. Through this notification, you can accept cookie usage or manage your cookie preferences.',
    example: {
      title: 'Cookie Notice Example',
      content: 'Our website uses cookies to enhance your experience. By continuing to use our site, you accept our cookie policy.',
      detailButton: 'More Information',
      acceptButton: 'Accept'
    },
    additionalContent: 'You can change your cookie preferences at any time. To change your cookie preferences, you can use the "Cookie Preferences" link at the bottom of our website.'
  },
  
  // Bölüm 6
  section6: {
    title: '6. Data Security',
    content: 'We implement technical and administrative measures to ensure the security of information collected through cookies. However, we would like to remind you that no data transmission over the internet is 100 percent secure.'
  },
  
  // Bölüm 7
  section7: {
    title: '7. Changes',
    content: 'We may update this Cookie Policy from time to time. When there is a change, we will publish the updated policy on our website. We recommend that you check the Cookie Policy every time you visit our website.'
  },
  
  // Bölüm 8
  section8: {
    title: '8. Contact',
    content: 'If you have questions or concerns about this Cookie Policy, please contact us through the following channels:',
    address: {
      label: 'Address',
      value: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/Istanbul'
    },
    email: {
      label: 'Email',
      value: 'info@klinikakademi.com'
    },
    phone: {
      label: 'Phone',
      value: '+90 (216) 123 45 67'
    },
    contactButton: 'Go to Contact Page'
  },
  
  // Footer
  lastUpdate: 'Last Update Date:'
} 