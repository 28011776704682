<template>
  <section class="tourism">
    <div class="container">
      <div class="row align-items-center g-5">
        <div class="col-lg-6" data-aos="fade-right">
          <div class="tourism__content">
            <span class="section-badge">{{ $t('tourism.badge') }}</span>
            <h2 class="section-title">
              {{ $t('tourism.title') }}<br>
              <span class="gradient-text">{{ $t('tourism.subtitle') }}</span>
            </h2>
            <div class="tourism__text">
              <p>{{ $t('tourism.description') }}</p>
              <a href="https://api.whatsapp.com/send?phone=905539736480" class="btn btn-gradient w-100">
                <i class="bi bi-whatsapp"></i>
                {{ $t('tourism.ctaButton') }}
              </a>
            </div>

            <div class="tourism__features">
              <div class="feature-item" v-for="feature in localizedFeatures" :key="feature.id">
                <div class="feature-icon">
                  <i :class="`bi bi-${feature.icon}`"></i>
                </div>
                <div class="feature-content">
                  <h4>{{ feature.title }}</h4>
                  <p>{{ feature.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6" data-aos="fade-left">
          <div class="tourism__image">
            <img src="@/assets/images/tourism/tourism.png" alt="Modern Diş Kliniği" class="img-fluid">
            <div class="floating-badge">
              <i class="bi bi-shield-check"></i>
              <span>{{ $t('tourism.floatingBadge') }}</span>
            </div>
            <div class="floating-stats">
              <div class="stat-item">
                <span class="number">15+</span>
                <span class="text">{{ $t('tourism.stat1') }}</span>
              </div>
              <div class="stat-item">
                <span class="number">%100</span>
                <span class="text">{{ $t('tourism.stat2') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TourismSection',
  data() {
    return {
      features: [
        {
          id: 1,
          title: 'feature1',
          description: 'description1',
          icon: 'gear'
        },
        {
          id: 2,
          title: 'feature2',
          description: 'description2',
          icon: 'people'
        },
        {
          id: 3,
          title: 'feature3',
          description: 'description3',
          icon: 'camera'
        },
        {
          id: 4,
          title: 'feature4',
          description: 'description4',
          icon: 'shield-check'
        }
      ]
    }
  },
  computed: {
    localizedFeatures() {
      return this.features.map(feature => {
        return {
          ...feature,
          title: this.$t(`tourism.features.${feature.title}`),
          description: this.$t(`tourism.features.${feature.description}`)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tourism {
  padding: 60px 0;
  background: white;

  &__content {
    padding-right: 3rem;

    .section-badge {
      display: inline-block;
      padding: 8px 16px;
      background: rgba(0, 164, 166, 0.1);
      color: var(--primary-color);
      border-radius: 50px;
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
    }

    .section-title {
      font-size: 2.8rem;
      font-weight: 800;
      margin-bottom: 1.5rem;
      line-height: 1.2;
      color: var(--heading-color);

      .gradient-text {
        background: linear-gradient(135deg, #00A4A6 0%, #004E50 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }

  &__text {
    p {
      font-size: 1.1rem;
      line-height: 1.8;
      color: var(--text-color-secondary);
      opacity: 0.9;
      margin-bottom: 1rem;

      &.highlight-text {
        color: var(--primary-color);
        font-weight: 500;
        font-size: 1.2rem;
      }
    }

    .btn-gradient {
      background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
      color: white;
      padding: 1rem 0;
      border-radius: 50px;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
      transition: all 0.3s ease;
      border: none;
      box-shadow: 0 8px 25px rgba(0, 164, 166, 0.25);
      margin-top: 0.5rem;

      i {
        font-size: 1.3rem;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 12px 30px rgba(0, 164, 166, 0.35);
      }
    }
  }

  &__features {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin: 2rem 0;

    .feature-item {
      background: white;
      padding: 1.25rem;
      border-radius: 12px;
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      .feature-icon {
        width: 40px;
        height: 40px;
        background: rgba(0, 164, 166, 0.1);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--primary-color);
        font-size: 1.2rem;
      }

      .feature-content {
        h4 {
          font-size: 1rem;
          margin-bottom: 0.25rem;
          color: var(--heading-color);
        }

        p {
          font-size: 0.85rem;
          color: var(--text-color);
          opacity: 0.8;
          margin: 0;
          line-height: 1.4;
        }
      }
    }
  }

  &__image {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);

    img {
      width: 100%;
      height: auto;
      transition: transform 0.5s ease;
    }

    .floating-badge {
      position: absolute;
      bottom: 20px;
      left: 20px;
      background: rgba(255, 255, 255, 0.95);
      padding: 12px 24px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      gap: 10px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

      i {
        color: var(--primary-color);
        font-size: 1.2rem;
      }

      span {
        font-weight: 500;
        color: var(--text-color);
      }
    }

    .floating-stats {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (max-width: 992px) {
        display: none;
      }

      .stat-item {
        background: rgba(255, 255, 255, 0.95);
        padding: 15px;
        border-radius: 12px;
        text-align: center;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

        .number {
          display: block;
          font-size: 1.8rem;
          font-weight: 700;
          color: var(--primary-color);
          line-height: 1;
          margin-bottom: 5px;
        }

        .text {
          font-size: 0.8rem;
          color: var(--text-color);
          opacity: 0.8;
        }
      }
    }

    &:hover img {
      transform: scale(1.05);
    }
  }
}

@media (max-width: 992px) {
  .tourism {
    text-align: center;
    padding: 40px 0;
    
    &__content {
      padding-right: 0;
      margin-bottom: 2rem;

      .section-title {
        font-size: 2.2rem;
      }
    }

    &__features {
      grid-template-columns: 1fr;
      max-width: 500px;
      margin: 2rem auto;

      .feature-item {
        text-align: left;
      }
    }
  }
}
</style> 