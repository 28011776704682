<template>
  <section class="services">
    <div class="services__bg">
      <div class="blob blob-1"></div>
      <div class="blob blob-2"></div>
      <div class="blob blob-3"></div>
      <div class="blob blob-4"></div>
    </div>
    <div class="container position-relative">
      <div class="section-header text-center" data-aos="fade-up">
        <span class="section-badge">
          <i class="bi bi-plus-circle me-2"></i>
          {{ $t('services.badge') }}
        </span>
        <h2 class="section-title">
          {{ $t('services.title') }}<br>
          <span class="gradient-text">{{ $t('services.subtitle') }}</span>
        </h2>
      </div>

      <div class="services__grid" data-aos="fade-up" data-aos-delay="100">
        <router-link v-for="service in localizedServices" :key="service.id" 
          :to="getServiceLink(service.treatmentKey)" 
          class="service-card">
          <div class="service-icon">
            <i :class="`bi bi-${service.icon}`"></i>
          </div>
          <div class="service-content">
            <h3>{{ service.title }}</h3>
            <p>{{ service.description }}</p>
          </div>
        </router-link>
      </div>

      <div class="text-center mt-5">
        <router-link :to="getTreatmentsPage()" class="btn btn-gradient w-100">
          <i class="bi bi-grid-3x3-gap me-2"></i>
          {{ $t('services.viewAllButton') }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import { getTreatmentRoute, getTreatmentsBaseSlug } from '@/utils/slug'

export default {
  name: 'ServicesSection',
  props: {
    services: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getServiceLink(treatmentKey) {
      return getTreatmentRoute(treatmentKey)
    },
    getTreatmentsPage() {
      return '/' + getTreatmentsBaseSlug()
    }
  },
  computed: {
    localizedServices() {
      return this.services.map(service => {
        return {
          ...service,
          title: this.$t(`services.items.${service.id}.title`),
          description: this.$t(`services.items.${service.id}.description`)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.services {
  padding: 100px 0;
  background: #f8fafc;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 60px 0;
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: 0;
    overflow: visible;

    .blob {
      position: absolute;
      border-radius: 40% 60% 60% 40% / 60% 30% 70% 40%;
      filter: blur(30px);
      opacity: 0.15;
      animation: float 12s ease-in-out infinite;
    }

    .blob-1 {
      width: 600px;
      height: 600px;
      background: var(--primary-color);
      top: -200px;
      right: -200px;
      animation-delay: 0s;
    }

    .blob-2 {
      width: 500px;
      height: 500px;
      background: var(--secondary-color);
      bottom: -150px;
      left: -150px;
      animation-delay: -3s;
      border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    }

    .blob-3 {
      width: 400px;
      height: 400px;
      background: #4F46E5;
      top: 30%;
      right: 10%;
      animation-delay: -6s;
      border-radius: 50% 50% 40% 60% / 40% 50% 50% 60%;
    }

    .blob-4 {
      width: 300px;
      height: 300px;
      background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
      top: 10%;
      left: 5%;
      animation-delay: -9s;
      border-radius: 40% 60% 50% 50% / 50% 40% 60% 50%;
    }
  }

  .container {
    position: relative;
    z-index: 1;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  @keyframes float {
    0%, 100% {
      transform: translate(0, 0) rotate(0deg) scale(1);
    }
    33% {
      transform: translate(15px, -15px) rotate(2deg) scale(1.05);
    }
    66% {
      transform: translate(-15px, 15px) rotate(-2deg) scale(0.95);
    }
  }

  .section-header {
    max-width: 800px;
    margin: 0 auto 5rem;

    .section-badge {
      display: inline-flex;
      align-items: center;
      padding: 12px 24px;
      background: rgba(0, 164, 166, 0.08);
      color: var(--primary-color);
      border-radius: 4px;
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-bottom: 2rem;

      i {
        font-size: 1.1rem;
      }
    }

    .section-title {
      font-size: 2.8rem;
      font-weight: 700;
      margin-bottom: 1.5rem;
      line-height: 1.2;
      color: var(--heading-color);

      .gradient-text {
        background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 0;
    margin: 0 -1rem;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .service-card {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 8px;
    text-decoration: none;
    position: relative;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    margin: 0 1rem;

    @media (max-width: 768px) {
      padding: 1.5rem;
      margin: 0 0.5rem;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.98);
      border-color: var(--primary-color);
      box-shadow: 0 8px 24px rgba(0, 164, 166, 0.12);
      transform: translateY(-2px);

      .service-icon {
        background: var(--primary-color);
        i {
          color: white;
        }
      }

      h3 {
        color: var(--primary-color);
      }
    }

    .service-icon {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      background: rgba(0, 164, 166, 0.08);
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      @media (max-width: 768px) {
        width: 40px;
        height: 40px;
      }

      i {
        font-size: 1.25rem;
        color: var(--primary-color);
        transition: all 0.3s ease;

        @media (max-width: 768px) {
          font-size: 1.1rem;
        }
      }
    }

    .service-content {
      flex-grow: 1;
      min-width: 0;

      h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.75rem;
        color: var(--heading-color);
        transition: all 0.3s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 768px) {
          font-size: 1.1rem;
        }
      }

      p {
        font-size: 0.95rem;
        color: var(--text-color);
        margin: 0;
        line-height: 1.6;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media (max-width: 768px) {
          font-size: 0.9rem;
        }
      }
    }
  }

  .btn-gradient {
    padding: 1rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    border-radius: 12px;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    border: none;
    transition: all 0.3s ease;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    max-width: 300px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding: 0.8rem 1.5rem;
      font-size: 1rem;
    }

    @media (max-width: 576px) {
      padding: 0.7rem 1.2rem;
      font-size: 0.95rem;
      max-width: 100%;
    }
  }
}
</style> 