export default {
  // Hero Bölümü
  badge: 'Yasal Bilgilendirme',
  title: {
    main: 'Çerez',
    highlight: 'Politikası'
  },
  description: 'Web sitemizde kullanılan çerezler, bunların kullanım amaçları ve çerez tercihlerinizin yönetimi hakkında bilgi edinebilirsiniz.',
  
  meta: {
    title: 'Çerez Politikası | Klinik Akademi Diş Polikliniği',
    description: 'Klinik Akademi Diş Polikliniği çerez kullanım politikası ve çerez ayarlarınızı nasıl yönetebileceğiniz hakkında detaylı bilgi.'
  },
  
  // İçerik Bölümü
  intro: 'Klinik Akademi Diş Polikliniği olarak, web sitemizi kullanırken çerezleri (cookies) nasıl kullandığımız hakkında bu politikayı hazırladık. Bu politika, hangi çerezleri kullandığımızı, bunların nasıl çalıştığını ve çerezlerle ilgili tercihlerinizi nasıl yönetebileceğinizi açıklamaktadır.',
  
  // Bölüm 1
  section1: {
    title: '1. Çerezler Nedir?',
    content: 'Çerezler, ziyaret ettiğiniz web siteleri tarafından bilgisayarınıza, tabletinize veya mobil cihazınıza yerleştirilen küçük metin dosyalarıdır. Çerezler, web sitesinin doğru şekilde çalışmasını sağlamak, kullanıcı deneyimini geliştirmek, web sitesini analiz etmek ve bazı durumlarda hedefli reklamlar sunmak için kullanılır.'
  },
  
  // Bölüm 2
  section2: {
    title: '2. Kullandığımız Çerezler',
    content: 'Web sitemizde aşağıdaki çerez türlerini kullanmaktayız:',
    type1: {
      title: 'Zorunlu Çerezler',
      content: 'Bu çerezler, web sitemizin düzgün çalışabilmesi için gereklidir ve sistem ayarlarını içerir. Bu çerezleri tarayıcınız aracılığıyla engelleyebilirsiniz ancak bu durumda web sitemizin bazı bölümleri düzgün çalışmayabilir.'
    },
    type2: {
      title: 'Performans ve Analiz Çerezleri',
      content: 'Bu çerezler, ziyaretçilerin web sitemizi nasıl kullandığına dair bilgileri toplar. Örneğin, en çok ziyaret edilen sayfaları, ziyaretçilerin sitede geçirdiği süreyi ve karşılaştıkları hata mesajlarını izlemek için kullanılır.'
    },
    type3: {
      title: 'İşlevsellik Çerezleri',
      content: 'Bu çerezler, web sitemizi ziyaret ettiğinizde yaptığınız seçimleri (kullanıcı adınız, diliniz veya bulunduğunuz bölge gibi) hatırlamak için kullanılır. Bu çerezler, size daha kişiselleştirilmiş bir deneyim sunmamıza olanak tanır.'
    },
    type4: {
      title: 'Pazarlama ve Hedefleme Çerezleri',
      content: 'Bu çerezler, web sitemizde ve diğer web sitelerinde gezinme alışkanlıklarınızı izlemek için kullanılır. Amacımız, sizin ilgi alanlarınıza uygun reklamlar göstermek ve içerik sunmaktır.'
    }
  },
  
  // Bölüm 3
  section3: {
    title: '3. Üçüncü Taraf Çerezleri',
    content: 'Web sitemiz, üçüncü taraf hizmet sağlayıcılarının çerezlerini içerebilir. Bu çerezler, aşağıdaki hizmetleri sağlamak için kullanılabilir:',
    items: [
      'Web sitesi analizi ve istatistikleri (Google Analytics, Hotjar vb.)',
      'Sosyal medya özellikleri (Facebook, Twitter, Instagram vb.)',
      'Reklam hizmetleri (Google AdSense, Facebook Pixel vb.)'
    ],
    additionalContent: 'Bu üçüncü taraf çerezleri, ilgili üçüncü tarafların gizlilik politikalarına tabidir. Bu politikalara ilgili üçüncü tarafların web sitelerinden ulaşabilirsiniz.'
  },
  
  // Bölüm 4
  section4: {
    title: '4. Çerez Tercihleri ve Kontrolü',
    content: 'Çoğu web tarayıcısı, çerezleri kabul etmeye otomatik olarak ayarlıdır. Ancak, tarayıcı ayarlarınızı değiştirerek çerezleri engelleyebilir veya çerez ayarlarınızı yönetebilirsiniz.',
    additionalContent: 'Çerezleri yönetmek için aşağıdaki yöntemleri kullanabilirsiniz:',
    method1: {
      title: 'Tarayıcı Ayarları',
      content: 'Çoğu tarayıcı, çerezleri engellemenizi veya belirli web siteleri için çerezleri kabul etmenizi sağlayan ayarlar sunar.'
    },
    method2: {
      title: 'Çerez Tercihleri Araçları',
      content: 'Web sitemizde sunulan çerez tercihleri aracını kullanarak çerez tercihlerinizi güncelleyebilirsiniz.'
    },
    method3: {
      title: 'Üçüncü Taraf Araçları',
      content: 'Bazı üçüncü taraf web siteleri, belirli şirketlerin çerezlerini kabul etmeme seçeneği sunar.'
    },
    warning: 'Çerezleri tamamen engellerseniz, web sitemizin bazı özelliklerinin düzgün çalışmayabileceğini ve kullanıcı deneyiminizin olumsuz etkilenebileceğini unutmayın.'
  },
  
  // Bölüm 5
  section5: {
    title: '5. Çerez Kullanımı Hakkında Bilgilendirme',
    content: 'Web sitemizi ilk ziyaret ettiğinizde, çerezlerin kullanımı hakkında bir bilgilendirme bildirimi görüntülenir. Bu bildirim aracılığıyla, çerez kullanımını kabul edebilir veya çerez tercihlerinizi yönetebilirsiniz.',
    example: {
      title: 'Çerez Bildirimi Örneği',
      content: 'Web sitemiz, deneyiminizi geliştirmek için çerezleri kullanmaktadır. Sitemizi kullanmaya devam ederek çerez politikamızı kabul etmiş olursunuz.',
      detailButton: 'Detaylı Bilgi',
      acceptButton: 'Kabul Et'
    },
    additionalContent: 'Çerez tercihlerinizi istediğiniz zaman değiştirebilirsiniz. Çerez tercihlerinizi değiştirmek için web sitemizin alt kısmında bulunan "Çerez Tercihleri" bağlantısını kullanabilirsiniz.'
  },
  
  // Bölüm 6
  section6: {
    title: '6. Veri Güvenliği',
    content: 'Çerezler aracılığıyla toplanan bilgilerin güvenliğini sağlamak için teknik ve idari tedbirler uygulamaktayız. Ancak, internet üzerinden hiçbir veri iletiminin yüzde 100 güvenli olmadığını hatırlatmak isteriz.'
  },
  
  // Bölüm 7
  section7: {
    title: '7. Değişiklikler',
    content: 'Bu Çerez Politikasını zaman zaman güncelleyebiliriz. Herhangi bir değişiklik olduğunda, güncellenmiş politikayı web sitemizde yayınlayacağız. Web sitemizi her ziyaret ettiğinizde Çerez Politikasını kontrol etmenizi öneririz.'
  },
  
  // Bölüm 8
  section8: {
    title: '8. İletişim',
    content: 'Bu Çerez Politikası hakkında sorularınız veya endişeleriniz varsa, lütfen bizimle aşağıdaki kanallar üzerinden iletişime geçin:',
    address: {
      label: 'Adres',
      value: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/İstanbul'
    },
    email: {
      label: 'E-posta',
      value: 'info@klinikakademi.com'
    },
    phone: {
      label: 'Telefon',
      value: '+90 (216) 123 45 67'
    },
    contactButton: 'İletişim Sayfasına Git'
  },
  
  // Footer
  lastUpdate: 'Son Güncelleme Tarihi:'
} 