<template>
  <a 
    :href="href" 
    class="app-button" 
    :class="[
      `app-button--${variant}`,
      `app-button--${size}`
    ]"
  >
    <i v-if="icon" :class="`bi bi-${icon}`"></i>
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'outline'].includes(value)
    },
    size: {
      type: String,
      default: 'md',
      validator: value => ['sm', 'md', 'lg'].includes(value)
    },
    icon: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: '#'
    }
  }
}
</script>

<style lang="scss" scoped>
.app-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  font-weight: 500;
  border-radius: 50px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }

  &--primary {
    background-color: var(--light-color);
    color: var(--primary-color);

    &:hover {
      background-color: var(--secondary-color);
      color: var(--light-color);
    }
  }

  &--outline {
    border: 2px solid rgba(255, 255, 255, 0.2);
    color: var(--light-color);

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  &--sm { padding: 0.5rem 1rem; }
  &--md { padding: 0.75rem 1.5rem; }
  &--lg { padding: 1rem 2rem; }
}
</style> 