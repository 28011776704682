import legal from './legal'

export default {
  legal,
  nav: {
    home: 'Anasayfa',
    about: 'Hakkımızda',
    aboutLink: 'Hakkımızda',
    treatments: 'Tedaviler',
    treatmentsLink: 'Tedaviler',
    media: 'Medya',
    contact: 'İletişim',
    appointment: 'Randevu Al',
    appointmentBtn: 'Randevu Al',
    aboutMenu: {
      corporate: {
        title: 'Kurumsal',
        aboutUs: {
          title: 'Hakkımızda',
          subtitle: 'Misyonumuz ve Vizyonumuz'
        },
        team: {
          title: 'Ekibimiz',
          subtitle: 'Profesyonel Kadromuz'
        }
      },
      clinics: {
        title: 'Kliniklerimiz',
        kartal: {
          title: 'Kartal Şube',
          subtitle: 'Ana Merkez'
        },
        dikili: {
          title: 'Dikili Şube',
          subtitle: 'İzmir'
        }
      }
    },
    treatmentsMenu: {
      basic: {
        title: 'Temel Tedaviler',
        implant: {
          title: 'İmplant Tedavisi',
          subtitle: 'Hassas Diş İmplantları'
        },
        surgery: {
          title: 'Ağız, Diş ve Çene Cerrahisi',
          subtitle: 'Uzman Cerrahi Müdahaleler'
        },
        rootCanal: {
          title: 'Kanal Tedavisi',
          subtitle: 'Endodontik Tedaviler'
        },
        prosthesis: {
          title: 'Protez Tedavisi',
          subtitle: 'Gelişmiş Protez Çözümleri'
        }
      },
      aesthetic: {
        title: 'Estetik ve Özel Tedaviler',
        whitening: {
          title: 'Diş Beyazlatma',
          subtitle: 'Profesyonel Diş Beyazlatma'
        },
        orthodontics: {
          title: 'Ortodonti',
          subtitle: 'Gülüş Tasarımı ve Düzeltme'
        },
        periodontics: {
          title: 'Periodonti',
          subtitle: 'Diş Eti Tedavileri'
        },
        pediatrics: {
          title: 'Pedodonti',
          subtitle: 'Çocuk Diş Hekimliği'
        },
        invisalign: {
          title: 'Invisalign Şeffaf Plak',
          subtitle: 'Görünmez Ortodonti'
        }
      },
      appointment: {
        title: 'Online Randevu',
        description: 'Hemen randevu alın, uzman kadromuzla tanışın.',
        button: 'Randevu Al'
      }
    }
  },
  navbar: {
    home: 'Anasayfa',
    treatments: 'Tedaviler',
    about: 'Hakkımızda',
    media: 'Medya',
    contact: 'İletişim'
  },
  header: {
    clinicName: 'Klinik Akademi',
    openNow: 'Şu an açık',
    workingHours: 'Pazartesi - Pazar 09:00 - 00:00',
    workingDays: 'Pazartesi - Pazar',
    workingTime: '09:00 - 00:00',
    address: 'Orta, Yavuz Selim Cd. No:15-A Kartal/İstanbul'
  },
  hero: {
    title: 'Kartal Soğanlık Diş Kliniği',
    subtitle: 'haftanın 7 günü hizmetinizdeyiz.',
    description: 'Modern teknoloji ve uzman kadromuzla, gülümsemenizi yeniden tasarlıyoruz. Ücretsiz muayene ve dijital röntgen ile tedavi planınızı oluşturalım.',
    ctaButton: 'Randevu Al',
    secondaryButton: 'Hizmetlerimiz',
    feature1: 'Ücretsiz Muayene',
    feature2: 'Dijital Röntgen',
    feature3: '3D Planlama',
    cardTitle: 'Online Randevu',
    cardText: 'Haftanın 7 Günü Hizmetinizdeyiz',
    badgeTitle: '10.000+',
    badgeText: 'Sağlıklı Gülüş',
    badge1: '15+ Yıllık Deneyim',
    badge2: 'Üst Düzey Teknoloji'
  },
  whyUs: {
    badge: 'Neden Biz?',
    title: 'Burada Olmamızın Sebebi:',
    subtitle: 'Doğal Bir Gülümseme',
    description: 'Güncel teknolojik ekipmanlarımız, modern teşhis ve tedavi yöntemlerimiz ve çözüm odaklı ekibimizle hizmetinizdeyiz.',
    feature1: 'Modern Teknoloji',
    feature2: 'Uzman Kadro',
    feature3: 'Steril Ortam',
    button: 'Daha Fazla Bilgi',
    floatingBadge: '15+ Yıllık Deneyim'
  },
  tourism: {
    badge: 'Modern Klinik',
    title: 'Güvenilir ve Konforlu',
    subtitle: 'Diş Tedavisi',
    description: 'Modern teknolojimiz ve uzman kadromuzla, size en konforlu tedavi deneyimini sunuyoruz. Hasta odaklı yaklaşımımız ve steril ortamımızla güvenilir diş tedavisi için yanınızdayız.',
    ctaButton: 'Randevu Al',
    floatingBadge: 'Üst Düzey Teknoloji',
    stat1: 'Yıllık<br>Deneyim',
    stat2: 'Hasta<br>Memnuniyeti',
    features: {
      feature1: 'Modern Teknoloji',
      description1: 'En son teknoloji cihazlar ve malzemeler',
      feature2: 'Uzman Kadro',
      description2: 'Deneyimli ve uzman diş hekimleri',
      feature3: 'Dijital Planlama',
      description3: '3D görüntüleme ile detaylı tedavi planı',
      feature4: 'Steril Ortam',
      description4: 'Tam sterilizasyon ve hijyen standartları'
    }
  },
  // 404 Sayfası
  notFound: {
    title: 'Sayfa Bulunamadı',
    subtitle: '404 Hatası',
    message: 'Aradığınız sayfa bulunamadı veya taşınmış olabilir.',
    button: 'Anasayfaya Dön',
    helpText: 'Yardıma mı ihtiyacınız var?',
    contactUs: 'Bizimle İletişime Geçin',
    suggestedPages: 'Önerilen Sayfalar:',
    home: 'Anasayfa',
    treatments: 'Tedaviler',
    contact: 'İletişim'
  },
  checkup: {
    badge: 'Ücretsiz Muayene',
    title: 'Ağız ve Diş Sağlığınızı',
    subtitle: 'Kontrol Edelim',
    description: 'Diş sağlığınız için ilk adımı atın. Uzman diş hekimlerimiz tarafından yapılacak ücretsiz muayene ile ağız ve diş sağlığınızı kontrol edelim, size özel tedavi planı oluşturalım.',
    ctaButton: 'Ücretsiz Muayene Randevusu',
    doctorName: 'Dt. Harun Atay',
    features: {
      feature1: 'Detaylı Muayene',
      description1: 'Kapsamlı ağız ve diş kontrolü',
      feature2: 'Dijital Röntgen',
      description2: 'Panoramik ve periapikal röntgen',
      feature3: 'Tedavi Planı',
      description3: 'Size özel tedavi planlaması',
      feature4: 'Fiyat Teklifi',
      description4: 'Şeffaf ve detaylı maliyet bilgisi'
    }
  },
  cta: {
    badge: 'Uzman Kadro',
    title: 'Her Gün Sağlıkla',
    subtitle: 'Gülümseyin!',
    description: 'Gününüze bir gülümsemeyle başlayın: Ağız, diş ve çene cerrahisi alanında uzman ekibimizle tanışmak için randevu alın.',
    button: 'Randevu Al'
  },
  services: {
    badge: 'Tedavilerimiz',
    title: 'Modern Diş Hekimliğinde',
    subtitle: 'Uzman Tedaviler',
    viewAllButton: 'Tüm Tedavilerimiz',
    items: {
      1: {
        title: 'İmplant Tedavisi',
        description: 'Ömür boyu garanti ile kalıcı diş tedavisi'
      },
      2: {
        title: 'Zirkonyum Kaplama',
        description: 'Doğal görünümlü estetik kaplamalar'
      },
      3: {
        title: 'Ortodonti',
        description: 'Modern tekniklerle diş düzenleme'
      },
      4: {
        title: 'Estetik Diş Hekimliği',
        description: 'Gülüş tasarımı ve estetik uygulamalar'
      },
      5: {
        title: 'Diş Beyazlatma',
        description: 'Profesyonel beyazlatma sistemleri'
      },
      6: {
        title: 'Çocuk Diş Hekimliği',
        description: 'Pedodonti uzman tedavileri'
      },
      implant: {
        title: 'İmplant Tedavisi',
        description: 'Ömür boyu garanti ile kalıcı diş tedavisi'
      },
      zirconium: {
        title: 'Zirkonyum Kaplama',
        description: 'Doğal görünümlü estetik kaplamalar'
      },
      orthodontics: {
        title: 'Ortodonti',
        description: 'Modern tekniklerle diş düzenleme'
      },
      aesthetics: {
        title: 'Estetik Diş Hekimliği',
        description: 'Gülüş tasarımı ve estetik uygulamalar'
      },
      whitening: {
        title: 'Diş Beyazlatma',
        description: 'Profesyonel beyazlatma sistemleri'
      },
      pediatric: {
        title: 'Çocuk Diş Hekimliği',
        description: 'Pedodonti uzman tedavileri'
      }
    }
  },
  about: {
    title: 'Hakkımızda',
    subtitle: 'Klinik Akademi',
    description: '20 yılı aşkın deneyimimizle Kartal\'da hastalarımıza yüksek kaliteli diş bakımı sunuyoruz. Uzman ekibimiz, modern ekipmanlar ve hasta odaklı yaklaşımla tüm diş problemlerinize çözümler sunmaktadır.',
    expertise: 'Uzmanlık',
    experience: 'Deneyim',
    technology: 'Teknoloji',
    comfort: 'Konfor',
    
    hero: {
      title: "Sağlıklı Gülüşler için",
      highlight: "Profesyonel Çözümler",
      text1: "Birinci sınıf diş bakımı için varış noktanız olan Kartal Soğanlık Klinik Akademi'ye hoş geldiniz. Modern kliniğimiz, ağız sağlığınıza ve ışıltılı gülümsemelerinize kendini adamış deneyimli profesyonellerden oluşan bir ekip ve son teknoloji ekipmanlarla donatılmıştır.",
      text2: "Klinik Akademi'de son teknolojiyi kişisel bakımla harmanlayarak rutin kontrollerden ileri tedavilere kadar geniş bir yelpazede hizmet sunuyoruz. Misafirperver ortamımız konforunuzu sağlamak için tasarlanmıştır ve güler yüzlü ekibimiz diş hekimliği deneyiminizi keyifli hale getirmeye kendini adamıştır.",
      text3: "Koruyucu bakıma ve hasta eğitimine öncelik vererek, optimum ağız sağlığını korumanızı sağlamaya çalışıyoruz. İster rutin bir ziyaret için ister kozmetik iyileştirmeler için burada olun, daha sağlıklı, daha güzel bir gülümsemeye giden yolculuğunuzda size rehberlik etmek için buradayız.",
      stats: {
        experience: "Yıllık Deneyim",
        smiles: "Sağlıklı Gülüşler"
      },
      badge: "Üst Düzey Teknoloji"
    },
    mission: {
      badge: "Misyonumuz",
      title: "Önem ve Değerlerimiz",
      text: "Klinik Akademi'de misyonumuz, önleyici sağlık hizmetlerini ve hastanın olabilecek en sağlıklı pozisyonda kliniğimizden ayrılmasını vurgulayarak üst düzey diş bakımı hizmetini sunmaktır. En son teknoloji ve özel bir ekip aracılığıyla, her hasta için olumlu ve konforlu bir deneyim yaratmayı hedefliyoruz.",
      services: {
        digital: "Dijital Diş Hekimliği",
        aesthetic: "Estetik Diş Hekimliği",
        surgery: "Ağız cerrahisi",
        whitening: "Diş Beyazlatma",
        pediatric: "Çocuk Diş Hekimliği",
        extraction: "Diş çekimi"
      },
      badge_doctor: "Uzman Hekim"
    },
    vision: {
      badge: "Vizyonumuz",
      title: "Mükemmel Bir Gülümseme",
      text: "Klinik Akademi'de sağlıklı, kendine güvenen gülümsemelerin genel refaha katkıda bulunduğu bir topluluk hayal ediyoruz. Mükemmeliyetçi, yenilikçi ve şefkat dolu çözümlerle, diş hekimliği alanında güvenilir liderler olmayı hedefliyoruz.",
      services: {
        patient: "Hasta Odaklı",
        technology: "Modern Teknoloji",
        expert: "Uzman Kadro"
      },
      badge_doctor: "Diş Hekimi"
    },
    treatments: {
      badge: "Tedavilerimiz",
      title: "Modern Diş Hekimliğinde",
      highlight: "Uzman Tedaviler",
      services: {
        implant: {
          title: "İmplant Tedavisi",
          description: "Ömür boyu garanti ile kalıcı diş tedavisi"
        },
        zirconium: {
          title: "Zirkonyum Kaplama",
          description: "Doğal görünümlü estetik kaplamalar"
        },
        orthodontics: {
          title: "Ortodonti",
          description: "Modern tekniklerle diş düzenleme"
        },
        aesthetic: {
          title: "Estetik Diş Hekimliği",
          description: "Gülüş tasarımı ve estetik uygulamalar"
        },
        whitening: {
          title: "Diş Beyazlatma",
          description: "Profesyonel beyazlatma sistemleri"
        },
        pediatric: {
          title: "Çocuk Diş Hekimliği",
          description: "Pedodonti uzman tedavileri"
        }
      },
      all_treatments: "Tüm Tedavilerimiz"
    }
  },
  treatments: {
    title: 'Tedaviler',
    subtitle: 'Profesyonel Hizmetler',
    basic: {
      title: 'Temel Tedaviler',
      implant: {
        title: 'İmplant Tedavisi',
        subtitle: 'Hassas Diş İmplantları'
      },
      surgery: {
        title: 'Ağız, Diş ve Çene Cerrahisi',
        subtitle: 'Uzman Cerrahi Müdahaleler'
      },
      rootCanal: {
        title: 'Kanal Tedavisi',
        subtitle: 'Endodontik Tedaviler'
      },
      prosthesis: {
        title: 'Protez Tedavisi',
        subtitle: 'Gelişmiş Protez Çözümleri'
      }
    },
    aesthetic: {
      title: 'Estetik ve Özel Tedaviler',
      whitening: {
        title: 'Diş Beyazlatma',
        subtitle: 'Profesyonel Diş Beyazlatma'
      },
      orthodontics: {
        title: 'Ortodonti',
        subtitle: 'Gülüş Tasarımı ve Düzeltme'
      },
      periodontics: {
        title: 'Periodonti',
        subtitle: 'Diş Eti Tedavileri'
      },
      pediatrics: {
        title: 'Pedodonti',
        subtitle: 'Çocuk Diş Hekimliği'
      },
      invisalign: {
        title: 'Invisalign Şeffaf Plak',
        subtitle: 'Görünmez Ortodonti'
      }
    },
    appointment: {
      title: 'Online Randevu',
      description: 'Hemen randevu alın, uzman kadromuzla tanışın.',
      button: 'Randevu Al'
    },
    implant: {
      title: 'İmplant Tedavisi',
      description: 'Eksik dişler için yüksek başarı oranına sahip kalıcı çözüm.'
    },
    orthodontics: {
      title: 'Ortodonti',
      description: 'Diş dizilim problemleri için etkili tedaviler.'
    },
    aesthetics: {
      title: 'Estetik Diş Hekimliği',
      description: 'Daha güzel ve özgüvenli bir gülüş için çözümler.'
    },
    whitening: {
      title: 'Diş Beyazlatma',
      description: 'Daha parlak bir gülüş için etkili beyazlatma.'
    },
    surgery: {
      title: 'Ağız, Diş ve Çene Cerrahisi',
      description: 'Cerrahi işlemler için uzman çözümler.'
    },
    pediatric: {
      title: 'Pedodonti',
      description: 'Çocuk dostu diş bakım hizmetleri.'
    },
    digital: {
      title: 'Dijital Diş Hekimliği',
      description: 'Modern teknolojilerle ileri düzey tedavi.'
    },
    general: {
      title: 'Genel Diş Hekimliği',
      description: 'Temel diş bakımı ve koruyucu tedaviler.'
    },
    rootCanal: {
      title: 'Kanal Tedavisi',
      description: 'Diş sinirlerinin tedavisi ve korunması.'
    },
    periodontics: {
      title: 'Periodonti',
      description: 'Sağlıklı diş etleri için özel tedaviler.'
    },
    prosthesis: {
      title: 'Protez Tedavisi',
      description: 'Dayanıklı ve estetik protez çözümleri.'
    },
    invisalign: {
      title: 'Invisalign Şeffaf Plak',
      description: 'Görünmez ortodonti ile düzgün dişler.'
    },
    viewAll: 'Tüm Tedavi Hizmetlerimizi Keşfedin',
    
    seo: {
      title: "Tüm Tedavilerimiz | Klinik Akademi",
      description: "Klinik Akademi'de uygulanan tüm diş tedavileri ve hizmetlerimiz hakkında detaylı bilgi alın."
    },
    hero: {
      title: "Kartal Soğanlık Diş Kliniği",
      subtitle: "Tüm Tedaviler",
      text1: "En son teknoloji ve uzman kadromuzla, ağız ve diş sağlığınız için kapsamlı tedavi seçenekleri sunuyoruz. Her tedavi planı, hastalarımızın ihtiyaçlarına özel olarak tasarlanır ve en yüksek standartlarda uygulanır.",
      text2: "Modern diş hekimliğinin tüm imkanlarını kullanarak, en son teknolojik gelişmeleri takip ediyor ve tedavi protokollerimizi sürekli güncelliyoruz. Konforunuzu ve sağlığınızı ön planda tutuyor, ileri teknolojiyi yumuşak bir dokunuşla birleştirerek pozitif bir deneyim yaşatıyoruz.",
      features: {
        expert: "Uzman Tedavi",
        patient: "Hasta Odaklı",
        quality: "Kaliteli Hizmet"
      },
      imageAlt: "Klinik Akademi Diş Tedavileri ve Hizmetleri",
      experience: "15+ Yıllık Deneyim"
    },
    grid: {
      badge: "Tedavilerimiz",
      title: "Klinik Akademi'de",
      subtitle: "Uzman Tedaviler"
    },
    items: {
      implant: {
        title: "İmplant Tedavisi",
        description: "Eksik dişleriniz için kalıcı ve doğal görünümlü çözüm",
        feature1: "Ömür boyu garanti",
        feature2: "Modern implant sistemleri",
        feature3: "Hızlı iyileşme süreci"
      },
      orthodontics: {
        title: "Ortodonti",
        description: "Modern tekniklerle düzgün ve estetik dişler",
        feature1: "Şeffaf plak tedavisi",
        feature2: "Invisalign",
        feature3: "Metal ve seramik braketler"
      },
      whitening: {
        title: "Diş Beyazlatma",
        description: "Profesyonel beyazlatma sistemleri ile parlak gülüşler",
        feature1: "Lazer beyazlatma",
        feature2: "Ofis tipi beyazlatma",
        feature3: "Ev tipi beyazlatma"
      },
      rootCanal: {
        title: "Kanal Tedavisi",
        description: "Doğal dişlerinizi korumak için uzman tedavi",
        feature1: "Mikroskop altında tedavi",
        feature2: "Ağrısız işlem",
        feature3: "Başarılı sonuçlar"
      },
      prosthesis: {
        title: "Protez Tedavisi",
        description: "Eksik dişleriniz için estetik ve fonksiyonel çözümler",
        feature1: "Zirkonyum protezler",
        feature2: "Hareketli protezler",
        feature3: "İmplant üstü protezler"
      },
      periodontics: {
        title: "Periodonti",
        description: "Diş eti hastalıkları ve estetik diş eti tedavileri",
        feature1: "Diş eti estetiği",
        feature2: "Diş eti hastalıkları tedavisi",
        feature3: "Kemik grefti"
      },
      pediatric: {
        title: "Pedodonti",
        description: "Çocuklarınız için özel ve güvenli tedavi",
        feature1: "Çocuk dostu ortam",
        feature2: "Uzman kadro",
        feature3: "Erken tedavi"
      },
      surgery: {
        title: "Ağız, Diş ve Çene Cerrahisi",
        description: "Karmaşık diş çekimi ve cerrahi işlemler",
        feature1: "20 yıllık deneyim",
        feature2: "Modern cerrahi teknikler",
        feature3: "Konforlu iyileşme"
      },
      invisalign: {
        title: "Invisalign Şeffaf Plak",
        description: "Görünmez ortodonti ile düzgün dişler",
        feature1: "Şeffaf plaklar",
        feature2: "Rahat kullanım",
        feature3: "Estetik görünüm"
      }
    },
    implantPage: {
      seo: {
        title: "Kartal Soğanlık'ta Diş İmplantı | Klinik Akademi",
        description: "Kartal Soğanlık'ta uzman kadromuzla diş implantı tedavisi. Modern implant sistemleri ve ömür boyu garanti ile kalıcı diş tedavisi.",
        keywords: "kartal diş implantı, soğanlık implant, kartal implant tedavisi, soğanlık diş implantı, kartal implant fiyatları"
      },
      hero: {
        badge: "Diş İmplantoloji",
        title: "Kartal Soğanlık Diş İmplantı",
        highlight: "Diş İmplantolojisi",
        description: "Diş implantları, doğal dişlerin işlevini ve hissini taklit ederek yaşamı değiştiren bir çözüm sunar. Bu dönüştürücü seçenek hakkında daha fazla bilgi edinin!",
        buttons: {
          appointment: "Randevu Al",
          details: "Detaylı Bilgi"
        },
        features: {
          expert: "Uzman Kadro",
          expertSmall: "Deneyimli Ekip",
          technology: "Modern Teknoloji",
          technologySmall: "Güvenli Tedavi",
          experience: "15+ Yıl Deneyim",
          experienceSmall: "Uzman Hizmet"
        },
        imageAlt: "Diş İmplantoloji"
      },
      header: {
        title: "Diş İmplantoloji",
        description: "Modern İmplant Teknolojisi ile Güvenilir Tedavi"
      },
      responsibilities: {
        badge: "Cerrahi Tedaviler",
        title: "Uzman Kadromuzun",
        highlight: "Sorumlulukları",
        description: "Diş implantlarını yapay diş kökleri olarak düşünün. Titanyum gibi biyouyumlu malzemelerden üretilen bu malzemeler, doğal olarak kaynaşmaları için cerrahi olarak çene kemiğinize yerleştirilir. Bu, kuronların, köprülerin veya takma dişlerin takılması için güçlü bir temel oluşturarak olağanüstü stabilite ve destek sunar.",
        imageAlt: 'Ağız, Diş ve Çene Cerrahisi Ekibi',
        items: [
          "Eksik dişlerin yerine doğal bir görünüm ve işlev sunan diş implantları",
          "Yüz dengesini, ısırma ve nefes almayı etkileyen düzensizliklerin giderilmesi",
          "Kaza veya yaralanma sonucu oluşan yüz travmasını onarıcı ameliyatlar",
          "Ağız kanserleri de dahil olmak üzere ağız hastalıklarının teşhisi ve tedavisi",
          "İşlev ve görünümü iyileştirmek için yarık dudak ve damak cerrahi onarımı",
          "Obstrüktif uyku apnesine yönelik müdahaleler",
          "Çene eklemi bozukluklarının tedavisi",
          "Rekonstrüktif ameliyatlar"
        ]
      },
      advantages: {
        badge: "Tedavi Avantajları",
        title: "Neden Cerrahi",
        highlight: "Tedavi?",
        description: "Modern teknoloji ve uzman kadromuzla sunduğumuz cerrahi tedavilerin avantajları",
        items: [
          "Karmaşık koşullar için uzman hekimler tarafından yaratıcı operasyonlar",
          "Tedaviye bütünsel yaklaşım ve fonksiyonel sonuçlar",
          "Ağız işlevselliğinde ve sağlığında iyileşmeler",
          "Yüz estetiğinde iyileşmeler",
          "Artan güven ve yaşam kalitesi"
        ]
      },
      suitable: {
        badge: "Tedavi Kriterleri",
        title: "Kimler İçin",
        highlight: "Uygundur?",
        description: "Diş implant tedavisinden kimler yararlanabilir?",
        items: [
          "Her yaştan yetişkinler (yüz gelişimi tamamlandıktan sonra)",
          "Genel ve ağız sağlığı iyi olan kişiler",
          "Bir veya daha fazla diş eksikliği olanlar",
          "Protezlerden veya köprülerden memnun olmayanlar",
          "Daha iyi çiğneme fonksiyonu ve konuşma arayışında olanlar",
          "Kemik kaybı endişesi taşıyanlar"
        ]
      },
      faq: {
        badge: "İmplant Tedavisi Hakkında",
        title: "Sık Sorulan",
        highlight: "Sorular",
        description: "İmplant tedavisi hakkında merak edilen soruların cevaplarını aşağıda bulabilirsiniz",
        items: [
          {
            question: "İmplant tedavisinin belli bir süresi var mıdır?",
            answer: "Kemiklerin implantları taşıyacak kadar sağlam olmaması durumunda uygulama öncesi uzman hekim tarafından kemik güçlendirme tedavisi yapılır. Ancak bu tedavi tamamlandıktan ve hasta implantlara uygun kemiklere sahip olduğundan emin olduktan sonra asıl tedaviye başlanır. İmplant tedavisinin tamamlanması için 2-4 aylık bir süreye ihtiyaç vardır."
          },
          {
            question: "İmplant tedavisi başarısız olabilir mi?",
            answer: "Kemiğe yerleştirilen implantların kemiğe kaynaması için 2 ila 4 ay kadar beklenmesine rağmen implantların hala kaynamadığı tespit edildiğinde söz konusu uygulamanın başarısız olduğu anlamına gelir. İmplant başarısızlığı olarak da bilinen bu sorunun temel nedeni kemiklerin implantı taşıyacak kadar güçlü olmamasıdır. Uygulama öncesinde bunun muayenesi uzman hekim tarafından yapılmalı ve gerekli görülmesi halinde ek tedaviler uygulanmalıdır."
          },
          {
            question: "İmplant uygulamasının ömrü ne kadardır?",
            answer: "İmplant dişler de orijinal dişler gibi bakımlarının düzenli yapılması ve ağız hijyenine dikkat edilmesi halinde uzun ömürlüdür. İmplant yerleştirildikten sonra uzun yıllar fonksiyon kaybı yaşamadan kullanmak, hatta ömür boyu sorunsuz ve sağlıklı bir şekilde ağızda tutmak mümkündür."
          },
          {
            question: "İmplant tedavisi için yaş sınırı var mıdır?",
            answer: "İmplant, diş eksikliği olan ve mevcut kemik yapısı tedaviye uygun olan herkese uygulanabilir. Ancak burada önemli olan kriter kemiklerin gelişimini tamamlamış olmasıdır. Gelişmekte olan kemiklere uygulanan implantlar bir süre sonra sorun yaratabilir ve bu implantların değiştirilmesi gerekebilir. Bu nedenle implant tedavisi için alt sınır 18 yaştır. 18 yaşını doldurmuş herkese implant yapılabilir."
          },
          {
            question: "İmplante dişlere özel bir bakım tedavisi var mıdır?",
            answer: "Tüm dişlerde olduğu gibi implant dişlerde de temizlik ve bakım oldukça önemlidir. Aslında bu dişlerin biraz daha önemli olduğu söylenebilir. İmplant dişleri düzenli olarak fırçalanmalı ve gerekiyorsa diş ipi kullanılmalıdır. Ağız hijyeninin tam olarak sağlanabilmesi için belirli aralıklarla gargara da yapılabilir. İmplant çevresinde biriken yiyecek artığı gibi maddeler uzun vadede diş taşı ve diş eti çekilmesi gibi sorunlara neden olabilir."
          }
        ]
      }
    },
    oralSurgeryPage: {
      seo: {
        title: 'Ağız, Diş ve Çene Cerrahisi | Kartal Soğanlık Diş Kliniği',
        description: 'Kartal ve Soğanlık bölgesinde ağız, diş ve çene cerrahisi uzmanlarımızla yüksek kaliteli diş tedavileri sunuyoruz. Ücretsiz muayene için hemen arayın.',
        keywords: 'ağız cerrahisi, diş cerrahisi, çene cerrahisi, yirmilik diş çekimi, gömülü diş çekimi, çene kistleri, kemik ogmentasyonu, kartal diş kliniği, soğanlık diş kliniği'
      },
      hero: {
        badge: 'Cerrahi Tedaviler',
        title: 'Kartal Soğanlık Ağız, Diş ve Çene Cerrahisi',
        highlight: 'Uzman Cerrahi Ekibi',
        description: 'Ağız, diş ve çene cerrahisi bölümümüzde deneyimli uzmanlarımız diş ve çene yapılarını etkileyen karmaşık sorunlara çözümler sunar. İmplant cerrahisi, gömülü diş çekimi, kemik ogmentasyonu ve çene kistleri tedavisinde uzmanız.',
        buttons: {
          appointment: 'Randevu Al',
          details: 'Detaylı Bilgi'
        },
        expertFeature: 'Uzman Cerrahi Ekibi',
        technologyFeature: 'İleri Teknoloji Cihazlar',
        experienceFeature: '15+ Yıllık Deneyim',
        features: {
          expert: 'Uzman Kadro',
          expertSmall: 'Deneyimli Ekip',
          technology: 'Modern Teknoloji',
          technologySmall: 'Güvenli Tedavi',
          experience: '15+ Yıl Deneyim',
          experienceSmall: 'Uzman Hizmet'
        },
        imageAlt: 'Ağız, Diş ve Çene Cerrahisi'
      },
      header: {
        title: 'Ağız, Diş ve Çene Cerrahisi',
        description: 'Yüz, Çene ve Ağız Hastalıkları İçin Özel Tedaviler'
      },
      responsibilities: {
        badge: 'Cerrahi Tedaviler',
        title: 'Uzman Kadromuzun',
        highlight: 'Sorumluluk Alanları',
        description: 'Ağız, diş ve çene cerrahisi uzmanlarımız tarafından gerçekleştirilen kapsamlı tedavi hizmetleri',
        imageAlt: 'Ağız, Diş ve Çene Cerrahisi Ekibi',
        items: [
          'Eksik dişlerin yerine doğal bir görünüm ve işlev sunan diş implantları',
          'Yüz dengesini, ısırma ve nefes almayı etkileyen düzensizliklerin giderilmesi',
          'Diş ve çene bölgesindeki kistlerin ve tümörlerin tedavisi',
          'Çenelerdeki kemik yetersizliklerinde kemik ogmentasyonu (kemik artırma) işlemleri',
          'Gömülü dişlerin (yirmilik diş) çekimi',
          'Travma sonrası oluşan çene ve yüz kırıklarının tedavisi',
          'Çene eklemi (TME) rahatsızlıklarının tedavisi',
          'Diş eti hastalıklarının cerrahi tedavisi'
        ]
      },
      advantages: {
        badge: 'Tedavi Avantajları',
        title: 'Neden Cerrahi',
        highlight: 'Tedavi Gerekli?',
        description: 'Ağız, diş ve çene cerrahisi tedavilerinin avantajları ve dental implantlarla gelen fonksiyonel ve estetik faydalar',
        items: [
          'Karmaşık koşullar için uzman hekimler tarafından yaratıcı operasyonlar',
          'Tedaviye bütünsel yaklaşım ve fonksiyonel sonuçlar',
          'Gelişmiş cerrahi teknikler ile minimal invaziv müdahaleler',
          'Konforlu bir hasta deneyimi için sedasyon seçenekleri',
          'Üç boyutlu planlama ile yüksek başarı oranları'
        ]
      },
      suitable: {
        badge: 'Tedavi Kriterleri',
        title: 'Kimler İçin',
        highlight: 'Uygundur?',
        description: 'Ağız, diş ve çene cerrahisi tedavisi aşağıdaki durumlarda gerekli olabilir:',
        items: [
          'Diş kaybı nedeniyle diş implantına ihtiyaç duyanlar',
          'Çene düzensizlikleri olanlar',
          'Gömülü yirmilik dişleri bulunanlar',
          'Çene ve diş bölgesinde kist veya tümör gelişenler',
          'Çene kemiği yetersizliği olanlar',
          'Diş eti hastalıkları için cerrahi tedaviye ihtiyaç duyanlar'
        ]
      },
      faq: {
        badge: 'Ağız, Diş ve Çene Cerrahisi Hakkında',
        title: 'Sık Sorulan',
        highlight: 'Sorular',
        items: [
          {
            question: 'Gömülü dişlerin çekilmesi gerekir mi?',
            answer: 'Hayır, her zaman değil. Gömülü diş kist veya tümör oluşumuyla ilişkiliyse, kırılmaya neden olma ihtimali varsa, apseye neden oluyorsa çekilmesi gerekir.'
          },
          {
            question: 'Tüm yirmi yaş dişleri çekilmeli mi?',
            answer: 'Hayır. Ağızda sorunsuz bir şekilde çıkan yirmilik dişler sağlıklı bir yapıya sahipse çekilmelerine gerek yoktur.'
          },
          {
            question: 'Gömülü diş çekimi nasıl uygulanır?',
            answer: 'Ağız diş ve çene cerrahisi uygulamaları arasında yer alan gömülü diş çekimi klinik ortamda ve lokal anestezi altında yapılmaktadır.'
          },
          {
            question: 'Gömülü diş çekilirken acı verir mi?',
            answer: 'İşlem lokal anestezi altında yapıldığı için ağrıya neden olmaz.'
          },
          {
            question: 'Tüm ağız içi cerrahi işlemler lokal anestezi ile yapılır mı?',
            answer: 'Hayır. Yapılacak işleme göre lokal veya genel anestezi uygulanabilir. Uzman diş hekimlerinin kontrolünde gerçekleştirilen operasyonlar çoğunlukla lokal anestezi altında gerçekleştirilir.'
          }
        ]
      }
    },
    rootCanalPage: {
      seo: {
        title: 'Kanal Tedavisi | Kartal Soğanlık Diş Kliniği',
        description: 'Kartal Soğanlık\'ta mikroskop altında konforlu ve ağrısız kanal tedavisi hizmetimiz. Sağlıklı dişler için hemen arayın.',
        keywords: 'kanal tedavisi, kartal kanal tedavisi, soğanlık kanal tedavisi, ağrısız kanal tedavisi, mikroskop altında kanal tedavisi, konforlu kanal tedavisi, diş siniri tedavisi'
      },
      hero: {
        badge: 'Endodontik Tedavi',
        title: 'Kartal Soğanlık Kanal Tedavisi',
        highlight: 'Sağlıklı Dişler',
        description: 'Endodonti, öncelikle sinirleri, kan damarlarını ve bağ dokularını barındıran pulpaya odaklanarak dişlerin iç yönlerinin tedavisinde uzmanlaşmıştır. Çürük, yaralanma veya kapsamlı diş prosedürleri sonucu oluşan pulpa iltihaplanması durumlarında uygulanan bu tedavi dişinizi kurtarır.',
        buttons: {
          appointment: 'Randevu Al',
          details: 'Detaylı Bilgi'
        },
        features: {
          expert: 'Uzman Kadro',
          expertSmall: 'Deneyimli Ekip',
          technology: 'Modern Teknoloji',
          technologySmall: 'Güvenli Tedavi',
          experience: '15+ Yıl Deneyim',
          experienceSmall: 'Uzman Hizmet'
        },
        imageAlt: 'Kanal Tedavisi'
      },
      header: {
        title: 'Kanal Tedavisi',
        description: 'Modern Endodontik Tedavi ile Diş Sağlığınızı Koruyun'
      },
      responsibilities: {
        badge: 'Endodontik Tedavi',
        title: 'Endodontistlerin',
        highlight: 'Uzmanlaştığı Konular',
        description: 'Uzman diş hekimlerimizin endodontik tedavi alanındaki uzmanlık konuları',
        imageAlt: 'Kanal Tedavisi Uzmanı',
        items: [
          'Kök kanal tedavilerinin yapılması: Zarar görmüş pulpanın çıkarılması, dişin sterilize edilmesi ve doldurulması',
          'Karmaşık diş sorunlarının ele alınması: İç diş erimesinin tedavisi, kırık dişlerin tedavisi',
          'Başarısız kök kanallarının tekrar ziyaret edilmesi: Yeniden tedavi prosedürleri',
          'Cerrahi müdahalelerin uygulanması: Apikal cerrahi, kök ucu rezeksiyonu'
        ]
      },
      consultation: {
        badge: 'Konsültasyon',
        title: 'Endodontik Konsültasyon',
        highlight: 'Endikasyonları',
        description: 'Devam eden diş ağrısı, hassasiyet, dişin yakınında şişlik veya çiğneme sırasında rahatsızlık hissediyorsanız, kapsamlı bir değerlendirme ve potansiyel tedavi için bir endodontiste danışmanız tavsiye edilebilir. Ek olarak, düzenli olarak ziyaret ettiğiniz diş hekiminiz dişin iç yapısında sorunlar tespit ederse endodontik konsültasyon önerebilir.'
      },
      advantages: {
        badge: 'Tedavi Avantajları',
        title: 'Endodontik Tedavinin',
        highlight: 'Avantajları',
        description: 'Modern endodontik tedavi ile sunduğumuz avantajlar',
        items: [
          'Doğal dişlerin korunması: Temel amaç, işlevsellik ve estetik çekicilik açısından tüm protez alternatiflerini geride bırakan doğal dişlerinizi korumaktır.',
          'İşlem sonrası rahatlık: Modern endodontik teknikler, tedavi sırasında ve sonrasında konforu en üst düzeye çıkarmak için etkili anestezi, ağrı yönetimi ve hassas teknikleri vurgular.',
          'Genel ağız sağlığının güvenliği: Dişleri çekilen hastalarda, komşu dişler kayabilir ve çiğneme işlevini bozabilir.',
          'Fonksiyonel dönüş: İyi bir şekilde tedavi edilen kök kanalından sonra, diş yeniden normal işlevine döner ve tipik olarak birkaç gün içinde tam çiğneme yeteneği geri gelir.',
          'Kozmetik bütünlük: Doğal dişin korunmasıyla hastalar, kendinden emin bir şekilde gülümseyerek kozmetik görünümlerini korumaya devam edebilirler.'
        ]
      },
      suitable: {
        badge: 'Tedavi Kriterleri',
        title: 'Kimler İçin',
        highlight: 'Uygundur?',
        description: 'Kanal tedavisi genellikle aşağıdaki durumlar için uygundur:',
        items: [
          'Derin çürükleri olanlar',
          'Diş kırılması yaşayanlar',
          'Tekrarlayan diş prosedürlerine ihtiyaç duyanlar',
          'Diş travması yaşayanlar',
          'Uzun süreli diş ağrısı çekenler',
          'Diş hassasiyeti yaşayanlar'
        ]
      },
      faq: {
        badge: 'Kanal Tedavisi Hakkında',
        title: 'Sık Sorulan',
        highlight: 'Sorular',
        items: [
          {
            question: 'Kanal tedavisi ağrılı mıdır?',
            answer: 'Modern diş hekimliğinde, kanal tedavisi genellikle ağrısız bir prosedürdür. İşlem bölgesi anestezi ile uyuşturulur ve hasta konforunu artırmak için çeşitli teknikler kullanılır. Tedavi sonrası birkaç gün boyunca hafif bir hassasiyet olabilir, ancak bu genellikle reçeteli veya reçetesiz ağrı kesicilerle kolayca yönetilebilir.'
          },
          {
            question: 'Kanal tedavisi sonrası diş canlılığını kaybeder mi?',
            answer: 'Evet, kanal tedavisi dişin pulpasını çıkardığı için, sinir ve kan damarları uzaklaştırılır ve diş artık canlı olarak kabul edilmez. Ancak, diş hala çene kemiğine bağlı kalmaya ve işlevini sürdürmeye devam eder. Tedavi edilmiş dişlere genellikle ek destek ve koruma sağlamak için kron takılması önerilir.'
          },
          {
            question: 'Kanal tedavisi ne kadar uzun sürer?',
            answer: 'Bir kanal tedavisi tipik olarak bir veya iki seans sürer, her seans yaklaşık 60-90 dakika arasında değişir. Gerekli seans sayısı, tedavi edilen dişin konumu, kök kanallarının karmaşıklığı ve enfeksiyonun varlığı gibi faktörlere bağlıdır.'
          },
          {
            question: 'Kanal tedavisi sonrası ne kadar süre geçtikten sonra yemek yiyebilirim?',
            answer: 'Ağzınızdaki anestezi etkisi geçtikten sonra yemek yiyebilirsiniz, bu genellikle randevudan sonraki birkaç saat içinde olur. Tedavi edilmiş diş tamamen iyileşene ve kalıcı dolgu veya kron ile restore edilene kadar, o tarafta sert veya yapışkan gıdalardan kaçınmak tavsiye edilir.'
          },
          {
            question: 'Kanal tedavisi yerine dişi çekmek daha iyi bir seçenek olabilir mi?',
            answer: 'Mümkün olduğunda, dişin çekilmesi yerine kanal tedavisi tercih edilir. Doğal dişinizi korumak her zaman en iyi seçenektir. Çekim, değiştirmeyi gerektiren bir boşluk bırakır (implant, köprü veya takma diş ile) ve komşu dişlerin kaymasına neden olabilir. Ancak, diş aşırı derecede zarar görmüşse veya prognoz zayıfsa, çekim gerekli olabilir.'
          }
        ]
      }
    },
    prosthesisPage: {
      seo: {
        title: 'Protez Tedavisi | Kartal Soğanlık Diş Kliniği',
        description: 'Kartal Soğanlık\'ta uzman kadromuzla modern protez tedavisi. Zirkonyum, porselen, hareketli ve implant üstü protez seçenekleriyle doğal görünüm.',
        keywords: 'protez, diş protezi, zirkonyum kaplama, porselen kaplama, hareketli protez, implant üstü protez, total protez, bölümlü protez, kartal protez, soğanlık protez'
      },
      hero: {
        badge: 'Protez Tedavisi',
        title: 'Kartal Soğanlık Protez Tedavisi',
        highlight: 'Diş Protezleri',
        description: 'Modern diş protezi teknolojileri ile doğal görünümlü ve konforlu protez çözümleri sunuyoruz. Uzman kadromuzla size özel protez seçenekleri için yanınızdayız.',
        buttons: {
          appointment: 'Randevu Al',
          details: 'Detaylı Bilgi'
        },
        features: {
          expert: 'Uzman Kadro',
          expertSmall: 'Deneyimli Ekip',
          technology: 'Modern Teknoloji',
          technologySmall: 'Güvenli Tedavi',
          experience: '15+ Yıl Deneyim',
          experienceSmall: 'Uzman Hizmet'
        },
        imageAlt: 'Diş Protez Tedavisi'
      },
      header: {
        title: 'Protez Tedavisi',
        description: 'Güvenli ve Doğal Görünümlü Protez Tedavisi'
      },
      responsibilities: {
        badge: 'Protez Tedavisi',
        title: 'Uzman Kadromuzla',
        highlight: 'Protez Tedavisi',
        description: 'Diş protezleri, eksik dişlerin yerine konularak çiğneme fonksiyonunun ve estetik görünümün yeniden kazandırılmasını sağlayan tedavi yöntemidir. Modern diş hekimliğinde kullanılan protezler, hem fonksiyonel hem de estetik açıdan mükemmel sonuçlar sunar.',
        imageAlt: 'Protez Tedavisi',
        items: [
          'Sabit protezler: Zirkonyum ve porselen kronlar ile doğal görünümlü kaplamalar',
          'Hareketli protezler: Total ve bölümlü protezlerle eksik dişlerin tamamlanması',
          'İmplant üstü protezler: İmplantlara bağlanan sabit veya çıkarılabilir protezler',
          'Laminate veneerler: Dişlerin ön yüzeyine yapılan ince porselen kaplamalar',
          'Geçici protezler: Tedavi süreçleri arasında kullanılan koruyucu protezler',
          'Dijital gülüş tasarımı: Bilgisayar destekli estetik diş protezleri'
        ]
      },
      advantages: {
        badge: 'Tedavi Avantajları',
        title: 'Protez Tedavisinin',
        highlight: 'Avantajları',
        description: 'Modern protez teknolojilerimizle sunduğumuz avantajlar',
        items: [
          'Çiğneme fonksiyonunun yeniden kazanılması: Protezler, eksik dişlerin neden olduğu çiğneme zorluklarını ortadan kaldırır.',
          'Yüz estetiğinin korunması: Eksik dişler yüz yapısında değişimlere neden olabilir, protezler bu değişimi önler.',
          'Konuşma fonksiyonunun iyileştirilmesi: Eksik dişler konuşma problemlerine yol açabilir, protezler bu sorunları çözer.',
          'Komşu dişlerin korunması: Protezler diğer dişlerin yer değiştirmesini ve çapraşıklığı önler.',
          'Özgüven artışı: Estetik görünüm ile sosyal yaşamda özgüven artışı sağlar.'
        ]
      },
      suitable: {
        badge: 'Tedavi Kriterleri',
        title: 'Kimler İçin',
        highlight: 'Uygundur?',
        description: 'Protez tedavisi genellikle aşağıdaki durumlar için uygundur:',
        items: [
          'Bir veya birden fazla diş eksikliği olanlar',
          'Aşırı yıpranmış dişleri olanlar',
          'Estetik görünümünü iyileştirmek isteyenler',
          'Çiğneme fonksiyonlarında sorun yaşayanlar',
          'Konuşma bozukluğu yaşayan hastalar',
          'İmplant tedavisi sonrası üst yapı ihtiyacı olanlar'
        ]
      },
      types: {
        badge: 'Protez Çeşitleri',
        title: 'Sunduğumuz',
        highlight: 'Protez Seçenekleri',
        description: 'Hastalarımızın ihtiyaçlarına göre sunduğumuz farklı protez alternatifleri',
        items: [
          {
            title: 'Zirkonyum Kronlar',
            description: 'Metal içermeyen, doğal diş görünümü sunan dayanıklı kaplamalar'
          },
          {
            title: 'Porselen Kronlar',
            description: 'Estetik açıdan mükemmel sonuçlar veren, ışık geçirgenliği yüksek kaplamalar'
          },
          {
            title: 'İmplant Üstü Protezler',
            description: 'İmplantlara bağlanan sabit veya çıkarılabilir protezler'
          },
          {
            title: 'Total Protezler',
            description: 'Tüm dişlerin kaybedildiği durumlarda kullanılan tam takım protezler'
          },
          {
            title: 'Bölümlü Protezler',
            description: 'Bazı dişlerin eksik olduğu durumlarda kullanılan kısmi protezler'
          },
          {
            title: 'Laminate Veneerler',
            description: 'Dişlerin ön yüzeyine yapıştırılan ince porselen kaplamalar'
          }
        ]
      },
      process: {
        badge: 'Tedavi Süreci',
        title: 'Protez Tedavisi',
        highlight: 'Aşamaları',
        description: 'Protez tedavisi genel olarak aşağıdaki aşamalardan oluşur:',
        items: [
          {
            title: 'Detaylı Muayene',
            description: 'Diş ve çene yapısının kapsamlı değerlendirilmesi'
          },
          {
            title: 'Tedavi Planı',
            description: 'Hastaya özel protez tedavi planının oluşturulması'
          },
          {
            title: 'Diş Hazırlığı',
            description: 'Gerekli dişlerin protez için hazırlanması'
          },
          {
            title: 'Ölçü Alma',
            description: 'Dijital veya geleneksel yöntemlerle hassas ölçülerin alınması'
          },
          {
            title: 'Geçici Protez',
            description: 'Kalıcı protez hazırlanırken geçici protezin uygulanması'
          },
          {
            title: 'Kalıcı Protez',
            description: 'Son prova ve kalıcı protezin uygulanması'
          }
        ]
      },
      faq: {
        badge: 'Protez Tedavisi Hakkında',
        title: 'Sık Sorulan',
        highlight: 'Sorular',
        items: [
          {
            question: 'Protezlerin kullanım ömrü ne kadardır?',
            answer: 'Protezlerin kullanım ömrü, malzemesine, bakımına ve kişinin ağız hijyenine bağlı olarak değişir. Sabit protezler (kronlar ve köprüler) uygun bakımla 10-15 yıl, zirkonyum kronlar 15-20 yıl, hareketli protezler ise ortalama 5-7 yıl kullanılabilir. Düzenli diş hekimi kontrolü ve uygun bakımla bu süreler uzayabilir.'
          },
          {
            question: 'Protez kullanımına alışmak ne kadar sürer?',
            answer: 'Protez kullanımına alışma süreci kişiden kişiye değişiklik gösterir. Sabit protezlere genellikle birkaç gün içinde alışılırken, hareketli protezlere alışmak 2-4 hafta sürebilir. Bu süreçte konuşma, çiğneme gibi fonksiyonların yeniden düzenlenmesi gerekebilir. Diş hekiminizin önerilerine uymanız adaptasyon sürecini hızlandıracaktır.'
          },
          {
            question: 'Protezlerin bakımı nasıl yapılır?',
            answer: 'Sabit protezler normal dişler gibi düzenli fırçalanmalı ve diş ipi kullanılmalıdır. Hareketli protezler ise her yemekten sonra çıkarılıp temizlenmeli, gece özel solüsyonlarda bekletilmelidir. Tüm protez türleri için düzenli diş hekimi kontrolleri önemlidir. Uygun bakım, protezlerin ömrünü uzatır ve ağız sağlığını korur.'
          },
          {
            question: 'Zirkonyum ve porselen kronlar arasındaki fark nedir?',
            answer: 'Zirkonyum kronlar daha dayanıklıdır ve metal içermez, bu nedenle alerji riski yoktur. Ayrıca ışık geçirgenliği doğal dişe daha yakındır. Porselen kronlar ise estetik açıdan mükemmel sonuçlar verir ancak zirkonyuma göre daha kırılgandır. Zirkonyum kronlar özellikle arka dişler için, porselen kronlar ise daha çok ön dişler için tercih edilebilir. Seçim, hastanın ihtiyaçlarına göre diş hekimi tarafından belirlenir.'
          },
          {
            question: 'Protez tedavisi ağrılı mıdır?',
            answer: 'Protez tedavisi sürecinde gerekli durumlarda lokal anestezi uygulanır, bu nedenle işlem sırasında ağrı hissedilmez. İşlem sonrasında hafif hassasiyet olabilir ancak bu genellikle birkaç gün içinde geçer. Hareketli protezlerde uyum sağlama sürecinde bazı bölgelerde baskı hissedilebilir, bu durumda diş hekiminiz gerekli ayarlamaları yapacaktır.'
          }
        ]
      }
    },
    teethWhiteningPage: {
      seo: {
        title: 'Diş Beyazlatma | Kartal Soğanlık Diş Kliniği',
        description: 'Kartal Soğanlık\'ta profesyonel diş beyazlatma tedavisi. Modern teknoloji ve uzman kadro ile güvenli beyazlatma, parlak gülüşler.',
        keywords: 'diş beyazlatma, kartal diş beyazlatma, soğanlık diş beyazlatma, ofis tipi beyazlatma, lazer diş beyazlatma, profesyonel diş beyazlatma'
      },
      hero: {
        badge: 'Diş Beyazlatma',
        title: 'Diş Beyazlatma Çözümleri',
        highlight: 'Profesyonel Beyazlatma',
        description: 'Modern teknolojimiz ve uzman kadromuzla, dişlerinizi güvenli bir şekilde beyazlatıyor, size daha parlak bir gülümseme sunuyoruz.',
        buttons: {
          appointment: 'Randevu Al',
          details: 'Detaylı Bilgi'
        },
        features: {
          expert: 'Uzman Kadro',
          expertSmall: 'Deneyimli Ekip',
          technology: 'Modern Teknoloji',
          technologySmall: 'Güvenli Tedavi',
          experience: '15+ Yıl Deneyim',
          experienceSmall: 'Uzman Hizmet'
        },
        imageAlt: 'Diş Beyazlatma',
        experience: 'Yıllık Deneyim'
      },
      header: {
        title: 'Diş Beyazlatma',
        description: 'Profesyonel Beyazlatma Tedavisi ile Parlak Gülüşler'
      },
      responsibilities: {
        badge: 'Beyazlatma Tedavileri',
        title: 'Diş Beyazlatma',
        highlight: 'Uzmanları',
        description: 'Profesyonel diş beyazlatma tedavileri ile daha parlak bir gülüşe kavuşun.',
        imageAlt: 'Diş Beyazlatma Uzmanı',
        items: [
          'Klinikte Beyazlatma',
          'Evde Beyazlatma Kitleri',
          'Diş Rengi Analizi',
          'Hassasiyet Kontrolü',
          'Beyazlatma Sonrası Bakım',
          'Düzenli Kontrol'
        ]
      },
      advantages: {
        badge: 'Tedavi Avantajları',
        title: 'Profesyonel Beyazlatmanın',
        highlight: 'Faydaları',
        description: 'Modern teknolojimiz ve uzman kadromuzla, dişlerinizi güvenli bir şekilde beyazlatıyor, size daha parlak bir gülümseme sunuyoruz.',
        items: [
          'Optimum ve Hızlı Sonuçlar',
          'Kişiye Özel Tedaviler',
          'Beyazlatma Etkilerinin Kalıcılığı',
          'Güvenlik ve Mesleki Denetim',
          'Kapsamlı Leke Çıkarma',
          'Artan Özgüven'
        ]
      },
      suitable: {
        badge: 'Tedavi Kriterleri',
        title: 'Diş Beyazlatma İşleminden',
        highlight: 'Kimler Faydalanabilir?',
        description: 'Diş beyazlatma tedavisinin uygun olduğu durumlar',
        items: [
          'Kahve, çay, şarap veya sigara nedeniyle lekelenmiş veya rengi solmuş dişleri olan kişiler',
          'Diş rengindeki doğal yaşlanma etkilerini tersine çevirmek isteyenler',
          'Özel bir durum veya kişisel tatmin için kozmetik bir iyileştirme arayan herkes',
          'Ağız sağlığı iyi olan ve estetik diş tedavileri isteyen hastalar'
        ]
      },
      faq: {
        badge: 'Diş Beyazlatma Hakkında',
        title: 'Sık Sorulan',
        highlight: 'Sorular',
        items: [
          {
            question: 'Farklı beyazlatma yöntemlerinden hangisini seçmeliyim?',
            answer: 'Bu sorunun cevabı dişlerinizdeki sararma ya da lekelenmenin boyutuna göre değişir. Uzman diş hekiminiz muayene sonucunda hangi tedavinin sizin için en etkili olduğuna karar verecektir.'
          },
          {
            question: 'Diş beyazlatmanın başarılı olması için kaç terapiye ihtiyaç vardır?',
            answer: 'Dişlerinizdeki renklenme probleminin büyüklüğüne göre seans sayısı artabilir veya azalabilir. Bazı sararma veya lekelenme vakaları tek seansta tedavi edilebilirken bazı durumlarda birden fazla seansa ihtiyaç duyulabilir.'
          },
          {
            question: 'Beyazlatma dişlerde hassasiyete neden olur mu?',
            answer: 'Hayır. Ancak tedavi öncesinde zaten dişlerde hassasiyet şikayeti varsa, uygulama sonrasında da bu durum devam edebilir. Beyazlatma işlemi öncesi uzman tarafından yapılan muayenede diş hassasiyeti tespit edilirse uygulama aşamasına geçilmeden önce hassasiyetin giderilmesi gerekmektedir.'
          },
          {
            question: 'Beyazlatma sonrası diş bakımı nasıl olmalıdır?',
            answer: 'Diş beyazlatma uygulamasının hemen ardından dişlerin bir süre normalden biraz daha fazla korunmasında fayda vardır. Sigara, kahve gibi sararmaya veya lekelenmeye neden olabilecek alışkanlıklardan bir ila iki hafta süreyle uzak durmak doğru olacaktır. Sonrasında tüm dişlerde olduğu gibi beyazlatma işlemine tabi tutulan dişlerde de rutin diş bakımına devam edilmeli ve ağız hijyeni asla ihmal edilmemelidir. Bu sayede beyazlatma uygulamasının etkisi uzun süre devam eder.'
          },
          {
            question: 'Beyazlatılan dişler zamanla rengini kaybeder mi?',
            answer: 'Hiçbir diş hekimi diş beyazlatma tedavisinin ömür boyu olduğunu iddia edemez. Beyazlatma işleminin dişlerdeki etkisini ne kadar süre koruyacağını belirleyen farklı faktörler vardır. Dişin mevcut yapısı, sararmaya ve lekelenmeye ne kadar yatkın olduğu, beslenme alışkanlıkları ve ağız ve diş bakımıyla ilgili alışkanlıklar bu konuda belirleyicidir. İyi bakım ve sağlıklı beslenme alışkanlıkları ile dişler beyazlığını uzun süre koruyabilir. Herhangi bir sebeple yeniden renklenme olması durumunda uzman hekim kontrolünde ek beyazlatma tedavisi uygulanabilir.'
          }
        ]
      }
    }
  },
  contact: {
    seo: {
      title: "Klinik Akademi | İletişim",
      description: "Klinik Akademi'ye hoş geldiniz. Randevu almak, bilgi edinmek veya herhangi bir sorunuz için bizimle iletişime geçebilirsiniz. Uzman ekibimiz size yardımcı olmak için hazır.",
      keywords: "iletişim, diş kliniği, randevu, adres, telefon, konum"
    },
    hero: {
      badge: "Bize Ulaşın",
      title: "Klinik Akademi",
      highlight: "İletişim Merkezi",
      text: "Klinik Akademi'ye hoş geldiniz. Randevu almak, bilgi edinmek veya herhangi bir sorunuz için bizimle iletişime geçebilirsiniz. Uzman ekibimiz size yardımcı olmak için hazır.",
      imageAlt: "Klinik Akademi İletişim"
    },
    info: {
      phone: {
        title: "Telefon",
        content: "+90 553 973 64 80"
      },
      email: {
        title: "E-posta",
        content: "info@klinikakademi.com"
      },
      openHours: {
        badge: "Haftanın 7 Günü Açık",
        content: "Her gün 09:00 - 00:00"
      }
    },
    map: {
      badge: "Konum Bilgisi",
      titlePrefix: "Bize Nasıl",
      titleHighlight: "Ulaşırsınız?",
      description: "Kliniğimize ulaşmak için haritayı kullanabilir veya aşağıdaki yönergeleri takip edebilirsiniz",
      address: {
        title: "Adres",
        value: "Orta, Yavuz Selim Cd. No:15-A Kartal/İstanbul"
      },
      phone: {
        title: "Telefon",
        value: "+90 553 973 64 80"
      },
      email: {
        title: "E-posta",
        value: "info@klinikakademi.com"
      },
      hours: {
        title: "Açık",
        value: "Her gün 09:00 - 00:00"
      },
      markerTitle: "Klinik Akademi"
    },
    directions: {
      title: "Ulaşım Bilgileri",
      car: {
        title: "Özel Araç ile",
        description: "E-5 karayolundan Kartal yönüne, TEM'den Kartal-Samandıra bağlantı yolunu kullanarak ulaşabilirsiniz.",
        time1: "E-5'ten 5 dakika",
        time2: "TEM'den 10 dakika",
        time3: "Sahil yolundan 8 dakika"
      },
      public: {
        title: "Toplu Taşıma ile",
        description: "Metro, otobüs ve minibüs hatlarıyla kolayca ulaşım sağlayabilirsiniz.",
        option1: "Kartal Metro İstasyonu - 5 dk yürüme",
        option2: "KM20, KM21, KM22 minibüs hatları",
        option3: "14K, 16KH, 17K otobüs hatları"
      },
      parking: {
        title: "Otopark",
        description: "Kliniğimizin çevresinde ücretsiz park alanları mevcuttur."
      },
      getDirections: "Yol Tarifi Al"
    },
    form: {
      badge: "İletişim Bilgileri",
      titlePrefix: "Bizimle",
      titleHighlight: "İletişime Geçin",
      description: "Sorularınız, önerileriniz veya randevu talepleriniz için bize ulaşın",
      card: {
        title: "Mesaj Gönderin",
        subtitle: "Aşağıdaki formu doldurarak bize mesaj gönderebilirsiniz."
      },
      fields: {
        name: {
          label: "Adınız Soyadınız",
          placeholder: "Adınız Soyadınız"
        },
        phone: {
          label: "Telefon Numaranız",
          placeholder: "Telefon Numaranız"
        },
        email: {
          label: "E-posta Adresiniz",
          placeholder: "E-posta Adresiniz"
        },
        subject: {
          label: "Konu",
          placeholder: "Konu Seçiniz",
          options: {
            appointment: "Randevu Talebi",
            info: "Bilgi Talebi",
            price: "Fiyat Bilgisi",
            other: "Diğer"
          }
        },
        message: {
          label: "Mesajınız",
          placeholder: "Mesajınız"
        },
        consent: {
          label: "Kişisel verilerimin işlenmesine izin veriyorum."
        }
      },
      buttons: {
        send: "Mesaj Gönder",
        sending: "Gönderiliyor..."
      },
      messages: {
        success: "Mesajınız başarıyla gönderildi.",
        error: "Mesajınız gönderilirken bir hata oluştu."
      }
    },
    details: {
      title: "İletişim Bilgilerimiz",
      subtitle: "Aşağıdaki kanallardan bize ulaşabilirsiniz",
      address: {
        title: "Adres",
        value: "Orta, Yavuz Selim Cd. No:15-A Kartal/İstanbul"
      },
      phone: {
        title: "Telefon",
        value: "+90 553 973 64 80"
      },
      email: {
        title: "E-posta",
        value: "info@klinikakademi.com"
      },
      hours: {
        title: "Çalışma Saatleri",
        value: "Her gün: 09:00 - 00:00"
      },
      social: {
        title: "Sosyal Medya"
      },
      formTitle: "Bizimle İletişime Geçin",
      formSubtitle: "Mesaj Gönderin",
      formDescription: "Sorularınız, önerileriniz veya randevu talepleriniz için bize ulaşın"
    }
  },
  appointment: {
    title: 'Online Randevu',
    description: 'Size en uygun zamanda randevunuzu oluşturalım',
    form: {
      name: 'Ad Soyad',
      namePlaceholder: 'Adınız ve Soyadınız',
      phone: 'Telefon',
      phonePlaceholder: '0(5XX) XXX XX XX',
      email: 'E-posta',
      emailPlaceholder: 'E-posta Adresiniz',
      treatment: 'Tedavi',
      treatmentPlaceholder: 'Tedavi Seçiniz',
      treatments: {
        implant: 'İmplant Tedavisi',
        rootcanal: 'Kanal Tedavisi',
        orthodontics: 'Ortodonti',
        invisalign: 'Invisalign Şeffaf Plak',
        periodontics: 'Periodontoloji',
        surgery: 'Ağız, Diş ve Çene Cerrahisi',
        aesthetics: 'Estetik Diş Hekimliği',
        whitening: 'Diş Beyazlatma',
        prosthesis: 'Protez Tedavileri',
        pediatric: 'Çocuk Diş Hekimliği',
        general: 'Genel Diş Hekimliği',
        emergency: 'Acil Diş Tedavisi'
      },
      message: 'Mesajınız',
      messagePlaceholder: 'Eklemek istediğiniz notlar...',
      sending: 'Gönderiliyor...',
      submit: 'Randevu Talebi Oluştur',
      privacy: 'Kişisel verileriniz gizlilik politikamız kapsamında korunmaktadır',
      success: 'Randevu talebiniz başarıyla oluşturuldu. En kısa sürede sizinle iletişime geçeceğiz.',
      error: 'Randevu talebiniz oluşturulurken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.'
    }
  },
  footer: {
    clinicName: 'Klinik Akademi',
    description: 'Modern diş hekimliği teknolojileri ve uzman kadromuzla, sağlıklı gülüşler için yanınızdayız.',
    quickLinks: 'Hızlı Erişim',
    team: 'Ekibimiz',
    services: 'Hizmetlerimiz',
    doctors: 'Doktorlarımız',
    treatmentsTitle: 'Tedavilerimiz',
    treatments: {
      implant: 'İmplant Tedavisi',
      whitening: 'Estetik Diş Beyazlatma',
      orthodontics: 'Modern Ortodonti',
      periodontal: 'Diş Eti Hastalıkları Tedavisi',
      surgery: 'Ağız, Diş ve Çene Cerrahisi'
    },
    contactInfo: 'İletişim',
    address: 'Yavuz Selim Cd. No:15-A Kartal/İstanbul',
    copyright: 'Copyright © {year} Klinik Akademi. Tüm hakları saklıdır.',
    designedBy: 'ResRam Dijital Dönüşüm Tarafından Tasarlanmıştır',
    followUs: 'Bizi Takip Edin',
    legal: 'Yasal',
    privacy: 'Gizlilik Politikası',
    cookies: 'Çerez Politikası'
  },
  team: {
    hero: {
      title: "Profesyonel",
      highlight: "Ekibimizle Tanışın",
      text1: "Deneyimli diş hekimleri tarafından yönetilen ve ilgili profesyoneller tarafından desteklenen özel ekibimiz, kişiselleştirilmiş ve üst düzey diş bakımı sağlamaya kendini adamıştır. Konforunuzu ve sağlığınızı ön planda tutuyor, ileri teknolojiyi yumuşak bir dokunuşla birleştirerek pozitif bir deneyim yaşatıyoruz.",
      text2: "Her bir ekip üyemiz alanında uzman, sürekli kendini geliştiren ve hasta memnuniyetini en üst düzeyde tutan profesyonellerden oluşmaktadır. Modern diş hekimliğinin tüm imkanlarını kullanarak, en son teknolojik gelişmeleri takip ediyor ve tedavi protokollerimizi sürekli güncelliyoruz.",
      services: {
        expert: "Uzman Kadro",
        patient: "Hasta Odaklı",
        quality: "Kaliteli Hizmet"
      },
      experience: "15+ Yıllık Deneyim"
    },
    section: {
      badge: "Profesyonel Ekip",
      title: "Uzman Kadromuzla",
      highlight: "Tanışın",
      teamAlt: "Klinik Akademi Ekibi"
    },
    join: {
      title1: "İşe mi ihtiyacınız var?",
      title2: "Ekibimize Katılın!",
      description: "Diş hekimliği dünyasında fark yaratmak isteyen tutkulu ve kendini adamış bir birey misiniz? Başka yerde arama! Büyüyen ekibimize katılacak ve değerli hastalarımıza olağanüstü bakım sunmamıza yardımcı olacak yetenekli bireyler arıyoruz.",
      button: "Bir iş için başvur",
      imageAlt: "Kariyer Fırsatları"
    },
    members: {
      owner: "Diş Hekimi ve Sahibi",
      gm: "Genel Müdür",
      bm: "İşletme Müdürü",
      dentist: "Diş Hekimi",
      chief: "Baş Asistan"
    }
  },
  media: {
    hero: {
      badge: "Medya Galerisi",
      title: "Klinik Akademi",
      highlight: "Medya Merkezi",
      text: "Klinik Akademi'nin modern tesisleri, başarılı tedavi sonuçları ve etkinliklerinden oluşan medya galerimize hoş geldiniz. Kliniğimizin kaliteli hizmet anlayışını ve profesyonel yaklaşımını yansıtan görsel içeriklerimizi keşfedin.",
      photos: "Fotoğraflar",
      videos: "Videolar",
      press: "Basın",
      facilities: "Modern Tesislerimiz"
    },
    categories: {
      title: "Medya Kategorileri",
      heading: "Klinik Akademi'den",
      highlight: "Son Gelişmeler",
      description: "Klinik haberlerimiz, etkinliklerimiz ve güncellemelerimiz",
      all: "Tümü",
      photos: "Fotoğraflar",
      videos: "Videolar",
      news: "Basın",
      social: "Sosyal Medya"
    },
    actions: {
      loadMore: "Daha Fazla Göster",
      loading: "Yükleniyor...",
      close: "Kapat"
    },
    items: {
      clinic: "Modern Kliniğimiz",
      clinicDesc: "Sağanlık'taki yeni kliniğimizin modern tedavi üniteleri",
      treatment: "Tedavi Odalarımız",
      treatmentDesc: "En son teknoloji ile donatılmış steril tedavi ortamımız",
      waiting: "Bekleme Salonumuz",
      waitingDesc: "Konforlu ve ferah bekleme alanımız",
      xray: "Dijital Röntgen",
      xrayDesc: "İleri teknoloji dijital görüntüleme sistemlerimiz",
      sterilization: "Sterilizasyon Ünitemiz",
      sterilizationDesc: "Yüksek standartlarda sterilizasyon ve hijyen"
    }
  },
  emergency: {
    title: 'Acil Diş Hekimi',
    status: 'Aktif - Hizmet Veriyoruz',
    call: 'Hemen Arayın',
    whatsapp: 'WhatsApp',
    instantResponse: 'Anında Dönüş',
    services: {
      service1: {
        title: '7 Gün Hizmet',
        description: 'Haftanın 7 Günü Açık'
      },
      service2: {
        title: 'Hızlı Müdahale',
        description: '30dk İçinde'
      },
      service3: {
        title: 'Uzman Ekip',
        description: 'Deneyimli Kadro'
      },
      service4: {
        title: 'Acil Bakım',
        description: 'Öncelikli Hizmet'
      }
    },
    form: {
      toggle: 'Acil Durum Bildir',
      close: 'Formu Kapat',
      name: 'Ad Soyad',
      namePlaceholder: 'Adınız ve Soyadınız',
      phone: 'Telefon',
      phonePlaceholder: '0(5XX) XXX XX XX',
      email: 'E-posta',
      emailPlaceholder: 'E-posta Adresiniz',
      details: 'Acil Durum Detayları',
      detailsPlaceholder: 'Acil durumunuzu kısaca açıklayın...',
      sending: 'Gönderiliyor...',
      submit: 'Acil Durum Bildir',
      success: 'Acil durum bildiriminiz başarıyla gönderildi. En kısa sürede sizinle iletişime geçeceğiz.',
      error: 'Acil durum bildiriminiz gönderilirken bir hata oluştu. Lütfen bizi arayınız.'
    }
  },
  cookieConsent: {
    title: 'Çerez Bildirimi',
    text: 'Web sitemiz, deneyiminizi geliştirmek için çerezleri kullanmaktadır. Sitemizi kullanmaya devam ederek çerez politikamızı kabul etmiş olursunuz.',
    detailButton: 'Detaylı Bilgi',
    acceptButton: 'Kabul Et'
  }
} 