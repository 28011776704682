<template>
  <header 
    class="header" 
    :class="{ 
      'header--scrolled': isScrolled,
      'header--transparent': isHomePage,
      'header--dark': !isHomePage
    }"
  >
    <div class="header__top" v-if="!isScrolled && !isMobile">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-9">
            <div class="header__contact-info">
              <a href="tel:+905539736480" class="header__contact-link">
                <i class="bi bi-telephone"></i>
                +90 553 973 64 80
              </a>
              <div class="header__contact-hours">
                <div class="hours-detail">
                  <div class="time-wrapper">
                    <span class="current-status">{{ $t('header.openNow') }}</span>
                    <span class="time">{{ $t('header.workingHours') }}</span>
                  </div>
                </div>
              </div>
              <div class="header__location">
                <i class="bi bi-geo-alt"></i>
                {{ $t('header.address') }}
              </div>
            </div>
          </div>
          <div class="col-lg-3 text-lg-end">
            <div class="d-flex justify-content-end align-items-center">
              <div class="header__social">
                <a href="https://www.facebook.com/KlinikAkademiAgizveDis/" class="header__social-link" target="_blank">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="https://www.instagram.com/klinikakademi/" class="header__social-link" target="_blank">
                  <i class="bi bi-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nav class="navbar navbar-expand-lg" :class="{ 'sticky-nav': isScrolled }">
      <div class="container">
        <router-link class="navbar-brand" :to="getPageRoute('home')">
          <div class="brand-wrapper">
            <span class="brand-text" :class="{ 'text-dark': isScrolled && isHomePage }">
              {{ $t('header.clinicName') }}
            </span>
          </div>
        </router-link>

        <button 
          class="navbar-toggler" 
          type="button" 
          :class="{ 'is-active': isMenuOpen }"
          @click="toggleMenu"
        >
          <div class="hamburger">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>
        </button>

        <div class="navbar-collapse" v-if="!isMobile">
          <ul class="navbar-nav ms-auto responsive-nav">
            <li class="nav-item">
              <router-link class="nav-link" :to="getPageRoute('home')">{{ $t('nav.home') }}</router-link>
            </li>
            <li class="nav-item dropdown">
              <a 
                class="nav-link dropdown-toggle" 
                href="#" 
                @click.prevent="toggleSubmenu('about')"
                @mouseenter="handleMouseEnter('about')"
                @mouseleave="handleMouseLeave('about')"
              >
                {{ $t('nav.aboutLink') }}
              </a>
              <div 
                class="mega-menu" 
                :class="{ 'show': submenus.about }"
                @mouseenter="handleMouseEnter('about')"
                @mouseleave="handleMouseLeave('about')"
              >
                <div class="mega-menu__content">
                  <div class="mega-menu__section">
                    <h4>{{ $t('nav.aboutMenu.corporate.title') }}</h4>
                    <router-link :to="getPageRoute('about')" class="mega-menu__link">
                      <i class="bi bi-building"></i>
                      <div>
                        <span>{{ $t('nav.aboutMenu.corporate.aboutUs.title') }}</span>
                        <small>{{ $t('nav.aboutMenu.corporate.aboutUs.subtitle') }}</small>
                      </div>
                    </router-link>
                    <router-link :to="getPageRoute('team')" class="mega-menu__link">
                      <i class="bi bi-people"></i>
                      <div>
                        <span>{{ $t('nav.aboutMenu.corporate.team.title') }}</span>
                        <small>{{ $t('nav.aboutMenu.corporate.team.subtitle') }}</small>
                      </div>
                    </router-link>
                  </div>
                  <div class="mega-menu__section">
                    <h4>{{ $t('nav.aboutMenu.clinics.title') }}</h4>
                    <a href="https://g.co/kgs/GXdnnYr" target="_blank" class="mega-menu__link">
                      <i class="bi bi-geo-alt"></i>
                      <div>
                        <span>{{ $t('nav.aboutMenu.clinics.kartal.title') }}</span>
                        <small>{{ $t('nav.aboutMenu.clinics.kartal.subtitle') }}</small>
                      </div>
                      <i class="bi bi-box-arrow-up-right link-icon"></i>
                    </a>
                    <a 
                      href="https://dikiliklinikakademi.com" 
                      target="_blank" 
                      class="mega-menu__link"
                    >
                      <i class="bi bi-geo-alt"></i>
                      <div>
                        <span>{{ $t('nav.aboutMenu.clinics.dikili.title') }}</span>
                        <small>{{ $t('nav.aboutMenu.clinics.dikili.subtitle') }}</small>
                      </div>
                      <i class="bi bi-box-arrow-up-right link-icon"></i>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a 
                class="nav-link dropdown-toggle" 
                href="#" 
                @click.prevent="toggleSubmenu('treatments')"
                @mouseenter="handleMouseEnter('treatments')"
                @mouseleave="handleMouseLeave('treatments')"
              >
                {{ $t('nav.treatmentsLink') }}
              </a>
              <div 
                class="mega-menu" 
                :class="{ 'show': submenus.treatments }"
                @mouseenter="handleMouseEnter('treatments')"
                @mouseleave="handleMouseLeave('treatments')"
              >
                <div class="mega-menu__content">
                  <div class="mega-menu__section">
                    <h4>{{ $t('nav.treatmentsMenu.basic.title') }}</h4>
                    <div class="mega-menu__grid">
                      <router-link :to="getTreatmentLink('implant')" class="mega-menu__link">
                        <i class="bi bi-plus-circle"></i>
                        <div>
                          <span>{{ $t('nav.treatmentsMenu.basic.implant.title') }}</span>
                          <small>{{ $t('nav.treatmentsMenu.basic.implant.subtitle') }}</small>
                        </div>
                      </router-link>
                      <router-link :to="getTreatmentLink('surgery')" class="mega-menu__link">
                        <i class="bi bi-scissors"></i>
                        <div>
                          <span>{{ $t('nav.treatmentsMenu.basic.surgery.title') }}</span>
                          <small>{{ $t('nav.treatmentsMenu.basic.surgery.subtitle') }}</small>
                        </div>
                      </router-link>
                      <router-link :to="getTreatmentLink('rootCanal')" class="mega-menu__link">
                        <i class="bi bi-activity"></i>
                        <div>
                          <span>{{ $t('nav.treatmentsMenu.basic.rootCanal.title') }}</span>
                          <small>{{ $t('nav.treatmentsMenu.basic.rootCanal.subtitle') }}</small>
                        </div>
                      </router-link>
                      <router-link :to="getTreatmentLink('prosthesis')" class="mega-menu__link">
                        <i class="bi bi-emoji-smile"></i>
                        <div>
                          <span>{{ $t('nav.treatmentsMenu.basic.prosthesis.title') }}</span>
                          <small>{{ $t('nav.treatmentsMenu.basic.prosthesis.subtitle') }}</small>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="mega-menu__section">
                    <h4>{{ $t('nav.treatmentsMenu.aesthetic.title') }}</h4>
                    <div class="mega-menu__grid">
                      <router-link :to="getTreatmentLink('whitening')" class="mega-menu__link">
                        <i class="bi bi-brightness-high"></i>
                        <div>
                          <span>{{ $t('nav.treatmentsMenu.aesthetic.whitening.title') }}</span>
                          <small>{{ $t('nav.treatmentsMenu.aesthetic.whitening.subtitle') }}</small>
                        </div>
                      </router-link>
                      <router-link :to="getTreatmentLink('orthodontics')" class="mega-menu__link">
                        <i class="bi bi-arrow-left-right"></i>
                        <div>
                          <span>{{ $t('nav.treatmentsMenu.aesthetic.orthodontics.title') }}</span>
                          <small>{{ $t('nav.treatmentsMenu.aesthetic.orthodontics.subtitle') }}</small>
                        </div>
                      </router-link>
                      <router-link :to="getTreatmentLink('periodontics')" class="mega-menu__link">
                        <i class="bi bi-heart-pulse"></i>
                        <div>
                          <span>{{ $t('nav.treatmentsMenu.aesthetic.periodontics.title') }}</span>
                          <small>{{ $t('nav.treatmentsMenu.aesthetic.periodontics.subtitle') }}</small>
                        </div>
                      </router-link>
                      <router-link :to="getTreatmentLink('pediatric')" class="mega-menu__link">
                        <i class="bi bi-emoji-smile"></i>
                        <div>
                          <span>{{ $t('nav.treatmentsMenu.aesthetic.pediatrics.title') }}</span>
                          <small>{{ $t('nav.treatmentsMenu.aesthetic.pediatrics.subtitle') }}</small>
                        </div>
                      </router-link>
                      <router-link :to="getTreatmentLink('invisalign')" class="mega-menu__link">
                        <i class="bi bi-shield"></i>
                        <div>
                          <span>{{ $t('nav.treatmentsMenu.aesthetic.invisalign.title') }}</span>
                          <small>{{ $t('nav.treatmentsMenu.aesthetic.invisalign.subtitle') }}</small>
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="mega-menu__section mega-menu__featured">
                    <div class="featured-card">
                      <i class="bi bi-calendar-check"></i>
                      <h4>{{ $t('nav.treatmentsMenu.appointment.title') }}</h4>
                      <p>{{ $t('nav.treatmentsMenu.appointment.description') }}</p>
                      <a href="https://api.whatsapp.com/send?phone=905539736480" class="featured-button">
                        {{ $t('nav.treatmentsMenu.appointment.button') }}
                        <i class="bi bi-arrow-right"></i>
                      </a>
                    </div>
                    <router-link :to="getTreatmentsPage()" class="view-all-treatments">
                      {{ $t('treatments.viewAll') }}
                      <i class="bi bi-grid-3x3-gap"></i>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="getPageRoute('media')">{{ $t('nav.media') }}</router-link>
            </li>

            <!--
            <li class="nav-item">
              <router-link class="nav-link" :to="getPageRoute('blog')">{{ $t('nav.blog') }}</router-link>
            </li>
            -->

            <li class="nav-item">
              <router-link class="nav-link" :to="getPageRoute('contact')">{{ $t('nav.contact') }}</router-link>
            </li>
            <li class="nav-item dropdown">
              <a 
                class="nav-link dropdown-toggle" 
                href="#" 
                @click.prevent="toggleSubmenu('lang')"
                @mouseenter="handleMouseEnter('lang')"
                @mouseleave="handleMouseLeave('lang')"
              >
                <i class="bi bi-translate"></i>
                {{ currentLanguage }}
              </a>
              <div 
                class="lang-dropdown" 
                :class="{ 'show': submenus.lang }"
                @mouseenter="handleMouseEnter('lang')"
                @mouseleave="handleMouseLeave('lang')"
              >
                <a 
                  v-for="lang in languages" 
                  :key="lang.code"
                  class="lang-option" 
                  :class="{ 'active': currentLanguage === lang.name }"
                  @click="changeLanguage(lang.code)"
                >
                  <img :src="lang.flag" :alt="lang.name">
                  {{ lang.name }}
                </a>
              </div>
            </li>
          </ul>
          <div style="color: white;" class="navbar-buttons">
            <a href="https://api.whatsapp.com/send?phone=905539736480" class="nav-button nav-button-solid">
              <i class="bi bi-calendar-check"></i>
              {{ $t('nav.appointmentBtn') }}
            </a>
          </div>
          <!-- Mobil için top bar bilgileri -->
          <div style="color: white;" class="mobile-top-info" v-if="isMobile">
            <div class="info-group">
              <div class="info-item">
                <i class="bi bi-clock"></i>
                <div>
                  <span class="status">{{ $t('header.openNow') }}</span>
                  <span>{{ $t('header.workingDays') }}</span>
                  <span>{{ $t('header.workingTime') }}</span>
                </div>
              </div>
              
              <div style="color: white;" class="info-item">
                <i class="bi bi-geo-alt"></i>
                <span>{{ $t('header.address') }}</span>
              </div>
            </div>
            
            <div class="mobile-social">
              <a href="https://www.facebook.com/KlinikAkademiAgizveDis/" target="_blank" class="social-link"><i class="bi bi-facebook"></i></a>
              <a href="https://www.instagram.com/klinikakademi/" target="_blank" class="social-link"><i class="bi bi-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- Mobil Menü -->
    <MobileMenu 
      :is-open="isMenuOpen"
      @close="closeMenu"
    />
  </header>
</template>

<script>
import { useRouter } from 'vue-router'
import MobileMenu from './MobileMenu.vue'
import { setLocale, getLocale } from '@/plugins/i18n'
import { getTreatmentRoute, getTreatmentsBaseSlug, getPageRoute } from '@/utils/slug'

export default {
  name: 'TheNavbar',
  components: {
    MobileMenu
  },
  created() {
    const router = useRouter()
    router.afterEach(() => {
      this.closeAllMenus()
      this.isMenuOpen = false
    })
  },
  data() {
    return {
      isScrolled: false,
      isMenuOpen: false,
      submenus: {
        about: false,
        treatments: false,
        lang: false
      },
      currentLanguage: 'Türkçe',
      isMobile: false,
      hoverTimeout: null,
      languages: [
        { 
          code: 'tr', 
          name: 'Türkçe',
          flag: require('@/assets/images/flags/tr.svg')
        },
        { 
          code: 'en', 
          name: 'English',
          flag: require('@/assets/images/flags/en.svg')
        },
        { 
          code: 'de', 
          name: 'Deutsch',
          flag: require('@/assets/images/flags/de.svg')
        }
      ]
    }
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 50
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
      document.body.style.overflow = this.isMenuOpen ? 'hidden' : ''
    },
    toggleSubmenu(menu) {
      Object.keys(this.submenus).forEach(key => {
        if (key !== menu) this.submenus[key] = false
      })
      this.submenus[menu] = !this.submenus[menu]
    },
    handleMouseEnter(menu) {
      if (!this.isMobile) {
        if (this.hoverTimeout) {
          clearTimeout(this.hoverTimeout)
        }
        Object.keys(this.submenus).forEach(key => {
          if (key !== menu) this.submenus[key] = false
        })
        this.submenus[menu] = true
      }
    },
    handleMouseLeave(menu) {
      if (!this.isMobile) {
        this.hoverTimeout = setTimeout(() => {
          this.submenus[menu] = false
        }, 200)
      }
    },
    closeAllMenus() {
      Object.keys(this.submenus).forEach(key => {
        this.submenus[key] = false
      })
    },
    changeLanguage(code) {
      const lang = this.languages.find(l => l.code === code)
      if (lang) {
        this.currentLanguage = lang.name
        setLocale(code)
      }
      this.submenus.lang = false
    },
    resetMenus() {
      this.isMenuOpen = false
      Object.keys(this.submenus).forEach(key => {
        this.submenus[key] = false
      })
      document.body.style.overflow = ''
    },
    checkMobile() {
      this.isMobile = window.innerWidth < 992
    },
    closeMenu() {
      this.isMenuOpen = false
      document.body.style.overflow = ''
    },
    getTreatmentLink(treatmentKey) {
      return getTreatmentRoute(treatmentKey)
    },
    getTreatmentsPage() {
      return '/' + getTreatmentsBaseSlug();
    },
    getPageRoute(pageKey) {
      return getPageRoute(pageKey);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.resetMenus)
    window.addEventListener('resize', this.checkMobile)
    this.checkMobile()
    
    // Mevcut dili ayarla
    const currentLocale = getLocale()
    const lang = this.languages.find(l => l.code === currentLocale)
    if (lang) {
      this.currentLanguage = lang.name
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.resetMenus)
    window.removeEventListener('resize', this.checkMobile)
    document.body.style.overflow = ''
  },
  computed: {
    isHomePage() {
      return this.$route.path === '/'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables';

:root {
  --mobile-menu-radius: 24px;
  --mobile-menu-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1);
  --mobile-link-radius: 14px;
  --mobile-link-border: 1px solid rgba(255, 255, 255, 0.2);
  --mobile-link-bg: rgba(20, 20, 25, 0.8);
  --mobile-menu-backdrop: linear-gradient(135deg, rgba(15, 15, 20, 0.95), rgba(20, 20, 25, 0.98));
  --mobile-menu-animation: cubic-bezier(0.4, 0, 0.2, 1);
  --menu-item-hover: #{rgba(#4A90E2, 0.9)};
  --menu-item-active: #FFD700;
  --menu-item-bg: rgba(30, 30, 35, 0.8);
  --text-color-primary: #{rgba(255, 255, 255, 0.9)};
  --text-color-secondary: #{rgba(255, 255, 255, 0.7)};
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: all 0.3s ease;

  &--transparent {
    background: transparent;
    
    .navbar {
      background: transparent;
      
      &.sticky-nav {
        background: rgba(255, 255, 255, 0.95);
        backdrop-filter: blur(10px);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .nav-link {
          color: #1a1a1a;
          
          &:hover {
            color: var(--primary-color);
          }
        }
        
        .navbar-brand {
          color: #1a1a1a;
        }
        
        .dropdown-menu {
          background: rgba(255, 255, 255, 0.95);
          border: 1px solid rgba(0, 0, 0, 0.1);
          
          .dropdown-item {
            color: #1a1a1a;
            
            &:hover {
              background: rgba(0, 0, 0, 0.05);
              color: var(--primary-color);
            }
          }
        }
        
        .navbar-toggler {
          .line {
            background-color: #1a1a1a;
          }
        }
      }
      
      .nav-link {
        color: var(--light-color);
        
        &:hover {
          color: var(--primary-color);
        }
      }
      
      .navbar-brand {
        color: var(--light-color);
      }

      .dropdown-menu {
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        border: 1px solid rgba(255, 255, 255, 0.2);
        
        .dropdown-item {
          color: var(--light-color);
          
          &:hover {
            background: rgba(255, 255, 255, 0.2);
            color: var(--primary-color);
          }
        }
      }
    }

    .header__top {
      background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(10px);
      
      .header__contact-link,
      .header__location,
      .header__social-link {
        color: var(--light-color);
        opacity: 0.9;
        
        &:hover {
          opacity: 1;
        }
      }
    }

    &.header--scrolled {
      background: transparent;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    }
  }

  &--dark {
    background: #1a1a1a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
    .navbar {
      background: #1a1a1a;
      
      .nav-link {
        color: var(--light-color);
        
        &:hover {
          color: var(--primary-color);
        }
      }
      
      .navbar-brand {
        color: var(--light-color);
      }
    }
    
    .dropdown-menu {
      background: #1a1a1a;
      border-color: rgba(255, 255, 255, 0.1);
      
      .dropdown-item {
        color: var(--light-color);
        
        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: var(--primary-color);
        }
      }
    }
  }

  &--scrolled {
    &:not(.header--transparent) {
      background: #1a1a1a;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }

  &__top {
    background: rgba(0, 0, 0, 0.8);
    padding: 0.5rem 0;
    color: var(--light-color);
    font-size: 0.9rem;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .container {
      @media (min-width: 1024px) and (max-width: 1366px) {
        padding: 0 1rem;
        max-width: 1400px;
      }
    }

    .row {
      @media (min-width: 1024px) and (max-width: 1366px) {
        > div {
          padding: 0 0.5rem;
        }
      }
    }

    @media (max-width: $breakpoint-lg) {
      display: none;
    }
  }

  &__contact-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: nowrap;
    flex-shrink: 0;
    width: 100%;
    justify-content: flex-start;

    @media (min-width: 1024px) and (max-width: 1366px) {
      gap: 0.35rem;
    }
  }

  &__contact-link {
    color: var(--light-color);
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.4rem 0.8rem;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    white-space: nowrap;
    min-width: auto;
    font-size: 0.85rem;
    flex-shrink: 0;

    i {
      font-size: 1rem;
      color: var(--primary-color);
      transition: all 0.3s ease;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.15);
      border-color: rgba(255, 255, 255, 0.2);
      transform: translateY(-1px);
      
      i {
        transform: scale(1.1);
        color: var(--light-color);
      }
    }

    @media (min-width: 1024px) and (max-width: 1366px) {
      padding: 0.35rem 0.6rem;
      font-size: 0.8rem;
      gap: 0.5rem;

      i {
        font-size: 0.9rem;
      }
    }
  }

  &__contact-hours {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.4rem 0.8rem;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    min-width: auto;
    flex-shrink: 0;

    .hours-detail {
      .time-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        white-space: nowrap;
        
        .current-status {
          color: #4ADE80;
          font-size: 0.75rem;
          padding: 2px 6px;
        }
        
        .time {
          font-size: 0.75rem;
          br {
            display: none;
          }
        }
      }
    }

    @media (min-width: 1024px) and (max-width: 1366px) {
      padding: 0.35rem 0.6rem;
      font-size: 0.8rem;
      gap: 0.5rem;

      .hours-detail {
        .time-wrapper {
          gap: 0.5rem;

          .time {
            font-size: 0.75rem;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &__location {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.4rem 0.8rem;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    justify-content: center;
    transition: all 0.3s ease;
    font-size: 0.75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: auto;
    flex-shrink: 1;

    i {
      font-size: 1rem;
      color: var(--primary-color);
      flex-shrink: 0;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.15);
      border-color: rgba(255, 255, 255, 0.2);
      transform: translateY(-1px);
    }

    @media (min-width: 1024px) and (max-width: 1366px) {
      font-size: 0.75rem;
      padding: 0.35rem 0.6rem;
      min-width: auto;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-end;
    flex-shrink: 0;

    @media (min-width: 1024px) and (max-width: 1366px) {
      gap: 0.35rem;
      margin-left: 0;
      padding-right: 0;

      &-link {
        width: 26px;
        height: 26px;
      }
    }

    &-link {
      color: var(--light-color);
      text-decoration: none;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);
      transition: all 0.3s ease;

      i {
        font-size: 0.8rem;
        transition: all 0.3s ease;
      }

      &:hover {
        background: var(--primary-color);
        border-color: var(--primary-color);
        transform: translateY(-2px);
        
        i {
          transform: scale(1.1);
        }
      }
    }
  }
}

.navbar {
  padding: 1rem 0;
  transition: all 0.3s ease;
  background: transparent;

  &.sticky-nav {
    animation: fadeInNavbar 0.3s ease forwards;
  }

  .brand-wrapper {
    .logo {
      height: 40px;
      width: auto;
      transition: all 0.3s ease;
    }

    .brand-text {
      font-family: $font-family-heading;
      font-size: 1.75rem;
      font-weight: 700;
      color: $light-color;
      transition: all 0.3s ease;

      &.text-dark {
        color: #1a1a1a;
      }

      @media (max-width: 1199px) {
        font-size: 1.5rem;
      }

      @media (max-width: 1100px) {
        font-size: 1.25rem;
      }
    }
  }

  .responsive-nav {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 1280px) {
      margin-left: 1rem;
    }
  }

  .nav-link {
    color: $light-color;
    font-weight: 500;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
    white-space: nowrap;

    @media (max-width: 1280px) {
      padding: 0.5rem 0.75rem;
      font-size: 0.9rem;
    }

    @media (max-width: 1100px) {
      padding: 0.5rem 0.5rem;
      font-size: 0.85rem;
    }

    &:hover {
      color: #4A90E2;
    }

    &.router-link-active {
      color: #FFD700;
      font-weight: 600;
    }
  }

  .navbar-toggler {
    color: $light-color;
    border: none;
    padding: 0;
    font-size: 2rem;
  }

  .navbar-buttons {
    display: flex;
    gap: 0.75rem;

    @media (max-width: 1280px) {
      gap: 0.5rem;
    }

    @media (max-width: 1100px) {
      gap: 0.25rem;
    }
  }

  .nav-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    font-weight: 500;
    transition: all 0.3s ease;
    text-decoration: none;
    white-space: nowrap;

    @media (max-width: 1280px) {
      padding: 0.5rem 0.75rem;
      font-size: 0.9rem;
    }

    @media (max-width: 1100px) {
      padding: 0.4rem 0.6rem;
      font-size: 0.8rem;
    }

    i {
      font-size: 1.1rem;

      @media (max-width: 1280px) {
        font-size: 1rem;
      }

      @media (max-width: 1100px) {
        font-size: 0.9rem;
      }
    }

    &-outline {
      color: $light-color;
      border: 2px solid $light-color;
      &:hover {
        background: $light-color;
        color: $primary-color;
      }
    }

    &-solid {
      background: $light-color;
      color: $primary-color;
      border: 2px solid $light-color;
      &:hover {
        background: darken($light-color, 10%);
        border-color: darken($light-color, 10%);
      }
    }
  }

  @media (max-width: $breakpoint-lg) {
    background: $light-color;
    padding: 1rem 0;

    .navbar-collapse {
      position: fixed;
      top: 6rem;
      left: 1rem;
      right: 1rem;
      background: #000000f5;
      backdrop-filter: blur(20px);
      -webkit-backdrop-filter: blur(20px);
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-shadow: 1px 0px 20px 20px rgb(0 0 0 / 33%), 
        inset 0px 0px 62px -9px rgb(0 0 0);
      padding: 1.25rem;
      border-radius: 30px;
      transform: translateY(-10px);
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s var(--mobile-menu-animation);
      max-height: calc(100vh - 8rem);
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      display: flex;
      flex-direction: column;

      &::-webkit-scrollbar {
        display: none;
      }

      &.show {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }

      .navbar-nav {
        padding-bottom: 0.5rem;
        width: 100%;

        .nav-item {
          .nav-link {
            padding: 1rem 1.5rem;
            margin: 0.25rem 0;
            border-radius: 16px;
            background: var(--mobile-link-bg);
            color: white;
            border: 1px solid rgba(255, 255, 255, 0.2);
            box-shadow: 
              0 4px 8px rgb(255 255 255 / 68%),
              inset 0 0 0 1px rgb(255 255 255 / 95%);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            font-weight: 500;
            letter-spacing: 0.3px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            &:hover {
              background: linear-gradient(135deg, #0c484b, rgb(0 0 0));
              border-color: rgb(255 255 255 / 43%);
              transform: translateY(-2px);
              box-shadow: 0 8px 16px rgb(255 255 255 / 83%);
            }
            
            &.router-link-active {
              background: linear-gradient(135deg, #FFD700, darken(#FFD700, 10%));
              color: $text-color;
              border-color: rgba(255, 255, 255, 0.4);
              font-weight: 600;
            }
          }
        }

        .nav-item.dropdown {
          .nav-link {
            i {
              font-size: 1rem;
              transition: transform 0.3s ease;
              margin-left: auto;
              opacity: 0.7;
              color: var(--text-color-secondary);
            }
          }
          
          .dropdown-menu {
            padding: 0.5rem;
            margin: 0.25rem 0 0.25rem 1.5rem;
            border: none;
            background: transparent;
            
            .dropdown-item {
              padding: 0.75rem 1.25rem;
              margin: 0.25rem 0;
              border-radius: 14px;
              background: var(--menu-item-bg);
              color: white;
              border: 1px solid rgba(255, 255, 255, 0.2);
              font-size: 0.95rem;
              font-weight: 500;
              transition: all 0.3s ease;
              white-space: normal;
              letter-spacing: 0.2px;
              
              &:hover {
                background: linear-gradient(135deg, #4A90E2, darken(#4A90E2, 15%));
                color: $light-color;
                border-color: rgba(255, 255, 255, 0.3);
              }

              &.router-link-active {
                background: linear-gradient(135deg, #FFD700, darken(#FFD700, 10%));
                color: $text-color;
                border-color: rgba(255, 255, 255, 0.4);
                font-weight: 600;
              }
            }
          }
        }
      }

      .navbar-buttons {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px solid rgba(255, 255, 255, 0.08);
        margin-bottom: 0.5rem;
        display: flex;
        gap: 0.75rem;

        .nav-button {
          padding: 1rem;
          border-radius: 16px;
          font-size: 0.9rem;
          font-weight: 600;
          letter-spacing: 0.3px;
          transition: all 0.3s var(--mobile-menu-animation);
          background: var(--menu-item-bg);
          color: white;
          border: 1px solid rgba(255, 255, 255, 0.2);
          box-shadow: 
            0 4px 8px rgba(0, 0, 0, 0.2),
            inset 0 0 0 1px rgba(255, 255, 255, 0.1);
          
          &:hover {
            background: linear-gradient(135deg, #4A90E2, darken(#4A90E2, 15%));
            color: $light-color;
            border-color: rgba(255, 255, 255, 0.3);
            transform: translateY(-2px);
            box-shadow: 0 8px 16px rgba(#4A90E2, 0.3);
          }
          
          i {
            font-size: 1.1rem;
            margin-right: 0.5rem;
          }
        }
      }
    }

    .navbar-toggler {
      border: none;
      padding: 0.75rem;
      background: var(--primary-color);
      border-radius: 12px;
      transition: all 0.3s ease;
      
      &:hover {
        background: darken($primary-color, 10%);
        transform: translateY(-1px);
      }
      
      .hamburger {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 24px;
        
        .line {
          width: 100%;
          height: 2px;
          border-radius: 2px;
          background-color: white;
          transition: all 0.3s var(--mobile-menu-animation);
        }
      }

      &.is-active {
        .line {
          &:nth-child(1) {
            transform: translateY(8px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translateY(-8px) rotate(-45deg);
          }
        }
      }
    }

    .brand-wrapper {
      .logo {
        height: 40px;
        width: auto;
        transition: all 0.3s ease;
      }
    }
  }

  // Desktop dropdown styles
  @media (min-width: $breakpoint-lg) {
    .dropdown {
      position: static;
      
      .dropdown-toggle {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        
        i {
          font-size: 0.8rem;
          transition: transform 0.3s ease;
        }
      }
      
      .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 220px;
        padding: 0.5rem;
        margin-top: 0.5rem;
        background: var(--menu-item-bg);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        box-shadow: 
          0 20px 40px rgba(0, 0, 0, 0.1),
          inset 0 0 0 1px rgba(255, 255, 255, 0.1);
        border-radius: var(--mobile-menu-radius);
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
        transition: all 0.3s ease;
        z-index: 1000;
        
        &.show {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
        
        .dropdown-item {
          padding: 0.75rem 1rem;
          color: var(--text-color-primary);
          border-radius: var(--mobile-link-radius);
          transition: all 0.3s ease;
          
          &:hover {
            background: linear-gradient(135deg, #4A90E2, darken(#4A90E2, 8%));
            color: $light-color;
          }

          &.router-link-active {
            background: linear-gradient(135deg, #FFD700, darken(#FFD700, 10%));
            color: $text-color;
            font-weight: 600;
          }
        }
      }
    }
    
    .language-selector {
      margin-left: 1rem;
      
      .dropdown-menu {
        min-width: 120px;
        
        .dropdown-item {
          text-align: center;
        }
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1366px) {
    .container {
      padding: 0 1rem;
      max-width: 100%;
    }
    
    .brand-wrapper {
      .logo {
        height: 40px;
        width: auto;
      }
    }
    
    .nav-link {
      padding: 0.4rem 0.8rem;
      font-size: 0.9rem;
    }
    
    .navbar-buttons {
      gap: 0.5rem;
      margin-left: 0.5rem;
      
      .nav-button {
        padding: 0.4rem 0.8rem;
        font-size: 0.8rem;
        white-space: nowrap;
        min-width: auto;

        i {
          font-size: 0.9rem;
        }
      }
    }
    
    .dropdown-menu {
      min-width: 200px;
      
      .dropdown-item {
        padding: 0.6rem 0.8rem;
        font-size: 0.85rem;
      }
    }
  }
}

// Container genel ayarı
@media (min-width: 1024px) and (max-width: 1366px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.mobile-top-info {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  
  .info-group {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  
  .info-item {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    color: var(--text-color-primary);
    
    i {
      font-size: 1.2rem;
      color: var(--primary-color);
    }
    
    .status {
      display: block;
      color: #4ADE80;
      font-size: 0.8rem;
      margin-bottom: 0.25rem;
    }
    
    span {
      display: block;
      font-size: 0.9rem;
      line-height: 1.4;
    }
  }

  .mobile-social {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    
    .social-link {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      color: var(--text-color-primary);
      transition: all 0.3s ease;
      
      &:hover {
        background: var(--primary-color);
        border-color: var(--primary-color);
        transform: translateY(-2px);
        
        i {
          transform: scale(1.1);
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1100px) {
  .navbar {
    .container {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    
    .navbar-nav {
      margin-left: 0.5rem;
    }
    
    .navbar-buttons {
      .nav-button {
        min-width: auto;
        padding: 0.35rem 0.5rem;
      }
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
    
    @media (max-width: 1280px) {
      padding-right: 0.6rem !important;
      padding-left: 0.6rem !important;
    }
    
    @media (max-width: 1100px) {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
  }

  .dropdown {
    position: relative !important;
    
    .dropdown-toggle {
      padding-right: 0.5rem !important;
      
      @media (max-width: 1100px) {
        padding-right: 0.25rem !important;
      }
    }
  }
}

@keyframes fadeInNavbar {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.mega-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 280px;
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
  box-shadow: 0 15px 40px rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.05);
  margin-top: 1rem;
  max-width: 95vw;
  overflow: hidden;
  
  @media (max-width: 1366px) {
    padding: 1.25rem;
  }
  
  @media (max-width: 1100px) {
    padding: 1rem;
  }
  
  &.show {
    opacity: 1;
    visibility: visible;
  }
  
  &__content {
    display: flex;
    gap: 4rem;
    
    // Tedaviler menüsü için daha geniş görünüm
    .dropdown:nth-child(2) & {
      min-width: 900px;
      max-width: 95vw;
      
      @media (max-width: 1500px) {
        min-width: 850px;
        gap: 1.8rem;
      }
      
      @media (max-width: 1366px) {
        min-width: 800px;
        gap: 1.5rem;
      }
      
      @media (max-width: 1200px) {
        min-width: 730px;
        gap: 1rem;
      }
      
      @media (max-width: 1100px) {
        min-width: 660px;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      
      @media (max-width: 992px) {
        min-width: 100%;
        flex-direction: column;
        gap: 1.5rem;
      }
    }
    
    // Hakkımızda menüsü için daha kompakt görünüm
    .dropdown:first-child & {
      max-width: 600px;
      gap: 1rem;
      
      @media (max-width: 1200px) {
        max-width: 550px;
      }
      
      @media (max-width: 992px) {
        flex-direction: column;
        min-width: 100%;
      }
    }
  }
  
  &__section {
    min-width: 240px;
    
    @media (max-width: 1366px) {
      min-width: 230px;
    }
    
    @media (max-width: 1200px) {
      min-width: 220px;
    }
    
    @media (max-width: 1100px) {
      min-width: 200px;
      flex: 1 1 45%;
    }
    
    @media (max-width: 992px) {
      min-width: 100%;
      margin-bottom: 1rem;
    }

    // Tedaviler menüsü için özel genişlikler
    .dropdown:nth-child(2) .mega-menu__content & {
      &:nth-child(1) {
        flex: 0 0 32%;
      }
      
      &:nth-child(2) {
        flex: 0 0 43%;
      }
      
      &:nth-child(3) {
        flex: 0 0 25%;
      }
      
      @media (max-width: 1200px) {
        &:nth-child(1) {
          flex: 0 0 30%;
        }
        
        &:nth-child(2) {
          flex: 0 0 45%;
        }
        
        &:nth-child(3) {
          flex: 0 0 25%;
        }
      }
    }
    
    h4 {
      color: var(--text-color);
      font-size: 1rem;
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(0,0,0,0.05);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  &__link {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 1rem;
    color: var(--text-color);
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.3s ease;
    margin-bottom: 0.5rem;
    
    @media (max-width: 1366px) {
      padding: 0.1rem 0.1rem;
      gap: 0.1rem;
    }
    
    @media (max-width: 1200px) {
      padding: 0.1rem 0.1rem;
      gap: 0.1rem;
    }
    
    @media (max-width: 1100px) {
      padding: 0.1rem 0.1rem;
      gap: 0.1rem;
    }
    
    i {
      font-size: 1.2rem;
      color: var(--primary-color);
      width: 24px;
      text-align: center;
      flex-shrink: 0;
      
      @media (max-width: 1366px) {
        font-size: 1.15rem;
      }
      
      @media (max-width: 1200px) {
        font-size: 1.1rem;
      }
      
      @media (max-width: 1100px) {
        font-size: 1rem;
        width: 20px;
      }
    }
    
    div {
      flex: 1;
      min-width: 0;
      
      span {
        display: block;
        font-size: 0.9rem;
        margin-bottom: 0.125rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        @media (max-width: 1366px) {
          font-size: 0.88rem;
        }
        
        @media (max-width: 1200px) {
          font-size: 0.85rem;
        }
        
        @media (max-width: 1100px) {
          font-size: 0.8rem;
        }
      }
      
      small {
        display: block;
        color: var(--text-color-light);
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        
        @media (max-width: 1366px) {
          font-size: 0.78rem;
        }
        
        @media (max-width: 1200px) {
          font-size: 0.75rem;
          -webkit-line-clamp: 1;
        }
        
        @media (max-width: 1100px) {
          font-size: 0.7rem;
        }
      }
    }
    
    &:hover {
      background: rgba(0,0,0,0.03);
      transform: translateX(5px);
    }
  }
  
  &__grid {
    display: grid;
    gap: 0.75rem;
    
    @media (max-width: 1366px) {
      gap: 0.7rem;
    }
    
    @media (max-width: 1200px) {
      gap: 0.5rem;
    }
    
    @media (max-width: 1100px) {
      gap: 0.5rem;
    }
    
    @media (max-width: 992px) {
    }
    
    @media (max-width: 768px) {
    }

    // Almanca için özel düzenlemeler
    html[lang="de"] & {
      .dropdown:nth-child(2) .mega-menu__content .mega-menu__section:nth-child(2) & {
        grid-template-columns: repeat(2, minmax(215px, 1fr));
        
        @media (max-width: 1366px) {
          grid-template-columns: repeat(2, minmax(200px, 1fr));
        }
        
        @media (max-width: 1200px) {
          grid-template-columns: repeat(2, minmax(190px, 1fr));
        }
      }
    }
  }
  
  &__featured {
    .featured-card {
      background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
      padding: 1.5rem;
      border-radius: 12px;
      color: white;
      text-align: center;
      min-width: 220px;
      
      @media (max-width: 1366px) {
        padding: 1.35rem;
        min-width: 210px;
      }
      
      @media (max-width: 1200px) {
        padding: 1.25rem;
        min-width: 200px;
      }
      
      @media (max-width: 1100px) {
        padding: 1.15rem;
        min-width: 190px;
      }
      
      @media (max-width: 992px) {
        min-width: 100%;
        max-width: 400px;
        margin: 0 auto;
      }
      
      i {
        font-size: 2rem;
        margin-bottom: 1rem;
        color: white;
        display: inline-block;
        
        @media (max-width: 1366px) {
          font-size: 1.85rem;
          margin-bottom: 0.85rem;
        }
        
        @media (max-width: 1200px) {
          font-size: 1.75rem;
          margin-bottom: 0.75rem;
        }
        
        @media (max-width: 1100px) {
          font-size: 1.65rem;
          margin-bottom: 0.7rem;
        }
      }
      
      h4 {
        color: white;
        margin-bottom: 0.5rem;
        border: none;
        font-size: 1.2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        @media (max-width: 1366px) {
          font-size: 1.15rem;
        }
        
        @media (max-width: 1200px) {
          font-size: 1.1rem;
        }
        
        @media (max-width: 1100px) {
          font-size: 1rem;
          margin-bottom: 0.4rem;
        }
      }
      
      p {
        opacity: 0.9;
        margin-bottom: 1rem;
        font-size: 0.85rem;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 3.5rem;
        
        @media (max-width: 1366px) {
          font-size: 0.83rem;
          margin-bottom: 0.9rem;
          min-height: 3.4rem;
        }
        
        @media (max-width: 1200px) {
          font-size: 0.8rem;
          margin-bottom: 0.75rem;
          min-height: 3.3rem;
        }
        
        @media (max-width: 1100px) {
          font-size: 0.78rem;
          margin-bottom: 0.7rem;
          min-height: 3.2rem;
          -webkit-line-clamp: 2;
        }
      }
      
      .featured-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 0.75rem 1.5rem;
        background: white;
        color: var(--primary-color);
        border-radius: 50px;
        text-decoration: none;
        font-weight: 500;
        font-size: 0.85rem;
        width: 100%;
        transition: all 0.3s ease;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        @media (max-width: 1366px) {
          padding: 0.7rem 1.3rem;
          font-size: 0.83rem;
        }
        
        @media (max-width: 1200px) {
          padding: 0.6rem 1rem;
          font-size: 0.8rem;
        }
        
        @media (max-width: 1100px) {
          padding: 0.55rem 0.9rem;
          font-size: 0.78rem;
        }
        
        i {
          font-size: 1rem;
          margin: 0;
          color: var(--primary-color);
          flex-shrink: 0;
          
          @media (max-width: 1366px) {
            font-size: 0.95rem;
          }
          
          @media (max-width: 1200px) {
            font-size: 0.9rem;
          }
          
          @media (max-width: 1100px) {
            font-size: 0.85rem;
          }
        }
        
        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 5px 15px rgba(0,0,0,0.1);
          background: var(--light-color);
          
          i {
            transform: translateX(3px);
          }
        }
      }
    }
  }
}

// Dil özelleştirmeleri
html[lang="de"] {
  .mega-menu {
    // Almanca için tedaviler menüsü grid düzeni optimizasyonu
    &__content {
      .dropdown:nth-child(2) & {
        min-width: 980px; // Daha da geniş
        gap: 0.75rem; // Sectionlar arası boşluğu azalt
        
        @media (max-width: 1366px) {
          min-width: 920px;
          gap: 0.6rem;
        }
        
        @media (max-width: 1200px) {
          min-width: 820px;
          gap: 0.5rem;
        }
      }
    }
    
    &__section {
      .dropdown:nth-child(2) .mega-menu__content & {
        &:nth-child(1) {
          flex: 0 0 24%; // Daha dar
        }
        
        &:nth-child(2) {
          flex: 0 0 56%; // Daha geniş
        }
        
        &:nth-child(3) {
          flex: 0 0 20%; // Daha dar
        }
        
        @media (max-width: 1366px) {
          &:nth-child(1) {
            flex: 0 0 24%;
          }
          
          &:nth-child(2) {
            flex: 0 0 56%;
          }
          
          &:nth-child(3) {
            flex: 0 0 20%;
          }
        }
        
        @media (max-width: 1200px) {
          &:nth-child(1) {
            flex: 0 0 24%;
          }
          
          &:nth-child(2) {
            flex: 0 0 56%;
          }
          
          &:nth-child(3) {
            flex: 0 0 20%;
          }
        }
      }
    }
    
    &__grid {
      // Almanca'da ortodonti ve pedodonti için tek sütun düzeni
      .dropdown:nth-child(2) .mega-menu__content .mega-menu__section:nth-child(2) & {
        grid-template-columns: 1fr; // Tek sütun
        gap: 0.5rem;
        
        a:nth-child(2), // Ortodonti
        a:nth-child(4) { // Pedodonti
          max-width: 100%;
          
          small {
            display: inline;
            white-space: normal;
            overflow: visible;
          }
        }
        
        // Diğer öğeler için iki sütun grid
        display: grid;
        grid-template-areas:
          "whitening whitening"
          "ortho ortho"
          "perio invisalign"
          "pedo pedo";
          
        a:nth-child(1) { grid-area: whitening; }
        a:nth-child(2) { grid-area: ortho; }
        a:nth-child(3) { grid-area: perio; }
        a:nth-child(4) { grid-area: pedo; }
        a:nth-child(5) { grid-area: invisalign; }
      }
    }
    
    &__featured {
      .featured-card {
        min-width: 190px; // Daha dar
        padding: 1.1rem;
        
        @media (max-width: 1366px) {
          min-width: 180px;
          padding: 1rem;
        }
        
        @media (max-width: 1200px) {
          min-width: 170px;
          padding: 0.9rem;
        }
        
        h4 {
          font-size: 1rem;
        }
        
        p {
          font-size: 0.75rem;
          min-height: 2.6rem;
          -webkit-line-clamp: 2;
          margin-bottom: 0.7rem;
        }
        
        .featured-button {
          padding: 0.5rem 0.8rem;
          font-size: 0.75rem;
          
          i {
            font-size: 0.9rem;
          }
          
          @media (max-width: 1366px) {
            padding: 0.45rem 0.75rem;
            font-size: 0.73rem;
          }
          
          @media (max-width: 1200px) {
            padding: 0.4rem 0.7rem;
            font-size: 0.7rem;
          }
        }
      }
    }

    &__link {
      div {
        span {
          font-size: 0.8rem;
          
          @media (max-width: 1366px) {
            font-size: 0.78rem;
          }
          
          @media (max-width: 1200px) {
            font-size: 0.76rem;
          }
        }
        
        small {
          font-size: 0.72rem;
          line-height: 1.2;
          -webkit-line-clamp: 1; // Her zaman tek satır
          
          @media (max-width: 1366px) {
            font-size: 0.7rem;
          }
          
          @media (max-width: 1200px) {
            font-size: 0.68rem;
          }
        }
      }
      
      // Ortodonti ve pedodonti için özel düzen
      &:nth-child(2),
      &:nth-child(4) {
        i {
          align-self: flex-start;
          margin-top: 0.1rem;
        }
      }
    }
  }
}

// Spesifik düzeltmeler - mobil ve küçük ekranlar için
@media (max-width: 992px) {
  .mega-menu {
    left: 0;
    right: 0;
    transform: translateX(0);
    width: 100%;
    max-width: 100%;
    margin-top: 0.5rem;
  }
}

// Dark tema için featured card düzenlemesi
.header--dark {
  .mega-menu__featured {
    .featured-card {
      
      .featured-button {
        background: var(--light-color);
        
        &:hover {
          background: white;
        }
      }
    }
  }
}

// Dropdown container için position düzeltmesi
.dropdown {
  @media (min-width: $breakpoint-lg) {
    position: relative !important;
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Dil seçimi dropdown stilleri
.lang-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 180px;
  background: white;
  border-radius: 12px;
  padding: 0.75rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  z-index: 1000;
  box-shadow: 0 15px 30px rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.05);
  margin-top: 0.5rem;
  
  &.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .lang-option {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    color: var(--text-color);
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
    
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
    }
    
    &:hover {
      background: rgba(0,0,0,0.03);
      transform: translateX(5px);
    }
    
    &.active {
      background: var(--primary-color);
      color: white;
    }
  }
}

// Mobil görünüm için dil seçimi düzenlemesi
@media (max-width: 991px) {
  .lang-dropdown {
    position: static;
    margin-top: 0.5rem;
    background: rgba(255,255,255,0.05);
    border: 1px solid rgba(255,255,255,0.1);
    
    .lang-option {
      color: white;
      
      &:hover {
        background: rgba(255,255,255,0.1);
      }
      
      &.active {
        background: var(--primary-color);
      }
    }
  }
}

// Dil seçimi nav-link düzenlemesi
.nav-item.dropdown {
  .nav-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    i {
      font-size: 1rem;
      color: currentColor;
      
      @media (max-width: 1280px) {
        font-size: 0.9rem;
      }
      
      @media (max-width: 1100px) {
        font-size: 0.85rem;
      }
    }
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1325px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 98%;
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .navbar .navbar-buttons .nav-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 50px;
    font-weight: 500;
    transition: all 0.3s ease;
    text-decoration: none;
    white-space: nowrap;
  }
}

.view-all-treatments {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem;
  margin-top: 1rem;
  background: rgba(var(--primary-color-rgb), 0.1);
  color: var(--primary-color);
  border-radius: 8px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 0.9rem;

  &:hover {
    background: var(--primary-color);
    color: white;
    transform: translateY(-2px);
  }

  i {
    font-size: 1rem;
  }
}
</style> 