<template>
  <div class="home">
    <SeoHead
      title="Kartal Soğanlık Diş Kliniği | Klinik Akademi"
      description="Kartal Soğanlık'ta 20 yıllık deneyimli kadromuzla implant, ortodonti, zirkonyum, diş beyazlatma ve tüm diş tedavileriniz için hizmetinizdeyiz."
      keywords="kartal diş kliniği, soğanlık diş hekimi, kartal implant, soğanlık ortodonti, kartal zirkonyum, diş beyazlatma kartal"
    />
    <HeroSection />
    <ServicesSection :services="services" />
    <CheckupSection />
    <WhyUsSection :stats="stats" />
    <TourismSection />
    <ContactCta />
    <ContactSection />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SeoHead from '../components/common/SeoHead.vue'
import HeroSection from '@/components/home/HeroSection.vue'
import ServicesSection from '@/components/home/ServicesSection.vue'
import WhyUsSection from '../components/home/WhyUsSection.vue'
import TourismSection from '../components/home/TourismSection.vue'
import CheckupSection from '../components/home/CheckupSection.vue'
import ContactCta from '../components/home/ContactCta.vue'
import ContactSection from '@/components/home/ContactSection.vue'
import { getTreatmentRoute } from '@/utils/slug'

export default defineComponent({
  name: 'HomeView',
  components: {
    SeoHead,
    HeroSection,
    ServicesSection,
    WhyUsSection,
    CheckupSection,
    TourismSection,
    ContactCta,
    ContactSection
  },
  data() {
    const stats = [
      { title: '12,500+', description: this.$t('homepage.stats.patients'), icon: 'people' },
      { title: '15+', description: this.$t('homepage.stats.experience'), icon: 'alarm' },
      { title: '9.8/10', description: this.$t('homepage.stats.satisfaction'), icon: 'emoji-smile' },
      { title: '24/7', description: this.$t('homepage.stats.support'), icon: 'headset' }
    ]
    
    return {
      services: [
        {
          id: 'implant',
          icon: 'plus-circle',
          treatmentKey: 'implant'
        },
        {
          id: 'zirconium',
          icon: 'gem',
          treatmentKey: 'prosthesis'
        },
        {
          id: 'orthodontics',
          icon: 'arrow-left-right',
          treatmentKey: 'orthodontics'
        },
        {
          id: 'aesthetics',
          icon: 'stars',
          treatmentKey: 'aesthetics'
        },
        {
          id: 'whitening',
          icon: 'brightness-high',
          treatmentKey: 'whitening'
        },
        {
          id: 'pediatric',
          icon: 'shield-plus',
          treatmentKey: 'pediatric'
        }
      ],
      stats
    }
  },
  methods: {
    getServiceLink(treatmentKey) {
      return getTreatmentRoute(treatmentKey)
    }
  }
})
</script>

<style>
.map-container {
  position: relative;
  height: 400px;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);

  #map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>