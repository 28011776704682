export default {
  // Hero Bölümü
  badge: 'Rechtliche Informationen',
  title: {
    main: 'Cookie',
    highlight: 'Richtlinie'
  },
  description: 'Erfahren Sie mehr über die auf unserer Website verwendeten Cookies, deren Zwecke und wie Sie Ihre Cookie-Einstellungen verwalten können.',
  
  meta: {
    title: 'Cookie-Richtlinie | Klinik Akademi Zahnklinik',
    description: 'Detaillierte Informationen über die Cookie-Nutzungsrichtlinie der Klinik Akademi Zahnklinik und wie Sie Ihre Cookie-Einstellungen verwalten können.'
  },
  
  // İçerik Bölümü
  intro: 'Als Klinik Akademi Zahnklinik haben wir diese Richtlinie darüber erstellt, wie wir Cookies (Cookies) bei der Nutzung unserer Website verwenden. Diese Richtlinie erklärt, welche Cookies wir verwenden, wie sie funktionieren und wie Sie Ihre Cookie-Einstellungen verwalten können.',
  
  // Bölüm 1
  section1: {
    title: '1. Was sind Cookies?',
    content: 'Cookies sind kleine Textdateien, die von besuchten Websites auf Ihrem Computer, Tablet oder Mobilgerät platziert werden. Cookies werden verwendet, um sicherzustellen, dass die Website richtig funktioniert, die Benutzererfahrung zu verbessern, die Website zu analysieren und in einigen Fällen gezielte Werbung bereitzustellen.'
  },
  
  // Bölüm 2
  section2: {
    title: '2. Cookies, die wir verwenden',
    content: 'Wir verwenden auf unserer Website die folgenden Arten von Cookies:',
    type1: {
      title: 'Wesentliche Cookies',
      content: 'Diese Cookies sind für das ordnungsgemäße Funktionieren unserer Website notwendig und enthalten Systemeinstellungen. Sie können diese Cookies über Ihren Browser blockieren, aber in diesem Fall funktionieren einige Teile unserer Website möglicherweise nicht richtig.'
    },
    type2: {
      title: 'Leistungs- und Analyse-Cookies',
      content: 'Diese Cookies sammeln Informationen darüber, wie Besucher unsere Website nutzen. Sie werden zum Beispiel verwendet, um die am häufigsten besuchten Seiten, die Zeit, die Besucher auf der Website verbringen, und die Fehlermeldungen, die sie erhalten, zu verfolgen.'
    },
    type3: {
      title: 'Funktionalitäts-Cookies',
      content: 'Diese Cookies werden verwendet, um Entscheidungen zu speichern, die Sie bei Ihrem Besuch auf unserer Website treffen (wie Ihren Benutzernamen, Ihre Sprache oder Ihre Region). Diese Cookies ermöglichen es uns, Ihnen eine persönlichere Erfahrung zu bieten.'
    },
    type4: {
      title: 'Marketing- und Targeting-Cookies',
      content: 'Diese Cookies werden verwendet, um Ihre Surfgewohnheiten auf unserer Website und anderen Websites zu verfolgen. Unser Ziel ist es, Ihnen Werbung und Inhalte zu zeigen, die für Ihre Interessen relevant sind.'
    }
  },
  
  // Bölüm 3
  section3: {
    title: '3. Cookies von Drittanbietern',
    content: 'Unsere Website kann Cookies von Drittanbietern enthalten. Diese Cookies können verwendet werden, um die folgenden Dienste bereitzustellen:',
    items: [
      'Website-Analyse und Statistiken (Google Analytics, Hotjar usw.)',
      'Social-Media-Funktionen (Facebook, Twitter, Instagram usw.)',
      'Werbedienste (Google AdSense, Facebook Pixel usw.)'
    ],
    additionalContent: 'Diese Cookies von Drittanbietern unterliegen den Datenschutzrichtlinien der jeweiligen Drittanbieter. Sie können auf diese Richtlinien von den Websites der jeweiligen Drittanbieter zugreifen.'
  },
  
  // Bölüm 4
  section4: {
    title: '4. Cookie-Einstellungen und -Kontrolle',
    content: 'Die meisten Webbrowser sind automatisch so eingestellt, dass sie Cookies akzeptieren. Sie können jedoch Cookies blockieren oder Ihre Cookie-Einstellungen verwalten, indem Sie Ihre Browsereinstellungen ändern.',
    additionalContent: 'Sie können die folgenden Methoden verwenden, um Cookies zu verwalten:',
    method1: {
      title: 'Browsereinstellungen',
      content: 'Die meisten Browser bieten Einstellungen, mit denen Sie Cookies blockieren oder Cookies für bestimmte Websites akzeptieren können.'
    },
    method2: {
      title: 'Cookie-Präferenz-Tools',
      content: 'Sie können Ihre Cookie-Einstellungen mit dem auf unserer Website angebotenen Cookie-Einstellungstool aktualisieren.'
    },
    method3: {
      title: 'Tools von Drittanbietern',
      content: 'Einige Websites von Drittanbietern bieten die Möglichkeit, Cookies von bestimmten Unternehmen nicht zu akzeptieren.'
    },
    warning: 'Wenn Sie Cookies vollständig blockieren, beachten Sie bitte, dass einige Funktionen unserer Website möglicherweise nicht richtig funktionieren und Ihre Benutzererfahrung negativ beeinflusst werden kann.'
  },
  
  // Bölüm 5
  section5: {
    title: '5. Informationen zur Cookie-Nutzung',
    content: 'Wenn Sie unsere Website zum ersten Mal besuchen, wird eine Informationsbenachrichtigung über die Verwendung von Cookies angezeigt. Über diese Benachrichtigung können Sie die Cookie-Nutzung akzeptieren oder Ihre Cookie-Einstellungen verwalten.',
    example: {
      title: 'Cookie-Benachrichtigungsbeispiel',
      content: 'Unsere Website verwendet Cookies, um Ihre Erfahrung zu verbessern. Indem Sie unsere Website weiterhin nutzen, akzeptieren Sie unsere Cookie-Richtlinie.',
      detailButton: 'Weitere Informationen',
      acceptButton: 'Akzeptieren'
    },
    additionalContent: 'Sie können Ihre Cookie-Einstellungen jederzeit ändern. Um Ihre Cookie-Einstellungen zu ändern, können Sie den Link "Cookie-Einstellungen" am unteren Rand unserer Website verwenden.'
  },
  
  // Bölüm 6
  section6: {
    title: '6. Datensicherheit',
    content: 'Wir implementieren technische und administrative Maßnahmen, um die Sicherheit der über Cookies gesammelten Informationen zu gewährleisten. Wir möchten Sie jedoch daran erinnern, dass keine Datenübertragung über das Internet zu 100 Prozent sicher ist.'
  },
  
  // Bölüm 7
  section7: {
    title: '7. Änderungen',
    content: 'Wir können diese Cookie-Richtlinie von Zeit zu Zeit aktualisieren. Bei Änderungen werden wir die aktualisierte Richtlinie auf unserer Website veröffentlichen. Wir empfehlen Ihnen, die Cookie-Richtlinie bei jedem Besuch unserer Website zu überprüfen.'
  },
  
  // Bölüm 8
  section8: {
    title: '8. Kontakt',
    content: 'Wenn Sie Fragen oder Bedenken zu dieser Cookie-Richtlinie haben, kontaktieren Sie uns bitte über die folgenden Kanäle:',
    address: {
      label: 'Adresse',
      value: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/Istanbul'
    },
    email: {
      label: 'E-Mail',
      value: 'info@klinikakademi.com'
    },
    phone: {
      label: 'Telefon',
      value: '+90 (216) 123 45 67'
    },
    contactButton: 'Zur Kontaktseite'
  },
  
  // Footer
  lastUpdate: 'Letztes Aktualisierungsdatum:'
} 