export default {
  // Hero Bölümü
  badge: 'Rechtliche Informationen',
  title: {
    main: 'Datenschutz',
    highlight: 'Erklärung'
  },
  description: 'Erfahren Sie mehr über unsere Richtlinien zur Sicherheit und zum Schutz Ihrer persönlichen Daten.',
  
  meta: {
    title: 'KVKK Aufklärungstext',
    description: 'Detaillierte Informationen zum Datenschutzgesetz (KVKK) und zur Verarbeitung personenbezogener Daten in der Klinik Akademi Zahnklinik.',
  },
  
  // İçerik Bölümü
  intro: {
    content: 'Als Klinik Akademi Zahnklinik können wir als Datenverantwortlicher gemäß dem Gesetz zum Schutz personenbezogener Daten Nr. 6698 (KVKK) Ihre personenbezogenen Daten für die unten erläuterten Zwecke in Übereinstimmung mit dem Gesetz und den Regeln der Ehrlichkeit verarbeiten, aufzeichnen, speichern, klassifizieren, aktualisieren und in begrenzten, gesetzlich erlaubten Fällen an Dritte weitergeben/übermitteln.'
  },
  
  // Bölüm 1
  section1: {
    title: '1. Datenverantwortlicher',
    content: 'Klinik Akademi Zahnklinik fungiert als Datenverantwortlicher bei der Verarbeitung und Speicherung Ihrer personenbezogenen Daten. Gemäß KVKK werden Ihre personenbezogenen Daten von der Klinik Akademi Zahnklinik als Datenverantwortlicher im unten erläuterten Umfang erfasst und verarbeitet.'
  },
  
  // Bölüm 2
  section2: {
    title: '2. Zweck der Verarbeitung personenbezogener Daten',
    content: 'Ihre personenbezogenen Daten können für Zwecke verarbeitet werden, die unter anderem die Durchführung notwendiger Operationen umfassen, damit Sie von den Dienstleistungen der Klinik Akademi Zahnklinik profitieren können, die Bereitstellung von Gesundheitsdienstleistungen und deren Planung und Verfolgung, die Überprüfung Ihrer Identität, die Erstellung und Verfolgung von Terminen, die Verwaltung von Behandlungsprozessen, die Durchführung von Zahlungsvorgängen und andere zahnärztliche Operationen für die folgenden Zwecke:<br><br><ul><li>Durchführung und Verbesserung von Gesundheitsdienstleistungen</li><li>Durchführung der Patientenregistrierung und -aufnahme</li><li>Durchführung medizinischer Diagnose-, Behandlungs- und Pflegedienste</li><li>Planung und Verwaltung der Finanzierung von Gesundheitsdienstleistungen</li><li>Bereitstellung von Terminerstellung und Erinnerungsdiensten</li><li>Verwaltung von Abrechnungs- und Zahlungsprozessen</li><li>Erfüllung gesetzlicher und behördlicher Anforderungen</li><li>Durchführung von Analysen zu unseren Gesundheitsdienstleistungen und Verbesserung unserer Gesundheitsdienstleistungen</li></ul>'
  },
  
  // Bölüm 3
  section3: {
    title: '3. Kategorien der verarbeiteten personenbezogenen Daten',
    content: 'Die Hauptkategorien personenbezogener Daten, die von unserer Klinik verarbeitet werden, sind wie folgt:<br><br><strong>Identitäts- und Kontaktinformationen:</strong><br><ul><li>Identitätsinformationen (Name, Nachname, Ausweisnummer, Geburtsdatum, Geschlecht usw.)</li><li>Kontaktinformationen (Telefonnummer, E-Mail, Adresse usw.)</li><li>Finanzielle Informationen (Bankverbindung, Rechnungsinformationen usw.)</li></ul><br><strong>Gesundheits- und andere Informationen:</strong><br><ul><li>Gesundheitsinformationen (Krankengeschichte, Diagnose, Behandlungsinformationen, Röntgen- und Bildergebnisse usw.)</li><li>Versicherungsinformationen (private Krankenversicherung, Sozialversicherung usw.)</li><li>Visuelle und akustische Aufnahmen (bei Bedarf Fotos vor und nach der Behandlung)</li></ul>'
  },
  
  // Bölüm 4
  section4: {
    title: '4. Übermittlung personenbezogener Daten',
    content: 'Ihre personenbezogenen Daten können im Rahmen des Gesetzes und anderer Rechtsvorschriften für die oben genannten Zwecke mit den unten angegebenen in- und ausländischen Organisationen geteilt werden:<br><br><ul><li>Gesundheitsministerium und dessen angeschlossene Untereinheiten</li><li>Private Versicherungsunternehmen und Sozialversicherungsanstalt</li><li>Anwälte und befugte öffentliche Einrichtungen und Organisationen zum Zweck der Durchführung rechtlicher Verfahren</li><li>Regulierungs- und Aufsichtsbehörden und offizielle Stellen</li><li>Dritte, von denen Dienstleistungen im In-/Ausland bezogen werden (Labore und medizinische Gerätelieferanten usw.)</li></ul><br>Ihre personenbezogenen Daten können im Rahmen der in den Artikeln 8 und 9 des Gesetzes festgelegten Bedingungen und Zwecke der Verarbeitung personenbezogener Daten übermittelt werden.'
  },
  
  // Bölüm 5
  section5: {
    title: '5. Methode und Rechtsgrundlage für die Erhebung personenbezogener Daten',
    content: 'Ihre personenbezogenen Daten können von der Klinik Akademi Zahnklinik elektronisch oder physisch erfasst werden. In diesem Zusammenhang werden Ihre personenbezogenen Daten durch die folgenden Methoden und Rechtsgrundlagen erhoben:<br><br><ul><li>Informationen, die Sie während der Patientenaufnahme-, Registrierungs- und Terminerstellungsprozesse teilen</li><li>Gesundheitsdaten, die als Ergebnis von Untersuchungen und Tests, die vom Arzt während des Behandlungsprozesses durchgeführt werden, erhalten werden</li><li>Informationen, die Sie über unsere Website, Telefon, E-Mail und Social-Media-Kanäle bereitstellen</li><li>Informationsaustausch mit Regulierungs- und Aufsichtsbehörden zum Zweck der Finanzierung und Planung von Gesundheitsdienstleistungen</li><li>Informationen, die von Institutionen erhalten werden, von denen Dienstleistungen bezogen werden (private Versicherung, Sozialversicherung usw.)</li></ul>',
    list: {
      item1: {
        title: 'Ausdrückliche Einwilligung',
        content: 'Für Datenverarbeitungsaktivitäten, die nicht ausdrücklich gesetzlich vorgeschrieben sind und eine ausdrückliche Einwilligung erfordern, werden Ihre personenbezogenen Daten durch Einholung Ihrer ausdrücklichen Einwilligung verarbeitet.'
      },
      item2: {
        title: 'Gesetzlich vorgeschrieben',
        content: 'Im Rahmen der gesetzlichen Bestimmungen zu Gesundheitsdienstleistungen ist es eine gesetzliche Verpflichtung, Ihre Daten zu verarbeiten und mit den relevanten Institutionen zu teilen.'
      },
      item3: {
        title: 'Vertragserfüllung',
        content: 'Als Klinik Akademi Zahnklinik werden Ihre personenbezogenen Daten verarbeitet, um die aus unserem Vertrag mit Ihnen resultierenden Dienstleistungen zu erfüllen und eine Behandlungsbeziehung herzustellen.'
      }
    }
  },
  
  // Bölüm 6
  section6: {
    title: '6. Ihre Rechte als betroffene Person',
    content: 'Gemäß Artikel 11 der KVKK haben Sie als betroffene Person folgende Rechte:',
    category1: {
      items: [
        'Zu erfahren, ob Ihre personenbezogenen Daten verarbeitet werden',
        'Informationen über Ihre personenbezogenen Daten zu verlangen, wenn sie verarbeitet wurden',
        'Den Zweck der Verarbeitung Ihrer personenbezogenen Daten zu erfahren und ob sie dem Zweck entsprechend verwendet werden',
        'Die dritten Parteien zu kennen, an die Ihre personenbezogenen Daten im In- oder Ausland übermittelt werden',
        'Die Berichtigung Ihrer personenbezogenen Daten zu verlangen, wenn sie unvollständig oder falsch verarbeitet wurden'
      ]
    },
    category2: {
      items: [
        'Die Löschung oder Vernichtung Ihrer personenbezogenen Daten im Rahmen der in Artikel 7 der KVKK vorgesehenen Bedingungen zu verlangen',
        'Die Benachrichtigung der dritten Parteien, an die Ihre personenbezogenen Daten übermittelt wurden, über Ihren Antrag auf Berichtigung, Löschung oder Vernichtung zu verlangen',
        'Einspruch gegen das Entstehen eines Ergebnisses zu Ihren Ungunsten als Ergebnis der Analyse der ausschließlich durch automatisierte Systeme verarbeiteten Daten einzulegen',
        'Entschädigung für Schäden zu verlangen, die Ihnen aufgrund der unrechtmäßigen Verarbeitung Ihrer personenbezogenen Daten entstanden sind'
      ]
    }
  },
  
  // Bölüm 7
  section7: {
    title: '7. Antragsmethoden',
    content: 'Sie können eine der folgenden Methoden anwenden, um die oben genannten Rechte auszuüben:',
    contactMethods: {
      inPerson: {
        clinic: 'Unsere Klinik',
        address: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/Istanbul',
        method: 'persönliche Anwendung'
      },
      mail: {
        address: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/Istanbul',
        method: 'per Post mit einem handschriftlich unterschriebenen Antrag'
      },
      email: {
        address: 'info@klinikakademi.com',
        method: 'Antrag per E-Mail'
      }
    },
    additionalContent: 'Ihr Antrag wird je nach Art des Antrags so schnell wie möglich und spätestens innerhalb von 30 Tagen kostenlos abgeschlossen. Wenn der Vorgang zusätzliche Kosten verursacht, kann gemäß dem von der Datenschutzbehörde festgelegten Tarif eine Gebühr erhoben werden.'
  },
  
  // Bölüm 8
  section8: {
    title: '8. Änderungen an der Erklärung',
    content: 'Die Klinik Akademi Zahnklinik kann diese Erklärung bei Bedarf aktualisieren. Updates werden auf unserer Website veröffentlicht.'
  },
  
  // Footer
  lastUpdate: 'Letztes Aktualisierungsdatum:'
} 