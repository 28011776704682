import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import AboutView from '@/views/AboutView.vue'
import TeamView from '@/views/TeamView.vue'
import TreatmentsView from '@/views/TreatmentsView.vue'
import slugs from '@/translations/slugs'
import { getLocale } from '@/plugins/i18n'

// Dil bazlı tedavi yolları oluşturur
function createTreatmentRoutes() {
  const routes = []
  
  // Her bir tedavi türü için
  const treatmentKeys = [
    'implant',
    'orthodontics',
    'whitening',
    'rootCanal',
    'prosthesis',
    'periodontics',
    'pediatric',
    'surgery',
    'invisalign'
  ]
  
  // Her dil için
  const languages = Object.keys(slugs)
  
  languages.forEach(lang => {
    const treatmentBase = slugs[lang].treatments
    
    treatmentKeys.forEach(key => {
      const slug = slugs[lang][key]
      const componentMap = {
        'implant': () => import('@/views/treatments/ImplantPage.vue'),
        'orthodontics': () => import('@/views/treatments/OrthodonticsView.vue'),
        'whitening': () => import('@/views/treatments/TeethWhitening.vue'),
        'rootCanal': () => import('@/views/treatments/RootCanalView.vue'),
        'prosthesis': () => import('@/views/treatments/ProsthesisView.vue'),
        'periodontics': () => import('@/views/treatments/PeriodonticsView.vue'),
        'pediatric': () => import('@/views/treatments/PediatricDentistryView.vue'),
        'surgery': () => import('@/views/treatments/OralSurgery.vue'),
        'invisalign': () => import('@/views/treatments/InvisalignView.vue')
      }
      
      routes.push({
        path: `/${treatmentBase}/${slug}`,
        name: `${lang}-${key}`,
        component: componentMap[key],
        meta: {
          treatmentKey: key,
          locale: lang
        }
      })
    })
  })
  
  return routes
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // Blog Sayfaları
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/blog/BlogListView.vue'),
    meta: {
      title: 'Blog | Klinik Akademi',
      description: 'Diş sağlığı, ağız bakımı ve dental tedaviler hakkında bilgilendirici yazılar.'
    }
  },
  {
    path: '/blog/:slug',
    name: 'blog-detail',
    component: () => import('@/views/blog/BlogDetailView.vue'),
    meta: {
      title: 'Blog Yazısı | Klinik Akademi',
      description: 'Diş sağlığı ve tedaviler hakkında detaylı bilgiler.'
    }
  },
  // Hakkımızda sayfası redirectleri ve yeni yolları
  {
    path: '/hakkimizda',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].about}`
    }
  },
  {
    path: '/about-us',
    redirect: () => {
      return `/${slugs['en'].about}`
    }
  },
  {
    path: '/uber-uns',
    redirect: () => {
      return `/${slugs['de'].about}`
    }
  },
  {
    path: `/${slugs['tr'].about}`,
    name: 'hakkimizda',
    component: AboutView
  },
  {
    path: `/${slugs['en'].about}`,
    name: 'about-us',
    component: AboutView
  },
  {
    path: `/${slugs['de'].about}`,
    name: 'uber-uns',
    component: AboutView
  },
  // Ekibimiz sayfası redirectleri ve yeni yolları
  {
    path: '/ekibimiz',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].team}`
    }
  },
  {
    path: '/our-team',
    redirect: () => {
      return `/${slugs['en'].team}`
    }
  },
  {
    path: '/unser-team',
    redirect: () => {
      return `/${slugs['de'].team}`
    }
  },
  {
    path: `/${slugs['tr'].team}`,
    name: 'ekibimiz',
    component: TeamView
  },
  {
    path: `/${slugs['en'].team}`,
    name: 'our-team',
    component: TeamView
  },
  {
    path: `/${slugs['de'].team}`,
    name: 'unser-team',
    component: TeamView
  },
  // Tedaviler sayfası redirectleri ve yeni yolları
  {
    path: '/tedaviler',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].treatments}`
    }
  },
  {
    path: '/treatments',
    redirect: () => {
      return `/${slugs['en'].treatments}`
    }
  },
  {
    path: '/behandlungen',
    redirect: () => {
      return `/${slugs['de'].treatments}`
    }
  },
  {
    path: `/${slugs['tr'].treatments}`,
    name: 'tedaviler',
    component: TreatmentsView
  },
  {
    path: `/${slugs['en'].treatments}`,
    name: 'treatments-en',
    component: TreatmentsView
  },
  {
    path: `/${slugs['de'].treatments}`,
    name: 'treatments-de',
    component: TreatmentsView
  },
  // Hizmetlerimiz sayfası redirectleri ve yeni yolları
  {
    path: '/hizmetlerimiz',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].services}`
    }
  },
  {
    path: '/services',
    redirect: () => {
      return `/${slugs['en'].services}`
    }
  },
  {
    path: '/dienstleistungen',
    redirect: () => {
      return `/${slugs['de'].services}`
    }
  },
  {
    path: `/${slugs['tr'].services}`,
    name: 'hizmetlerimiz',
    component: () => import('@/views/ServicesView.vue')
  },
  {
    path: `/${slugs['en'].services}`,
    name: 'services-en',
    component: () => import('@/views/ServicesView.vue')
  },
  {
    path: `/${slugs['de'].services}`,
    name: 'services-de',
    component: () => import('@/views/ServicesView.vue')
  },
  // Doktorlarımız sayfası redirectleri ve yeni yolları
  {
    path: '/doktorlarimiz',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].doctors}`
    }
  },
  {
    path: '/our-doctors',
    redirect: () => {
      return `/${slugs['en'].doctors}`
    }
  },
  {
    path: '/unsere-zahnärzte',
    redirect: () => {
      return `/${slugs['de'].doctors}`
    }
  },
  {
    path: `/${slugs['tr'].doctors}`,
    name: 'doktorlarimiz',
    component: () => import('@/views/DoctorsView.vue')
  },
  {
    path: `/${slugs['en'].doctors}`,
    name: 'doctors-en',
    component: () => import('@/views/DoctorsView.vue')
  },
  {
    path: `/${slugs['de'].doctors}`,
    name: 'doctors-de',
    component: () => import('@/views/DoctorsView.vue')
  },
  // İletişim sayfası redirectleri ve yeni yolları
  {
    path: '/iletisim',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].contact}`
    }
  },
  {
    path: '/contact',
    redirect: () => {
      return `/${slugs['en'].contact}`
    }
  },
  {
    path: '/kontakt',
    redirect: () => {
      return `/${slugs['de'].contact}`
    }
  },
  {
    path: `/${slugs['tr'].contact}`,
    name: 'iletisim',
    component: () => import('@/views/ContactView.vue')
  },
  {
    path: `/${slugs['en'].contact}`,
    name: 'contact-en',
    component: () => import('@/views/ContactView.vue')
  },
  {
    path: `/${slugs['de'].contact}`,
    name: 'contact-de',
    component: () => import('@/views/ContactView.vue')
  },
  // Klinik sayfası redirectleri ve yeni yolları
  {
    path: '/klinikler/kartal',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].clinic}`
    }
  },
  {
    path: `/${slugs['tr'].clinic}`,
    name: 'kartal-clinic',
    component: () => import('@/views/locations/KartalClinic.vue')
  },
  {
    path: `/${slugs['en'].clinic}`,
    name: 'kartal-clinic-en',
    component: () => import('@/views/locations/KartalClinic.vue')
  },
  {
    path: `/${slugs['de'].clinic}`,
    name: 'kartal-clinic-de',
    component: () => import('@/views/locations/KartalClinic.vue')
  },
  // Medya sayfası redirectleri ve yeni yolları
  {
    path: '/media',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].media}`
    }
  },
  {
    path: `/${slugs['tr'].media}`,
    name: 'media',
    component: () => import('@/views/MediaView.vue'),
    meta: {
      title: 'Medya | Klinik Akademi',
      description: 'Klinik Akademi\'nin basın haberleri, klinik fotoğrafları, videolar ve sosyal medya içerikleri.'
    }
  },
  {
    path: `/${slugs['en'].media}`,
    name: 'media-en',
    component: () => import('@/views/MediaView.vue'),
    meta: {
      title: 'Media | Klinik Akademi',
      description: 'Press news, clinic photos, videos and social media content of Klinik Akademi.'
    }
  },
  {
    path: `/${slugs['de'].media}`,
    name: 'media-de',
    component: () => import('@/views/MediaView.vue'),
    meta: {
      title: 'Medien | Klinik Akademi',
      description: 'Pressemitteilungen, Klinikfotos, Videos und Social-Media-Inhalte von Klinik Akademi.'
    }
  },
  // Eski tedavi yolları - yönlendirme için tutuldu
  {
    path: '/tedaviler/agiz-dis-cerrahisi',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].treatments}/${slugs[currentLocale].surgery}`
    }
  },
  {
    path: '/tedaviler/dis-beyazlatma',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].treatments}/${slugs[currentLocale].whitening}`
    }
  },
  {
    path: '/tedaviler/implant',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].treatments}/${slugs[currentLocale].implant}`
    }
  },
  {
    path: '/tedaviler/kanal-tedavisi',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].treatments}/${slugs[currentLocale].rootCanal}`
    }
  },
  {
    path: '/tedaviler/protez',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].treatments}/${slugs[currentLocale].prosthesis}`
    }
  },
  {
    path: '/tedaviler/ortodonti',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].treatments}/${slugs[currentLocale].orthodontics}`
    }
  },
  {
    path: '/tedaviler/periodonti',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].treatments}/${slugs[currentLocale].periodontics}`
    }
  },
  {
    path: '/tedaviler/cocuk-dis-hekimligi',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].treatments}/${slugs[currentLocale].pediatric}`
    }
  },
  {
    path: '/tedaviler/invisalign',
    redirect: () => {
      const currentLocale = getLocale()
      return `/${slugs[currentLocale].treatments}/${slugs[currentLocale].invisalign}`
    }
  },
  // Dinamik olarak oluşturulan tedavi yolları
  ...createTreatmentRoutes(),
  
  // Yasal Sayfalar
  // KVKK sayfası dil bazlı yolları
  {
    path: `/yasal/kvkk-aydinlatma-metni`,
    name: 'kvkk-tr-direct-path',
    component: () => import('@/pages/legal/KVKKPage.vue'),
    meta: {
      title: 'KVKK Aydınlatma Metni | Klinik Akademi Diş Polikliniği',
      description: 'Klinik Akademi Diş Polikliniği KVKK Aydınlatma Metni ve kişisel verilerin korunması hakkında bilgi.'
    }
  },
  {
    path: `/${slugs['tr'].legal}/${slugs['tr'].kvkk}`,
    name: 'kvkk-tr',
    component: () => import('@/pages/legal/KVKKPage.vue'),
    meta: {
      title: 'KVKK Aydınlatma Metni | Klinik Akademi Diş Polikliniği',
      description: 'Klinik Akademi Diş Polikliniği KVKK Aydınlatma Metni ve kişisel verilerin korunması hakkında bilgi.'
    }
  },
  {
    path: '/kvkk',
    name: 'kvkk-direct',
    redirect: `/yasal/kvkk-aydinlatma-metni`,
    meta: {
      title: 'KVKK Aydınlatma Metni | Klinik Akademi Diş Polikliniği',
      description: 'Klinik Akademi Diş Polikliniği KVKK Aydınlatma Metni ve kişisel verilerin korunması hakkında bilgi.'
    }
  },
  {
    path: `/${slugs['en'].legal}/${slugs['en'].kvkk}`,
    name: 'kvkk-en',
    component: () => import('@/pages/legal/KVKKPage.vue'),
    meta: {
      title: 'Personal Data Protection Information | Klinik Akademi Dental Clinic',
      description: 'Klinik Akademi Dental Clinic Personal Data Protection Information and procedures.'
    }
  },
  {
    path: `/${slugs['en'].kvkk}`,
    name: 'kvkk-en-direct',
    redirect: `/${slugs['en'].legal}/${slugs['en'].kvkk}`,
    meta: {
      title: 'Personal Data Protection Information | Klinik Akademi Dental Clinic',
      description: 'Klinik Akademi Dental Clinic Personal Data Protection Information and procedures.'
    }
  },
  {
    path: `/${slugs['de'].legal}/${slugs['de'].kvkk}`,
    name: 'kvkk-de',
    component: () => import('@/pages/legal/KVKKPage.vue'),
    meta: {
      title: 'Datenschutz-Informationen | Klinik Akademi Zahnklinik',
      description: 'Klinik Akademi Zahnklinik Datenschutz-Informationen und Details zur Verarbeitung personenbezogener Daten.'
    }
  },
  {
    path: `/${slugs['de'].kvkk}`,
    name: 'kvkk-de-direct',
    redirect: `/${slugs['de'].legal}/${slugs['de'].kvkk}`,
    meta: {
      title: 'Datenschutz-Informationen | Klinik Akademi Zahnklinik',
      description: 'Klinik Akademi Zahnklinik Datenschutz-Informationen und Details zur Verarbeitung personenbezogener Daten.'
    }
  },
  
  // Gizlilik Politikası Sayfası
  {
    path: `/${slugs['tr'].legal}/${slugs['tr'].privacyPolicy}`,
    name: 'privacy-policy-tr',
    component: () => import('@/pages/legal/PrivacyPolicyPage.vue'),
    meta: {
      title: 'Gizlilik Politikası | Klinik Akademi Diş Polikliniği',
      description: 'Klinik Akademi Diş Polikliniği\'nin gizlilik politikası ve kişisel verilerin korunması hakkında bilgi.'
    }
  },
  {
    path: '/gizlilik-politikasi',
    name: 'privacy-policy-direct',
    redirect: `/${slugs['tr'].legal}/${slugs['tr'].privacyPolicy}`,
    meta: {
      title: 'Gizlilik Politikası | Klinik Akademi Diş Polikliniği',
      description: 'Klinik Akademi Diş Polikliniği\'nin gizlilik politikası ve kişisel verilerin korunması hakkında bilgi.'
    }
  },
  {
    path: `/${slugs['en'].legal}/${slugs['en'].privacyPolicy}`,
    name: 'privacy-policy-en',
    component: () => import('@/pages/legal/PrivacyPolicyPage.vue'),
    meta: {
      title: 'Privacy Policy | Klinik Akademi Dental Clinic',
      description: 'Klinik Akademi Dental Clinic\'s privacy policy and information about the protection of personal data.'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy-en-direct',
    redirect: `/${slugs['en'].legal}/${slugs['en'].privacyPolicy}`,
    meta: {
      title: 'Privacy Policy | Klinik Akademi Dental Clinic',
      description: 'Klinik Akademi Dental Clinic\'s privacy policy and information about the protection of personal data.'
    }
  },
  {
    path: `/${slugs['de'].legal}/${slugs['de'].privacyPolicy}`,
    name: 'privacy-policy-de',
    component: () => import('@/pages/legal/PrivacyPolicyPage.vue'),
    meta: {
      title: 'Datenschutzrichtlinie | Klinik Akademi Zahnklinik',
      description: 'Datenschutzrichtlinie der Klinik Akademi Zahnklinik und Informationen zum Schutz persönlicher Daten.'
    }
  },
  {
    path: '/datenschutzrichtlinie',
    name: 'privacy-policy-de-direct',
    redirect: `/${slugs['de'].legal}/${slugs['de'].privacyPolicy}`,
    meta: {
      title: 'Datenschutzrichtlinie | Klinik Akademi Zahnklinik',
      description: 'Datenschutzrichtlinie der Klinik Akademi Zahnklinik und Informationen zum Schutz persönlicher Daten.'
    }
  },
  
  // Çerez Politikası Sayfası
  {
    path: `/${slugs['tr'].legal}/${slugs['tr'].cookiePolicy}`,
    name: 'cookie-policy-tr',
    component: () => import('@/pages/legal/CookiePolicyPage.vue'),
    meta: {
      title: 'Çerez Politikası | Klinik Akademi Diş Polikliniği',
      description: 'Klinik Akademi Diş Polikliniği\'nin çerez kullanım politikası hakkında bilgi.'
    }
  },
  {
    path: '/cerez-politikasi',
    name: 'cookie-policy-direct',
    redirect: `/${slugs['tr'].legal}/${slugs['tr'].cookiePolicy}`,
    meta: {
      title: 'Çerez Politikası | Klinik Akademi Diş Polikliniği',
      description: 'Klinik Akademi Diş Polikliniği\'nin çerez kullanım politikası hakkında bilgi.'
    }
  },
  {
    path: `/${slugs['en'].legal}/${slugs['en'].cookiePolicy}`,
    name: 'cookie-policy-en',
    component: () => import('@/pages/legal/CookiePolicyPage.vue'),
    meta: {
      title: 'Cookie Policy | Klinik Akademi Dental Clinic',
      description: 'Information about the cookie usage policy of Klinik Akademi Dental Clinic.'
    }
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy-en-direct',
    redirect: `/${slugs['en'].legal}/${slugs['en'].cookiePolicy}`,
    meta: {
      title: 'Cookie Policy | Klinik Akademi Dental Clinic',
      description: 'Information about the cookie usage policy of Klinik Akademi Dental Clinic.'
    }
  },
  {
    path: `/${slugs['de'].legal}/${slugs['de'].cookiePolicy}`,
    name: 'cookie-policy-de',
    component: () => import('@/pages/legal/CookiePolicyPage.vue'),
    meta: {
      title: 'Cookie-Richtlinie | Klinik Akademi Zahnklinik',
      description: 'Informationen zur Cookie-Nutzungsrichtlinie der Klinik Akademi Zahnklinik.'
    }
  },
  {
    path: '/cookie-richtlinie',
    name: 'cookie-policy-de-direct',
    redirect: `/${slugs['de'].legal}/${slugs['de'].cookiePolicy}`,
    meta: {
      title: 'Cookie-Richtlinie | Klinik Akademi Zahnklinik',
      description: 'Informationen zur Cookie-Nutzungsrichtlinie der Klinik Akademi Zahnklinik.'
    }
  }
]

// Menü için tedavi listesi güncellendi
export const treatmentMenu = [
  {
    key: 'implant',
    icon: 'bi-plus-circle'
  },
  {
    key: 'orthodontics',
    icon: 'bi-arrows-expand'
  },
  {
    key: 'whitening',
    icon: 'bi-brightness-high'
  },
  {
    key: 'rootCanal',
    icon: 'bi-diagram-3'
  },
  {
    key: 'prosthesis',
    icon: 'bi-gem'
  },
  {
    key: 'periodontics',
    icon: 'bi-heart-pulse'
  },
  {
    key: 'pediatric',
    icon: 'bi-emoji-smile'
  },
  {
    key: 'surgery',
    icon: 'bi-bandaid'
  },
  {
    key: 'invisalign',
    icon: 'bi-badge-3d'
  }
]

// 404 Sayfası için route ekleyelim
routes.push({
  path: `/${slugs['tr'].notFound}`,
  name: 'not-found-tr',
  component: () => import('@/views/NotFound.vue')
})

routes.push({
  path: `/${slugs['en'].notFound}`,
  name: 'not-found-en',
  component: () => import('@/views/NotFound.vue')
})

routes.push({
  path: `/${slugs['de'].notFound}`,
  name: 'not-found-de',
  component: () => import('@/views/NotFound.vue')
})

// Bulunamayan tüm rotaları 404 sayfasına yönlendir
routes.push({
  path: '/:pathMatch(.*)*',
  redirect: () => {
    const currentLocale = getLocale()
    return `/${slugs[currentLocale].notFound}`
  }
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
