<template>
  <section class="hero">
    <div class="hero__shape"></div>
    <div class="hero__particles">
      <div class="particle" v-for="n in 50" :key="n"></div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6" data-aos="fade-right">
          <div class="hero__content">
            <div class="hero__badges">
              <AppBadge 
                icon="shield-check" 
                :text="$t('hero.badge1')"
                data-aos="fade-up"
                data-aos-delay="200"
              />
              <AppBadge 
                icon="award" 
                :text="$t('hero.badge2')"
                data-aos="fade-up"
                data-aos-delay="300"
              />
            </div>
            <h1 class="hero__title" data-aos="fade-up" data-aos-delay="400">
              {{ $t('hero.title') }}<br>
              <span class="hero__title-highlight">{{ $t('hero.subtitle') }}</span>
            </h1>
            <p class="hero__text" data-aos="fade-up" data-aos-delay="500">
              {{ $t('hero.description') }}
            </p>
            <div class="hero__buttons" data-aos="fade-up" data-aos-delay="600">
              <AppButton 
                variant="primary" 
                size="lg"
                icon="calendar-check"
                href="https://api.whatsapp.com/send?phone=905539736480"
              >
                {{ $t('hero.ctaButton') }}
              </AppButton>
              <AppButton 
                variant="outline" 
                size="lg"
                icon="arrow-right"
                href="/tedaviler"
              >
                {{ $t('hero.secondaryButton') }}
              </AppButton>
            </div>
            <div class="hero__features" data-aos="fade-up" data-aos-delay="700">
              <div class="hero__feature">
                <i class="bi bi-check-circle-fill"></i>
                <span>{{ $t('hero.feature1') }}</span>
              </div>
              <div class="hero__feature">
                <i class="bi bi-check-circle-fill"></i>
                <span>{{ $t('hero.feature2') }}</span>
              </div>
              <div class="hero__feature">
                <i class="bi bi-check-circle-fill"></i>
                <span>{{ $t('hero.feature3') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left">
          <div class="hero__image">
            <img src="@/assets/images/hero/home.png" alt="Doktor" class="img-fluid">
            <div class="hero__image-card" data-aos="fade-up" data-aos-delay="800">
              <div class="hero__image-card-icon">
                <i class="bi bi-calendar-check"></i>
              </div>
              <div class="hero__image-card-content">
                <h4>{{ $t('hero.cardTitle') }}</h4>
                <p>{{ $t('hero.cardText') }}</p>
              </div>
            </div>
            <div class="hero__image-badge" data-aos="fade-up" data-aos-delay="900">
              <div class="hero__image-badge-icon">
                <i class="bi bi-stars"></i>
              </div>
              <div class="hero__image-badge-content">
                <h4>{{ $t('hero.badgeTitle') }}</h4>
                <p>{{ $t('hero.badgeText') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import AppBadge from '@/components/common/AppBadge.vue'
import AppButton from '@/components/common/AppButton.vue'
import { useParticles } from '@/composables/useParticles'

export default defineComponent({
  name: 'HeroSection',
  components: {
    AppBadge,
    AppButton
  },
  setup() {
    useParticles()
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables';
@import '@/assets/styles/_mixins';

.hero {
  @include section-padding;
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  min-height: 100vh;
  position: relative;
  padding: 162px 0 0px;

  @media (max-width: $breakpoint-lg) {
    width: 100vw;
    max-width: 100%;
    padding: 150px 15px 0px;
  }

  @media (max-width: 991px) {
    padding-top: 100px;
    
    &__content {
      margin-top: 10px;
    }
  }

  @media (max-width: 768px) {
    padding-top: 90px;
    
    &__content {
      margin-top: -15px;
    }
  }

  &__shape {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.03);
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
    z-index: 1;
  }

  &__particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
  }

  .container {
    position: relative;
    z-index: 2;
    @media (max-width: $breakpoint-lg) {
      padding: 0 15px;
      width: 100%;
      max-width: 100%;
    }
  }

  .particle {
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    animation: float 20s linear infinite;
  }

  &__badges {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  &__title {
    color: $light-color;
    font-size: 4.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    font-family: $font-family-heading;
    line-height: 1.2;

    &-highlight {
      color: #FFD700;
      display: block;
    }
  }

  &__text {
    color: $light-color;
    font-size: 1.25rem;
    margin-bottom: 2.5rem;
    opacity: 0.9;
    max-width: 500px;
    line-height: 1.8;
  }

  &__buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 3rem;
  }

  &__features {
    display: flex;
    gap: 2rem;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__feature {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: $light-color;

    i {
      color: #FFD700;
      font-size: 1.25rem;
    }

    span {
      font-size: 1rem;
      opacity: 0.9;
    }
  }

  &__image {
    position: relative;
    
    img {
      width: 100%;
      height: auto;
      border-radius: $border-radius-xl;
      box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
    }

    &-card, &-badge {
      position: absolute;
      background: $light-color;
      padding: 1.5rem;
      border-radius: $border-radius-lg;
      display: flex;
      align-items: center;
      gap: 1rem;
      @include card-shadow;
    }

    &-card {
      bottom: 30px;
      left: -30px;

      &-icon {
        width: 50px;
        height: 50px;
        background: $primary-color;
        border-radius: $border-radius-md;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $light-color;
        font-size: 1.5rem;
      }

      &-content {
        h4 {
          color: $text-color;
          font-size: 1.1rem;
          font-weight: 600;
          margin-bottom: 0.25rem;
        }

        p {
          color: $text-color;
          opacity: 0.8;
          margin: 0;
          font-size: 0.9rem;
        }
      }
    }

    &-badge {
      top: 30px;
      right: -30px;
      padding: 1rem;

      &-icon {
        width: 40px;
        height: 40px;
        background: rgba($primary-color, 0.1);
        border-radius: $border-radius-sm;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary-color;
        font-size: 1.25rem;
      }

      &-content {
        h4 {
          color: $primary-color;
          font-size: 1.25rem;
          font-weight: 700;
          margin-bottom: 0.25rem;
        }

        p {
          color: $text-color;
          opacity: 0.8;
          margin: 0;
          font-size: 0.9rem;
        }
      }
    }
  }

  &__content {
    @media (max-width: $breakpoint-lg) {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }
}

@keyframes float {
  0% { transform: translateY(0) translateX(0) rotate(0); }
  25% { transform: translateY(-10px) translateX(5px) rotate(45deg); }
  50% { transform: translateY(-20px) translateX(-5px) rotate(90deg); }
  75% { transform: translateY(-10px) translateX(5px) rotate(135deg); }
  100% { transform: translateY(0) translateX(0) rotate(360deg); }
}

@media (max-width: $breakpoint-sm) {
  .hero {
    &__title {
      font-size: 2.5rem;
    }

    &__buttons {
      flex-direction: column;
    }

    &__features {
      flex-direction: column;
      align-items: center;
    }
  }
}


</style> 