<template>
  <div class="treatments-page">
    <SeoHead
      :title="$t('treatments.seo.title')"
      :description="$t('treatments.seo.description')"
    />

    <!-- Hero Section -->
    <section class="hero-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6" data-aos="fade-right">
            <div class="hero-content">
              <h1 class="section-title">
                <span class="dark-text">{{ $t('treatments.hero.title') }}</span><br>
                <span class="gradient-text highlight-group">{{ $t('treatments.hero.subtitle') }}</span>
              </h1>
              <p class="section-text">
                {{ $t('treatments.hero.text1') }}
              </p>
              <p class="section-text mt-3">
                {{ $t('treatments.hero.text2') }}
              </p>
              
              <div class="service-grid">
                <div class="service-item">
                  <div class="service-icon">
                    <i class="bi bi-shield-check"></i>
                  </div>
                  <h3>{{ $t('treatments.hero.features.expert') }}</h3>
                </div>
                <div class="service-item">
                  <div class="service-icon">
                    <i class="bi bi-heart-pulse"></i>
                  </div>
                  <h3>{{ $t('treatments.hero.features.patient') }}</h3>
                </div>
                <div class="service-item">
                  <div class="service-icon">
                    <i class="bi bi-award"></i>
                  </div>
                  <h3>{{ $t('treatments.hero.features.quality') }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6" data-aos="fade-left">
            <div class="doctor-card">
              <div class="image-wrapper">
                <img src="@/assets/images/treatments/img-service.png" :alt="$t('treatments.hero.imageAlt')">
              </div>
              <div class="info-badge">
                <div class="icon">
                  <i class="bi bi-stars"></i>
                </div>
                <div class="text">
                  {{ $t('treatments.hero.experience') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Treatments Grid Section -->
    <section class="treatments-grid">
      <div class="container">
        <div class="section-header text-center" data-aos="fade-up">
          <span class="section-badge">
            <i class="bi bi-plus-circle me-2"></i>
            {{ $t('treatments.grid.badge') }}
          </span>
          <h2 class="section-title">
            {{ $t('treatments.grid.title') }}<br>
            <span class="gradient-text">{{ $t('treatments.grid.subtitle') }}</span>
          </h2>
        </div>
        <div class="row g-4">
          <div v-for="treatment in treatments" :key="treatment.id" class="col-lg-4 col-md-6" data-aos="fade-up">
            <router-link :to="treatment.link" class="treatment-card">
              <div class="treatment-image">
                <img :src="treatment.image" :alt="treatment.title">
                <div class="treatment-overlay">
                  <i class="bi bi-arrow-right"></i>
                </div>
              </div>
              <div class="treatment-content">
                <h3>{{ treatment.title }}</h3>
                <p>{{ treatment.description }}</p>
                <ul class="treatment-features">
                  <li v-for="(feature, index) in treatment.features" :key="index">
                    <i class="bi bi-check-circle"></i>
                    {{ feature }}
                  </li>
                </ul>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <!-- Why Us Section -->
    <WhyUsSection :stats="stats" />

    <!-- Contact Section -->
    <ContactSection />
  </div>
</template>

<script>
import SeoHead from '../components/common/SeoHead.vue'
import WhyUsSection from '../components/home/WhyUsSection.vue'
import ContactSection from '../components/home/ContactSection.vue'
import { stats } from '../constants/stats'
import { getTreatmentRoute } from '../utils/slug'

export default {
  name: 'TreatmentsView',
  components: {
    SeoHead,
    WhyUsSection,
    ContactSection
  },
  data() {
    return {
      stats,
      treatments: this.getLocalizedTreatments()
    }
  },
  created() {
    this.updateTreatments()
  },
  watch: {
    '$i18n.locale': function() {
      this.updateTreatments()
    }
  },
  methods: {
    getLocalizedTreatments() {
      return [
        {
          id: 1,
          title: this.$t('treatments.items.implant.title'),
          description: this.$t('treatments.items.implant.description'),
          image: require('@/assets/images/treatments/dentist.png'),
          link: getTreatmentRoute('implant'),
          features: [
            this.$t('treatments.items.implant.feature1'),
            this.$t('treatments.items.implant.feature2'),
            this.$t('treatments.items.implant.feature3')
          ]
        },
        {
          id: 2,
          title: this.$t('treatments.items.orthodontics.title'),
          description: this.$t('treatments.items.orthodontics.description'),
          image: require('@/assets/images/treatments/img-service.png'),
          link: getTreatmentRoute('orthodontics'),
          features: [
            this.$t('treatments.items.orthodontics.feature1'),
            this.$t('treatments.items.orthodontics.feature2'),
            this.$t('treatments.items.orthodontics.feature3')
          ]
        },
        {
          id: 3,
          title: this.$t('treatments.items.whitening.title'),
          description: this.$t('treatments.items.whitening.description'),
          image: require('@/assets/images/treatments/whitening.png'),
          link: getTreatmentRoute('whitening'),
          features: [
            this.$t('treatments.items.whitening.feature1'),
            this.$t('treatments.items.whitening.feature2'),
            this.$t('treatments.items.whitening.feature3')
          ]
        },
        {
          id: 4,
          title: this.$t('treatments.items.rootCanal.title'),
          description: this.$t('treatments.items.rootCanal.description'),
          image: require('@/assets/images/treatments/responsibilities.png'),
          link: getTreatmentRoute('rootCanal'),
          features: [
            this.$t('treatments.items.rootCanal.feature1'),
            this.$t('treatments.items.rootCanal.feature2'),
            this.$t('treatments.items.rootCanal.feature3')
          ]
        },
        {
          id: 5,
          title: this.$t('treatments.items.prosthesis.title'),
          description: this.$t('treatments.items.prosthesis.description'),
          image: require('@/assets/images/treatments/dentist.png'),
          link: getTreatmentRoute('prosthesis'),
          features: [
            this.$t('treatments.items.prosthesis.feature1'),
            this.$t('treatments.items.prosthesis.feature2'),
            this.$t('treatments.items.prosthesis.feature3')
          ]
        },
        {
          id: 6,
          title: this.$t('treatments.items.periodontics.title'),
          description: this.$t('treatments.items.periodontics.description'),
          image: require('@/assets/images/treatments/img-service.png'),
          link: getTreatmentRoute('periodontics'),
          features: [
            this.$t('treatments.items.periodontics.feature1'),
            this.$t('treatments.items.periodontics.feature2'),
            this.$t('treatments.items.periodontics.feature3')
          ]
        },
        {
          id: 7,
          title: this.$t('treatments.items.pediatric.title'),
          description: this.$t('treatments.items.pediatric.description'),
          image: require('@/assets/images/treatments/whitening.png'),
          link: getTreatmentRoute('pediatric'),
          features: [
            this.$t('treatments.items.pediatric.feature1'),
            this.$t('treatments.items.pediatric.feature2'),
            this.$t('treatments.items.pediatric.feature3')
          ]
        },
        {
          id: 8,
          title: this.$t('treatments.items.surgery.title'),
          description: this.$t('treatments.items.surgery.description'),
          image: require('@/assets/images/treatments/responsibilities.png'),
          link: getTreatmentRoute('surgery'),
          features: [
            this.$t('treatments.items.surgery.feature1'),
            this.$t('treatments.items.surgery.feature2'),
            this.$t('treatments.items.surgery.feature3')
          ]
        },
        {
          id: 9,
          title: this.$t('treatments.items.invisalign.title'),
          description: this.$t('treatments.items.invisalign.description'),
          image: require('@/assets/images/treatments/img-service.png'),
          link: getTreatmentRoute('invisalign'),
          features: [
            this.$t('treatments.items.invisalign.feature1'),
            this.$t('treatments.items.invisalign.feature2'),
            this.$t('treatments.items.invisalign.feature3')
          ]
        }
      ]
    },
    updateTreatments() {
      this.treatments = this.getLocalizedTreatments()
    }
  }
}
</script>

<style lang="scss" scoped>
.treatments-page {
  padding-top: 0;
  background: linear-gradient(135deg, rgba(var(--secondary-color-rgb), 0.02), rgba(var(--primary-color-rgb), 0.05));

  // Hero Section
  .hero-section {
    padding: 160px 0 100px;
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    background: linear-gradient(135deg, rgba(var(--secondary-color-rgb), 0.02), rgba(var(--primary-color-rgb), 0.05));

    .container {
      position: relative;
      z-index: 1;
    }

    .hero-content {
      position: relative;
      
      .section-title {
        font-size: 3.4rem;
        font-weight: 800;
        margin-bottom: 2rem;
        line-height: 1.2;

        .dark-text {
          color: var(--secondary-color);
        }

        .gradient-text {
          background: linear-gradient(120deg, var(--primary-color), var(--secondary-color));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .highlight-group {
          position: relative;
          display: inline-block;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 3px;
            background: var(--primary-color);
            border-radius: 2px;
            opacity: 0.5;
          }
        }
      }

      .section-text {
        font-size: 1.2rem;
        line-height: 1.6;
        color: var(--text-color-secondary);
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .service-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
        margin-top: 2rem;

        .service-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 1.5rem;
          background: rgba(var(--primary-color-rgb), 0.03);
          border-radius: var(--border-radius-md);
          transition: var(--transition-normal);
          border: 1px solid rgba(var(--primary-color-rgb), 0.1);

          &:hover {
            background: rgba(var(--primary-color-rgb), 0.05);
            transform: translateY(-2px);
            box-shadow: 0 10px 20px rgba(var(--primary-color-rgb), 0.1);
          }

          .service-icon {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--primary-color);
            border-radius: 12px;
            color: white;
            font-size: 1.4rem;
            margin-bottom: 1rem;
          }

          h3 {
            font-size: 1.1rem;
            font-weight: 600;
            color: var(--secondary-color);
            margin: 0;
          }
        }
      }
    }

    .doctor-card {
      position: relative;
      padding: 2rem;

      .image-wrapper {
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      .info-badge {
        position: absolute;
        bottom: 3rem;
        right: 3rem;
        background: white;
        padding: 1.2rem 1.8rem;
        border-radius: 50px;
        box-shadow: var(--shadow-md);
        display: flex;
        align-items: center;
        gap: 1rem;
        z-index: 3;

        .icon {
          color: var(--primary-color);
          font-size: 1.4rem;
        }

        .text {
          font-weight: 600;
          color: var(--text-color);
          font-size: 1.1rem;
        }
      }
    }
  }

  .treatments-grid {
    padding: 100px 0;
    background: var(--light-color);

    .section-header {
      margin-bottom: 50px;

      .section-badge {
        display: inline-flex;
        align-items: center;
        background: var(--primary-color);
        color: #fff;
        padding: 8px 16px;
        border-radius: 50px;
        margin-bottom: 20px;
        font-size: 0.9rem;

        i {
          margin-right: 8px;
        }
      }

      .section-title {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 20px;
        line-height: 1.2;

        .gradient-text {
          background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .treatment-card {
      display: block;
      background: #fff;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      text-decoration: none;
      color: inherit;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);

        .treatment-overlay {
          opacity: 1;
        }
      }

      .treatment-image {
        position: relative;
        height: 250px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }

        .treatment-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(var(--primary-rgb), 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.3s ease;

          i {
            font-size: 2rem;
            color: #fff;
          }
        }
      }

      .treatment-content {
        padding: 30px;

        h3 {
          font-size: 1.5rem;
          margin-bottom: 15px;
          color: var(--dark-color);
        }

        p {
          color: var(--text-color);
          margin-bottom: 20px;
        }

        .treatment-features {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: var(--text-color);

            i {
              color: var(--primary-color);
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .treatments-page {
    .hero-section {
      padding: 100px 0 60px;
      min-height: auto;

      .hero-content {
        .section-title {
          font-size: 2.8rem;
        }

        .service-grid {
          grid-template-columns: 1fr;
          gap: 1rem;
        }
      }

      .doctor-card {
        padding: 1rem;
        margin-top: 2rem;
      }
    }
  }
}

@media (max-width: 767px) {
  .treatments-page {
    .hero-section {
      padding: 80px 0 40px;

      .hero-content {
        .section-title {
          font-size: 2.4rem;
        }

        .section-text {
          font-size: 1.1rem;
        }
      }

      .doctor-card {
        .info-badge {
          bottom: 2rem;
          right: 2rem;
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}
</style> 