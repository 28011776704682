import { onUnmounted } from 'vue'

export function useSchema() {
  let scriptElement = null

  const injectSchema = (schema) => {
    scriptElement = document.createElement('script')
    scriptElement.setAttribute('type', 'application/ld+json')
    scriptElement.textContent = JSON.stringify(schema)
    document.head.appendChild(scriptElement)
  }

  onUnmounted(() => {
    if (scriptElement && scriptElement.parentNode) {
      document.head.removeChild(scriptElement)
    }
  })

  return {
    injectSchema
  }
} 