<template>
  <section class="why-us">
    <div class="container">
      <div class="row align-items-center g-5">
        <div class="col-lg-6 order-lg-1 order-2" data-aos="fade-right">
          <div class="why-us__image">
            <img src="@/assets/images/home/toothhealth.png" alt="Neden Biz?" class="img-fluid">
            <div class="floating-badge">
              <i class="bi bi-award"></i>
              <span>{{ $t('whyUs.floatingBadge') }}</span>
            </div>
          </div>
        </div>

        <div class="col-lg-6 order-lg-2 order-1" data-aos="fade-left">
          <div class="why-us__content">
            <span class="section-badge">{{ $t('whyUs.badge') }}</span>
            <h2 class="section-title">
              {{ $t('whyUs.title') }}<br>
              <span class="gradient-text">{{ $t('whyUs.subtitle') }}</span>
            </h2>
            <div class="why-us__text">
              <p>{{ $t('whyUs.description') }}</p>
            </div>

            <div class="why-us__features">
              <div class="feature-item">
                <i class="bi bi-check2-circle"></i>
                <span>{{ $t('whyUs.feature1') }}</span>
              </div>
              <div class="feature-item">
                <i class="bi bi-heart-pulse"></i>
                <span>{{ $t('whyUs.feature2') }}</span>
              </div>
              <div class="feature-item">
                <i class="bi bi-shield-check"></i>
                <span>{{ $t('whyUs.feature3') }}</span>
              </div>
            </div>

            <div class="text-center mt-4">
              <router-link :to="getPageRoute('about')" class="btn btn-gradient w-100">
                <i class="bi bi-arrow-right me-2"></i>
                {{ $t('whyUs.button') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getPageRoute } from '@/utils/slug'

export default {
  name: 'WhyUsSection',
  methods: {
    getPageRoute(pageKey) {
      return getPageRoute(pageKey)
    }
  }
}
</script>

<style lang="scss" scoped>
.why-us {
  padding: 60px 0;
  background: white;

  &__content {
    padding-left: 3rem;

    .section-badge {
      display: inline-block;
      padding: 8px 16px;
      background: rgba(0, 164, 166, 0.1);
      color: var(--primary-color);
      border-radius: 50px;
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
    }

    .section-title {
      font-size: 2.8rem;
      font-weight: 800;
      margin-bottom: 1.5rem;
      line-height: 1.2;
      color: var(--heading-color);

      .gradient-text {
        background: linear-gradient(135deg, #00A4A6 0%, #004E50 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    @media (max-width: 992px) {
      padding-left: 0;
      text-align: center;
      margin-bottom: 0;
    }
  }

  &__text {
    p {
      font-size: 1.1rem;
      line-height: 1.8;
      color: var(--text-color-secondary);
      opacity: 0.9;
      margin-bottom: 2rem;
    }
  }

  &__features {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 2rem 0;

    .feature-item {
      flex: 1;
      min-width: 150px;
      padding: 1.5rem;
      background: white;
      border-radius: 12px;
      display: flex;
      align-items: center;
      gap: 10px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
      transition: transform 0.3s ease;

      i {
        font-size: 1.5rem;
        color: var(--primary-color);
        opacity: 0.9;
      }

      span {
        font-weight: 600;
        color: var(--heading-color);
        font-size: 1rem;
      }

      &:hover {
        transform: translateY(-5px);
        background: linear-gradient(135deg, rgba(0, 164, 166, 0.05) 0%, rgba(0, 78, 80, 0.02) 100%);
      }
    }
  }

  &__image {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    margin: 3rem 0;

    img {
      width: 100%;
      height: auto;
      transition: transform 0.5s ease;
    }

    .floating-badge {
      position: absolute;
      bottom: 20px;
      left: 20px;
      background: rgba(255, 255, 255, 0.95);
      padding: 12px 24px;
      border-radius: 50px;
      display: flex;
      align-items: center;
      gap: 10px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

      i {
        color: var(--primary-color);
        font-size: 1.2rem;
      }

      span {
        font-weight: 500;
        color: var(--text-color);
      }
    }

    &:hover img {
      transform: scale(1.05);
    }

    @media (max-width: 992px) {
      margin: 0;
    }
  }

  .btn-gradient {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: white;
    padding: 1rem 0;
    border-radius: 50px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    transition: all 0.3s ease;
    border: none;
    box-shadow: 0 8px 25px rgba(0, 164, 166, 0.25);
    text-decoration: none;

    i {
      font-size: 1.3rem;
    }

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 12px 30px rgba(0, 164, 166, 0.35);
    }
  }

  @media (max-width: 992px) {
    .order-1 {
      order: 1 !important;
    }
    .order-2 {
      order: 2 !important;
    }

    &__features {
      justify-content: center;
    }

    .btn-gradient {
      margin: 0 auto;
    }
  }

  @media (max-width: 576px) {
    &__features {
      flex-direction: column;

      .feature-item {
        width: 100%;
      }
    }
  }
}
</style> 