<template>
  <div class="team-page">
    <!-- Hero Section -->
    <section class="hero-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6" data-aos="fade-right">
            <div class="hero-content">
              <h2 class="section-title">
                <span class="dark-text">{{ $t('team.hero.title') }}</span><br>
                <span class="gradient-text highlight-group">{{ $t('team.hero.highlight') }}</span>
              </h2>
              <p class="section-text">
                {{ $t('team.hero.text1') }}
              </p>
              <p class="section-text mt-3">
                {{ $t('team.hero.text2') }}
              </p>
              
              <div class="service-grid">
                <div class="service-item">
                  <div class="service-icon">
                    <i class="bi bi-shield-check"></i>
                  </div>
                  <h3>{{ $t('team.hero.services.expert') }}</h3>
                </div>
                <div class="service-item">
                  <div class="service-icon">
                    <i class="bi bi-heart-pulse"></i>
                  </div>
                  <h3>{{ $t('team.hero.services.patient') }}</h3>
                </div>
                <div class="service-item">
                  <div class="service-icon">
                    <i class="bi bi-award"></i>
                  </div>
                  <h3>{{ $t('team.hero.services.quality') }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6" data-aos="fade-left">
            <div class="doctor-card">
              <div class="image-wrapper">
                <img src="@/assets/images/about/team.png" alt="{{ $t('team.section.teamAlt') }}">
              </div>
              <div class="info-badge">
                <div class="icon">
                  <i class="bi bi-stars"></i>
                </div>
                <div class="text">
                  {{ $t('team.hero.experience') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Team Section -->
    <section class="team-section">
      <div class="container">
        <div class="section-header text-center" data-aos="fade-up">
          <span class="section-badge">
            <i class="bi bi-people me-2"></i>
            {{ $t('team.section.badge') }}
          </span>
          <h2 class="section-title">
            {{ $t('team.section.title') }}<br>
            <span class="gradient-text">{{ $t('team.section.highlight') }}</span>
          </h2>
        </div>

        <div class="row g-4">
          <div class="col-lg-3 col-md-4 col-sm-6" v-for="member in teamMembers" :key="member.name">
            <div class="doctor-card" data-aos="fade-up">
              <div class="image-wrapper">
                <img :src="member.image" :alt="member.name">
                <div class="card-overlay"></div>
                <div class="info-badge">
                  <div class="icon">
                    <i class="bi bi-shield-check"></i>
                  </div>
                  <div class="text">{{ member.title }}</div>
                </div>
              </div>
              <div class="doctor-info">
                <h3>{{ member.name }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Testimonials Section'ı kaldır -->
    <!-- <TestimonialsSection /> -->

    <!-- Join Team Section -->
    <section class="join-team-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="join-team-content">
              <h2 class="gradient-text ">{{ $t('team.join.title1') }}</h2>
              <h3>{{ $t('team.join.title2') }}</h3>
              <p class="team-description">
                {{ $t('team.join.description') }}
              </p>
              <a href="#apply" class="btn btn-primary">
                <i class="bi bi-person-plus"></i>
                {{ $t('team.join.button') }}
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="join-team-image-wrapper">
              <img src="@/assets/images/team/dentist.png" alt="{{ $t('team.join.imageAlt') }}" class="join-team-image">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// TestimonialsSection import'unu kaldır
// import TestimonialsSection from '@/components/shared/TestimonialsSection.vue'
export default {
  name: 'TeamView',
  components: {
    // TestimonialsSection'ı kaldır
    // TestimonialsSection
  },
  data() {
    return {
      teamMembers: [
        {
          name: 'HARUN ATAY',
          title: '',
          image: '/images/team/harun-atay.png'
        },
        {
          name: 'EZGİ TÜFEKÇİ',
          title: '',
          image: '/images/team/ezgi-tufekci.png'
        },
        {
          name: 'MURAT CAN ADIYAMAN',
          title: '',
          image: '/images/team/murat-can-adiyaman.png'
        },
        {
          name: 'AYGÜL BÜŞRA DİRİK',
          title: '',
          image: '/images/team/aygul-dirik.png'
        },
        {
          name: 'ECE İLDENİZ',
          title: '',
          image: '/images/team/ece-ildeniz.png'
        },
        {
          name: 'DAMLA ŞEN',
          title: '',
          image: '/images/team/damla-sen.png'
        },
        {
          name: 'VEYSEL ÖMER ERDİL',
          title: '',
          image: '/images/team/veysel-omer-erdil.png'
        },
        {
          name: 'KİBAR HAFİF',
          title: '',
          image: '/images/team/kibar-hafif.png'
        }
      ]
    }
  },
  created() {
    // Ekip üyelerinin unvanlarını güncelle
    this.updateTeamMemberTitles();
  },
  updated() {
    // Dil değiştiğinde unvanları güncelle
    this.updateTeamMemberTitles();
  },
  methods: {
    updateTeamMemberTitles() {
      this.teamMembers[0].title = this.$t('team.members.owner');
      this.teamMembers[1].title = this.$t('team.members.gm');
      this.teamMembers[2].title = this.$t('team.members.bm');
      this.teamMembers[3].title = this.$t('team.members.dentist');
      this.teamMembers[4].title = this.$t('team.members.dentist');
      this.teamMembers[5].title = this.$t('team.members.dentist');
      this.teamMembers[6].title = this.$t('team.members.dentist');
      this.teamMembers[7].title = this.$t('team.members.chief');
    }
  }
}
</script>

<style lang="scss" scoped>
// Yeni renk değişkenleri
$gradient-primary: linear-gradient(135deg, #00A4A6 0%, #004E50 100%);
$gradient-light: linear-gradient(135deg, rgba(0, 164, 166, 0.1) 0%, rgba(0, 78, 80, 0.05) 100%);
$accent-color: #FF6B6B;
$success-color: #20C997;

.team-page {
  padding-top: 80px;
  background: linear-gradient(135deg, 
    rgba(var(--secondary-color-rgb), 0.02),
    rgba(var(--primary-color-rgb), 0.05)
  );

  // Hero Section styles
  .hero-section {
    padding: 100px 0 100px 0px;
    position: relative;
    background: linear-gradient(135deg, rgba(var(--secondary-color-rgb), 0.03) 0%, rgba(var(--primary-color-rgb), 0.05) 100%);
    overflow: hidden;

    .floaters {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 0;

      .floater {
        position: absolute;
        width: 300px;
        height: 300px;

        &-1 {
          top: -10%;
          left: -5%;
          animation: float 15s ease-in-out infinite;
        }

        &-2 {
          top: 40%;
          right: -10%;
          width: 250px;
          height: 250px;
          animation: float 18s ease-in-out infinite reverse;
        }

        &-3 {
          bottom: -15%;
          left: 35%;
          width: 200px;
          height: 200px;
          animation: float 20s ease-in-out infinite;
        }
      }
    }

    .hero-content {
      .section-badge {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.6rem 1.2rem;
        background: rgba(var(--primary-color-rgb), 0.1);
        color: var(--primary-color);
        border-radius: 50px;
        margin-bottom: 1.5rem;
        
        i {
          font-size: 1.2rem;
        }
      }

      .section-title {
        font-size: 3.5rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        line-height: 1.2;

        br {
          display: block;
          content: "";
          margin-bottom: 0.5rem;
        }

        .dark-text {
          color: #2c3e50;
          display: inline-block;
        }

        .gradient-text {
          background: linear-gradient(120deg, var(--primary-color), var(--secondary-color));
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          display: inline-block;
        }

        .highlight-group {
          background: linear-gradient(120deg, var(--primary-color), var(--secondary-color));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          display: inline-block;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 3px;
            background: var(--primary-color);
            border-radius: 2px;
            opacity: 0.5;
          }
        }
      }

      .section-text {
        font-size: 1.1rem;
        line-height: 1.8;
        color: var(--text-color);
        margin-bottom: 2.5rem;
        opacity: 0.9;
      }

      .service-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;

        .service-item {
          background: white;
          padding: 1.5rem;
          border-radius: 16px;
          text-align: center;
          transition: all 0.3s ease;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);

          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 15px 30px rgba(var(--primary-color-rgb), 0.1);

            .service-icon {
              background: var(--primary-color);
              color: white;
            }
          }

          .service-icon {
            width: 50px;
            height: 50px;
            background: rgba(var(--primary-color-rgb), 0.1);
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            color: var(--primary-color);
            margin: 0 auto 1rem;
            transition: all 0.3s ease;
          }

          h3 {
            font-size: 1rem;
            font-weight: 600;
            color: var(--secondary-color);
            margin: 0;
          }
        }
      }
    }

    .doctor-card {
      position: relative;
      padding: 1.5rem;

      .image-wrapper {
        width: 100%;
        border-radius: 30px;
        box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;

        &:hover {
          transform: translateY(-10px);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        transition: transform 0.4s ease;
      }

      .info-badge {
        position: absolute;
        bottom: 40px;
        left: 0;
        background: white;
        padding: 1.2rem;
        border-radius: 20px;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        gap: 1rem;

        .icon {
          width: 32px;
          height: 32px;
          background: rgba(var(--primary-color-rgb), 0.1);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--primary-color);
          font-size: 1.1rem;
        }

        .text {
          font-size: 0.85rem;
          font-weight: 600;
          color: var(--secondary-color);
        }
      }

      &:hover {
        transform: translateY(-10px);
      }
    }
  }

  // Team Section
  .team-section {
    padding: 30px 0;
    background: linear-gradient(135deg,
      rgba(var(--secondary-color-rgb), 0.03) 0%,
      rgba(var(--primary-color-rgb), 0.05) 100%
    );

    .section-header {
      text-align: center;
      margin-bottom: 3rem;

      .section-badge {
        display: inline-flex;
        align-items: center;
        padding: 0.6rem 1.2rem;
        background: rgba(var(--primary-color-rgb), 0.1);
        color: var(--primary-color);
        border-radius: 50px;
        font-size: 0.95rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }

      .section-title {
        font-size: 2.5rem;
        font-weight: 700;
        color: var(--heading-color);
        margin-bottom: 1rem;
      }
    }

    .doctor-card {
      height: 100%;
      position: relative;
      border-radius: 30px;
      padding: 20px;
      background: white;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
      transition: all 0.4s ease;
      overflow: hidden;
      
      &:hover {
        transform: translateY(-10px);
        
        .image-wrapper {
          img {
            transform: scale(1.1);
          }
          
          .card-overlay {
            opacity: 0.3;
          }
          
        }
      }
      
      .image-wrapper {
        width: 100%;
        aspect-ratio: 3/4;
        border-radius: 20px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
          transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
        }

        .card-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            135deg,
            rgba(var(--primary-color-rgb), 0.2),
            rgba(var(--secondary-color-rgb), 0.1)
          );
          opacity: 0;
          transition: all 0.4s ease;
          z-index: 1;
        }

        .info-badge {
          position: absolute;
          bottom: 20px;
          left: 20px;
          background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
          padding: 12px 20px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          gap: 10px;
          z-index: 2;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);

          .icon {
            width: 32px;
            height: 32px;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 1.1rem;
          }

          .text {
            font-size: 0.85rem;
            font-weight: 600;
            color: white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 150px;
          }
        }
      }

      .doctor-info {
        padding: 1.5rem 0.5rem 0.5rem;
        text-align: center;

        h3 {
          font-size: 1.1rem;
          font-weight: 700;
          color: var(--secondary-color);
          margin-bottom: 0.5rem;
        }

        p {
          font-size: 0.9rem;
          color: var(--text-color);
          opacity: 0.8;
          margin: 0;
        }
      }
    }
  }

  // Testimonials Section styles
  .testimonials-section {
    padding: 80px 0;
    background: #f8f9fa;

    .testimonials-slider {
      padding: 20px 0 40px;
      
      :deep(.swiper-pagination) {
        bottom: 0;
        
        .swiper-pagination-bullet {
          background: $accent-color;
          opacity: 0.5;
          
          &-active {
            opacity: 1;
            background: $accent-color;
          }
        }
      }
    }

    .testimonial-card {
      background: white;
      border-radius: 20px;
      padding: 2rem;
      height: 320px;
      display: flex;
      flex-direction: column;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
      }

      &__quote {
        font-size: 4rem;
        color: $accent-color;
        opacity: 0.15;
        margin-bottom: 1rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: $gradient-primary;
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }

      &__text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__link {
        color: $accent-color;
        font-size: 0.9rem;
        text-decoration: none;
        display: block;
        margin-bottom: 1rem;
        transition: all 0.3s ease;
        
        &:hover {
          color: darken($accent-color, 10%);
        }
      }

      &__author {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: auto;
        
        &-info {
          flex-grow: 1;
        }

        &-name {
          font-size: 1.1rem;
          font-weight: 600;
          margin-bottom: 0.25rem;
          color: var(--text-color);
        }

        &-date {
          font-size: 0.9rem;
          color: var(--text-color-secondary);
          margin: 0;
        }
      }
    }
  }

  // Join Team Section styles
  .join-team-section {
    padding: 80px 0;
    background: white;

    .join-team-content {
      h2 {
        color: var(--primary-color);
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }

      h3 {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
      }

      p {
        font-size: 1.1rem;
        line-height: 1.8;
        margin-bottom: 2rem;
      }

      .btn {
        padding: 1rem 2rem;
        font-size: 1.1rem;
        background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
        width: 100% !important;
        border-radius: 10px;
      }
      
      .btn:hover {
        transform: translateY(-2px);
        box-shadow: 0 12px 30px rgba(0, 164, 166, 0.35);
      }
    }

    .join-team-image-wrapper {
      position: relative;
      border-radius: 30px;
      overflow: hidden;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
      margin-top: 1rem;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(135deg, rgba(58, 161, 165, 0.2), rgba(58, 161, 165, 0.1));
        z-index: 0;
      }
      
      .join-team-image {
        width: 100%;
        height: auto;
        object-fit: cover;
        transition: all 0.5s ease;
      }
      
      &:hover .join-team-image {
        transform: scale(1.05);
      }
    }
  }

  .section-pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    
    .pattern {
      position: absolute;
      opacity: 0.1;
      width: 300px;
      height: 300px;
      background-image: radial-gradient(circle, var(--primary-color) 2px, transparent 2px);
      background-size: 30px 30px;
      
      &-1 {
        top: -10%;
        left: -5%;
        animation: float 6s ease-in-out infinite;
        transform: rotate(15deg);
      }
      
      &-2 {
        bottom: -10%;
        right: -5%;
        animation: float 8s ease-in-out infinite reverse;
        transform: rotate(-15deg);
        background-image: radial-gradient(circle, var(--primary-color) 1px, transparent 1px);
        background-size: 20px 20px;
      }
    }
  }

  .hero-features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    margin-top: 2rem;
    
    .feature-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      background: white;
      border-radius: 12px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
      }
      
      i {
        font-size: 1.5rem;
        color: var(--primary-color);
      }
      
      span {
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }

  .section-header {
    margin-bottom: 4rem;
    
    .section-subtitle {
      color: var(--primary-color);
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 1rem;
      display: block;
    }
    
    .section-title {
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
    
    .section-description {
      font-size: 1.1rem;
      color: var(--text-color-secondary);
      max-width: 600px;
      margin: 0 auto;
    }
  }

  .hero-image-wrapper {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(135deg, rgba(58, 161, 165, 0.2), rgba(58, 161, 165, 0.1));
      z-index: 1;
    }

    .hero-image {
      position: relative;
      padding: 1.5rem;
    }

    &:hover .hero-image {
      transform: scale(1.05);
    }
  }
}

@keyframes float {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(15px, -15px) rotate(5deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

// Responsive styles
@media (max-width: 992px) {
  .hero-section {
    padding: 10px 0 10px !important;
    
    .row {
      flex-direction: row !important;
    }

    .col-lg-6 {
      width: 100%;
      
      &:nth-child(2) { // Fotoğraf
        order: 2;
        margin-top: 2rem;
      }
      
      &:nth-child(1) { // İçerik
        order: 1;
        padding-top: 10px !important;
      }
    }

    .hero-content {
      .section-title {
        font-size: 2.8rem;
        
        .highlight-group::after {
          bottom: 5px;
          height: 10px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 40px 0;

    .hero-content {
      .section-title {
        font-size: 2.2rem;
        
        .highlight-group::after {
          bottom: 3px;
          height: 8px;
        }
      }

      .service-grid {
        gap: 1rem;
      }
    }
  }

  .team-section {
    padding: 60px 0;
    
    .doctor-card {
      padding: 15px;
      
      .image-wrapper {
        .info-badge {
          padding: 8px 15px;
          
          .icon {
            width: 28px;
            height: 28px;
            font-size: 1rem;
          }
          
          .text {
            font-size: 0.8rem;
            max-width: 120px;
          }
        }
      }
      
      .doctor-info {
        padding: 1rem 0.5rem 0.3rem;
        
        h3 {
          font-size: 1rem;
        }
        
        p {
          font-size: 0.85rem;
        }
      }
    }
  }
}

.team-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style> 