<template>
  <section class="cta-section">
    <div class="container">
      <div class="section-header">
        <span class="section-badge">
          <i class="bi bi-people"></i>
          {{ $t('cta.badge') }}
        </span>
        <h2>
          {{ $t('cta.title') }}
          <span class="text-white">{{ $t('cta.subtitle') }}</span>
        </h2>
        <p class="section-description">
          {{ $t('cta.description') }}
        </p>
        <div class="cta-buttons">
          <a href="https://api.whatsapp.com/send?phone=905539736480" class="btn btn-light">
            <i class="bi bi-whatsapp"></i>
            {{ $t('cta.button') }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'CtaSection'
}
</script>

<style lang="scss" scoped>
.cta-section {
  padding: 2.5rem 0;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  position: relative;
  border-radius: 20px;
  margin: 0 1rem;

  .section-header {
    text-align: center;
    margin-bottom: 0;

    .section-badge {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.4rem 0.8rem;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 50px;
      color: white;
      font-size: 0.9rem;
      margin-bottom: 0.5rem;

      i {
        font-size: 1rem;
      }
    }

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: white;
      margin-bottom: 0.5rem;
      font-family: 'El Messiri', sans-serif;
    }

    .section-description {
      max-width: 600px;
      margin: 0 auto 1rem;
      color: rgba(255, 255, 255, 0.9);
      font-size: 1rem;
      line-height: 1.4;
    }
  }

  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;

    .btn {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 50px;
      
      i {
        font-size: 1.1rem;
      }
    }

    .btn-light {
      background: white;
      color: var(--primary-color);
      border: none;

      &:hover {
        background: rgba(255, 255, 255, 0.9);
      }
    }
  }
}
</style> 