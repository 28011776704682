<template>
  <span class="app-badge">
    <i :class="`bi bi-${icon}`"></i>
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'AppBadge',
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.app-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.1);
  color: var(--light-color);
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  backdrop-filter: blur(10px);

  i {
    font-size: 1.25rem;
  }
}
</style> 