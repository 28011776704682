import { onMounted } from 'vue'

export function useParticles() {
  const initParticles = () => {
    const particles = document.querySelectorAll('.particle')
    particles.forEach(particle => {
      const size = Math.random() * 5 + 2
      const left = Math.random() * 100
      const top = Math.random() * 100
      const delay = Math.random() * 10
      const duration = Math.random() * 30 + 20
      const opacity = Math.random() * 0.3

      particle.style.width = `${size}px`
      particle.style.height = `${size}px`
      particle.style.left = `${left}%`
      particle.style.top = `${top}%`
      particle.style.animationDelay = `${delay}s`
      particle.style.animationDuration = `${duration}s`
      particle.style.opacity = opacity
    })
  }

  onMounted(() => {
    initParticles()
  })

  return {
    initParticles
  }
} 