<template>
  
  <div class="about-page">
    <!-- Hero Section -->
    <section class="hero-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6" data-aos="fade-right">
            <div class="hero-content">
              <h1 class="hero-title">
                {{ $t('about.hero.title') }} <br>
                <span class="highlight">{{ $t('about.hero.highlight') }}</span>
              </h1>
              <div class="hero-text">
                <p>
                  {{ $t('about.hero.text1') }}
                </p>
                <p>
                  {{ $t('about.hero.text2') }}
                </p>
                <p>
                  {{ $t('about.hero.text3') }}
                </p>
              </div>
              <div class="hero-stats">
                <div class="stat-item">
                  <div class="stat-icon">
                    <i class="bi bi-heart-fill"></i>
                  </div>
                  <div class="stat-content">
                    <span class="number">15+</span>
                    <span class="label">{{ $t('about.hero.stats.experience') }}</span>
                  </div>
                </div>
                <div class="stat-item">
                  <div class="stat-icon">
                    <i class="bi bi-people-fill"></i>
                  </div>
                  <div class="stat-content">
                    <span class="number">5000+</span>
                    <span class="label">{{ $t('about.hero.stats.smiles') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6" data-aos="fade-left">
            <div class="hero-image">
              <img src="@/assets/images/about/team.png" alt="Klinik Akademi" class="main-image">
              <div class="experience-badge">
                <i class="bi bi-stars"></i>
                <span>{{ $t('about.hero.badge') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Mission Section -->
    <section class="mission-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6" data-aos="fade-right">
            <div class="section-content">
              <div class="section-badge">
                <i class="bi bi-stars"></i>
                <span>{{ $t('about.mission.badge') }}</span>
              </div>
              <h2 class="section-title">
                {{ $t('about.mission.title') }}
              </h2>
              <p class="section-text">
                {{ $t('about.mission.text') }}
              </p>
              <div class="service-grid">
                <div class="service-item" v-for="service in services" :key="service.id">
                  <div class="service-icon">
                    <i :class="'bi bi-' + service.icon"></i>
                  </div>
                  <h3>{{ $t(service.titleKey) }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6" data-aos="fade-left">
            <div class="doctor-card">
              <div class="image-wrapper">
                <img src="@/assets/images/about/why-us.png" alt="Dt. Harun Atay">
              </div>
              <div class="info-badge">
                <div class="icon">
                  <i class="bi bi-shield-check"></i>
                </div>
                <div class="text">
                  {{ $t('about.mission.badge_doctor') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Vision Section -->
    <section class="vision-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6" data-aos="fade-right">
            <div class="doctor-card">
              <div class="image-wrapper">
                <img src="@/assets/images/team/aygul.png" alt="Dr. Aygül Dirik">
              </div>
              <div class="info-badge">
                <div class="icon">
                  <i class="bi bi-heart-pulse"></i>
                </div>
                <div class="text">
                  {{ $t('about.vision.badge_doctor') }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6" data-aos="fade-left">
            <div class="section-content">
              <div class="section-badge">
                <i class="bi bi-eye"></i>
                <span>{{ $t('about.vision.badge') }}</span>
              </div>
              <h2 class="section-title">
                {{ $t('about.vision.title') }}
              </h2>
              <p class="section-text">
                {{ $t('about.vision.text') }}
              </p>
              <div class="service-grid">
                <div class="service-item">
                  <div class="service-icon">
                    <i class="bi bi-people"></i>
                  </div>
                  <h3>{{ $t('about.vision.services.patient') }}</h3>
                </div>
                <div class="service-item">
                  <div class="service-icon">
                    <i class="bi bi-gear"></i>
                  </div>
                  <h3>{{ $t('about.vision.services.technology') }}</h3>
                </div>
                <div class="service-item">
                  <div class="service-icon">
                    <i class="bi bi-heart"></i>
                  </div>
                  <h3>{{ $t('about.vision.services.expert') }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Services Section -->
    <section class="services-section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-header text-center mb-5">
              <div class="section-badge">
                <i class="bi bi-grid-3x3-gap"></i>
                <span>{{ $t('about.treatments.badge') }}</span>
              </div>
              <h2 class="section-title">
                {{ $t('about.treatments.title') }}<br>
                <span class="highlight">{{ $t('about.treatments.highlight') }}</span>
              </h2>
            </div>
          </div>
        </div>
        <div class="row g-4">
          <div class="col-md-4" v-for="service in treatmentServices" :key="service.id">
            <router-link :to="service.link" class="service-card">
              <div class="service-icon">
                <i :class="`bi bi-${service.icon}`"></i>
              </div>
              <div class="service-content">
                <h3>{{ $t(service.titleKey) }}</h3>
                <p>{{ $t(service.descKey) }}</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 text-center">
            <router-link :to="treatmentsBasePath" class="btn btn-gradient">
              <i class="bi bi-grid-3x3-gap me-2"></i>
              {{ $t('about.treatments.all_treatments') }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
    
    <!-- CTA Section -->
    <ContactCta />
      </div>
</template>

<script>
import ContactCta from '@/components/home/ContactCta.vue'
import { getTreatmentRoute, getTreatmentsBaseSlug } from '@/utils/slug'

export default {
  name: 'AboutView',
  components: {
    ContactCta
  },
  data() {
    return {
      services: [
        { id: 1, titleKey: 'about.mission.services.digital', icon: 'display' },
        { id: 2, titleKey: 'about.mission.services.aesthetic', icon: 'star' },
        { id: 3, titleKey: 'about.mission.services.surgery', icon: 'hospital' },
        { id: 4, titleKey: 'about.mission.services.whitening', icon: 'brightness-high' },
        { id: 5, titleKey: 'about.mission.services.pediatric', icon: 'emoji-smile' },
        { id: 6, titleKey: 'about.mission.services.extraction', icon: 'bandaid' }
      ]
    }
  },
  computed: {
    treatmentsBasePath() {
      return '/' + getTreatmentsBaseSlug();
    },
    treatmentServices() {
      return [
        {
          id: 1,
          titleKey: 'about.treatments.services.implant.title',
          descKey: 'about.treatments.services.implant.description',
          icon: 'plus-circle',
          link: this.getTreatmentLink('implant')
        },
        {
          id: 2,
          titleKey: 'about.treatments.services.zirconium.title',
          descKey: 'about.treatments.services.zirconium.description',
          icon: 'gem',
          link: this.getTreatmentLink('prosthesis')
        },
        {
          id: 3,
          titleKey: 'about.treatments.services.orthodontics.title',
          descKey: 'about.treatments.services.orthodontics.description',
          icon: 'arrow-left-right',
          link: this.getTreatmentLink('orthodontics')
        },
        {
          id: 4,
          titleKey: 'about.treatments.services.aesthetic.title',
          descKey: 'about.treatments.services.aesthetic.description',
          icon: 'stars',
          link: this.getTreatmentLink('cosmetic')
        },
        {
          id: 5,
          titleKey: 'about.treatments.services.whitening.title',
          descKey: 'about.treatments.services.whitening.description',
          icon: 'brightness-high',
          link: this.getTreatmentLink('whitening')
        },
        {
          id: 6,
          titleKey: 'about.treatments.services.pediatric.title',
          descKey: 'about.treatments.services.pediatric.description',
          icon: 'shield-plus',
          link: this.getTreatmentLink('pediatric')
        }
      ]
    }
  },
  methods: {
    getTreatmentLink(treatmentKey) {
      return getTreatmentRoute(treatmentKey)
    }
  }
}
</script>

<style lang="scss" scoped>
.about-page {
  padding-top: 80px;
  background: linear-gradient(135deg, 
    rgba(var(--secondary-color-rgb), 0.02),
    rgba(var(--primary-color-rgb), 0.05)
  );

  // Hero Section
  .hero-section {
    padding: 100px 0 60px;
    position: relative;
    overflow: hidden;
    background: linear-gradient(135deg,
      rgba(var(--secondary-color-rgb), 0.03) 0%,
      rgba(var(--primary-color-rgb), 0.05) 100%
    );

    .hero-content {
      position: relative;
      
      .hero-badge {
        display: inline-flex;
        align-items: center;
        padding: 0.6rem 1.2rem;
        background: rgba(var(--primary-color-rgb), 0.1);
        color: var(--primary-color);
        border-radius: 50px;
        font-size: 0.95rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
        transition: all 0.3s ease;

        i {
          font-size: 1.1rem;
        }

        &:hover {
          background: var(--primary-color);
          color: white;
          transform: translateY(-2px);
        }
      }

      .hero-title {
        font-size: 2.8rem;
        font-weight: 800;
        color: var(--secondary-color);
        margin-bottom: 1.5rem;
        line-height: 1.2;

        .highlight {
          background: linear-gradient(120deg, var(--primary-color), var(--secondary-color));
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;
          display: inline-block;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 3px;
            background: var(--primary-color);
            border-radius: 2px;
            opacity: 0.5;
          }
        }
      }

      .hero-text {
        p {
          font-size: 1rem;
          line-height: 1.7;
          color: var(--text-color);
          margin-bottom: 1rem;
          opacity: 0.9;
        }
      }

      .hero-stats {
        display: flex;
        gap: 1.5rem;
        margin-top: 2rem;

        .stat-item {
          display: flex;
          align-items: center;
          gap: 1rem;

          .stat-icon {
            width: 45px;
            height: 45px;
            background: var(--primary-color);
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-size: 1.2rem;
            box-shadow: 0 10px 20px rgba(var(--primary-color-rgb), 0.2);
          }

          .stat-content {
            .number {
              display: block;
              font-size: 1.6rem;
              font-weight: 700;
              color: var(--secondary-color);
              line-height: 1;
            }

            .label {
              font-size: 0.85rem;
              color: var(--text-color);
              opacity: 0.8;
            }
          }
        }
      }
    }

    .hero-image {
      position: relative;
      padding: 1.5rem;

      .main-image {
        width: 100%;
        border-radius: 30px;
        box-shadow: 0 30px 60px rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;

        &:hover {
          transform: translateY(-10px);
        }
      }

      .floating-image {
        position: absolute;
        top: 0;
        right: 0;
        width: 180px;
        animation: float 3s ease-in-out infinite;
      }

      .experience-badge {
        position: absolute;
        bottom: 40px;
        left: 0;
        background: white;
        padding: 1.2rem;
        border-radius: 20px;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        gap: 1rem;
        
        i {
          font-size: 1.5rem;
          color: var(--primary-color);
        }

        span {
          font-size: 1rem;
          font-weight: 600;
          color: var(--secondary-color);
        }
      }
    }
  }

  // Mission Section
  .mission-section {
    padding: 100px 0;
    background: linear-gradient(135deg,
      rgba(var(--secondary-color-rgb), 0.02),
      rgba(var(--primary-color-rgb), 0.05)
    );

    .container {
      .row {
        align-items: flex-start;
      }
    }

    .section-content {
      .section-badge {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.6rem 1.2rem;
        background: rgba(var(--primary-color-rgb), 0.1);
        color: var(--primary-color);
        border-radius: 50px;
        font-size: 0.95rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
        transition: all 0.3s ease;

        i {
          font-size: 1.1rem;
        }

        &:hover {
          transform: translateY(-2px);
        }
      }

      .section-title {
        font-size: 2.5rem;
        font-weight: 700;
        color: var(--secondary-color);
        margin-bottom: 1.5rem;
        line-height: 1.2;
      }

      .section-text {
        font-size: 1.1rem;
        line-height: 1.8;
        color: var(--text-color);
        margin-bottom: 2rem;
        opacity: 0.9;
      }

      .service-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        margin-top: 2rem;

        .service-item {
          background: white;
          padding: 1.2rem;
          border-radius: 12px;
          transition: all 0.3s ease;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);

          &:hover {
            transform: translateY(-3px);
            box-shadow: 0 12px 25px rgba(var(--primary-color-rgb), 0.1);

            .service-icon {
              background: var(--primary-color);
              color: white;
            }
          }

          .service-icon {
            width: 40px;
            height: 40px;
            background: rgba(var(--primary-color-rgb), 0.1);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            color: var(--primary-color);
            margin-bottom: 0.8rem;
            transition: all 0.3s ease;
          }

          h3 {
            font-size: 0.9rem;
            font-weight: 600;
            color: var(--secondary-color);
            margin: 0;
          }
        }
      }
    }

    .doctor-card {
      height: 90%;
      max-width: 90%;
      margin: 0 auto;
      position: relative;
      border-radius: 30px;
      padding: 20px;
      background: white;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
      transition: all 0.4s ease;
      
      .image-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            135deg,
            rgba(var(--primary-color-rgb), 0.2),
            rgba(var(--secondary-color-rgb), 0.1)
          );
          z-index: 1;
        }

        &::after {
          content: '';
          position: absolute;
          top: 20px;
          right: 20px;
          width: 60px;
          height: 60px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 50%;
          backdrop-filter: blur(5px);
          z-index: 2;
          border: 0 20px 40px rgba(0, 0, 0, 0.1);
        }
      }

      .info-badge {
        position: absolute;
        bottom: 40px;
        left: 0;
        background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
        padding: 12px 20px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 35px;
        z-index: 2;

        .icon {
          width: 32px;
          height: 32px;
          background: rgba(var(--primary-color-rgb), 0.1);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 1.1rem;
        }

        .text {
          font-size: 0.85rem;
          font-weight: 600;
          color: white;
        }
      }

      &:hover {
        transform: translateY(-10px);
      }
    }
  }

  // Vision Section
  .vision-section {
    padding: 100px 0;
    background: white;

    .doctor-card {
      height: 90%;
      max-width: 90%;
      margin: 0 auto;
      position: relative;
      border-radius: 30px;
      padding: 20px;
      background: white;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
      transition: all 0.4s ease;
      
      .image-wrapper {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            135deg,
            rgba(var(--primary-color-rgb), 0.2),
            rgba(var(--secondary-color-rgb), 0.1)
          );
          z-index: 1;
        }

        &::after {
          content: '';
          position: absolute;
          top: 20px;
          right: 20px;
          width: 60px;
          height: 60px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 50%;
          backdrop-filter: blur(5px);
          z-index: 2;
          border: 2px solid rgba(255, 255, 255, 0.2);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
        transition: transform 0.4s ease;
      }

      .info-badge {
        position: absolute;
        bottom: 40px;
        left: 0;
        background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
        padding: 12px 20px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 35px;
        z-index: 2;

        .icon {
          width: 32px;
          height: 32px;
          background: rgba(var(--primary-color-rgb), 0.1);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 1.1rem;
        }

        .text {
          font-size: 0.85rem;
          font-weight: 600;
          color: white;
        }
      }

      &:hover {
        transform: translateY(-10px);
      }
    }

    .section-content {
      .section-badge {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.6rem 1.2rem;
        background: rgba(var(--primary-color-rgb), 0.1);
        color: var(--primary-color);
        border-radius: 50px;
        font-size: 0.95rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
        transition: all 0.3s ease;

        i {
          font-size: 1.1rem;
        }

        &:hover {
          transform: translateY(-2px);
        }
      }

      .section-title {
        font-size: 2.5rem;
        font-weight: 700;
        color: var(--secondary-color);
        margin-bottom: 1.5rem;
        line-height: 1.2;
      }

      .section-text {
        font-size: 1.1rem;
        line-height: 1.8;
        color: var(--text-color);
        margin-bottom: 2rem;
        opacity: 0.9;
      }

      .service-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        margin-top: 2rem;

        .service-item {
          background: white;
          padding: 1.2rem;
          border-radius: 12px;
          transition: all 0.3s ease;
          box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);

          &:hover {
            transform: translateY(-3px);
            box-shadow: 0 12px 25px rgba(var(--primary-color-rgb), 0.1);

            .service-icon {
              background: var(--primary-color);
              color: white;
            }
          }

          .service-icon {
            width: 40px;
            height: 40px;
            background: rgba(var(--primary-color-rgb), 0.1);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            color: var(--primary-color);
            margin-bottom: 0.8rem;
            transition: all 0.3s ease;
          }

          h3 {
            font-size: 0.9rem;
            font-weight: 600;
            color: var(--secondary-color);
            margin: 0;
          }
        }
      }
    }
  }

  // Services Section
  .services-section {
    padding: 100px 0;
    background: #f8fafc;
    position: relative;

    @media (max-width: 768px) {
      padding: 60px 0;
    }

    .section-header {
      max-width: 800px;
      margin: 0 auto 3rem;

      .section-badge {
        display: inline-flex;
        align-items: center;
        padding: 12px 24px;
        background: rgba(0, 164, 166, 0.08);
        color: var(--primary-color);
        border-radius: 4px;
        font-size: 0.9rem;
        font-weight: 600;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-bottom: 2rem;

        i {
          font-size: 1.1rem;
          margin-right: 0.5rem;
        }
      }

      .section-title {
        font-size: 2.8rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
        line-height: 1.2;
        color: var(--heading-color);

        .highlight {
          background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
    }

    .service-card {
      background: rgba(255, 255, 255, 0.95);
      backdrop-filter: blur(10px);
      padding: 2rem;
      border-radius: 8px;
      text-decoration: none;
      position: relative;
      transition: all 0.3s ease;
      border: 1px solid rgba(255, 255, 255, 0.2);
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      box-shadow: 0px 4px 15px rgba(0,0,0,0.05);

      &:hover {
        background: rgba(255, 255, 255, 0.98);
        border-color: var(--primary-color);
        box-shadow: 0 8px 24px rgba(0, 164, 166, 0.12);
        transform: translateY(-2px);

        .service-icon {
          background: var(--primary-color);
          i {
            color: white;
          }
        }

        h3 {
          color: var(--primary-color);
        }
      }

      .service-icon {
        width: 64px;
        height: 64px;
        background: rgba(0, 164, 166, 0.08);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
        transition: all 0.3s ease;

        i {
          font-size: 1.75rem;
          color: var(--primary-color);
        }
      }

      .service-content {
        text-align: center;

        h3 {
          font-size: 1.15rem;
          font-weight: 600;
          margin-bottom: 0.75rem;
          color: var(--heading-color);
          transition: all 0.3s ease;
        }

        p {
          margin: 0;
          font-size: 0.9rem;
          color: var(--text-color);
          opacity: 0.8;
          line-height: 1.6;
        }
      }
    }

    .btn-gradient {
      background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
      color: white;
      padding: 0.75rem 1.5rem;
      border-radius: 50px;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      transition: all 0.3s ease;
      border: none;
      box-shadow: 0 8px 25px rgba(0, 164, 166, 0.25);
      text-decoration: none;

      i {
        font-size: 1.2rem;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 12px 30px rgba(0, 164, 166, 0.35);
      }
    }
  }

  // CTA Section
  .cta-section {
    padding: 80px 0;
    background: linear-gradient(135deg, var(--secondary-color), var(--primary-color));
    color: white;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
    }

    .cta-content {
      position: relative;
      z-index: 1;

      h2 {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }

      p {
        font-size: 1.1rem;
        opacity: 0.9;
        margin-bottom: 2rem;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }

      .btn-light {
        padding: 1rem 2rem;
        font-size: 1.1rem;
        border-radius: 8px;
        transition: all 0.3s ease;
        border: none;
        font-weight: 500;

        &:hover {
          transform: translateY(-3px);
          box-shadow: 0 10px 20px rgba(0,0,0,0.1);
        }

        i {
          font-size: 1.2rem;
        }
      }
    }
  }

  // Stats Section
  .stats-section {
    padding: 80px 0;
    background: linear-gradient(135deg,
      rgba(var(--primary-color-rgb), 0.05),
      rgba(var(--secondary-color-rgb), 0.05)
    );

    .stat-card {
      background: white;
      padding: 2rem;
      border-radius: 20px;
      text-align: center;
      transition: all 0.3s ease;
      height: 100%;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 20px 40px rgba(var(--primary-color-rgb), 0.1);

        .stat-icon {
          background: var(--primary-color);
          color: white;
        }
      }

      .stat-icon {
        width: 60px;
        height: 60px;
        background: rgba(var(--primary-color-rgb), 0.1);
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 1.5rem;
        font-size: 1.8rem;
        color: var(--primary-color);
        transition: all 0.3s ease;
      }

      .stat-number {
        font-size: 2.5rem;
        font-weight: 700;
        color: var(--secondary-color);
        margin-bottom: 1rem;
        line-height: 1;

        .counter {
          display: inline-block;
        }
      }

      .stat-title {
        font-size: 1.1rem;
        color: var(--text-color);
        font-weight: 500;
      }
    }
  }
}

@keyframes float {
  0% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
  100% { transform: translateY(0) rotate(0deg); }
}

// Responsive Styles
@media (max-width: 992px) {
  .hero-section {
    padding: 10px 0 10px !important;
    
    .hero-content {
      .hero-title {
        font-size: 2.4rem;
      }
    }

    .hero-image {
      margin-top: 2rem;
      
      .floating-image {
        width: 140px;
      }

      .experience-badge {
        bottom: 20px;
      }
    }
  }

  .mission-section {
    padding: 60px 0 30px;
    .row {
      flex-direction: row !important;
    }

    .col-lg-6 {
      width: 100%;
      
      &:nth-child(2) {
        order: 3;
        padding: 30px 0 0 0;
      }
      
      &:nth-child(1) {
        order: 1;
      }
    }
  }

  .vision-section {
    padding: 30px 0 60px;
    .row {
      flex-direction: row !important;
    }

    .col-lg-6 {
      width: 100%;
      
      &:nth-child(1) {
        order: 2;
        padding: 30px 0 0 0;
      }
      
      &:nth-child(2) {
        order: 1;
      }
    }
  }

  .stats-section {
    .stat-card {
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 60px 0 30px;
    
    .hero-content {
      .hero-title {
        font-size: 2rem;
        margin-bottom: 1rem;
      }

      .hero-text {
        p {
          margin-bottom: 0.8rem;
        }
      }
      
      .hero-stats {
        margin-top: 1.5rem;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .hero-image {
      margin-top: 1.5rem;
      padding: 1rem;
    }
  }

  .mission-section {
    padding: 40px 0 20px !important;
  }

  .vision-section {
    padding: 20px 0 40px;
  }

  .vision-section {
    padding-top: 0px !important;
    
    .doctor-card {
      margin-top: 1rem;
    }
  }

  .stats-section {
    .stat-card {
      margin-bottom: 1.5rem;

      .stat-number {
        font-size: 2rem;
      }

      .stat-title {
        font-size: 1rem;
      }
    }
  }

  .mission-section, .vision-section {
    .doctor-card {
      height: 350px;
      margin: 0.5rem auto;
    }
  }
}
</style> 