const mailService = {
  // API URL'ini window.location'a göre ayarla
  get API_URL() {
    const baseUrl = window.location.hostname === 'localhost' 
      ? 'http://localhost:8080'
      : window.location.origin;
    return `${baseUrl}/server/mail.php`;
  },

  async sendContactMail(formData) {
    try {
      // Form verilerini kontrol et
      if (!formData.name || !formData.email || !formData.message) {
        throw new Error('Lütfen tüm zorunlu alanları doldurun');
      }

      // Form tipini ekle
      const formDataWithType = {
        ...formData,
        form_type: formData.form_type || 'contact'
      };
      
      console.log('Mail gönderimi başlatılıyor:', formDataWithType);
      console.log('API URL:', this.API_URL);
      
      // İstek gönder
      const response = await fetch(this.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin
        },
        credentials: 'same-origin',
        mode: 'cors',
        body: JSON.stringify(formDataWithType)
      });

      // Yanıt içeriğini önce text olarak al
      const responseText = await response.text();
      console.log('Sunucu yanıtı:', responseText);

      // Text'i JSON'a çevir
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        console.error('JSON ayrıştırma hatası:', e);
        throw new Error('Sunucu geçersiz yanıt döndürdü: ' + responseText.substring(0, 100));
      }
      
      if (!response.ok) {
        throw new Error(data.message || 'Mail gönderilemedi');
      }

      return data;
    } catch (error) {
      console.error('Mail gönderme hatası:', error);
      throw new Error('Mail gönderilirken bir hata oluştu: ' + error.message);
    }
  },

  async sendAppointmentMail(formData) {
    try {
      if (!formData.name || !formData.email || !formData.phone) {
        throw new Error('Lütfen tüm zorunlu alanları doldurun');
      }

      const formDataWithType = {
        ...formData,
        form_type: 'appointment'
      };
      
      const response = await fetch(this.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin
        },
        credentials: 'same-origin',
        mode: 'cors',
        body: JSON.stringify(formDataWithType)
      });

      const responseText = await response.text();
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        throw new Error('Sunucu geçersiz yanıt döndürdü');
      }

      if (!response.ok) {
        throw new Error(data.message || 'Randevu talebi gönderilemedi');
      }

      return data;
    } catch (error) {
      console.error('Randevu mail hatası:', error);
      throw new Error('Randevu talebi gönderilirken bir hata oluştu: ' + error.message);
    }
  },

  async sendEmergencyMail(formData) {
    try {
      const formDataWithType = {
        ...formData,
        form_type: 'emergency'
      };
      
      const response = await fetch(this.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin
        },
        credentials: 'same-origin',
        mode: 'cors',
        body: JSON.stringify(formDataWithType)
      });

      const responseText = await response.text();
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        throw new Error('Sunucu geçersiz yanıt döndürdü');
      }

      if (!response.ok) {
        throw new Error(data.message || 'Acil durum bildirimi gönderilemedi');
      }

      return data;
    } catch (error) {
      console.error('Acil form gönderme hatası:', error);
      throw new Error('Acil durum maili gönderilirken bir hata oluştu: ' + error.message);
    }
  },
  
  async sendTreatmentMail(formData) {
    try {
      const formDataWithType = {
        ...formData,
        form_type: 'treatment'
      };
      
      const response = await fetch(this.API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Origin': window.location.origin
        },
        credentials: 'same-origin',
        mode: 'cors',
        body: JSON.stringify(formDataWithType)
      });

      const responseText = await response.text();
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        throw new Error('Sunucu geçersiz yanıt döndürdü');
      }

      if (!response.ok) {
        throw new Error(data.message || 'Tedavi bilgi talebi gönderilemedi');
      }

      return data;
    } catch (error) {
      console.error('Tedavi bilgi talebi hatası:', error);
      throw new Error('Tedavi bilgi talebi gönderilirken bir hata oluştu: ' + error.message);
    }
  }
}

export default mailService; 