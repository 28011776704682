export default {
  // Hero Bölümü
  badge: 'Legal Information',
  title: {
    main: 'Personal Data',
    highlight: 'Protection Statement'
  },
  description: 'You can learn about our policies for the security and protection of your personal data.',
  
  meta: {
    title: 'KVKK Illumination Text',
    description: 'Detailed information about the Personal Data Protection Law (KVKK) and the processing of personal data at Klinik Akademi Dental Polyclinic.',
  },
  
  // İçerik Bölümü
  intro: {
    content: 'As Klinik Akademi Dental Polyclinic, we may process, record, store, classify, update, and disclose/transfer your personal data to third parties under limited circumstances permitted by law, in accordance with the provisions of the Personal Data Protection Law No. 6698 (KVKK), as a data controller, for the purposes explained below in compliance with law and rules of honesty.'
  },
  
  // Bölüm 1
  section1: {
    title: '1. Data Controller',
    content: 'Klinik Akademi Dental Polyclinic acts as the data controller in the processing and storage of your personal data. In accordance with the KVKK, your personal data will be collected and processed by Klinik Akademi Dental Polyclinic as the data controller within the scope explained below.'
  },
  
  // Bölüm 2
  section2: {
    title: '2. Purpose of Personal Data Processing',
    content: 'Your personal data may be processed for purposes including but not limited to conducting necessary operations for you to benefit from the services provided by Klinik Akademi Dental Polyclinic, providing healthcare services and planning and tracking these services, verifying your identity, creating and tracking appointments, managing treatment processes, conducting payment transactions, and other dental operations for the following purposes:<br><br><ul><li>Conducting and improving healthcare services</li><li>Carrying out patient registration and admission processes</li><li>Conducting medical diagnosis, treatment, and care services</li><li>Planning and managing the financing of healthcare services</li><li>Providing appointment creation and reminder services</li><li>Managing billing and payment processes</li><li>Fulfilling legal and regulatory requirements</li><li>Conducting analysis related to our healthcare services and improving our healthcare services</li></ul>'
  },
  
  // Bölüm 3
  section3: {
    title: '3. Categories of Personal Data Processed',
    content: 'The main categories of personal data processed by our clinic are as follows:<br><br><strong>Identity and Contact Information:</strong><br><ul><li>Identity information (name, surname, ID number, date of birth, gender, etc.)</li><li>Contact information (phone number, email, address, etc.)</li><li>Financial information (bank account details, invoice information, etc.)</li></ul><br><strong>Health and Other Information:</strong><br><ul><li>Health information (medical history, diagnosis, treatment information, X-rays and imaging results, etc.)</li><li>Insurance information (private health insurance, Social Security, etc.)</li><li>Visual and audio recordings (before and after treatment photos when necessary)</li></ul>'
  },
  
  // Bölüm 4
  section4: {
    title: '4. Transfer of Personal Data',
    content: 'Your personal data may be shared with domestic and foreign organizations specified below for the purposes mentioned above, within the scope of the Law and other legislation:<br><br><ul><li>Ministry of Health and its affiliated sub-units</li><li>Private insurance companies and Social Security Institution</li><li>Lawyers and authorized public institutions and organizations for the purpose of conducting legal proceedings</li><li>Regulatory and supervisory institutions and official authorities</li><li>Third parties from whom services are received domestically/internationally (laboratories and medical equipment suppliers, etc.)</li></ul><br>Your personal data may be transferred within the framework of the personal data processing conditions and purposes specified in Articles 8 and 9 of the Law.'
  },
  
  // Bölüm 5
  section5: {
    title: '5. Method and Legal Basis for Collection of Personal Data',
    content: 'Your personal data may be collected by Klinik Akademi Dental Polyclinic electronically or physically. In this context, your personal data is collected through the following methods and legal grounds:<br><br><ul><li>Information shared by you during patient admission, registration, and appointment creation processes</li><li>Health data obtained as a result of examinations and tests performed by the physician during the treatment process</li><li>Information you provide through our website, phone, email, and social media channels</li><li>Information sharing with regulatory and supervisory institutions for the purpose of financing and planning healthcare services</li><li>Information obtained from institutions from which services are received (private insurance, Social Security, etc.)</li></ul>',
    list: {
      item1: {
        title: 'Explicit Consent',
        content: 'For personal data processing activities that are not explicitly stipulated by law and require explicit consent, your personal data is processed by obtaining your explicit consent.'
      },
      item2: {
        title: 'Explicitly Stipulated by Law',
        content: 'Within the scope of legal regulations regarding healthcare services, it is a legal obligation to process your data and share it with the relevant institutions.'
      },
      item3: {
        title: 'Performance of Contract',
        content: 'As Klinik Akademi Dental Polyclinic, your personal data is processed to fulfill the services arising from our contract with you and to establish a treatment relationship.'
      }
    }
  },
  
  // Bölüm 6
  section6: {
    title: '6. Your Rights as a Data Subject',
    content: 'In accordance with Article 11 of the PDPL, as a data subject, you have the following rights:',
    category1: {
      items: [
        'To learn whether your personal data is being processed',
        'To request information about your personal data if it has been processed',
        'To learn the purpose of processing your personal data and whether they are used in accordance with the purpose',
        'To know the third parties to whom your personal data is transferred domestically or abroad',
        'To request the correction of your personal data if it is incomplete or incorrectly processed'
      ]
    },
    category2: {
      items: [
        'To request the deletion or destruction of your personal data within the framework of the conditions stipulated in Article 7 of the PDPL',
        'To request notification of the above correction, deletion or destruction request to third parties to whom your personal data has been transferred',
        'To object to the occurrence of an outcome against you as a result of the analysis of the processed data exclusively through automated systems',
        'To request compensation for damages you incur due to unlawful processing of your personal data'
      ]
    }
  },
  
  // Bölüm 7
  section7: {
    title: '7. Application Methods',
    content: 'You can apply using one of the following methods to exercise your rights specified above:',
    contactMethods: {
      inPerson: {
        clinic: 'Our Clinic\'s',
        address: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/Istanbul',
        method: 'in person application'
      },
      mail: {
        address: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/Istanbul',
        method: 'by mail with a wet-signed petition'
      },
      email: {
        address: 'info@klinikakademi.com',
        method: 'application via email'
      }
    },
    additionalContent: 'Your application will be concluded free of charge as soon as possible and within 30 days at the latest, depending on the nature of the request. If the process requires an additional cost, a fee may be charged according to the tariff determined by the Personal Data Protection Authority.'
  },
  
  // Bölüm 8
  section8: {
    title: '8. Changes to the Statement',
    content: 'Klinik Akademi Dental Polyclinic may update this statement when necessary. Updates will be published on our website.'
  },
  
  // Footer
  lastUpdate: 'Last Update Date:'
} 