<template>
  <section class="contact" id="contact">
    <div class="container">
      <div class="contact__header text-center" data-aos="fade-up">
        <span class="section-badge">{{ trans('badge') }}</span>
        <h2 class="section-title">
          {{ trans('titlePrefix') }}<br>
          <span class="gradient-text">{{ trans('titleHighlight') }}</span>
        </h2>
        <p class="section-description">
          {{ trans('description') }}
        </p>
      </div>

      <div class="row g-4 align-items-stretch">
        <div class="col-lg-6" data-aos="fade-right">
          <div class="contact__map">
            <div class="map-container">
              <div id="map" style="width: 100%; height: 100%;"></div>
            </div>
            <div class="contact-info">
              <div class="info-item">
                <i class="bi bi-geo-alt"></i>
                <div class="info-content">
                  <h4>{{ trans('details.address.title') }}</h4>
                  <p>{{ trans('details.address.value') }}</p>
                </div>
              </div>
              <div class="info-item">
                <i class="bi bi-telephone"></i>
                <div class="info-content">
                  <h4>{{ trans('details.phone.title') }}</h4>
                  <p>{{ trans('details.phone.value') }}</p>
                </div>
              </div>
              <div class="info-item">
                <i class="bi bi-envelope"></i>
                <div class="info-content">
                  <h4>{{ trans('details.email.title') }}</h4>
                  <p>{{ trans('details.email.value') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6" data-aos="fade-left">
          <div class="contact__form-wrapper">
            <div class="contact__form-header">
              <h3>{{ trans('details.formTitle') }}</h3>
              <h4>{{ trans('details.formSubtitle') }}</h4>
              <p>{{ trans('details.formDescription') }}</p>
            </div>

            <form class="contact__form" @submit.prevent="handleSubmit">
              <div class="form-group">
                <div class="floating-label">
                  <input 
                    type="text" 
                    id="name" 
                    v-model="form.name"
                    class="form-control" 
                    required
                  >
                  <label for="name">{{ trans('form.fields.name.placeholder') }}</label>
                </div>
              </div>

              <div class="form-group">
                <div class="floating-label">
                  <input 
                    type="email" 
                    id="email" 
                    v-model="form.email"
                    class="form-control" 
                    required
                  >
                  <label for="email">{{ trans('form.fields.email.placeholder') }}</label>
                </div>
              </div>

              <div class="form-group">
                <div class="floating-label">
                  <textarea 
                    id="message" 
                    v-model="form.message"
                    class="form-control" 
                    rows="4"
                    required
                  ></textarea>
                  <label for="message">{{ trans('form.fields.message.placeholder') }}</label>
                </div>
              </div>

              <button type="submit" class="btn btn-gradient" :disabled="isSubmitting">
                <i class="bi bi-send me-2"></i>
                {{ isSubmitting ? trans('form.buttons.sending') : trans('form.buttons.send') }}
              </button>

              <div v-if="formSuccess" class="alert alert-success mt-3">
                <i class="bi bi-check-circle me-2"></i>
                {{ trans('form.messages.success') }}
              </div>
              
              <div v-if="formError" class="alert alert-danger mt-3">
                <i class="bi bi-exclamation-triangle me-2"></i>
                {{ trans('form.messages.error') }}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import mailService from '@/services/mailService'

export default {
  name: 'ContactSection',
  data() {
    return {
      form: {
        name: '',
        email: '',
        message: ''
      },
      map: null,
      marker: null,
      infoWindow: null,
      apiKey: 'AIzaSyA8kH-RGXssdAyyiWbiGvRS8tm2Q8pP1NQ',
      isSubmitting: false,
      formSuccess: false,
      formError: false,
      mapStyles: [
        {
          "elementType": "geometry",
          "stylers": [{ "color": "#f5f5f5" }]
        },
        {
          "elementType": "labels.icon",
          "stylers": [{ "visibility": "on" }]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [{ "color": "#616161" }]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [{ "color": "#f5f5f5" }]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [{ "color": "#bdbdbd" }]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [{ "color": "#eeeeee" }]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [{ "color": "#757575" }]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [{ "color": "#e5e5e5" }]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [{ "color": "#9e9e9e" }]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [{ "color": "#ffffff" }]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [{ "color": "#757575" }]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [{ "color": "#dadada" }]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [{ "color": "#616161" }]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [{ "color": "#9e9e9e" }]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [{ "color": "#e5e5e5" }]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [{ "color": "#eeeeee" }]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [{ "color": "#c9c9c9" }]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [{ "color": "#9e9e9e" }]
        }
      ],
      translations: {
        tr: {
          badge: 'İletişim',
          titlePrefix: 'Bizimle',
          titleHighlight: 'İletişime Geçin',
          description: 'Sorularınız için bize ulaşın, en kısa sürede size dönüş yapalım.',
          details: {
            address: {
              title: 'Adres',
              value: 'Orta, Yavuz Selim Cd. No:15-A Kartal/İstanbul'
            },
            phone: {
              title: 'Telefon',
              value: '+90 216 123 45 67'
            },
            email: {
              title: 'E-posta',
              value: 'info@klinikakademi.com'
            },
            formTitle: 'İletişim Formu',
            formSubtitle: 'Bize Ulaşın',
            formDescription: 'Sorularınız için formu doldurun, size en kısa sürede dönüş yapalım.'
          },
          form: {
            fields: {
              name: {
                placeholder: 'Adınız Soyadınız'
              },
              email: {
                placeholder: 'E-posta Adresiniz'
              },
              message: {
                placeholder: 'Mesajınız'
              }
            },
            buttons: {
              send: 'Gönder',
              sending: 'Gönderiliyor...'
            },
            messages: {
              success: 'Mesajınız başarıyla gönderildi. En kısa sürede size dönüş yapacağız.',
              error: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.'
            }
          },
          map: {
            markerTitle: 'Klinik Akademi'
          }
        },
        en: {
          badge: 'Contact',
          titlePrefix: 'Get in',
          titleHighlight: 'Touch with Us',
          description: 'Contact us for your questions, we will get back to you as soon as possible.',
          details: {
            address: {
              title: 'Address',
              value: 'Orta, Yavuz Selim St. No:15-A Kartal/Istanbul'
            },
            phone: {
              title: 'Phone',
              value: '+90 216 123 45 67'
            },
            email: {
              title: 'Email',
              value: 'info@klinikakademi.com'
            },
            formTitle: 'Contact Form',
            formSubtitle: 'Get in Touch',
            formDescription: 'Fill out the form for your questions, we will get back to you as soon as possible.'
          },
          form: {
            fields: {
              name: {
                placeholder: 'Your Name'
              },
              email: {
                placeholder: 'Your Email Address'
              },
              message: {
                placeholder: 'Your Message'
              }
            },
            buttons: {
              send: 'Send',
              sending: 'Sending...'
            },
            messages: {
              success: 'Your message has been sent successfully. We will get back to you as soon as possible.',
              error: 'An error occurred. Please try again later.'
            }
          },
          map: {
            markerTitle: 'Klinik Akademi'
          }
        },
        de: {
          badge: 'Kontakt',
          titlePrefix: 'Kontaktieren',
          titleHighlight: 'Sie uns',
          description: 'Kontaktieren Sie uns für Ihre Fragen, wir melden uns bei Ihnen.',
          details: {
            address: {
              title: 'Adresse',
              value: 'Orta, Yavuz Selim Str. Nr.15-A Kartal/Istanbul'
            },
            phone: {
              title: 'Telefon',
              value: '+90 216 123 45 67'
            },
            email: {
              title: 'E-Mail',
              value: 'info@klinikakademi.com'
            },
            formTitle: 'Kontaktformular',
            formSubtitle: 'Kontaktieren Sie uns',
            formDescription: 'Füllen Sie das Formular für Ihre Fragen aus, wir melden uns bei Ihnen.'
          },
          form: {
            fields: {
              name: {
                placeholder: 'Ihr Name'
              },
              email: {
                placeholder: 'Ihre E-Mail-Adresse'
              },
              message: {
                placeholder: 'Ihre Nachricht'
              }
            },
            buttons: {
              send: 'Senden',
              sending: 'Wird gesendet...'
            },
            messages: {
              success: 'Ihre Nachricht wurde erfolgreich gesendet. Wir melden uns bei Ihnen.',
              error: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
            }
          },
          map: {
            markerTitle: 'Klinik Akademi'
          }
        }
      }
    }
  },
  watch: {
    '$i18n.locale': {
      handler() {
        // Dil değiştiğinde harita bilgilerini güncelle
        if (this.marker && this.$t) {
          this.marker.setTitle(this.$t('contact.map.markerTitle'));
          
          // Harita bilgi penceresini yeniden oluştur ve göster
          if (this.map && this.marker) {
            this.updateInfoWindow();
          }
        }
      }
    }
  },
  async mounted() {
    try {
      if (!window.google) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places&v=weekly`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
        
        script.onload = () => {
          if (window.google && window.google.maps) {
            this.initMap(window.google.maps);
          } else {
            console.error('Google Maps API yüklenemedi');
          }
        };
      } else if (window.google.maps) {
        this.initMap(window.google.maps);
      }
    } catch (error) {
      console.error('Harita yükleme hatası:', error);
    }
  },
  methods: {
    trans(key) {
      const locale = this.$i18n.locale || 'tr'
      return key.split('.').reduce((obj, i) => obj?.[i], this.translations[locale]) || key
    },
    initMap(maps) {
      if (!maps || !maps.Map) {
        console.error('Google Maps API yüklenemedi');
        return;
      }

      const location = { lat: 40.8963, lng: 29.1927 }; // Kartal koordinatları
      const mapElement = document.getElementById('map');

      if (!mapElement) {
        console.error('Harita elementi bulunamadı');
        return;
      }

      try {
        this.map = new maps.Map(mapElement, {
          zoom: 15,
          center: location,
          styles: this.mapStyles,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false
        });

        // Marker ekle
        this.marker = new maps.Marker({
          map: this.map,
          position: location,
          title: this.trans('map.markerTitle')
        });

        // Info window içeriğini hazırla
        const contentString = `
          <div style="padding: 10px; max-width: 200px;">
            <h3 style="margin: 0 0 5px; color: #00A4A6; font-size: 16px;">${this.trans('map.markerTitle')}</h3>
            <p style="margin: 0; font-size: 13px; color: #666;">
              ${this.trans('details.address.value')}
            </p>
          </div>
        `;

        // Info window oluştur
        this.infoWindow = new maps.InfoWindow({
          content: contentString,
          maxWidth: 250
        });

        // Marker'a tıklandığında info window'u göster
        this.marker.addListener('click', () => {
          this.infoWindow.open(this.map, this.marker);
        });

        // Sayfa yüklendiğinde info window'u otomatik göster
        this.infoWindow.open(this.map, this.marker);
      } catch (error) {
        console.error('Harita başlatma hatası:', error);
      }
    },
    // Bilgi penceresini güncellemek için yeni metot
    updateInfoWindow() {
      if (!this.map || !this.marker || !window.google || !window.google.maps) return;
      
      // Yeni içeriği hazırla
      const contentString = `
        <div style="padding: 10px; max-width: 200px;">
          <h3 style="margin: 0 0 5px; color: #00A4A6; font-size: 16px;">${this.$t ? this.$t('contact.map.markerTitle') : 'Klinik Akademi'}</h3>
          <p style="margin: 0; font-size: 13px; color: #666;">
            ${this.$t ? this.$t('contact.details.address.value') : 'Orta, Yavuz Selim Cd. No:15-A Kartal/İstanbul'}
          </p>
          <p style="margin: 5px 0 0; font-size: 13px; color: #666;">
            <strong>${this.$t ? this.$t('contact.map.hours.title') : 'Açık'}:</strong> ${this.$t ? this.$t('contact.map.hours.value') : 'Her gün 09:00 - 00:00'}
          </p>
          <p style="margin: 5px 0 0; font-size: 13px; color: #666;">
            <a href="tel:${this.$t ? this.$t('contact.details.phone.value') : '+905539736480'}" style="color: #00A4A6; text-decoration: none;">${this.$t ? this.$t('contact.details.phone.value') : '+90 553 973 64 80'}</a>
          </p>
        </div>
      `;
      
      // Önceki info window'u kapat
      if (this.infoWindow) {
        this.infoWindow.close();
      }
      
      // Yeni info window oluştur
      this.infoWindow = new window.google.maps.InfoWindow({
        content: contentString,
        maxWidth: 250
      });
      
      // Marker'a tıklandığında info window'u göster
      window.google.maps.event.clearListeners(this.marker, 'click');
      this.marker.addListener('click', () => {
        this.infoWindow.open(this.map, this.marker);
      });
      
      // Bilgi penceresini göster
      this.infoWindow.open(this.map, this.marker);
    },
    async handleSubmit() {
      this.isSubmitting = true;
      this.formSuccess = false;
      this.formError = false;
      
      try {
        await mailService.sendContactMail(this.form);
        this.formSuccess = true;
        
        // Form verilerini sıfırla
        this.form = {
          name: '',
          email: '',
          message: ''
        };
        
        // Başarı mesajını 5 saniye sonra kaldır
        setTimeout(() => {
          this.formSuccess = false;
        }, 5000);
      } catch (error) {
        console.error('Form gönderme hatası:', error);
        this.formError = true;
        
        // Hata mesajını 5 saniye sonra kaldır
        setTimeout(() => {
          this.formError = false;
        }, 5000);
      } finally {
        this.isSubmitting = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact {
  padding: 60px 0;
  background: white;

  &__header {
    margin-bottom: 3rem;

    .section-badge {
      display: inline-block;
      padding: 8px 16px;
      background: rgba(0, 164, 166, 0.1);
      color: var(--primary-color);
      border-radius: 50px;
      font-size: 0.9rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
    }

    .section-title {
      font-size: 2.8rem;
      font-weight: 800;
      margin-bottom: 1.5rem;
      line-height: 1.2;
      color: var(--heading-color);

      .gradient-text {
        background: linear-gradient(135deg, #00A4A6 0%, #004E50 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    .section-description {
      font-size: 1.1rem;
      line-height: 1.8;
      color: var(--text-color-secondary);
      opacity: 0.9;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  &__map {
    height: 100%;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    .map-container {
      position: relative;
      height: 400px;
      border-radius: 20px 20px 0 0;
      overflow: hidden;

      #map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .contact-info {
      padding: 1.5rem;
      background: white;

      .info-item {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        padding: 0.75rem 0;
        border-bottom: 1px solid rgba(0, 164, 166, 0.1);

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }

        &:first-child {
          padding-top: 0;
        }

        i {
          font-size: 1.5rem;
          color: var(--primary-color);
          opacity: 0.9;
        }

        .info-content {
          h4 {
            font-size: 1rem;
            font-weight: 600;
            color: var(--heading-color);
            margin-bottom: 0.25rem;
          }

          p {
            font-size: 0.9rem;
            color: var(--text-color-secondary);
            margin: 0;
            line-height: 1.5;
          }
        }
      }
    }
  }

  &__form-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }

  &__form-header {
    margin-bottom: 1.5rem;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--primary-color);
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    h4 {
      font-size: 1.8rem;
      font-weight: 700;
      color: var(--heading-color);
      margin-bottom: 1rem;
      line-height: 1.3;
    }

    p {
      font-size: 1rem;
      line-height: 1.6;
      color: var(--text-color-secondary);
      opacity: 0.9;
    }
  }

  &__form {
    flex: 1;
    display: flex;
    flex-direction: column;

    .form-group {
      margin-bottom: 1rem;
    }

    .floating-label {
      position: relative;

      .form-control {
        height: auto;
        padding: 0.875rem 1rem;
        font-size: 1rem;
        border: 2px solid rgba(0, 164, 166, 0.1);
        border-radius: 12px;
        background: white;
        transition: all 0.3s ease;
        color: var(--heading-color);

        &::placeholder {
          color: transparent;
        }

        &:focus {
          border-color: var(--primary-color);
          box-shadow: 0 0 0 4px rgba(0, 164, 166, 0.1);
          outline: none;
        }

        &:focus + label,
        &:not(:placeholder-shown) + label {
          transform: translateY(-1.5rem) scale(0.85);
          background: white;
          padding: 0 0.5rem;
          color: var(--primary-color);
        }
      }

      textarea.form-control {
        min-height: 100px;
        resize: vertical;
      }

      label {
        position: absolute;
        left: 1rem;
        top: 1rem;
        color: var(--text-color-secondary);
        transition: all 0.3s ease;
        transform-origin: 0 0;
        background: transparent;
        pointer-events: none;
        font-size: 0.95rem;
      }
    }

    .btn-gradient {
      margin-top: auto;
      background: linear-gradient(135deg, #00A4A6 0%, #004E50 100%);
      color: white;
      padding: 1rem 2rem;
      border-radius: 50px;
      border: none;
      font-weight: 600;
      letter-spacing: 0.5px;
      transition: all 0.3s ease;
      text-transform: uppercase;
      font-size: 0.9rem;
      width: 100%;
      
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 5px 15px rgba(0, 164, 166, 0.3);
        opacity: 0.95;
      }

      i {
        font-size: 1.1rem;
      }

      &:focus {
        box-shadow: 0 0 0 4px rgba(0, 164, 166, 0.2);
        outline: none;
      }

      &:active {
        transform: translateY(0);
      }
    }
  }

  @media (max-width: 992px) {
    padding: 40px 0;

    &__header {
      .section-title {
        font-size: 2.2rem;
      }

      .section-description {
        font-size: 1rem;
      }
    }

    &__map {
      height: auto;

      .map-container {
        padding-bottom: 56.25%;
      }
    }

    &__form-wrapper {
      height: auto;
      padding: 1.5rem;
    }

    &__form-header {
      text-align: center;

      h4 {
        font-size: 1.5rem;
      }
    }
  }
}

/* Google Maps Info Window Stilleri */
:deep(.gm-style-iw) {
  padding: 0 !important;
  background-color: white !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1) !important;
  
  /* Close button */
  .gm-ui-hover-effect {
    top: 0 !important;
    right: 0 !important;
    padding: 6px !important;
    
    img {
      width: 16px !important;
      height: 16px !important;
    }
  }
}

:deep(.gm-style-iw-d) {
  overflow: hidden !important;
  padding: 0 !important;
}

:deep(.gm-style-iw-c) {
  padding: 0 !important;
  max-width: 300px !important;
}

:deep(.gm-style-iw-t) {
  &::after {
    background: white !important;
  }
}

.alert {
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-size: 0.9rem;

  &-success {
    background-color: rgba(76, 175, 80, 0.1);
    color: #2e7d32;
    border: 1px solid rgba(76, 175, 80, 0.2);
  }

  &-danger {
    background-color: rgba(244, 67, 54, 0.1);
    color: #d32f2f;
    border: 1px solid rgba(244, 67, 54, 0.2);
  }

  i {
    margin-right: 8px;
    font-size: 1.1rem;
  }
}
</style> 