<template>
  <div class="mobile-menu" :class="{ 'is-active': isOpen }">
    <div class="mobile-menu__overlay" @click="close"></div>
    <div class="mobile-menu__content">
      <div class="mobile-menu__header">
        <router-link :to="getPageRoute('home')" class="brand-wrapper" @click="close">
          <div class="brand-text">
            <span class="brand-name">Klinik Akademi</span>
          </div>
        </router-link>
        <button class="close-button" @click="close">
          <i class="bi bi-x"></i>
        </button>
      </div>

      <nav class="mobile-menu__nav">
        <router-link :to="getPageRoute('home')" class="mobile-nav-link" @click="close">
          <i class="bi bi-house-door"></i>
          <span>{{ $t('nav.home') }}</span>
        </router-link>

        <div class="menu-item" @click="toggleSubmenu('about')">
          <div class="menu-item__content">
            <i class="bi bi-building"></i>
            <span>{{ $t('nav.about') }}</span>
          </div>
          <i class="bi bi-chevron-down menu-item__arrow" :class="{ 'rotate': submenus.about }"></i>
        </div>

        <div class="submenu" v-if="submenus.about">
          <div class="submenu-section">
            <h4>{{ $t('nav.aboutMenu.corporate.title') }}</h4>
            <router-link :to="getPageRoute('about')" class="submenu-item" @click="close">
              <i class="bi bi-building"></i>
              <div>
                <span>{{ $t('nav.aboutMenu.corporate.aboutUs.title') }}</span>
                <small>{{ $t('nav.aboutMenu.corporate.aboutUs.subtitle') }}</small>
              </div>
              <i class="bi bi-arrow-right link-icon"></i>
            </router-link>
            <router-link :to="getPageRoute('team')" class="submenu-item" @click="close">
              <i class="bi bi-people"></i>
              <div>
                <span>{{ $t('nav.aboutMenu.corporate.team.title') }}</span>
                <small>{{ $t('nav.aboutMenu.corporate.team.subtitle') }}</small>
              </div>
              <i class="bi bi-arrow-right link-icon"></i>
            </router-link>
          </div>

          <div class="submenu-section">
            <h4>{{ $t('nav.aboutMenu.clinics.title') }}</h4>
            <a href="https://g.co/kgs/GXdnnYr" target="_blank" class="submenu-item">
              <i class="bi bi-geo-alt"></i>
              <div>
                <span>{{ $t('nav.aboutMenu.clinics.kartal.title') }}</span>
                <small>{{ $t('nav.aboutMenu.clinics.kartal.subtitle') }}</small>
              </div>
              <i class="bi bi-box-arrow-up-right link-icon"></i>
            </a>
            <a href="https://g.co/kgs/xxx" target="_blank" class="submenu-item">
              <i class="bi bi-geo-alt"></i>
              <div>
                <span>{{ $t('nav.aboutMenu.clinics.dikili.title') }}</span>
                <small>{{ $t('nav.aboutMenu.clinics.dikili.subtitle') }}</small>
              </div>
              <i class="bi bi-box-arrow-up-right link-icon"></i>
            </a>
          </div>
        </div>

        <div class="menu-item" @click="toggleSubmenu('treatments')">
          <div class="menu-item__content">
            <i class="bi bi-heart-pulse"></i>
            <span>{{ $t('nav.treatments') }}</span>
          </div>
          <i class="bi bi-chevron-down menu-item__arrow" :class="{ 'rotate': submenus.treatments }"></i>
        </div>

        <div class="submenu" v-if="submenus.treatments">
          <div class="submenu-section">
            <h4>{{ $t('nav.treatmentsMenu.basic.title') }}</h4>
            <router-link :to="getTreatmentLink('surgery')" class="submenu-item" @click="close">
              <i class="bi bi-bandaid"></i>
              <div>
                <span>{{ $t('nav.treatmentsMenu.basic.surgery.title') }}</span>
                <small>{{ $t('nav.treatmentsMenu.basic.surgery.subtitle') }}</small>
              </div>
              <i class="bi bi-arrow-right link-icon"></i>
            </router-link>
            <router-link :to="getTreatmentLink('implant')" class="submenu-item" @click="close">
              <i class="bi bi-plus-circle"></i>
              <div>
                <span>{{ $t('nav.treatmentsMenu.basic.implant.title') }}</span>
                <small>{{ $t('nav.treatmentsMenu.basic.implant.subtitle') }}</small>
              </div>
              <i class="bi bi-arrow-right link-icon"></i>
            </router-link>
            <router-link :to="getTreatmentLink('periodontics')" class="submenu-item" @click="close">
              <i class="bi bi-heart-pulse"></i>
              <div>
                <span>{{ $t('nav.treatmentsMenu.aesthetic.periodontics.title') }}</span>
                <small>{{ $t('nav.treatmentsMenu.aesthetic.periodontics.subtitle') }}</small>
              </div>
              <i class="bi bi-arrow-right link-icon"></i>
            </router-link>
          </div>

          <div class="submenu-section">
            <h4>{{ $t('nav.treatmentsMenu.aesthetic.title') }}</h4>
            <router-link :to="getTreatmentLink('whitening')" class="submenu-item" @click="close">
              <i class="bi bi-brightness-high"></i>
              <div>
                <span>{{ $t('nav.treatmentsMenu.aesthetic.whitening.title') }}</span>
                <small>{{ $t('nav.treatmentsMenu.aesthetic.whitening.subtitle') }}</small>
              </div>
              <i class="bi bi-arrow-right link-icon"></i>
            </router-link>
            <router-link :to="getTreatmentLink('prosthesis')" class="submenu-item" @click="close">
              <i class="bi bi-gem"></i>
              <div>
                <span>{{ $t('nav.treatmentsMenu.basic.prosthesis.title') }}</span>
                <small>{{ $t('nav.treatmentsMenu.basic.prosthesis.subtitle') }}</small>
              </div>
              <i class="bi bi-arrow-right link-icon"></i>
            </router-link>
            <router-link :to="getTreatmentLink('orthodontics')" class="submenu-item" @click="close">
              <i class="bi bi-shield-check"></i>
              <div>
                <span>{{ $t('nav.treatmentsMenu.aesthetic.orthodontics.title') }}</span>
                <small>{{ $t('nav.treatmentsMenu.aesthetic.orthodontics.subtitle') }}</small>
              </div>
              <i class="bi bi-arrow-right link-icon"></i>
            </router-link>
          </div>

          <div class="submenu-section">
            <h4>{{ $t('nav.treatmentsMenu.basic.title') }}</h4>
            <router-link :to="getTreatmentLink('rootCanal')" class="submenu-item" @click="close">
              <i class="bi bi-diagram-3"></i>
              <div>
                <span>{{ $t('nav.treatmentsMenu.basic.rootCanal.title') }}</span>
                <small>{{ $t('nav.treatmentsMenu.basic.rootCanal.subtitle') }}</small>
              </div>
              <i class="bi bi-arrow-right link-icon"></i>
            </router-link>
            <router-link :to="getTreatmentLink('pediatric')" class="submenu-item" @click="close">
              <i class="bi bi-emoji-smile"></i>
              <div>
                <span>{{ $t('nav.treatmentsMenu.aesthetic.pediatrics.title') }}</span>
                <small>{{ $t('nav.treatmentsMenu.aesthetic.pediatrics.subtitle') }}</small>
              </div>
              <i class="bi bi-arrow-right link-icon"></i>
            </router-link>
            
            <router-link :to="getTreatmentsPage()" class="view-all-treatments">
              {{ $t('treatments.viewAll') }}
              <i class="bi bi-grid-3x3-gap"></i>
            </router-link>
          </div>
        </div>

        <router-link :to="getPageRoute('media')" class="mobile-nav-link" @click="close">
          <i class="bi bi-image"></i>
          <span>{{ $t('nav.media') }}</span>
        </router-link>

        <router-link :to="getPageRoute('contact')" class="mobile-nav-link" @click="close">
          <i class="bi bi-envelope"></i>
          <span>{{ $t('nav.contact') }}</span>
        </router-link>
      </nav>

      <div class="mobile-menu__footer">
        <div class="language-selector">
          <button 
            v-for="lang in languages" 
            :key="lang.code"
            :class="['lang-btn', { active: currentLang === lang.code }]"
            @click="changeLang(lang.code)"
          >
            {{ lang.icon }} {{ lang.name }}
          </button>
        </div>

        <div class="social-links">
          <a href="https://www.facebook.com/KlinikAkademiAgizveDis/" target="_blank" class="social-link">
            <i class="bi bi-facebook"></i>
          </a>
          <a href="https://www.instagram.com/klinikakademi/" target="_blank" class="social-link">
            <i class="bi bi-instagram"></i>
          </a>
        </div>

        <div class="contact-bar">
          <div class="contact-info">
            <div class="contact-hours">
              <i class="bi bi-clock"></i>
              <div class="hours-detail">
                <span class="current-status">Şu an açık</span>
                <span class="time">09:00 - 00:00</span>
              </div>
            </div>
            <a href="tel:+905539736480" class="contact-phone">
              <i class="bi bi-telephone"></i>
              <span>+90 553 973 64 80</span>
            </a>
          </div>
          <div class="contact-location">
            <i class="bi bi-geo-alt"></i>
            <span>Orta, Yavuz Selim Cd. No:15-A</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setLocale, getLocale } from '@/plugins/i18n'
import { getTreatmentRoute, getPageRoute, getTreatmentsBaseSlug } from '@/utils/slug'

export default {
  name: 'MobileMenu',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      submenus: {
        about: false,
        treatments: false
      },
      currentLang: 'tr',
      languages: [
        { code: 'tr', name: 'Türkçe' },
        { code: 'en', name: 'English' },
        { code: 'de', name: 'Deutsch' }
      ]
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.closeAllSubmenus()
    },
    toggleSubmenu(menu) {
      Object.keys(this.submenus).forEach(key => {
        if (key !== menu) {
          this.submenus[key] = false
        }
      })
      this.submenus[menu] = !this.submenus[menu]
    },
    changeLang(lang) {
      this.currentLang = lang
      setLocale(lang)
    },
    closeAllSubmenus() {
      Object.keys(this.submenus).forEach(key => {
        this.submenus[key] = false
      })
    },
    getTreatmentLink(treatmentKey) {
      return getTreatmentRoute(treatmentKey)
    },
    getPageRoute(pageKey) {
      return getPageRoute(pageKey)
    },
    getTreatmentsPage() {
      return '/' + getTreatmentsBaseSlug()
    }
  },
  mounted() {
    this.currentLang = getLocale()
  }
}
</script>

<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  backdrop-filter: blur(5px);

  &.is-active {
    visibility: visible;
    opacity: 1;

    .mobile-menu__content {
      transform: translateX(0);
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 380px;
    height: 100%;
    background: linear-gradient(135deg, var(--primary-color) 0%, darken(#3aa1a5, 20%) 100%);
    transform: translateX(100%);
    transition: transform 0.2s ease;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  &__header {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .brand-wrapper {
      text-decoration: none;
      display: flex;
      align-items: center;
      
      .brand-text {
        font-family: "El Messiri", sans-serif;
        font-size: 1.90rem;
        font-weight: 700;
        color: #FFFFFF;
        transition: all 0.3s ease;
        line-height: 1;
        
        span {
          display: block;
          
          &:last-child {
            font-size: 1.90rem;
            opacity: 0.9;
          }
        }
      }
    }

    .close-button {
      background: transparent;
      border: none;
      color: white;
      font-size: 1.5rem;
      padding: 0.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.8;
        transform: scale(1.1);
      }

      i {
        font-size: 2rem;
      }
    }
  }

  &__nav {
    flex: 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .mobile-nav-link {
    display: flex;
    align-items: center;
    padding: 1.25rem;
    color: white;
    text-decoration: none;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
    gap: 1.25rem;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    i {
      font-size: 1.4rem;
    }
  }

  .menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem;
    color: white;
    text-decoration: none;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;

    &__content {
      display: flex;
      align-items: center;
      gap: 1.25rem;

      i {
        font-size: 1.5rem;
      }

      span {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }

    &__arrow {
      transition: transform 0.3s ease;

      &.rotate {
        transform: rotate(180deg);
      }
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .submenu {
    margin: 0.5rem 0 1rem 0;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.2s ease;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all 0.3s ease;
    max-height: 1000px;
  }

  .slide-enter-from,
  .slide-leave-to {
    max-height: 0;
    opacity: 0;
    transform: translateY(-10px);
  }

  .submenu-section {
    padding: 1.25rem;

    h4 {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1rem;
      margin-bottom: 1.25rem;
      padding-left: 0.5rem;
    }
  }

  .submenu-item {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    padding: 1.25rem;
    color: white;
    text-decoration: none;
    border-radius: 12px;
    margin-bottom: 0.75rem;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      transform: translateX(5px);
    }

    i {
      font-size: 1.4rem;
    }

    div {
      flex: 1;
      
      span {
        display: block;
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: 0.35rem;
      }

      small {
        display: block;
        font-size: 0.9rem;
      }
    }

    .link-icon {
      font-size: 1rem;
      opacity: 0.5;
    }
  }

  .close-button {
    width: 40px;
    height: 40px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);

    i {
      font-size: 1.5rem;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      transform: rotate(90deg);
    }
  }

  &__footer {
    margin-top: auto;
    padding: 1.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    .language-selector {
      display: flex;
      gap: 0.5rem;
      padding: 1rem;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      margin-bottom: 1.5rem;

      .lang-btn {
        flex: 1;
        padding: 0.75rem;
        border: none;
        background: transparent;
        color: white;
        border-radius: 6px;
        cursor: pointer;
        transition: all 0.3s ease;
        font-size: 1rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        &.active {
          background: var(--primary-color);
        }

        &:hover:not(.active) {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }

    .social-links {
      display: flex;
      gap: 0.5rem;
      padding: 1rem;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 8px;
      margin-bottom: 1.5rem;

      .social-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.1);
        color: white;
        text-decoration: none;
        transition: all 0.3s ease;

        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }

        i {
          font-size: 1.5rem;
        }
      }
    }

    .contact-bar {
      background: rgba(255, 255, 255, 0.05);
      border-radius: 12px;
      padding: 1.25rem;

      .contact-info {
        display: flex;
        align-items: center;
        gap: 1.75rem;
        padding-bottom: 1.25rem;
        margin-bottom: 1.25rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .contact-hours {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          color: white;

          i {
            font-size: 1.4rem;
          }

          .hours-detail {
            .current-status {
              display: block;
              color: #4CAF50;
              font-size: 0.9rem;
              margin-bottom: 0.25rem;
            }

            .time {
              font-size: 1rem;
              opacity: 0.9;
            }
          }
        }

        .contact-phone {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          color: white;
          text-decoration: none;

          i {
            font-size: 1.4rem;
          }

          span {
            font-size: 1rem;
          }

          &:hover {
            color: var(--primary-color);
          }
        }
      }

      .contact-location {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        color: white;

        i {
          font-size: 1.4rem;
        }

        span {
          font-size: 1rem;
          opacity: 0.9;
        }
      }
    }
  }

  .mobile-logo {
    height: 40px;
    width: auto;
    filter: brightness(0) invert(1);
  }
}
</style> 