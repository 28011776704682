export const stats = [
  {
    id: 1,
    number: '10K+',
    text: 'Sağlıklı Gülüş',
    icon: 'emoji-smile'
  },
  {
    id: 2,
    number: '15',
    text: 'Yıllık Deneyim',
    icon: 'award'
  },
  {
    id: 3,
    number: '25',
    text: 'Ülke',
    icon: 'globe'
  },
  {
    id: 4,
    number: '98',
    text: 'Başarı Oranı',
    icon: 'graph-up'
  }
] 