import { getLocale } from '../plugins/i18n'
import slugs from '../translations/slugs'

/**
 * Belirli bir tedavi türü için mevcut dile göre uygun slug'ı döndürür
 * @param {string} treatmentKey - tedavi anahtarı: implant, orthodontics, vb.
 * @returns {string} - Dile göre formatlanmış tam URL slug'ı
 */
export function getTreatmentSlug(treatmentKey) {
  const currentLocale = getLocale()
  return slugs[currentLocale]?.[treatmentKey] || slugs['tr'][treatmentKey]
}

/**
 * Mevcut dil için ana tedaviler sayfası slug'ını döndürür
 * @returns {string} - Dile göre formatlanmış tedaviler ana URL slug'ı
 */
export function getTreatmentsBaseSlug() {
  const currentLocale = getLocale()
  return slugs[currentLocale]?.treatments || 'tedaviler'
}

/**
 * Tam tedavi route'unu oluşturur
 * @param {string} treatmentKey - tedavi anahtarı: implant, orthodontics, vb.
 * @returns {string} - Dile göre formatlanmış tam URL
 */
export function getTreatmentRoute(treatmentKey) {
  return `/${getTreatmentsBaseSlug()}/${getTreatmentSlug(treatmentKey)}`
}

/**
 * Belirli bir sayfa için tam URL'yi döndürür
 * @param {string} pageKey - sayfa anahtarı: about, team, services, doctors, contact, media, clinic
 * @returns {string} - Dile göre formatlanmış tam URL
 */
export function getPageRoute(pageKey) {
  const currentLocale = getLocale()
  const pageSlug = slugs[currentLocale]?.[pageKey] || slugs['tr'][pageKey]
  return `/${pageSlug}`
}

/**
 * Verilen bir URL slug'ından tedavi anahtarını tespit etmeye çalışır
 * @param {string} slug - URL slug'ı
 * @returns {string|null} - Eşleşen tedavi anahtarı veya null
 */
export function getTreatmentKeyFromSlug(slug) {
  const currentLocale = getLocale()
  const localeSlug = slugs[currentLocale] || slugs['tr']
  
  for (const [key, value] of Object.entries(localeSlug)) {
    if (value === slug && key !== 'treatments') {
      return key
    }
  }
  
  return null
} 