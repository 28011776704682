import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18nPlugin from './plugins/i18n'

// Stil dosyalarını import et
import '@/assets/styles/main.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'aos/dist/aos.css'

// AOS'u import et ve yapılandır
import AOS from 'aos'
AOS.init({
  duration: 800,
  offset: 50,
  once: true
})

// Bootstrap JS'i lazy load et
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

const app = createApp(App)
app.use(router)
app.use(i18nPlugin)
app.mount('#app')
