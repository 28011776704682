<template>
  <section class="contact-cta">
    <div class="container">
      <CtaSection />
    </div>
  </section>
</template>

<script>
import CtaSection from '../common/CtaSection.vue'

export default {
  name: 'ContactCta',
  components: {
    CtaSection
  }
}
</script>

<style lang="scss" scoped>
.contact-cta {
  padding: 2rem 0;
  background: white;

  @media (max-width: 992px) {
    padding: 1.5rem 0;
  }
}
</style> 