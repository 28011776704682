import legal from './legal'

export default {
  nav: {
    home: 'Home',
    about: 'About Us',
    aboutLink: 'About Us',
    treatments: 'Treatments',
    treatmentsLink: 'Treatments',
    media: 'Media',
    contact: 'Contact',
    appointment: 'Make an Appointment',
    appointmentBtn: 'Make an Appointment',
    aboutMenu: {
      corporate: {
        title: 'Corporate',
        aboutUs: {
          title: 'About Us',
          subtitle: 'Our Mission and Vision'
        },
        team: {
          title: 'Our Team',
          subtitle: 'Professional Staff'
        }
      },
      clinics: {
        title: 'Our Clinics',
        kartal: {
          title: 'Kartal Branch',
          subtitle: 'Main Center'
        },
        dikili: {
          title: 'Dikili Branch',
          subtitle: 'Izmir'
        }
      }
    },
    treatmentsMenu: {
      basic: {
        title: 'Basic Treatments',
        implant: {
          title: 'Implantology',
          subtitle: 'Precision Dental Implants'
        },
        surgery: {
          title: 'Oral and Maxillofacial Surgery',
          subtitle: 'Expert Surgical Interventions'
        },
        rootCanal: {
          title: 'Root Canal Treatment',
          subtitle: 'Endodontic Treatments'
        },
        prosthesis: {
          title: 'Prosthetics',
          subtitle: 'Advanced Prosthetic Solutions'
        }
      },
      aesthetic: {
        title: 'Aesthetic and Special Treatments',
        whitening: {
          title: 'Whitening',
          subtitle: 'Professional Teeth Whitening'
        },
        orthodontics: {
          title: 'Orthodontics',
          subtitle: 'Smile Design and Correction'
        },
        periodontics: {
          title: 'Periodontics',
          subtitle: 'Gum Treatments'
        },
        pediatrics: {
          title: 'Pediatric Dentistry',
          subtitle: 'Children\'s Dental Care'
        },
        invisalign: {
          title: 'Clear Aligners',
          subtitle: 'Invisible Orthodontics'
        }
      },
      appointment: {
        title: 'Online Appointment',
        description: 'Book an appointment now and meet our expert team.',
        button: 'Make an Appointment'
      }
    }
  },
  
  navbar: {
    home: 'Home',
    treatments: 'Treatments',
    about: 'About Us',
    media: 'Media',
    contact: 'Contact'
  },
  
  header: {
    clinicName: 'Klinik Akademi',
    openNow: 'Now Open',
    workingHours: 'Monday - Sunday 09:00 - 00:00',
    workingDays: 'Monday - Sunday',
    workingTime: '09:00 - 00:00',
    address: 'Orta, Yavuz Selim Cd. No:15-A Kartal/Istanbul'
  },
  hero: {
    title: 'Kartal Soğanlık Dental Clinic',
    subtitle: 'we are at your service 7 days a week.',
    description: 'With our modern technology and expert staff, we redesign your smile. Let\'s create your treatment plan with free examination and digital X-ray.',
    ctaButton: 'Make an Appointment',
    secondaryButton: 'Our Services',
    feature1: 'Free Examination',
    feature2: 'Digital X-Ray',
    feature3: '3D Planning',
    cardTitle: 'Online Appointment',
    cardText: 'We Are At Your Service 7 Days a Week',
    badgeTitle: '10,000+',
    badgeText: 'Healthy Smiles',
    badge1: '15+ Years of Experience',
    badge2: 'High-Level Technology'
  },
  whyUs: {
    badge: 'Why Us?',
    title: 'The Reason We Are Here:',
    subtitle: 'A Natural Smile',
    description: 'We are at your service with our up-to-date technological equipment, modern diagnostic and treatment methods, and solution-oriented team.',
    feature1: 'Modern Technology',
    feature2: 'Expert Staff',
    feature3: 'Sterile Environment',
    button: 'More Information',
    floatingBadge: '15+ Years of Experience'
  },
  tourism: {
    badge: 'Modern Clinic',
    title: 'Reliable and Comfortable',
    subtitle: 'Dental Treatment',
    description: 'With our modern technology and expert staff, we offer you the most comfortable treatment experience. We are here for your reliable dental treatment with our patient-oriented approach and sterile environment.',
    ctaButton: 'Make an Appointment',
    floatingBadge: 'Advanced Technology',
    stat1: 'Years<br>Experience',
    stat2: 'Patient<br>Satisfaction',
    features: {
      feature1: 'Modern Technology',
      description1: 'Latest technology devices and materials',
      feature2: 'Expert Staff',
      description2: 'Experienced and specialized dentists',
      feature3: 'Digital Planning',
      description3: 'Detailed treatment plan with 3D imaging',
      feature4: 'Sterile Environment',
      description4: 'Full sterilization and hygiene standards'
    }
  },
  checkup: {
    badge: 'Free Examination',
    title: 'Check Your Oral and',
    subtitle: 'Dental Health',
    description: 'Take the first step for your dental health. Let our expert dentists check your oral and dental health with a free examination and create a personalized treatment plan for you.',
    ctaButton: 'Free Examination Appointment',
    doctorName: 'Dr. Harun Atay',
    features: {
      feature1: 'Detailed Examination',
      description1: 'Comprehensive oral and dental check',
      feature2: 'Digital X-Ray',
      description2: 'Panoramic and periapical x-ray',
      feature3: 'Treatment Plan',
      description3: 'Personalized treatment planning',
      feature4: 'Price Quote',
      description4: 'Transparent and detailed cost information'
    }
  },
  services: {
    badge: 'Our Treatments',
    title: 'In Modern Dentistry',
    subtitle: 'Expert Treatments',
    viewAllButton: 'All Our Treatments',
    items: {
      1: {
        title: 'Dental Implants',
        description: 'Permanent dental treatment with lifetime warranty'
      },
      2: {
        title: 'Zirconium Crowns',
        description: 'Natural-looking aesthetic coatings'
      },
      3: {
        title: 'Orthodontics',
        description: 'Teeth alignment with modern techniques'
      },
      4: {
        title: 'Aesthetic Dentistry',
        description: 'Smile design and aesthetic applications'
      },
      5: {
        title: 'Teeth Whitening',
        description: 'Professional whitening systems'
      },
      6: {
        title: 'Pediatric Dentistry',
        description: 'Specialized pediatric dental care'
      },
      implant: {
        title: 'Dental Implants',
        description: 'Permanent dental treatment with lifetime warranty'
      },
      zirconium: {
        title: 'Zirconium Crowns',
        description: 'Natural-looking aesthetic coatings'
      },
      orthodontics: {
        title: 'Orthodontics',
        description: 'Teeth alignment with modern techniques'
      },
      aesthetics: {
        title: 'Aesthetic Dentistry',
        description: 'Smile design and aesthetic applications'
      },
      whitening: {
        title: 'Teeth Whitening',
        description: 'Professional whitening systems'
      },
      pediatric: {
        title: 'Pediatric Dentistry',
        description: 'Specialized pediatric dental care'
      }
    }
  },
  about: {
    title: 'About Us',
    subtitle: 'Klinik Akademi',
    description: 'With over 20 years of experience, we provide high quality dental care to our patients in Kartal. Our expert team offers solutions for all your dental problems with modern equipment and a patient-focused approach.',
    expertise: 'Expertise',
    experience: 'Experience',
    technology: 'Technology',
    comfort: 'Comfort',
    
    hero: {
      title: "Professional Solutions",
      highlight: "for Healthy Smiles",
      text1: "Welcome to Kartal Soğanlık Klinik Akademi, your destination for first-class dental care. Our modern clinic is equipped with state-of-the-art equipment and a team of experienced professionals dedicated to your oral health and radiant smiles.",
      text2: "At Klinik Akademi, we blend the latest technology with personal care to provide a wide range of services from routine check-ups to advanced treatments. Our welcoming environment is designed to ensure your comfort, and our friendly team is committed to making your dental experience enjoyable.",
      text3: "By prioritizing preventive care and patient education, we strive to help you maintain optimal oral health. Whether you're here for a routine visit or cosmetic improvements, we're here to guide you on your journey to a healthier, more beautiful smile.",
      stats: {
        experience: "Years of Experience",
        smiles: "Healthy Smiles"
      },
      badge: "Advanced Technology"
    },
    mission: {
      badge: "Our Mission",
      title: "Our Values and Importance",
      text: "At Klinik Akademi, our mission is to provide top-tier dental care while emphasizing preventive healthcare and ensuring that patients leave our clinic in the healthiest position possible. Through cutting-edge technology and a dedicated team, we aim to create a positive and comfortable experience for each patient.",
      services: {
        digital: "Digital Dentistry",
        aesthetic: "Aesthetic Dentistry",
        surgery: "Oral Surgery",
        whitening: "Teeth Whitening",
        pediatric: "Pediatric Dentistry",
        extraction: "Tooth Extraction"
      },
      badge_doctor: "Specialist Doctor"
    },
    vision: {
      badge: "Our Vision",
      title: "A Perfect Smile",
      text: "At Klinik Akademi, we envision a community where healthy, confident smiles contribute to overall well-being. With perfectionist, innovative, and compassionate solutions, we aim to be trusted leaders in the field of dentistry.",
      services: {
        patient: "Patient-Centered",
        technology: "Modern Technology",
        expert: "Expert Staff"
      },
      badge_doctor: "Dentist"
    },
    treatments: {
      badge: "Our Treatments",
      title: "Expert Treatments",
      highlight: "in Modern Dentistry",
      services: {
        implant: {
          title: "Implant Treatment",
          description: "Permanent dental treatment with lifetime warranty"
        },
        zirconium: {
          title: "Zirconium Crowns",
          description: "Natural-looking aesthetic crowns"
        },
        orthodontics: {
          title: "Orthodontics",
          description: "Teeth alignment with modern techniques"
        },
        aesthetic: {
          title: "Aesthetic Dentistry",
          description: "Smile design and aesthetic applications"
        },
        whitening: {
          title: "Teeth Whitening",
          description: "Professional whitening systems"
        },
        pediatric: {
          title: "Pediatric Dentistry",
          description: "Pedodontics specialist treatments"
        }
      },
      all_treatments: "All Our Treatments"
    }
  },
  treatments: {
    title: 'Treatments',
    subtitle: 'Professional Services',
    basic: {
      title: 'Basic Treatments',
      implant: {
        title: 'Implantology',
        subtitle: 'Precision Dental Implants'
      },
      surgery: {
        title: 'Oral and Maxillofacial Surgery',
        subtitle: 'Expert Surgical Interventions'
      },
      rootCanal: {
        title: 'Root Canal Treatment',
        subtitle: 'Endodontic Treatments'
      },
      prosthesis: {
        title: 'Prosthetic Treatment',
        subtitle: 'Advanced Prosthetic Solutions'
      }
    },
    aesthetic: {
      title: 'Aesthetic and Special Treatments',
      whitening: {
        title: 'Teeth Whitening',
        subtitle: 'Professional Teeth Whitening'
      },
      orthodontics: {
        title: 'Orthodontics',
        subtitle: 'Smile Design and Correction'
      },
      periodontics: {
        title: 'Periodontics',
        subtitle: 'Gum Treatments'
      },
      pediatrics: {
        title: 'Pediatric Dentistry',
        subtitle: 'Children\'s Dental Care'
      },
      invisalign: {
        title: 'Invisalign Clear Aligners',
        subtitle: 'Invisible Orthodontics'
      }
    },
    appointment: {
      title: 'Online Appointment',
      description: 'Book an appointment now and meet our expert team.',
      button: 'Make an Appointment'
    },
    implant: {
      title: 'Dental Implants',
      description: 'Permanent solution for missing teeth with high success rate.'
    },
    orthodontics: {
      title: 'Orthodontics',
      description: 'Effective treatments for dental misalignment problems.'
    },
    aesthetics: {
      title: 'Aesthetic Dentistry',
      description: 'Solutions for a more beautiful and confident smile.'
    },
    whitening: {
      title: 'Teeth Whitening',
      description: 'Effective whitening for a brighter smile.'
    },
    surgery: {
      title: 'Oral and Maxillofacial Surgery',
      description: 'Expert solutions for surgical interventions.'
    },
    pediatric: {
      title: 'Pediatric Dentistry',
      description: 'Child-friendly dental care.'
    },
    digital: {
      title: 'Digital Dentistry',
      description: 'Advanced treatment with modern technology.'
    },
    general: {
      title: 'General Dentistry',
      description: 'Basic dental care and preventive treatments.'
    },
    rootCanal: {
      title: 'Root Canal Treatment',
      description: 'Treatment and preservation of dental nerves.'
    },
    periodontics: {
      title: 'Periodontics',
      description: 'Special treatments for healthy gums.'
    },
    prosthesis: {
      title: 'Prosthetic Treatment',
      description: 'Durable and aesthetic prosthetic solutions.'
    },
    invisalign: {
      title: 'Invisalign Clear Aligners',
      description: 'Straight teeth with invisible orthodontics.'
    },
    viewAll: 'View All Treatments',
    
    seo: {
      title: "All Our Treatments | Klinik Akademi",
      description: "Get detailed information about all dental treatments and services provided at Klinik Akademi."
    },
    hero: {
      title: "Kartal Soğanlık Dental Clinic",
      subtitle: "All Treatments",
      text1: "With our latest technology and expert team, we offer comprehensive treatment options for your oral and dental health. Each treatment plan is specially designed to meet the needs of our patients and is implemented to the highest standards.",
      text2: "Using all the possibilities of modern dentistry, we follow the latest technological developments and constantly update our treatment protocols. We prioritize your comfort and health, combining advanced technology with a gentle touch to create a positive experience.",
      features: {
        expert: "Expert Treatment",
        patient: "Patient-Centered",
        quality: "Quality Service"
      },
      imageAlt: "Klinik Akademi Dental Treatments and Services",
      experience: "15+ Years Experience"
    },
    grid: {
      badge: "Our Treatments",
      title: "At Klinik Akademi",
      subtitle: "Expert Treatments"
    },
    items: {
      implant: {
        title: "Dental Implant",
        description: "Permanent and natural-looking solution for your missing teeth",
        feature1: "Lifetime warranty",
        feature2: "Modern implant systems",
        feature3: "Fast recovery process"
      },
      orthodontics: {
        title: "Orthodontics",
        description: "Straight and aesthetic teeth with modern techniques",
        feature1: "Clear aligner treatment",
        feature2: "Invisalign",
        feature3: "Metal and ceramic braces"
      },
      whitening: {
        title: "Teeth Whitening",
        description: "Bright smiles with professional whitening systems",
        feature1: "Laser whitening",
        feature2: "Office-type whitening",
        feature3: "Home-type whitening"
      },
      rootCanal: {
        title: "Root Canal Treatment",
        description: "Expert treatment to protect your natural teeth",
        feature1: "Treatment under microscope",
        feature2: "Painless procedure",
        feature3: "Successful results"
      },
      prosthesis: {
        title: "Prosthetics",
        description: "Aesthetic and functional solutions for your missing teeth",
        feature1: "Zirconium prosthetics",
        feature2: "Removable prosthetics",
        feature3: "Implant-supported prosthetics"
      },
      periodontics: {
        title: "Periodontics",
        description: "Gum diseases and aesthetic gum treatments",
        feature1: "Gum aesthetics",
        feature2: "Treatment of gum diseases",
        feature3: "Bone grafting"
      },
      pediatric: {
        title: "Pediatric Dentistry",
        description: "Special and safe treatment for your children",
        feature1: "Child-friendly environment",
        feature2: "Expert staff",
        feature3: "Early treatment"
      },
      surgery: {
        title: "Oral and Maxillofacial Surgery",
        description: "Complex tooth extraction and surgical procedures",
        feature1: "20 years of experience",
        feature2: "Modern surgical techniques",
        feature3: "Comfortable recovery"
      },
      invisalign: {
        title: "Invisalign",
        description: "Straight teeth with invisible orthodontics",
        feature1: "Clear aligners",
        feature2: "Comfortable use",
        feature3: "Aesthetic appearance"
      }
    },
    implantPage: {
      seo: {
        title: "Dental Implants in Kartal Soğanlık | Klinik Akademi",
        description: "Dental implant treatment in Kartal Soğanlık with our expert team. Permanent dental treatment with modern implant systems and lifetime guarantee.",
        keywords: "kartal dental implant, soganlik implant, kartal implant treatment, soganlik dental implant, kartal implant prices"
      },
      hero: {
        badge: "Dental Implantology",
        title: "Kartal Soğanlık Dental Implants",
        highlight: "Dental Implantology",
        description: "Dental implants provide a life-changing solution that mimics the function and feel of natural teeth. Learn more about this transformative option!",
        buttons: {
          appointment: "Make an Appointment",
          details: "Detailed Information"
        },
        features: {
          expert: "Expert Staff",
          expertSmall: "Experienced Team",
          technology: "Modern Technology",
          technologySmall: "Safe Treatment",
          experience: "15+ Years Experience",
          experienceSmall: "Expert Service"
        },
        imageAlt: "Dental Implantology",
        experience: "Years Experience"
      },
      header: {
        title: "Dental Implantology",
        description: "Reliable Treatment with Modern Implant Technology"
      },
      responsibilities: {
        badge: "Surgical Treatments",
        title: "Responsibilities of",
        highlight: "Our Expert Team",
        description: "Think of dental implants as artificial tooth roots. Made from biocompatible materials like titanium, these materials are surgically placed into your jawbone to naturally fuse. This creates a strong foundation for attaching crowns, bridges, or dentures, offering exceptional stability and support.",
        imageAlt: 'Oral and Maxillofacial Surgery Team',
        items: [
          "Dental implants offering a natural appearance and function for missing teeth",
          "Correction of irregularities affecting facial balance, biting, and breathing",
          "Reconstructive surgeries for facial trauma resulting from accidents or injuries",
          "Diagnosis and treatment of oral diseases, including oral cancers",
          "Surgical repair of cleft lip and palate to improve function and appearance",
          "Interventions for obstructive sleep apnea",
          "Treatment of temporomandibular joint disorders",
          "Reconstructive surgeries"
        ]
      },
      advantages: {
        badge: "Treatment Advantages",
        title: "Why Surgical",
        highlight: "Treatment?",
        description: "Advantages of surgical treatments with our modern technology and expert team",
        items: [
          "Creative operations for complex conditions by specialist physicians",
          "Holistic approach to treatment and functional results",
          "Improvements in oral functionality and health",
          "Improvements in facial aesthetics",
          "Increased confidence and quality of life"
        ]
      },
      suitable: {
        badge: "Treatment Criteria",
        title: "Who Is It",
        highlight: "Suitable For?",
        description: "Who can benefit from dental implant treatment?",
        items: [
          "Adults of any age (after facial development is complete)",
          "People with good general and oral health",
          "Those with one or more missing teeth",
          "People dissatisfied with dentures or bridges",
          "Those seeking better chewing function and speech",
          "Those concerned about bone loss"
        ]
      },
      faq: {
        badge: "About Implant Treatment",
        title: "Frequently Asked",
        highlight: "Questions",
        description: "Find answers to commonly asked questions about implant treatment below",
        items: [
          {
            question: "Does implant treatment have a specific duration?",
            answer: "If the bones are not strong enough to carry the implants, bone strengthening treatment is performed by the specialist physician before the application. However, the actual treatment begins only after this treatment is completed and it is ensured that the patient has bones suitable for implants. It takes 2-4 months for the implant treatment to be completed."
          },
          {
            question: "Can implant treatment fail?",
            answer: "When it is detected that the implants placed in the bone have still not fused to the bone despite waiting for 2 to 4 months for osseointegration, it means that the relevant application has failed. The main reason for this problem, also known as implant failure, is that the bones are not strong enough to carry the implant. This should be examined by a specialist physician before the application, and additional treatments should be applied if deemed necessary."
          },
          {
            question: "What is the lifespan of implant application?",
            answer: "Implant teeth are long-lasting like original teeth if maintained regularly and attention is paid to oral hygiene. After the implant is placed, it is possible to use it for many years without loss of function, or even to keep it in the mouth for a lifetime without problems and in a healthy way."
          },
          {
            question: "Is there an age limit for implant treatment?",
            answer: "Implants can be applied to anyone who has a missing tooth and whose existing bone structure is suitable for treatment. However, the important criterion here is that the bones have completed their development. Implants applied to developing bones may cause problems after a while, and these implants may need to be replaced. Therefore, the lower limit for implant treatment is 18 years. Implants can be made to anyone who has reached the age of 18."
          },
          {
            question: "Is there a special care treatment for implanted teeth?",
            answer: "As with all teeth, cleaning and care are very important for implant teeth. In fact, it can be said that these teeth are a little more important. Implant teeth should be brushed regularly and dental floss should be used if necessary. Gargling can also be done at certain intervals to ensure complete oral hygiene. Substances such as food residue that accumulate around the implant can cause problems such as tartar and gum recession in the long term."
          }
        ]
      }
    },
    oralSurgeryPage: {
      seo: {
        title: 'Oral and Maxillofacial Surgery | Kartal Soğanlık Dental Clinic',
        description: 'We offer high-quality oral and maxillofacial surgery treatments with our specialist surgeons in Kartal and Soğanlık area. Call now for free examination.',
        keywords: 'oral surgery, dental surgery, maxillofacial surgery, wisdom tooth extraction, impacted tooth removal, jaw cysts, bone augmentation, kartal dental clinic, soganlik dental clinic'
      },
      hero: {
        badge: 'Surgical Treatments',
        title: 'Kartal Soğanlık Oral and Maxillofacial Surgery',
        highlight: 'Specialist Surgical Team',
        description: 'In our oral and maxillofacial surgery department, our experienced specialists provide solutions for complex problems affecting dental and jaw structures. We specialize in implant surgery, impacted tooth extraction, bone augmentation, and treatment of jaw cysts.',
        buttons: {
          appointment: 'Make Appointment',
          details: 'More Details'
        },
        expertFeature: 'Expert Surgical Team',
        technologyFeature: 'Advanced Technology Equipment',
        experienceFeature: '15+ Years Experience',
        features: {
          expert: 'Expert Staff',
          expertSmall: 'Experienced Team',
          technology: 'Modern Technology',
          technologySmall: 'Safe Treatment',
          experience: '15+ Years Experience',
          experienceSmall: 'Expert Service'
        },
        imageAlt: 'Oral and Maxillofacial Surgery',
        experience: "Years Experience"
      },
      header: {
        title: 'Oral and Maxillofacial Surgery',
        description: 'Specialized Treatments for Facial, Jaw and Oral Diseases'
      },
      responsibilities: {
        badge: 'Surgical Treatments',
        title: 'Our Specialist Team\'s',
        highlight: 'Areas of Responsibility',
        description: 'Comprehensive treatment services performed by our oral and maxillofacial surgery specialists',
        imageAlt: 'Oral and Maxillofacial Surgery Team',
        items: [
          'Dental implants that provide a natural appearance and function for missing teeth',
          'Correction of irregularities affecting facial balance, biting, and breathing',
          'Treatment of cysts and tumors in the dental and jaw area',
          'Bone augmentation procedures in cases of jaw bone deficiencies',
          'Extraction of impacted teeth (wisdom teeth)',
          'Treatment of jaw and facial fractures after trauma',
          'Treatment of temporomandibular joint (TMJ) disorders',
          'Surgical treatment of gum diseases'
        ]
      },
      advantages: {
        badge: 'Treatment Advantages',
        title: 'Why Surgical',
        highlight: 'Treatment Required?',
        description: 'Advantages of oral and maxillofacial surgery treatments and the functional and aesthetic benefits that come with dental implants',
        items: [
          'Creative operations by specialist physicians for complex conditions',
          'Holistic approach to treatment and functional results',
          'Minimally invasive interventions with advanced surgical techniques',
          'Sedation options for a comfortable patient experience',
          'High success rates with three-dimensional planning'
        ]
      },
      suitable: {
        badge: 'Treatment Criteria',
        title: 'Who Is It',
        highlight: 'Suitable For?',
        description: 'Oral and maxillofacial surgery treatment may be necessary in the following cases:',
        items: [
          'Those who need dental implants due to tooth loss',
          'Those with jaw irregularities',
          'Those with impacted wisdom teeth',
          'Those who develop cysts or tumors in the jaw and dental area',
          'Those with jaw bone deficiency',
          'Those who need surgical treatment for gum diseases'
        ]
      },
      faq: {
        badge: 'About Oral and Maxillofacial Surgery',
        title: 'Frequently Asked',
        highlight: 'Questions',
        items: [
          {
            question: 'Do impacted teeth need to be extracted?',
            answer: 'No, not always. Impacted teeth need to be extracted if they are associated with cyst or tumor formation, if they may cause fractures, or if they are causing an abscess.'
          },
          {
            question: 'Should all wisdom teeth be extracted?',
            answer: 'No. Wisdom teeth that emerge without problems in the mouth do not need to be extracted if they have a healthy structure.'
          },
          {
            question: 'How is impacted tooth extraction performed?',
            answer: 'Impacted tooth extraction, which is among oral and maxillofacial surgery applications, is performed in a clinical setting and under local anesthesia.'
          },
          {
            question: 'Does impacted tooth extraction cause pain?',
            answer: 'The procedure does not cause pain because it is performed under local anesthesia.'
          },
          {
            question: 'Are all intraoral surgical procedures performed with local anesthesia?',
            answer: 'No. Local or general anesthesia can be applied depending on the procedure to be performed. Operations performed under the supervision of specialist dentists are mostly performed under local anesthesia.'
          }
        ]
      }
    },
    rootCanalPage: {
      seo: {
        title: 'Root Canal Treatment | Kartal Soğanlık Dental Clinic',
        description: 'Comfortable and painless root canal treatment under microscope in Kartal Soğanlık. Call now for healthy teeth.',
        keywords: 'root canal treatment, kartal root canal, soganlik root canal, painless root canal, microscopic root canal, comfortable root canal, dental nerve treatment'
      },
      hero: {
        badge: 'Endodontic Treatment',
        title: 'Kartal Soğanlık Root Canal Treatment',
        highlight: 'Healthy Teeth',
        description: 'Endodontics specializes in treating the inner aspects of teeth, focusing primarily on the pulp, which houses nerves, blood vessels, and connective tissues. This treatment saves your tooth in cases of pulp inflammation caused by decay, injury, or extensive dental procedures.',
        buttons: {
          appointment: 'Make Appointment',
          details: 'More Details'
        },
        features: {
          expert: 'Expert Staff',
          expertSmall: 'Experienced Team',
          technology: 'Modern Technology',
          technologySmall: 'Safe Treatment',
          experience: '15+ Years Experience',
          experienceSmall: 'Expert Service'
        },
        imageAlt: 'Root Canal Treatment',
        experience: "Years Experience"
      },
      header: {
        title: 'Root Canal Treatment',
        description: 'Protect Your Dental Health with Modern Endodontic Treatment'
      },
      responsibilities: {
        badge: 'Endodontic Treatment',
        title: 'Endodontists\'',
        highlight: 'Areas of Expertise',
        description: 'Specialized areas of expertise of our dental specialists in endodontic treatment',
        imageAlt: 'Root Canal Treatment Specialist',
        items: [
          'Performing root canal treatments: Removing damaged pulp, sterilizing the tooth, and filling it',
          'Addressing complex dental issues: Treatment of internal tooth resorption, treatment of cracked teeth',
          'Revisiting failed root canals: Retreatment procedures',
          'Applying surgical interventions: Apical surgery, root-end resection'
        ]
      },
      consultation: {
        badge: 'Consultation',
        title: 'Endodontic Consultation',
        highlight: 'Indications',
        description: 'If you are experiencing ongoing tooth pain, sensitivity, swelling near a tooth, or discomfort while chewing, you may be advised to consult an endodontist for a comprehensive evaluation and potential treatment. Additionally, your regular dentist may recommend an endodontic consultation if they detect issues in the inner structure of the tooth.'
      },
      advantages: {
        badge: 'Treatment Advantages',
        title: 'Advantages of',
        highlight: 'Endodontic Treatment',
        description: 'Advantages we offer with modern endodontic treatment',
        items: [
          'Preservation of natural teeth: The primary goal is to preserve your natural teeth, which surpass all prosthetic alternatives in terms of functionality and aesthetic appeal.',
          'Post-procedure comfort: Modern endodontic techniques emphasize effective anesthesia, pain management, and gentle techniques to maximize comfort during and after treatment.',
          'Protection of overall oral health: In patients who have had teeth extracted, neighboring teeth can shift and disrupt chewing function.',
          'Functional return: After a well-treated root canal, the tooth returns to normal function, with full chewing ability typically restored within a few days.',
          'Cosmetic integrity: With the preservation of the natural tooth, patients can continue to maintain their cosmetic appearance, smiling confidently.'
        ]
      },
      suitable: {
        badge: 'Treatment Criteria',
        title: 'Who Is It',
        highlight: 'Suitable For?',
        description: 'Root canal treatment is generally suitable for:',
        items: [
          'Those with deep cavities',
          'Those experiencing tooth fractures',
          'Those needing repeated dental procedures',
          'Those who have experienced dental trauma',
          'Those suffering from prolonged tooth pain',
          'Those experiencing tooth sensitivity'
        ]
      },
      faq: {
        badge: 'About Root Canal Treatment',
        title: 'Frequently Asked',
        highlight: 'Questions',
        items: [
          {
            question: 'Is root canal treatment painful?',
            answer: 'In modern dentistry, root canal treatment is typically a painless procedure. The treatment area is numbed with anesthesia, and various techniques are used to enhance patient comfort. There may be mild sensitivity for a few days after treatment, but this can usually be easily managed with prescription or over-the-counter pain relievers.'
          },
          {
            question: 'Does a tooth lose its vitality after root canal treatment?',
            answer: 'Yes, since root canal treatment removes the pulp of the tooth, the nerves and blood vessels are removed, and the tooth is no longer considered vital. However, the tooth still continues to remain attached to the jawbone and maintain its function. Treated teeth are usually recommended to have a crown placed for additional support and protection.'
          },
          {
            question: 'How long does a root canal treatment take?',
            answer: 'A root canal treatment typically takes one or two sessions, with each session ranging from approximately 60-90 minutes. The number of sessions required depends on factors such as the location of the tooth being treated, the complexity of the root canals, and the presence of infection.'
          },
          {
            question: 'How long after root canal treatment can I eat?',
            answer: 'You can eat once the anesthesia in your mouth has worn off, which is usually within a few hours after the appointment. It is advised to avoid hard or sticky foods on that side until the treated tooth has fully healed and been restored with a permanent filling or crown.'
          },
          {
            question: 'Could extracting the tooth be a better option than root canal treatment?',
            answer: 'When possible, root canal treatment is preferred over tooth extraction. Preserving your natural tooth is always the best option. Extraction leaves a gap that requires replacement (with an implant, bridge, or denture) and can cause neighboring teeth to shift. However, if the tooth is severely damaged or has a poor prognosis, extraction may be necessary.'
          }
        ]
      }
    },
    prosthesisPage: {
      seo: {
        title: 'Prosthetic Treatment | Kartal Soğanlık Dental Clinic',
        description: 'Modern prosthetic dental treatment with our expert team in Kartal Soğanlık. Natural appearance with zirconium, porcelain, removable and implant-supported prosthesis options.',
        keywords: 'prosthesis, dental prosthesis, zirconium crown, porcelain crown, removable prosthesis, implant-supported prosthesis, complete denture, partial denture, kartal prosthesis, soğanlık prosthesis'
      },
      hero: {
        badge: 'Prosthetic Treatment',
        title: 'Kartal Soğanlık Prosthetic Treatment',
        highlight: 'Dental Prosthetics',
        description: 'We offer natural-looking and comfortable prosthetic solutions with modern dental prosthesis technologies. Our expert team is here for customized prosthesis options just for you.',
        buttons: {
          appointment: 'Make Appointment',
          details: 'More Details'
        },
        features: {
          expert: 'Expert Team',
          expertSmall: 'Experienced Staff',
          technology: 'Modern Technology',
          technologySmall: 'Safe Treatment',
          experience: '15+ Years Experience',
          experienceSmall: 'Expert Service'
        },
        imageAlt: 'Dental Prosthetic Treatment',
        experience: "Years Experience"
      },
      header: {
        title: 'Prosthetic Treatment',
        description: 'Safe and Natural-Looking Prosthetic Treatment'
      },
      responsibilities: {
        badge: 'Prosthetic Treatment',
        title: 'With Our Expert Team',
        highlight: 'Prosthetic Treatment',
        description: 'Dental prostheses are treatment methods that restore chewing function and aesthetic appearance by replacing missing teeth. Prostheses used in modern dentistry offer excellent results both functionally and aesthetically.',
        imageAlt: 'Prosthetic Treatment',
        items: [
          'Fixed prostheses: Natural-looking crowns with zirconium and porcelain',
          'Removable prostheses: Complete and partial dentures for missing teeth',
          'Implant-supported prostheses: Fixed or removable prostheses based on implants',
          'Laminate veneers: Thin porcelain coverings applied to the front surface of teeth',
          'Temporary prostheses: Protective prostheses used between treatment processes',
          'Digital smile design: Computer-assisted aesthetic dental prostheses'
        ]
      },
      advantages: {
        badge: 'Treatment Advantages',
        title: 'Advantages of',
        highlight: 'Prosthetic Treatment',
        description: 'Advantages we offer with our modern prosthetic technologies',
        items: [
          'Restoration of chewing function: Prostheses eliminate chewing difficulties caused by missing teeth.',
          'Preservation of facial aesthetics: Missing teeth can cause changes in facial structure, prostheses prevent this change.',
          'Improvement of speech function: Missing teeth can cause speech problems, prostheses solve these problems.',
          'Protection of adjacent teeth: Prostheses prevent other teeth from shifting and becoming crooked.',
          'Increased self-confidence: Aesthetic appearance provides increased self-confidence in social life.'
        ]
      },
      suitable: {
        badge: 'Treatment Criteria',
        title: 'Who Is It',
        highlight: 'Suitable For?',
        description: 'Prosthetic treatment is generally suitable for the following conditions:',
        items: [
          'Those with one or more missing teeth',
          'Those with severely worn teeth',
          'Those who want to improve their aesthetic appearance',
          'Those experiencing problems with chewing functions',
          'Patients experiencing speech disorders',
          'Those who need superstructure after implant treatment'
        ]
      },
      types: {
        badge: 'Prosthesis Types',
        title: 'Prosthesis Options',
        highlight: 'We Offer',
        description: 'Different prosthetic alternatives we offer according to our patients\' needs',
        items: [
          {
            title: 'Zirconium Crowns',
            description: 'Durable crowns that are metal-free and provide natural tooth appearance'
          },
          {
            title: 'Porcelain Crowns',
            description: 'Crowns with high light transmittance that give aesthetically perfect results'
          },
          {
            title: 'Implant-Supported Prostheses',
            description: 'Fixed or removable prostheses connected to implants'
          },
          {
            title: 'Complete Dentures',
            description: 'Full set dentures used when all teeth are lost'
          },
          {
            title: 'Partial Dentures',
            description: 'Partial prostheses used when some teeth are missing'
          },
          {
            title: 'Laminate Veneers',
            description: 'Thin porcelain coatings bonded to the front surface of teeth'
          }
        ]
      },
      process: {
        badge: 'Treatment Process',
        title: 'Prosthetic Treatment',
        highlight: 'Stages',
        description: 'Prosthetic treatment generally consists of the following stages:',
        items: [
          {
            title: 'Detailed Examination',
            description: 'Comprehensive evaluation of dental and jaw structure'
          },
          {
            title: 'Treatment Plan',
            description: 'Creating a personalized prosthetic treatment plan'
          },
          {
            title: 'Tooth Preparation',
            description: 'Preparation of necessary teeth for prosthesis'
          },
          {
            title: 'Taking Impressions',
            description: 'Taking precise impressions with digital or traditional methods'
          },
          {
            title: 'Temporary Prosthesis',
            description: 'Application of temporary prosthesis while permanent prosthesis is being prepared'
          },
          {
            title: 'Permanent Prosthesis',
            description: 'Final fitting and application of permanent prosthesis'
          }
        ]
      },
      faq: {
        badge: 'About Prosthetic Treatment',
        title: 'Frequently Asked',
        highlight: 'Questions',
        items: [
          {
            question: 'What is the lifespan of prostheses?',
            answer: 'The lifespan of prostheses varies depending on the material, maintenance, and the person\'s oral hygiene. Fixed prostheses (crowns and bridges) can last 10-15 years with proper care, zirconium crowns 15-20 years, and removable prostheses an average of 5-7 years. These periods can be extended with regular dental check-ups and proper care.'
          },
          {
            question: 'How long does it take to get used to using a prosthesis?',
            answer: 'The adaptation process to prosthesis use varies from person to person. While fixed prostheses are usually adapted to within a few days, it may take 2-4 weeks to get used to removable prostheses. During this process, functions such as speaking and chewing may need to be reorganized. Following your dentist\'s recommendations will speed up the adaptation process.'
          },
          {
            question: 'How are prostheses maintained?',
            answer: 'Fixed prostheses should be brushed regularly and flossed like normal teeth. Removable prostheses should be removed and cleaned after each meal and soaked in special solutions overnight. Regular dental check-ups are important for all types of prostheses. Proper care extends the life of prostheses and maintains oral health.'
          },
          {
            question: 'What is the difference between zirconium and porcelain crowns?',
            answer: 'Zirconium crowns are more durable and metal-free, so there is no risk of allergy. Also, their light transmittance is closer to natural teeth. Porcelain crowns give aesthetically perfect results but are more fragile compared to zirconium. Zirconium crowns may be preferred especially for back teeth, while porcelain crowns are more often preferred for front teeth. The selection is determined by the dentist according to the patient\'s needs.'
          },
          {
            question: 'Is prosthetic treatment painful?',
            answer: 'Local anesthesia is applied when necessary during the prosthetic treatment process, so pain is not felt during the procedure. Mild sensitivity may occur after the procedure, but this usually passes within a few days. In removable prostheses, pressure may be felt in some areas during the adaptation process, in which case your dentist will make the necessary adjustments.'
          }
        ]
      }
    },
    teethWhiteningPage: {
      seo: {
        title: 'Teeth Whitening | Kartal Soğanlık Dental Clinic',
        description: 'Professional teeth whitening treatment in Kartal Soğanlık. Safe whitening and bright smiles with modern technology and expert team.',
        keywords: 'teeth whitening, kartal teeth whitening, soganlik teeth whitening, office whitening, laser teeth whitening, professional teeth whitening'
      },
      hero: {
        badge: 'Teeth Whitening',
        title: 'Teeth Whitening Solutions',
        highlight: 'Professional Whitening',
        description: 'With our modern technology and expert team, we safely whiten your teeth and provide you with a brighter smile.',
        buttons: {
          appointment: 'Make Appointment',
          details: 'More Details'
        },
        features: {
          expert: 'Expert Staff',
          expertSmall: 'Experienced Team',
          technology: 'Modern Technology',
          technologySmall: 'Safe Treatment',
          experience: '15+ Years Experience',
          experienceSmall: 'Expert Service'
        },
        imageAlt: 'Teeth Whitening',
        experience: 'Years Experience'
      },
      header: {
        title: 'Teeth Whitening',
        description: 'Bright Smiles with Professional Whitening Treatment'
      },
      responsibilities: {
        badge: 'Whitening Treatments',
        title: 'Teeth Whitening',
        highlight: 'Specialists',
        description: 'Achieve a brighter smile with professional teeth whitening treatments.',
        imageAlt: 'Teeth Whitening Specialist',
        items: [
          'In-Office Whitening',
          'Home Whitening Kits',
          'Tooth Color Analysis',
          'Sensitivity Control',
          'Post-Whitening Care',
          'Regular Check-ups'
        ]
      },
      advantages: {
        badge: 'Treatment Advantages',
        title: 'Benefits of',
        highlight: 'Professional Whitening',
        description: 'With our modern technology and expert team, we safely whiten your teeth and provide you with a brighter smile.',
        items: [
          'Optimal and Quick Results',
          'Personalized Treatments',
          'Longevity of Whitening Effects',
          'Safety and Professional Supervision',
          'Comprehensive Stain Removal',
          'Increased Self-Confidence'
        ]
      },
      suitable: {
        badge: 'Treatment Criteria',
        title: 'Who Can Benefit from',
        highlight: 'Teeth Whitening?',
        description: 'Conditions suitable for teeth whitening treatment',
        items: [
          'People with stained or discolored teeth due to coffee, tea, wine, or smoking',
          'Those who want to reverse the natural aging effects on tooth color',
          'Anyone seeking a cosmetic improvement for a special occasion or personal satisfaction',
          'Patients with good oral health who desire aesthetic dental treatments'
        ]
      },
      faq: {
        badge: 'About Teeth Whitening',
        title: 'Frequently Asked',
        highlight: 'Questions',
        items: [
          {
            question: 'Which of the different whitening methods should I choose?',
            answer: 'The answer to this question varies depending on the extent of yellowing or staining on your teeth. Your expert dentist will decide which treatment will be most effective for you after an examination.'
          },
          {
            question: 'How many therapy sessions are needed for successful teeth whitening?',
            answer: 'The number of sessions may increase or decrease depending on the size of the discoloration problem on your teeth. While some yellowing or staining cases can be treated in a single session, multiple sessions may be needed in some cases.'
          },
          {
            question: 'Does whitening cause sensitivity in teeth?',
            answer: 'No. However, if there is already a complaint of tooth sensitivity before treatment, this condition may continue after application. If tooth sensitivity is detected in the examination performed by the expert before the whitening procedure, the sensitivity should be eliminated before proceeding to the application stage.'
          },
          {
            question: 'How should dental care be after whitening?',
            answer: 'It is beneficial to protect the teeth a little more than normal for a while immediately after the teeth whitening application. It would be right to stay away from habits that may cause yellowing or staining, such as smoking and coffee, for one to two weeks. Afterwards, routine dental care should be continued and oral hygiene should never be neglected for teeth that have undergone whitening treatment, as for all teeth. In this way, the effect of the whitening application lasts for a long time.'
          },
          {
            question: 'Do whitened teeth lose their color over time?',
            answer: 'No dentist can claim that teeth whitening treatment is lifelong. There are different factors that determine how long the whitening process will maintain its effect on teeth. The existing structure of the tooth, how prone it is to yellowing and staining, eating habits, and habits related to oral and dental care are decisive in this regard. With good care and healthy eating habits, teeth can maintain their whiteness for a long time. In case of re-discoloration for any reason, additional whitening treatment can be applied under the supervision of a specialist physician.'
          }
        ]
      }
    }
  },
  cta: {
    badge: 'Expert Team',
    title: 'Smile with Health',
    subtitle: 'Every Day!',
    description: 'Start your day with a smile: Make an appointment to meet our expert team in oral, dental, and maxillofacial surgery.',
    button: 'Make an Appointment'
  },
  contact: {
    seo: {
      title: "Klinik Akademi | Contact",
      description: "Welcome to Klinik Akademi. You can contact us for appointments, information, or any questions. Our expert team is ready to help you.",
      keywords: "contact, dental clinic, appointment, address, phone, location"
    },
    hero: {
      badge: "Contact Us",
      title: "Klinik Akademi",
      highlight: "Contact Center",
      text: "Welcome to Klinik Akademi. You can contact us for appointments, information, or any questions. Our expert team is ready to help you.",
      imageAlt: "Klinik Akademi Contact"
    },
    info: {
      phone: {
        title: "Phone",
        content: "+90 553 973 64 80"
      },
      email: {
        title: "Email",
        content: "info@klinikakademi.com"
      },
      openHours: {
        badge: "Open 7 Days a Week",
        content: "Every day 09:00 - 00:00"
      }
    },
    map: {
      badge: "Location Information",
      titlePrefix: "How to",
      titleHighlight: "Reach Us?",
      description: "You can use the map or follow the directions below to reach our clinic",
      address: {
        title: "Address",
        value: "Orta, Yavuz Selim Cd. No:15-A Kartal/İstanbul"
      },
      phone: {
        title: "Phone",
        value: "+90 553 973 64 80"
      },
      email: {
        title: "Email",
        value: "info@klinikakademi.com"
      },
      hours: {
        title: "Open",
        value: "Every day 09:00 - 00:00"
      },
      markerTitle: "Klinik Akademi"
    },
    directions: {
      title: "Transportation Information",
      car: {
        title: "By Private Vehicle",
        description: "You can reach us from the E-5 highway towards Kartal or using the Kartal-Samandira connection road from TEM.",
        time1: "5 minutes from E-5",
        time2: "10 minutes from TEM",
        time3: "8 minutes from the coastal road"
      },
      public: {
        title: "By Public Transportation",
        description: "You can easily access using metro, bus, and minibus lines.",
        option1: "Kartal Metro Station - 5 min walk",
        option2: "KM20, KM21, KM22 minibus lines",
        option3: "14K, 16KH, 17K bus lines"
      },
      parking: {
        title: "Parking",
        description: "Free parking areas are available around our clinic."
      },
      getDirections: "Get Directions"
    },
    form: {
      badge: "Contact Information",
      titlePrefix: "Get in",
      titleHighlight: "Touch With Us",
      description: "Contact us for your questions, suggestions, or appointment requests",
      card: {
        title: "Send Message",
        subtitle: "You can send us a message by filling out the form below."
      },
      fields: {
        name: {
          label: "Your Name and Surname",
          placeholder: "Your Name and Surname"
        },
        phone: {
          label: "Your Phone Number",
          placeholder: "Your Phone Number"
        },
        email: {
          label: "Your Email Address",
          placeholder: "Your Email Address"
        },
        subject: {
          label: "Subject",
          placeholder: "Select Subject",
          options: {
            appointment: "Appointment Request",
            info: "Information Request",
            price: "Price Information",
            other: "Other"
          }
        },
        message: {
          label: "Your Message",
          placeholder: "Your Message"
        },
        consent: {
          label: "I consent to the processing of my personal data."
        }
      },
      buttons: {
        send: "Send Message",
        sending: "Sending..."
      },
      messages: {
        success: "Your message has been sent successfully.",
        error: "An error occurred while sending your message."
      }
    },
    details: {
      title: "Our Contact Information",
      subtitle: "You can reach us through the following channels",
      address: {
        title: "Address",
        value: "Orta, Yavuz Selim Cd. No:15-A Kartal/İstanbul"
      },
      phone: {
        title: "Phone",
        value: "+90 553 973 64 80"
      },
      email: {
        title: "Email",
        value: "info@klinikakademi.com"
      },
      hours: {
        title: "Working Hours",
        value: "Every day: 09:00 - 00:00"
      },
      social: {
        title: "Social Media"
      },
      formTitle: "Get in Touch With Us",
      formSubtitle: "Send Message",
      formDescription: "Contact us for your questions, suggestions, or appointment requests"
    }
  },
  appointment: {
    title: 'Online Appointment',
    description: 'Let us create your appointment at the most convenient time for you',
    form: {
      name: 'Full Name',
      namePlaceholder: 'Your Name and Surname',
      phone: 'Phone',
      phonePlaceholder: 'Your Phone Number',
      email: 'Email',
      emailPlaceholder: 'Your Email Address',
      treatment: 'Treatment',
      treatmentPlaceholder: 'Select Treatment',
      treatments: {
        implant: 'Implant',
        rootcanal: 'Root Canal Treatment',
        orthodontics: 'Orthodontics',
        invisalign: 'Invisalign',
        periodontics: 'Periodontics',
        surgery: 'Oral and Maxillofacial Surgery',
        aesthetics: 'Aesthetic Dentistry',
        whitening: 'Teeth Whitening',
        prosthesis: 'Prosthetic Treatments',
        pediatric: 'Pediatric Dentistry',
        general: 'General Dentistry',
        emergency: 'Emergency Dental Treatment'
      },
      message: 'Your Message',
      messagePlaceholder: 'Additional notes you want to add...',
      sending: 'Sending...',
      submit: 'Create Appointment Request',
      privacy: 'Your personal data is protected under our privacy policy',
      success: 'Your appointment request has been successfully created. We will contact you as soon as possible.',
      error: 'An error occurred while creating your appointment request. Please try again later.'
    }
  },
  footer: {
    clinicName: 'Klinik Akademi',
    description: 'We are with you for healthy smiles with our modern dental technologies and expert staff.',
    quickLinks: 'Quick Links',
    team: 'Our Team',
    services: 'Our Services',
    doctors: 'Our Doctors',
    treatmentsTitle: 'Our Treatments',
    treatments: {
      implant: 'Dental Implants',
      whitening: 'Teeth Whitening',
      orthodontics: 'Orthodontics',
      periodontal: 'Periodontal Treatment',
      surgery: 'Oral and Maxillofacial Surgery'
    },
    contactInfo: 'Contact Information',
    address: 'Yavuz Selim St. No:15-A Kartal/Istanbul',
    copyright: 'Copyright © {year} Klinik Akademi. All rights reserved.',
    designedBy: 'Designed by ResRam Digital Transformation',
    followUs: 'Follow Us',
    legal: 'Legal',
    privacy: 'Privacy Policy',
    cookies: 'Cookie Policy'
  },
  team: {
    hero: {
      title: "Professional",
      highlight: "Meet Our Team",
      text1: "Our specialized team, led by experienced dentists and supported by dedicated professionals, is committed to providing personalized and top-tier dental care. We prioritize your comfort and health, combining advanced technology with a gentle touch to create a positive experience.",
      text2: "Each member of our team consists of professionals who are experts in their field, continuously improving themselves and maintaining the highest level of patient satisfaction. We use all the possibilities of modern dentistry, follow the latest technological developments, and continuously update our treatment protocols.",
      services: {
        expert: "Expert Staff",
        patient: "Patient-Centered",
        quality: "Quality Service"
      },
      experience: "15+ Years Experience"
    },
    section: {
      badge: "Professional Team",
      title: "Meet Our",
      highlight: "Expert Staff",
      teamAlt: "Klinik Akademi Team"
    },
    join: {
      title1: "Need a job?",
      title2: "Join Our Team!",
      description: "Are you a passionate and dedicated individual looking to make a difference in the world of dentistry? Look no further! We're seeking talented individuals to join our growing team and help us provide exceptional care to our valued patients.",
      button: "Apply for a job",
      imageAlt: "Career Opportunities"
    },
    members: {
      owner: "Dentist and Owner",
      gm: "General Manager",
      bm: "Business Manager",
      dentist: "Dentist",
      chief: "Chief Assistant"
    }
  },
  media: {
    hero: {
      badge: "Media Gallery",
      title: "Klinik Akademi",
      highlight: "Media Center",
      text: "Welcome to our media gallery featuring Klinik Akademi's modern facilities, successful treatment results, and events. Explore our visual content that reflects our clinic's quality service understanding and professional approach.",
      photos: "Photos",
      videos: "Videos",
      press: "Press",
      facilities: "Our Modern Facilities"
    },
    categories: {
      title: "Media Categories",
      heading: "Latest Updates",
      highlight: "from Klinik Akademi",
      description: "Our clinic news, events, and updates",
      all: "All",
      photos: "Photos",
      videos: "Videos",
      news: "Press",
      social: "Social Media"
    },
    actions: {
      loadMore: "Load More",
      loading: "Loading...",
      close: "Close"
    },
    items: {
      clinic: "Our Modern Clinic",
      clinicDesc: "Modern treatment units in our new clinic in Soganlik",
      treatment: "Treatment Rooms",
      treatmentDesc: "Sterile treatment environment equipped with the latest technology",
      waiting: "Waiting Lounge",
      waitingDesc: "Comfortable and spacious waiting area",
      xray: "Digital X-ray",
      xrayDesc: "Advanced technology digital imaging systems",
      sterilization: "Our Sterilization Unit",
      sterilizationDesc: "High standards of sterilization and hygiene"
    }
  },
  emergency: {
    title: 'Emergency Dentist',
    status: 'Active - Service Available',
    call: 'Call Now',
    whatsapp: 'WhatsApp',
    instantResponse: 'Instant Response',
    services: {
      service1: {
        title: '7 Days Service',
        description: 'Open 7 Days a Week'
      },
      service2: {
        title: 'Quick Response',
        description: 'Within 30 Minutes'
      },
      service3: {
        title: 'Expert Team',
        description: 'Experienced Staff'
      },
      service4: {
        title: 'Emergency Care',
        description: 'Priority Service'
      }
    },
    form: {
      toggle: 'Report Emergency',
      close: 'Close Form',
      name: 'Full Name',
      namePlaceholder: 'Your Name and Surname',
      phone: 'Phone',
      phonePlaceholder: 'Your Phone Number',
      email: 'Email',
      emailPlaceholder: 'Your Email Address',
      details: 'Emergency Details',
      detailsPlaceholder: 'Briefly describe your emergency...',
      sending: 'Sending...',
      submit: 'Report Emergency',
      success: 'Your emergency report has been successfully sent. We will contact you as soon as possible.',
      error: 'An error occurred while sending your emergency report. Please call us directly.'
    }
  },
  // 404 Page
  notFound: {
    title: 'Page Not Found',
    subtitle: '404 Error',
    message: 'The page you are looking for cannot be found or may have been moved.',
    button: 'Back to Home',
    helpText: 'Need help?',
    contactUs: 'Contact Us',
    suggestedPages: 'Suggested Pages:',
    home: 'Home',
    treatments: 'Treatments',
    contact: 'Contact'
  },
  cookieConsent: {
    title: 'Cookie Notice',
    text: 'Our website uses cookies to enhance your experience. By continuing to use our site, you accept our cookie policy.',
    detailButton: 'More Information',
    acceptButton: 'Accept'
  },
  legal
} 