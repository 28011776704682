import legal from './legal'

export default {
  nav: {
    home: 'Startseite',
    about: 'Über Uns',
    aboutLink: 'Über Uns',
    treatments: 'Behandlungen',
    treatmentsLink: 'Behandlungen',
    media: 'Medien',
    contact: 'Kontakt',
    appointment: 'Termin Vereinbaren',
    appointmentBtn: 'Termin Vereinbaren',
    aboutMenu: {
      corporate: {
        title: 'Unternehmen',
        aboutUs: {
          title: 'Über Uns',
          subtitle: 'Unsere Mission und Vision'
        },
        team: {
          title: 'Unser Team',
          subtitle: 'Professionelle Mitarbeiter'
        }
      },
      clinics: {
        title: 'Unsere Kliniken',
        kartal: {
          title: 'Kartal Filiale',
          subtitle: 'Hauptzentrum'
        },
        dikili: {
          title: 'Dikili Filiale',
          subtitle: 'Izmir'
        }
      }
    },
    treatmentsMenu: {
      basic: {
        title: 'Grundbehandlungen',
        implant: {
          title: 'Implantologie',
          subtitle: 'Präzise Zahnimplantate'
        },
        surgery: {
          title: 'Mund-, Kiefer- und Gesichtschirurgie',
          subtitle: 'Fachkundige chirurgische Eingriffe'
        },
        rootCanal: {
          title: 'Wurzelkanalbehandlung',
          subtitle: 'Endodontische Behandlungen'
        },
        prosthesis: {
          title: 'Prothetik',
          subtitle: 'Fortschrittliche prothetische Lösungen'
        }
      },
      aesthetic: {
        title: 'Ästhetische und spezielle Behandlungen',
        whitening: {
          title: 'Zahnaufhellung',
          subtitle: 'Professionelles Bleaching'
        },
        orthodontics: {
          title: 'Kieferorthopädie',
          subtitle: 'Lächeln-Design und Korrektur'
        },
        periodontics: {
          title: 'Parodontologie',
          subtitle: 'Zahnfleischbehandlungen'
        },
        pediatrics: {
          title: 'Kinderzahnheilkunde',
          subtitle: 'Zahnmedizin für Kinder'
        },
        invisalign: {
          title: 'Durchsichtige Zahnschienen',
          subtitle: 'Unsichtbare Kieferorthopädie'
        }
      },
      appointment: {
        title: 'Online-Termin',
        description: 'Vereinbaren Sie jetzt einen Termin und treffen Sie unser Expertenteam.',
        button: 'Termin Vereinbaren'
      }
    }
  },
  navbar: {
    home: 'Startseite',
    treatments: 'Behandlungen',
    about: 'Über Uns',
    media: 'Medien',
    contact: 'Kontakt'
  },
  header: {
    clinicName: 'Klinik Akademi',
    openNow: 'Jetzt Geöffnet',
    workingHours: 'Montag - Sonntag 09:00 - 00:00',
    workingDays: 'Montag - Sonntag',
    workingTime: '09:00 - 00:00',
    address: 'Orta, Yavuz Selim Cd. No:15-A Kartal/Istanbul'
  },
  hero: {
    title: 'Zahnklinik Kartal Soğanlık',
    subtitle: 'wir stehen Ihnen 7 Tage die Woche zur Verfügung.',
    description: 'Mit unserer modernen Technologie und unserem Expertenteam gestalten wir Ihr Lächeln neu. Lassen Sie uns Ihren Behandlungsplan mit kostenloser Untersuchung und digitalem Röntgen erstellen.',
    ctaButton: 'Termin Vereinbaren',
    secondaryButton: 'Unsere Dienstleistungen',
    feature1: 'Kostenlose Untersuchung',
    feature2: 'Digitales Röntgen',
    feature3: '3D-Planung',
    cardTitle: 'Online-Termin',
    cardText: 'Wir stehen Ihnen 7 Tage die Woche zur Verfügung',
    badgeTitle: '10.000+',
    badgeText: 'Gesunde Lächeln',
    badge1: '15+ Jahre Erfahrung',
    badge2: 'Hochmoderne Technologie'
  },
  whyUs: {
    badge: 'Warum Wir?',
    title: 'Der Grund für unsere Existenz:',
    subtitle: 'Ein natürliches Lächeln',
    description: 'Wir stehen Ihnen mit unserer modernen technologischen Ausrüstung, zeitgemäßen Diagnose- und Behandlungsmethoden und unserem lösungsorientierten Team zur Verfügung.',
    feature1: 'Moderne Technologie',
    feature2: 'Fachpersonal',
    feature3: 'Sterile Umgebung',
    button: 'Mehr Informationen',
    floatingBadge: '15+ Jahre Erfahrung'
  },
  tourism: {
    badge: 'Moderne Klinik',
    title: 'Zuverlässige und Komfortable',
    subtitle: 'Zahnbehandlung',
    description: 'Mit unserer modernen Technologie und unserem Fachpersonal bieten wir Ihnen die angenehmste Behandlungserfahrung. Wir sind mit unserem patientenorientierten Ansatz und unserer sterilen Umgebung für Ihre zuverlässige Zahnbehandlung da.',
    ctaButton: 'Termin Vereinbaren',
    floatingBadge: 'Hochmoderne Technologie',
    stat1: 'Jahre<br>Erfahrung',
    stat2: 'Patienten<br>Zufriedenheit',
    features: {
      feature1: 'Moderne Technologie',
      description1: 'Neueste Technologiegeräte und Materialien',
      feature2: 'Fachpersonal',
      description2: 'Erfahrene und spezialisierte Zahnärzte',
      feature3: 'Digitale Planung',
      description3: 'Detaillierter Behandlungsplan mit 3D-Bildgebung',
      feature4: 'Sterile Umgebung',
      description4: 'Vollständige Sterilisation und Hygienestandards'
    }
  },
  checkup: {
    badge: 'Kostenlose Untersuchung',
    title: 'Überprüfen Sie Ihre Mund- und',
    subtitle: 'Zahngesundheit',
    description: 'Machen Sie den ersten Schritt für Ihre Zahngesundheit. Lassen Sie unsere erfahrenen Zahnärzte Ihre Mund- und Zahngesundheit mit einer kostenlosen Untersuchung überprüfen und einen personalisierten Behandlungsplan für Sie erstellen.',
    ctaButton: 'Termin für kostenlose Untersuchung',
    doctorName: 'Dr. Harun Atay',
    features: {
      feature1: 'Detaillierte Untersuchung',
      description1: 'Umfassende Mund- und Zahnkontrolle',
      feature2: 'Digitales Röntgen',
      description2: 'Panorama- und Periapikalröntgen',
      feature3: 'Behandlungsplan',
      description3: 'Personalisierte Behandlungsplanung',
      feature4: 'Kostenvoranschlag',
      description4: 'Transparente und detaillierte Kosteninformation'
    }
  },
  services: {
    badge: 'Unsere Behandlungen',
    title: 'In der modernen Zahnmedizin',
    subtitle: 'Fachgerechte Behandlungen',
    viewAllButton: 'Alle unsere Behandlungen',
    items: {
      1: {
        title: 'Zahnimplantate',
        description: 'Dauerhafte Zahnbehandlung mit lebenslanger Garantie'
      },
      2: {
        title: 'Zirkoniumkronen',
        description: 'Natürlich aussehende ästhetische Beschichtungen'
      },
      3: {
        title: 'Kieferorthopädie',
        description: 'Zahnausrichtung mit modernen Techniken'
      },
      4: {
        title: 'Ästhetische Zahnheilkunde',
        description: 'Lächeln-Design und ästhetische Anwendungen'
      },
      5: {
        title: 'Zahnaufhellung',
        description: 'Professionelle Aufhellungssysteme'
      },
      6: {
        title: 'Kinderzahnheilkunde',
        description: 'Spezialisierte zahnärztliche Versorgung für Kinder'
      },
      implant: {
        title: 'Zahnimplantate',
        description: 'Dauerhafte Zahnbehandlung mit lebenslanger Garantie'
      },
      zirconium: {
        title: 'Zirkoniumkronen',
        description: 'Natürlich aussehende ästhetische Beschichtungen'
      },
      orthodontics: {
        title: 'Kieferorthopädie',
        description: 'Zahnausrichtung mit modernen Techniken'
      },
      aesthetics: {
        title: 'Ästhetische Zahnheilkunde',
        description: 'Lächeln-Design und ästhetische Anwendungen'
      },
      whitening: {
        title: 'Zahnaufhellung',
        description: 'Professionelle Aufhellungssysteme'
      },
      pediatric: {
        title: 'Kinderzahnheilkunde',
        description: 'Spezialisierte zahnärztliche Versorgung für Kinder'
      }
    }
  },
  about: {
    title: 'Über uns',
    subtitle: 'Klinik Akademi',
    description: 'Mit über 20 Jahren Erfahrung bieten wir unseren Patienten in Kartal hochwertige Zahnpflege. Unser erfahrenes Team bietet Lösungen für alle Ihre Zahnprobleme mit moderner Ausrüstung und einem patientenorientierten Ansatz.',
    expertise: 'Fachkenntnisse',
    experience: 'Erfahrung',
    technology: 'Technologie',
    comfort: 'Komfort',
    
    hero: {
      title: "Professionelle Lösungen",
      highlight: "für gesunde Lächeln",
      text1: "Willkommen bei Kartal Soğanlık Klinik Akademi, Ihrer Adresse für erstklassige Zahnpflege. Unsere moderne Klinik ist mit modernster Ausstattung und einem Team erfahrener Fachleute ausgestattet, die sich Ihrer Mundgesundheit und strahlenden Lächeln widmen.",
      text2: "Bei Klinik Akademi verbinden wir modernste Technologie mit persönlicher Betreuung, um ein breites Spektrum an Dienstleistungen von Routineuntersuchungen bis hin zu fortschrittlichen Behandlungen anzubieten. Unsere einladende Umgebung ist auf Ihren Komfort ausgerichtet, und unser freundliches Team ist bestrebt, Ihre Zahnarztbesuche angenehm zu gestalten.",
      text3: "Durch die Priorisierung von Präventivpflege und Patientenaufklärung bemühen wir uns, Ihnen zu helfen, eine optimale Mundgesundheit zu erhalten. Ob Sie für einen Routinebesuch oder für kosmetische Verbesserungen hier sind, wir sind hier, um Sie auf Ihrem Weg zu einem gesünderen, schöneren Lächeln zu begleiten.",
      stats: {
        experience: "Jahre Erfahrung",
        smiles: "Gesunde Lächeln"
      },
      badge: "Fortschrittliche Technologie"
    },
    mission: {
      badge: "Unsere Mission",
      title: "Unsere Werte und Bedeutung",
      text: "Bei Klinik Akademi ist es unsere Mission, erstklassige Zahnpflege zu bieten, wobei wir die präventive Gesundheitsversorgung betonen und sicherstellen, dass Patienten unsere Klinik in der bestmöglichen gesundheitlichen Verfassung verlassen. Durch modernste Technologie und ein engagiertes Team streben wir danach, für jeden Patienten ein positives und angenehmes Erlebnis zu schaffen.",
      services: {
        digital: "Digitale Zahnmedizin",
        aesthetic: "Ästhetische Zahnmedizin",
        surgery: "Mundchirurgie",
        whitening: "Zahnaufhellung",
        pediatric: "Kinderzahnheilkunde",
        extraction: "Zahnextraktion"
      },
      badge_doctor: "Facharzt"
    },
    vision: {
      badge: "Unsere Vision",
      title: "Ein perfektes Lächeln",
      text: "Bei Klinik Akademi stellen wir uns eine Gemeinschaft vor, in der gesunde, selbstbewusste Lächeln zum allgemeinen Wohlbefinden beitragen. Mit perfektionistischen, innovativen und mitfühlenden Lösungen streben wir danach, vertrauenswürdige Führungskräfte im Bereich der Zahnmedizin zu sein.",
      services: {
        patient: "Patientenorientiert",
        technology: "Moderne Technologie",
        expert: "Fachpersonal"
      },
      badge_doctor: "Zahnarzt"
    },
    treatments: {
      badge: "Unsere Behandlungen",
      title: "Expertenbehandlungen",
      highlight: "in moderner Zahnmedizin",
      services: {
        implant: {
          title: "Implantatbehandlung",
          description: "Dauerhafte Zahnbehandlung mit lebenslanger Garantie"
        },
        zirconium: {
          title: "Zirkonkronen",
          description: "Natürlich aussehende ästhetische Kronen"
        },
        orthodontics: {
          title: "Kieferorthopädie",
          description: "Zahnausrichtung mit modernen Techniken"
        },
        aesthetic: {
          title: "Ästhetische Zahnmedizin",
          description: "Lächelndesdesign und ästhetische Anwendungen"
        },
        whitening: {
          title: "Zahnaufhellung",
          description: "Professionelle Aufhellungssysteme"
        },
        pediatric: {
          title: "Kinderzahnheilkunde",
          description: "Pädodontische Facharztbehandlungen"
        }
      },
      all_treatments: "Alle unsere Behandlungen"
    }
  },
  treatments: {
    title: 'Behandlungen',
    subtitle: 'Professionelle Dienstleistungen',
    basic: {
      title: 'Grundbehandlungen',
      implant: {
        title: 'Implantologie',
        subtitle: 'Präzise Zahnimplantate'
      },
      surgery: {
        title: 'Mund-, Kiefer- und Gesichtschirurgie',
        subtitle: 'Fachkundige chirurgische Eingriffe'
      },
      rootCanal: {
        title: 'Wurzelbehandlung',
        subtitle: 'Endodontische Behandlungen'
      },
      prosthesis: {
        title: 'Prothetische Behandlung',
        subtitle: 'Fortschrittliche Prothesenlösungen'
      }
    },
    aesthetic: {
      title: 'Ästhetische und spezielle Behandlungen',
      whitening: {
        title: 'Zahnaufhellung',
        subtitle: 'Professionelle Zahnaufhellung'
      },
      orthodontics: {
        title: 'Kieferorthopädie',
        subtitle: 'Lächeln Design und Korrektur'
      },
      periodontics: {
        title: 'Parodontologie',
        subtitle: 'Zahnfleischbehandlungen'
      },
      pediatrics: {
        title: 'Kinderzahnheilkunde',
        subtitle: 'Zahnpflege für Kinder'
      },
      invisalign: {
        title: 'Invisalign Klarsichtschienen',
        subtitle: 'Unsichtbare Kieferorthopädie'
      }
    },
    appointment: {
      title: 'Online-Termin',
      description: 'Vereinbaren Sie jetzt einen Termin und treffen Sie unser Expertenteam.',
      button: 'Termin Vereinbaren'
    },
    implant: {
      title: 'Implantologie',
      description: 'Dauerhafte Lösung für fehlende Zähne mit hoher Erfolgsrate.'
    },
    orthodontics: {
      title: 'Kieferorthopädie',
      description: 'Wirksame Behandlungen für Zahnstellungsprobleme.'
    },
    aesthetics: {
      title: 'Ästhetische Zahnheilkunde',
      description: 'Lösungen für ein schöneres und selbstbewussteres Lächeln.'
    },
    whitening: {
      title: 'Zahnaufhellung',
      description: 'Effektive Aufhellung für ein strahlenderes Lächeln.'
    },
    surgery: {
      title: 'Mund-, Kiefer- und Gesichtschirurgie',
      description: 'Expertenlösungen für chirurgische Eingriffe.'
    },
    pediatric: {
      title: 'Kinderzahnheilkunde',
      description: 'Kinderfreundliche zahnärztliche Versorgung.'
    },
    digital: {
      title: 'Digitale Zahnheilkunde',
      description: 'Fortschrittliche Behandlung mit moderner Technologie.'
    },
    general: {
      title: 'Allgemeine Zahnheilkunde',
      description: 'Grundlegende Zahnpflege und vorbeugende Behandlungen.'
    },
    rootCanal: {
      title: 'Wurzelbehandlung',
      description: 'Behandlung und Erhaltung von Zahnnerven.'
    },
    periodontics: {
      title: 'Parodontologie',
      description: 'Spezielle Behandlungen für gesundes Zahnfleisch.'
    },
    prosthesis: {
      title: 'Prothetische Behandlung',
      description: 'Langlebige und ästhetische Prothesenlösungen.'
    },
    invisalign: {
      title: 'Invisalign Klarsichtschienen',
      description: 'Gerade Zähne mit unsichtbarer Kieferorthopädie.'
    },
    viewAll: 'Alle Behandlungen anzeigen',
    
    seo: {
      title: "Alle unsere Behandlungen | Klinik Akademi",
      description: "Erhalten Sie detaillierte Informationen über alle zahnmedizinischen Behandlungen und Dienstleistungen, die bei Klinik Akademi angeboten werden."
    },
    hero: {
      title: "Zahnklinik Kartal Soğanlık",
      subtitle: "Alle Behandlungen",
      text1: "Mit unserer neuesten Technologie und unserem Expertenteam bieten wir umfassende Behandlungsmöglichkeiten für Ihre Mund- und Zahngesundheit. Jeder Behandlungsplan wird speziell auf die Bedürfnisse unserer Patienten zugeschnitten und nach höchsten Standards durchgeführt.",
      text2: "Unter Nutzung aller Möglichkeiten der modernen Zahnmedizin verfolgen wir die neuesten technologischen Entwicklungen und aktualisieren ständig unsere Behandlungsprotokolle. Wir priorisieren Ihren Komfort und Ihre Gesundheit, indem wir fortschrittliche Technologie mit einer sanften Berührung kombinieren, um ein positives Erlebnis zu schaffen.",
      features: {
        expert: "Fachgerechte Behandlung",
        patient: "Patientenorientiert",
        quality: "Qualitätsservice"
      },
      imageAlt: "Klinik Akademi Dentalbehandlungen und Dienstleistungen",
      experience: "15+ Jahre Erfahrung"
    },
    grid: {
      badge: "Unsere Behandlungen",
      title: "Bei Klinik Akademi",
      subtitle: "Expertenbehandlungen"
    },
    items: {
      implant: {
        title: "Zahnimplantat",
        description: "Dauerhafte und natürliche Lösung für Ihre fehlenden Zähne",
        feature1: "Lebenslange Garantie",
        feature2: "Moderne Implantatsysteme",
        feature3: "Schneller Erholungsprozess"
      },
      orthodontics: {
        title: "Kieferorthopädie",
        description: "Gerade und ästhetische Zähne mit modernen Techniken",
        feature1: "Behandlung mit klaren Alignern",
        feature2: "Invisalign",
        feature3: "Metall- und Keramikbrackets"
      },
      whitening: {
        title: "Zahnaufhellung",
        description: "Strahlendes Lächeln mit professionellen Aufhellungssystemen",
        feature1: "Laseraufhellung",
        feature2: "Praxisaufhellung",
        feature3: "Heimaufhellung"
      },
      rootCanal: {
        title: "Wurzelkanalbehandlung",
        description: "Fachkundige Behandlung zum Schutz Ihrer natürlichen Zähne",
        feature1: "Behandlung unter dem Mikroskop",
        feature2: "Schmerzfreie Prozedur",
        feature3: "Erfolgreiche Ergebnisse"
      },
      prosthesis: {
        title: "Prothetik",
        description: "Ästhetische und funktionelle Lösungen für Ihre fehlenden Zähne",
        feature1: "Zirkonprothetik",
        feature2: "Herausnehmbare Prothetik",
        feature3: "Implantatgetragene Prothetik"
      },
      periodontics: {
        title: "Parodontologie",
        description: "Zahnfleischerkrankungen und ästhetische Zahnfleischbehandlungen",
        feature1: "Zahnfleischästhetik",
        feature2: "Behandlung von Zahnfleischerkrankungen",
        feature3: "Knochentransplantation"
      },
      pediatric: {
        title: "Kinderzahnheilkunde",
        description: "Spezielle und sichere Behandlung für Ihre Kinder",
        feature1: "Kinderfreundliche Umgebung",
        feature2: "Fachpersonal",
        feature3: "Frühzeitige Behandlung"
      },
      surgery: {
        title: "Mund-, Kiefer- und Gesichtschirurgie",
        description: "Komplexe Zahnextraktionen und chirurgische Eingriffe",
        feature1: "20 Jahre Erfahrung",
        feature2: "Moderne chirurgische Techniken",
        feature3: "Komfortable Genesung"
      },
      invisalign: {
        title: "Invisalign",
        description: "Gerade Zähne mit unsichtbarer Kieferorthopädie",
        feature1: "Transparente Aligner",
        feature2: "Komfortable Anwendung",
        feature3: "Ästhetisches Erscheinungsbild"
      }
    },
    implantPage: {
      seo: {
        title: "Zahnimplantate in Kartal Soğanlık | Klinik Akademi",
        description: "Zahnimplantatbehandlung in Kartal Soğanlık mit unserem Expertenteam. Dauerhafte Zahnbehandlung mit modernen Implantatsystemen und lebenslanger Garantie.",
        keywords: "kartal zahnimplantat, soğanlık implantat, kartal implantatbehandlung, soğanlık zahnimplantat, kartal implantatpreise"
      },
      hero: {
        badge: "Zahnimplantologie",
        title: "Zahnimplantate in Kartal Soğanlık",
        highlight: "Zahnimplantologie",
        description: "Zahnimplantate bieten eine lebensverändernde Lösung, die die Funktion und das Gefühl natürlicher Zähne nachahmt. Erfahren Sie mehr über diese transformative Option!",
        buttons: {
          appointment: "Termin Vereinbaren",
          details: "Weitere Informationen"
        },
        features: {
          expert: "Expertenteam",
          expertSmall: "Erfahrenes Team",
          technology: "Moderne Technologie",
          technologySmall: "Sichere Behandlung",
          experience: "15+ Jahre Erfahrung",
          experienceSmall: "Expertendienst"
        },
        imageAlt: "Zahnimplantologie"
      },
      header: {
        title: "Zahnimplantologie",
        description: "Zuverlässige Behandlung mit moderner Implantattechnologie"
      },
      responsibilities: {
        badge: "Chirurgische Behandlungen",
        title: "Verantwortlichkeiten",
        highlight: "unseres Expertenteams",
        description: "Stellen Sie sich Zahnimplantate als künstliche Zahnwurzeln vor. Diese aus biokompatiblen Materialien wie Titan hergestellten Materialien werden chirurgisch in Ihren Kieferknochen eingesetzt, damit sie auf natürliche Weise verwachsen können. Dies schafft eine starke Basis für die Anbringung von Kronen, Brücken oder Prothesen und bietet außergewöhnliche Stabilität und Unterstützung.",
        items: [
          "Zahnimplantate, die ein natürliches Aussehen und eine natürliche Funktion für fehlende Zähne bieten",
          "Korrektur von Unregelmäßigkeiten, die das Gesichtsgleichgewicht, Beißen und Atmen beeinflussen",
          "Wiederherstellende Operationen für Gesichtstraumata nach Unfällen oder Verletzungen",
          "Diagnose und Behandlung von Mundkrankheiten, einschließlich Mundkrebs",
          "Chirurgische Korrektur von Lippen- und Gaumenspalten zur Verbesserung von Funktion und Aussehen",
          "Interventionen für obstruktive Schlafapnoe",
          "Behandlung von Kiefergelenksstörungen",
          "Rekonstruktive Operationen"
        ]
      },
      advantages: {
        badge: "Behandlungsvorteile",
        title: "Warum chirurgische",
        highlight: "Behandlung?",
        description: "Vorteile chirurgischer Behandlungen mit unserer modernen Technologie und unserem Expertenteam",
        items: [
          "Kreative Operationen für komplexe Bedingungen durch Fachärzte",
          "Ganzheitlicher Ansatz zur Behandlung und funktionelle Ergebnisse",
          "Verbesserungen der Mundfunktion und -gesundheit",
          "Verbesserungen der Gesichtsästhetik",
          "Gesteigertes Selbstvertrauen und Lebensqualität"
        ]
      },
      suitable: {
        badge: "Behandlungskriterien",
        title: "Für wen ist es",
        highlight: "geeignet?",
        description: "Wer kann von der Zahnimplantatbehandlung profitieren?",
        items: [
          "Erwachsene jeden Alters (nach Abschluss der Gesichtsentwicklung)",
          "Personen mit guter allgemeiner und Mundgesundheit",
          "Personen mit einem oder mehreren fehlenden Zähnen",
          "Personen, die mit Prothesen oder Brücken unzufrieden sind",
          "Personen auf der Suche nach besserer Kau- und Sprechfunktion",
          "Personen mit Bedenken hinsichtlich Knochenverlust"
        ]
      },
      faq: {
        badge: "Über die Implantatbehandlung",
        title: "Häufig gestellte",
        highlight: "Fragen",
        description: "Hier finden Sie Antworten auf häufig gestellte Fragen zur Implantatbehandlung",
        items: [
          {
            question: "Hat die Implantatbehandlung eine bestimmte Dauer?",
            answer: "Wenn die Knochen nicht stark genug sind, um die Implantate zu tragen, wird vor der Anwendung eine Knochenaufbaubehandlung durch den Facharzt durchgeführt. Die eigentliche Behandlung beginnt jedoch erst, nachdem diese Behandlung abgeschlossen ist und sichergestellt wurde, dass der Patient über geeignete Knochen für Implantate verfügt. Es dauert 2-4 Monate, bis die Implantatbehandlung abgeschlossen ist."
          },
          {
            question: "Kann die Implantatbehandlung fehlschlagen?",
            answer: "Wenn festgestellt wird, dass die in den Knochen eingesetzten Implantate nach einer Wartezeit von 2 bis 4 Monaten für die Knocheneinheilung noch nicht eingeheilt sind, bedeutet dies, dass die betreffende Anwendung fehlgeschlagen ist. Die Hauptursache für dieses Problem, das auch als Implantatversagen bekannt ist, ist, dass die Knochen nicht stark genug sind, um das Implantat zu tragen. Dies sollte vor der Anwendung von einem Facharzt untersucht werden, und bei Bedarf sollten zusätzliche Behandlungen durchgeführt werden."
          },
          {
            question: "Wie lange halten Implantate?",
            answer: "Implantatszähne sind wie Originalzähne langlebig, wenn sie regelmäßig gepflegt werden und auf die Mundhygiene geachtet wird. Nach dem Einsetzen des Implantats ist es möglich, es viele Jahre lang ohne Funktionsverlust zu verwenden oder sogar ein Leben lang problemlos und gesund im Mund zu behalten."
          },
          {
            question: "Gibt es eine Altersgrenze für die Implantatbehandlung?",
            answer: "Implantate können bei jedem mit fehlendem Zahn und einer für die Behandlung geeigneten Knochenstruktur angewendet werden. Das wichtige Kriterium hier ist jedoch, dass die Knochen vollständig entwickelt sind. Implantate, die in sich entwickelnde Knochen eingesetzt werden, können nach einer Weile Probleme verursachen, und diese Implantate müssen möglicherweise ersetzt werden. Daher liegt die Untergrenze für die Implantatbehandlung bei 18 Jahren. Jedem, der 18 Jahre oder älter ist, können Implantate eingesetzt werden."
          },
          {
            question: "Gibt es eine spezielle Pflegebehandlung für Implantatszähne?",
            answer: "Wie bei allen Zähnen ist auch bei Implantatszähnen Reinigung und Pflege sehr wichtig. Man könnte sogar sagen, dass diese Zähne etwas wichtiger sind. Implantatszähne sollten regelmäßig geputzt und bei Bedarf Zahnseide verwendet werden. Für eine vollständige Mundhygiene kann auch in bestimmten Abständen gegurgelt werden. Substanzen wie Speisereste, die sich um das Implantat herum ansammeln, können langfristig zu Problemen wie Zahnstein und Zahnfleischrückgang führen."
          }
        ]
      }
    },
    oralSurgeryPage: {
      seo: {
        title: 'Mund-, Kiefer- und Gesichtschirurgie | Kartal Soğanlık Zahnklinik',
        description: 'Wir bieten hochwertige Mund-, Kiefer- und Gesichtschirurgie mit unseren Fachärzten in Kartal und Soğanlık an. Rufen Sie jetzt für eine kostenlose Untersuchung an.',
        keywords: 'mundchirurgie, zahnchirurgie, kieferchirurgie, weisheitszahnextraktion, retinierter zahn, kieferzysten, knochenaugmentation, kartal zahnklinik, soganlik zahnklinik'
      },
      hero: {
        badge: 'Chirurgische Behandlungen',
        title: 'Kartal Soğanlık Mund-, Kiefer- und Gesichtschirurgie',
        highlight: 'Spezialisiertes Chirurgie-Team',
        description: 'In unserer Abteilung für Mund-, Kiefer- und Gesichtschirurgie bieten unsere erfahrenen Spezialisten Lösungen für komplexe Probleme, die Zahn- und Kieferstrukturen betreffen. Wir sind spezialisiert auf Implantatchirurgie, Extraktion retinierter Zähne, Knochenaugmentation und Behandlung von Kieferzysten.',
        buttons: {
          appointment: 'Termin vereinbaren',
          details: 'Mehr Details'
        },
        expertFeature: 'Experten-Chirurgie-Team',
        technologyFeature: 'Fortschrittliche Technologie',
        experienceFeature: '15+ Jahre Erfahrung',
        features: {
          expert: 'Fachpersonal',
          expertSmall: 'Erfahrenes Team',
          technology: 'Moderne Technologie',
          technologySmall: 'Sichere Behandlung',
          experience: '15+ Jahre Erfahrung',
          experienceSmall: 'Fachkundiger Service'
        },
        imageAlt: 'Mund-, Kiefer- und Gesichtschirurgie'
      },
      header: {
        title: 'Mund-, Kiefer- und Gesichtschirurgie',
        description: 'Spezialbehandlungen für Gesicht-, Kiefer- und Mundkrankheiten'
      },
      responsibilities: {
        badge: 'Chirurgische Behandlungen',
        title: 'Verantwortungsbereiche',
        highlight: 'Unseres Fachteams',
        description: 'Umfassende Behandlungsleistungen durch unsere Spezialisten für Mund-, Kiefer- und Gesichtschirurgie',
        imageAlt: 'Mund-, Kiefer- und Gesichtschirurgie Team',
        items: [
          'Zahnimplantate, die ein natürliches Aussehen und Funktion für fehlende Zähne bieten',
          'Korrektur von Unregelmäßigkeiten, die das Gesichtsgleichgewicht, das Beißen und das Atmen beeinträchtigen',
          'Behandlung von Zysten und Tumoren im Zahn- und Kieferbereich',
          'Knochenaugmentationsverfahren bei Kieferknochenmangel',
          'Extraktion retinierter Zähne (Weisheitszähne)',
          'Behandlung von Kiefer- und Gesichtsfrakturen nach Trauma',
          'Behandlung von Kiefergelenkstörungen (TMJ)',
          'Chirurgische Behandlung von Zahnfleischerkrankungen'
        ]
      },
      advantages: {
        badge: 'Behandlungsvorteile',
        title: 'Warum Chirurgische',
        highlight: 'Behandlung Notwendig?',
        description: 'Vorteile der mund-, kiefer- und gesichtschirurgischen Behandlungen und die funktionellen und ästhetischen Vorteile von Zahnimplantaten',
        items: [
          'Kreative Operationen durch Fachärzte für komplexe Bedingungen',
          'Ganzheitlicher Behandlungsansatz und funktionelle Ergebnisse',
          'Minimalinvasive Eingriffe mit fortschrittlichen chirurgischen Techniken',
          'Sedierungsoptionen für ein komfortables Patientenerlebnis',
          'Hohe Erfolgsraten mit dreidimensionaler Planung'
        ]
      },
      suitable: {
        badge: 'Behandlungskriterien',
        title: 'Für Wen',
        highlight: 'Geeignet?',
        description: 'Eine mund-, kiefer- und gesichtschirurgische Behandlung kann in folgenden Fällen notwendig sein:',
        items: [
          'Personen, die aufgrund von Zahnverlust Zahnimplantate benötigen',
          'Personen mit Kieferunregelmäßigkeiten',
          'Personen mit retinierten Weisheitszähnen',
          'Personen, bei denen sich Zysten oder Tumore im Kiefer- und Zahnbereich entwickeln',
          'Personen mit Kieferknochenmangel',
          'Personen, die eine chirurgische Behandlung für Zahnfleischerkrankungen benötigen'
        ]
      },
      faq: {
        badge: 'Über Mund-, Kiefer- und Gesichtschirurgie',
        title: 'Häufig Gestellte',
        highlight: 'Fragen',
        items: [
          {
            question: 'Müssen retinierte Zähne extrahiert werden?',
            answer: 'Nein, nicht immer. Retinierte Zähne müssen extrahiert werden, wenn sie mit Zysten- oder Tumorbildung verbunden sind, wenn sie Frakturen verursachen können oder wenn sie einen Abszess verursachen.'
          },
          {
            question: 'Sollten alle Weisheitszähne gezogen werden?',
            answer: 'Nein. Weisheitszähne, die problemlos im Mund durchbrechen, müssen nicht gezogen werden, wenn sie eine gesunde Struktur haben.'
          },
          {
            question: 'Wie wird die Extraktion retinierter Zähne durchgeführt?',
            answer: 'Die Extraktion retinierter Zähne, die zu den Anwendungen der Mund-, Kiefer- und Gesichtschirurgie gehört, wird in einer klinischen Umgebung und unter örtlicher Betäubung durchgeführt.'
          },
          {
            question: 'Verursacht die Extraktion retinierter Zähne Schmerzen?',
            answer: 'Der Eingriff verursacht keine Schmerzen, da er unter örtlicher Betäubung durchgeführt wird.'
          },
          {
            question: 'Werden alle intraoralen chirurgischen Eingriffe mit Lokalanästhesie durchgeführt?',
            answer: 'Nein. Je nach durchzuführendem Eingriff kann eine lokale oder allgemeine Anästhesie angewendet werden. Operationen unter der Aufsicht von Fachzahnärzten werden meist unter Lokalanästhesie durchgeführt.'
          }
        ]
      }
    },
    rootCanalPage: {
      seo: {
        title: 'Wurzelkanalbehandlung | Kartal Soğanlık Zahnklinik',
        description: 'Komfortable und schmerzfreie Wurzelkanalbehandlung unter Mikroskop in Kartal Soğanlık. Rufen Sie jetzt für gesunde Zähne an.',
        keywords: 'wurzelkanalbehandlung, kartal wurzelkanal, soganlik wurzelkanal, schmerzfreie wurzelkanalbehandlung, mikroskopische wurzelkanalbehandlung, komfortable wurzelkanalbehandlung, zahnnerv behandlung'
      },
      hero: {
        badge: 'Endodontische Behandlung',
        title: 'Kartal Soğanlık Wurzelkanalbehandlung',
        highlight: 'Gesunde Zähne',
        description: 'Die Endodontie ist auf die Behandlung der inneren Aspekte der Zähne spezialisiert und konzentriert sich hauptsächlich auf die Pulpa, die Nerven, Blutgefäße und Bindegewebe beherbergt. Diese Behandlung rettet Ihren Zahn bei Pulpaentzündungen, die durch Karies, Verletzungen oder umfangreiche Zahnbehandlungen verursacht werden.',
        buttons: {
          appointment: 'Termin Vereinbaren',
          details: 'Weitere Details'
        },
        features: {
          expert: 'Fachpersonal',
          expertSmall: 'Erfahrenes Team',
          technology: 'Moderne Technologie',
          technologySmall: 'Sichere Behandlung',
          experience: '15+ Jahre Erfahrung',
          experienceSmall: 'Fachkundiger Service'
        },
        imageAlt: 'Wurzelkanalbehandlung'
      },
      header: {
        title: 'Wurzelkanalbehandlung',
        description: 'Schützen Sie Ihre Zahngesundheit mit moderner endodontischer Behandlung'
      },
      responsibilities: {
        badge: 'Endodontische Behandlung',
        title: 'Fachgebiete der',
        highlight: 'Endodontisten',
        description: 'Spezialisierte Fachgebiete unserer Zahnspezialisten in der endodontischen Behandlung',
        imageAlt: 'Wurzelkanalbehandlung Spezialist',
        items: [
          'Durchführung von Wurzelkanalbehandlungen: Entfernung der beschädigten Pulpa, Sterilisation des Zahns und Füllung',
          'Behandlung komplexer Zahnprobleme: Behandlung von interner Zahnresorption, Behandlung gebrochener Zähne',
          'Erneute Behandlung fehlgeschlagener Wurzelkanäle: Revisionsverfahren',
          'Anwendung chirurgischer Eingriffe: Apikale Chirurgie, Wurzelspitzenresektion'
        ]
      },
      consultation: {
        badge: 'Konsultation',
        title: 'Endodontische Konsultation',
        highlight: 'Indikationen',
        description: 'Wenn Sie anhaltende Zahnschmerzen, Empfindlichkeit, Schwellungen in der Nähe eines Zahns oder Beschwerden beim Kauen verspüren, wird Ihnen möglicherweise geraten, einen Endodontisten für eine umfassende Bewertung und potenzielle Behandlung zu konsultieren. Darüber hinaus kann Ihr regulärer Zahnarzt eine endodontische Konsultation empfehlen, wenn er Probleme in der inneren Struktur des Zahns feststellt.'
      },
      advantages: {
        badge: 'Behandlungsvorteile',
        title: 'Vorteile der',
        highlight: 'Endodontischen Behandlung',
        description: 'Vorteile, die wir mit moderner endodontischer Behandlung bieten',
        items: [
          'Erhaltung natürlicher Zähne: Das Hauptziel ist die Erhaltung Ihrer natürlichen Zähne, die in Bezug auf Funktionalität und ästhetische Anziehungskraft alle prothetischen Alternativen übertreffen.',
          'Komfort nach dem Eingriff: Moderne endodontische Techniken betonen wirksame Anästhesie, Schmerzmanagement und sanfte Techniken, um den Komfort während und nach der Behandlung zu maximieren.',
          'Schutz der allgemeinen Mundgesundheit: Bei Patienten, deren Zähne gezogen wurden, können benachbarte Zähne sich verschieben und die Kaufunktion stören.',
          'Funktionelle Rückkehr: Nach einer gut behandelten Wurzelkanalbehandlung kehrt der Zahn zu seiner normalen Funktion zurück, wobei die volle Kaufähigkeit typischerweise innerhalb weniger Tage wiederhergestellt wird.',
          'Kosmetische Integrität: Mit der Erhaltung des natürlichen Zahns können Patienten weiterhin ihr kosmetisches Erscheinungsbild bewahren und selbstbewusst lächeln.'
        ]
      },
      suitable: {
        badge: 'Behandlungskriterien',
        title: 'Für Wen Ist Es',
        highlight: 'Geeignet?',
        description: 'Eine Wurzelkanalbehandlung ist generell geeignet für:',
        items: [
          'Personen mit tiefen Karies',
          'Personen mit Zahnfrakturen',
          'Personen, die wiederholte Zahnbehandlungen benötigen',
          'Personen, die ein Zahntrauma erlitten haben',
          'Personen, die unter langanhaltenden Zahnschmerzen leiden',
          'Personen mit Zahnempfindlichkeit'
        ]
      },
      faq: {
        badge: 'Über Wurzelkanalbehandlung',
        title: 'Häufig Gestellte',
        highlight: 'Fragen',
        items: [
          {
            question: 'Ist eine Wurzelkanalbehandlung schmerzhaft?',
            answer: 'In der modernen Zahnmedizin ist eine Wurzelkanalbehandlung typischerweise ein schmerzfreier Eingriff. Der Behandlungsbereich wird mit Anästhesie betäubt, und verschiedene Techniken werden angewendet, um den Patientenkomfort zu erhöhen. Es kann einige Tage nach der Behandlung zu leichter Empfindlichkeit kommen, die jedoch in der Regel mit verschreibungspflichtigen oder rezeptfreien Schmerzmitteln leicht behandelt werden kann.'
          },
          {
            question: 'Verliert ein Zahn nach einer Wurzelkanalbehandlung seine Vitalität?',
            answer: 'Ja, da die Wurzelkanalbehandlung die Pulpa des Zahns entfernt, werden die Nerven und Blutgefäße entfernt, und der Zahn gilt nicht mehr als vital. Der Zahn bleibt jedoch weiterhin am Kieferknochen befestigt und behält seine Funktion bei. Behandelte Zähne sollten in der Regel mit einer Krone versehen werden, um zusätzlichen Halt und Schutz zu bieten.'
          },
          {
            question: 'Wie lange dauert eine Wurzelkanalbehandlung?',
            answer: 'Eine Wurzelkanalbehandlung dauert typischerweise ein oder zwei Sitzungen, wobei jede Sitzung etwa 60-90 Minuten dauert. Die Anzahl der erforderlichen Sitzungen hängt von Faktoren wie der Position des zu behandelnden Zahns, der Komplexität der Wurzelkanäle und dem Vorhandensein einer Infektion ab.'
          },
          {
            question: 'Wie lange nach einer Wurzelkanalbehandlung kann ich essen?',
            answer: 'Sie können essen, sobald die Betäubung in Ihrem Mund nachgelassen hat, was normalerweise innerhalb weniger Stunden nach dem Termin der Fall ist. Es wird empfohlen, harte oder klebrige Speisen auf dieser Seite zu vermeiden, bis der behandelte Zahn vollständig geheilt und mit einer permanenten Füllung oder Krone versorgt wurde.'
          },
          {
            question: 'Könnte das Ziehen des Zahns eine bessere Option als eine Wurzelkanalbehandlung sein?',
            answer: 'Wenn möglich, wird eine Wurzelkanalbehandlung einer Zahnextraktion vorgezogen. Die Erhaltung Ihres natürlichen Zahns ist immer die beste Option. Eine Extraktion hinterlässt eine Lücke, die ersetzt werden muss (mit einem Implantat, einer Brücke oder einer Prothese) und kann dazu führen, dass sich benachbarte Zähne verschieben. Wenn der Zahn jedoch stark beschädigt ist oder eine schlechte Prognose hat, kann eine Extraktion notwendig sein.'
          }
        ]
      }
    },
    prosthesisPage: {
      seo: {
        title: 'Prothetische Behandlung | Kartal Soğanlık Zahnklinik',
        description: 'Moderne prothetische Zahnbehandlung mit unserem Expertenteam in Kartal Soğanlık. Natürliches Aussehen mit Zirkonium-, Porzellan-, herausnehmbaren und implantatgetragenen Prothesenoptionen.',
        keywords: 'Prothese, Zahnprothese, Zirkoniumkrone, Porzellankrone, herausnehmbare Prothese, implantatgetragene Prothese, Vollprothese, Teilprothese, Kartal Prothese, Soğanlık Prothese'
      },
      hero: {
        badge: 'Prothetische Behandlung',
        title: 'Kartal Soğanlık Prothetische Behandlung',
        highlight: 'Zahnprothetik',
        description: 'Wir bieten natürlich aussehende und komfortable prothetische Lösungen mit modernen Zahnprothesentechnologien. Unser Expertenteam ist für individuell angepasste Prothesenoptionen speziell für Sie da.',
        buttons: {
          appointment: 'Termin Vereinbaren',
          details: 'Weitere Details'
        },
        features: {
          expert: 'Expertenteam',
          expertSmall: 'Erfahrenes Personal',
          technology: 'Moderne Technologie',
          technologySmall: 'Sichere Behandlung',
          experience: '15+ Jahre Erfahrung',
          experienceSmall: 'Fachgerechter Service'
        },
        imageAlt: 'Zahnprothetische Behandlung'
      },
      header: {
        title: 'Prothetische Behandlung',
        description: 'Sichere und natürlich aussehende prothetische Behandlung'
      },
      responsibilities: {
        badge: 'Prothetische Behandlung',
        title: 'Mit Unserem Expertenteam',
        highlight: 'Prothetische Behandlung',
        description: 'Zahnprothesen sind Behandlungsmethoden, die die Kaufunktion und das ästhetische Erscheinungsbild wiederherstellen, indem fehlende Zähne ersetzt werden. In der modernen Zahnmedizin verwendete Prothesen bieten sowohl funktionell als auch ästhetisch hervorragende Ergebnisse.',
        imageAlt: 'Prothetische Behandlung',
        items: [
          'Festsitzende Prothesen: Natürlich aussehende Kronen mit Zirkonium und Porzellan',
          'Herausnehmbare Prothesen: Voll- und Teilprothesen für fehlende Zähne',
          'Implantatgetragene Prothesen: Festsitzende oder herausnehmbare Prothesen auf Implantaten',
          'Laminat-Veneers: Dünne Porzellanverblendungen, die auf die Vorderfläche der Zähne aufgebracht werden',
          'Temporäre Prothesen: Schutzprothesen, die zwischen den Behandlungsprozessen verwendet werden',
          'Digitales Smile-Design: Computergestützte ästhetische Zahnprothesen'
        ]
      },
      advantages: {
        badge: 'Behandlungsvorteile',
        title: 'Vorteile der',
        highlight: 'Prothetischen Behandlung',
        description: 'Vorteile, die wir mit unseren modernen prothetischen Technologien bieten',
        items: [
          'Wiederherstellung der Kaufunktion: Prothesen beseitigen Kauschwierigkeiten, die durch fehlende Zähne verursacht werden.',
          'Erhaltung der Gesichtsästhetik: Fehlende Zähne können zu Veränderungen in der Gesichtsstruktur führen, Prothesen verhindern diese Veränderung.',
          'Verbesserung der Sprechfunktion: Fehlende Zähne können Sprachprobleme verursachen, Prothesen lösen diese Probleme.',
          'Schutz der Nachbarzähne: Prothesen verhindern, dass andere Zähne sich verschieben und schief werden.',
          'Erhöhtes Selbstvertrauen: Ästhetisches Erscheinungsbild sorgt für mehr Selbstvertrauen im sozialen Leben.'
        ]
      },
      suitable: {
        badge: 'Behandlungskriterien',
        title: 'Für Wen Ist Es',
        highlight: 'Geeignet?',
        description: 'Die prothetische Behandlung ist im Allgemeinen für folgende Zustände geeignet:',
        items: [
          'Personen mit einem oder mehreren fehlenden Zähnen',
          'Personen mit stark abgenutzten Zähnen',
          'Personen, die ihr ästhetisches Erscheinungsbild verbessern möchten',
          'Personen mit Problemen bei den Kaufunktionen',
          'Patienten mit Sprachstörungen',
          'Personen, die nach einer Implantatbehandlung eine Suprastruktur benötigen'
        ]
      },
      types: {
        badge: 'Prothesentypen',
        title: 'Prothesenoptionen',
        highlight: 'Die Wir Anbieten',
        description: 'Verschiedene prothetische Alternativen, die wir je nach den Bedürfnissen unserer Patienten anbieten',
        items: [
          {
            title: 'Zirkoniumkronen',
            description: 'Haltbare Kronen, die metallfrei sind und ein natürliches Zahnaussehen bieten'
          },
          {
            title: 'Porzellankronen',
            description: 'Kronen mit hoher Lichtdurchlässigkeit, die ästhetisch perfekte Ergebnisse liefern'
          },
          {
            title: 'Implantatgetragene Prothesen',
            description: 'Festsitzende oder herausnehmbare Prothesen, die mit Implantaten verbunden sind'
          },
          {
            title: 'Vollprothesen',
            description: 'Vollständige Zahnprothesen, die verwendet werden, wenn alle Zähne verloren gegangen sind'
          },
          {
            title: 'Teilprothesen',
            description: 'Teilprothesen, die verwendet werden, wenn einige Zähne fehlen'
          },
          {
            title: 'Laminat-Veneers',
            description: 'Dünne Porzellanbeschichtungen, die auf die Vorderfläche der Zähne geklebt werden'
          }
        ]
      },
      process: {
        badge: 'Behandlungsprozess',
        title: 'Prothetische Behandlung',
        highlight: 'Phasen',
        description: 'Die prothetische Behandlung besteht im Allgemeinen aus den folgenden Phasen:',
        items: [
          {
            title: 'Detaillierte Untersuchung',
            description: 'Umfassende Bewertung der Zahn- und Kieferstruktur'
          },
          {
            title: 'Behandlungsplan',
            description: 'Erstellung eines personalisierten prothetischen Behandlungsplans'
          },
          {
            title: 'Zahnvorbereitung',
            description: 'Vorbereitung der notwendigen Zähne für die Prothese'
          },
          {
            title: 'Abdrucknahme',
            description: 'Präzise Abdrücke mit digitalen oder traditionellen Methoden'
          },
          {
            title: 'Temporäre Prothese',
            description: 'Anwendung einer temporären Prothese, während die permanente Prothese vorbereitet wird'
          },
          {
            title: 'Permanente Prothese',
            description: 'Endgültige Anpassung und Anwendung der permanenten Prothese'
          }
        ]
      },
      faq: {
        badge: 'Über Prothetische Behandlung',
        title: 'Häufig Gestellte',
        highlight: 'Fragen',
        items: [
          {
            question: 'Wie lang ist die Lebensdauer von Prothesen?',
            answer: 'Die Lebensdauer von Prothesen variiert je nach Material, Pflege und der Mundhygiene der Person. Festsitzende Prothesen (Kronen und Brücken) können bei richtiger Pflege 10-15 Jahre halten, Zirkoniumkronen 15-20 Jahre und herausnehmbare Prothesen durchschnittlich 5-7 Jahre. Diese Zeiträume können mit regelmäßigen zahnärztlichen Kontrollen und richtiger Pflege verlängert werden.'
          },
          {
            question: 'Wie lange dauert es, sich an eine Prothese zu gewöhnen?',
            answer: 'Der Anpassungsprozess an die Verwendung einer Prothese variiert von Person zu Person. Während man sich an festsitzende Prothesen normalerweise innerhalb weniger Tage gewöhnt, kann es 2-4 Wochen dauern, bis man sich an herausnehmbare Prothesen gewöhnt hat. Während dieses Prozesses müssen möglicherweise Funktionen wie Sprechen und Kauen neu organisiert werden. Die Befolgung der Empfehlungen Ihres Zahnarztes beschleunigt den Anpassungsprozess.'
          },
          {
            question: 'Wie werden Prothesen gepflegt?',
            answer: 'Festsitzende Prothesen sollten regelmäßig gebürstet und mit Zahnseide gereinigt werden wie normale Zähne. Herausnehmbare Prothesen sollten nach jeder Mahlzeit entfernt und gereinigt und über Nacht in speziellen Lösungen eingeweicht werden. Regelmäßige zahnärztliche Kontrollen sind wichtig für alle Arten von Prothesen. Richtige Pflege verlängert die Lebensdauer von Prothesen und erhält die Mundgesundheit.'
          },
          {
            question: 'Was ist der Unterschied zwischen Zirkonium- und Porzellankronen?',
            answer: 'Zirkoniumkronen sind haltbarer und metallfrei, so dass kein Allergierisiko besteht. Außerdem ist ihre Lichtdurchlässigkeit näher an natürlichen Zähnen. Porzellankronen liefern ästhetisch perfekte Ergebnisse, sind aber im Vergleich zu Zirkonium zerbrechlicher. Zirkoniumkronen werden besonders für Backenzähne bevorzugt, während Porzellankronen häufiger für Frontzähne bevorzugt werden. Die Auswahl wird vom Zahnarzt entsprechend den Bedürfnissen des Patienten bestimmt.'
          },
          {
            question: 'Ist die prothetische Behandlung schmerzhaft?',
            answer: 'Während des prothetischen Behandlungsprozesses wird bei Bedarf eine lokale Anästhesie angewendet, so dass während des Eingriffs keine Schmerzen zu spüren sind. Nach dem Eingriff kann es zu leichter Empfindlichkeit kommen, die jedoch in der Regel innerhalb weniger Tage vergeht. Bei herausnehmbaren Prothesen kann während des Anpassungsprozesses in einigen Bereichen Druck zu spüren sein. In diesem Fall wird Ihr Zahnarzt die notwendigen Anpassungen vornehmen.'
          }
        ]
      }
    },
    teethWhiteningPage: {
      seo: {
        title: 'Zahnaufhellung | Kartal Soğanlık Zahnklinik',
        description: 'Professionelle Zahnaufhellung in Kartal Soğanlık. Sichere Aufhellung und strahlende Lächeln mit moderner Technologie und Expertenteam.',
        keywords: 'zahnaufhellung, bleaching, kartal zahnaufhellung, soganlik zahnaufhellung, professionelle zahnaufhellung, laseraufhellung'
      },
      hero: {
        badge: 'Zahnaufhellung',
        title: 'Zahnaufhellungslösungen',
        highlight: 'Professionelles Bleaching',
        description: 'Mit unserer modernen Technologie und unserem Expertenteam hellen wir Ihre Zähne sicher auf und sorgen für ein strahlenderes Lächeln.',
        buttons: {
          appointment: 'Termin Vereinbaren',
          details: 'Weitere Details'
        },
        features: {
          expert: 'Fachpersonal',
          expertSmall: 'Erfahrenes Team',
          technology: 'Moderne Technologie',
          technologySmall: 'Sichere Behandlung',
          experience: '15+ Jahre Erfahrung',
          experienceSmall: 'Fachkundiger Service'
        },
        imageAlt: 'Zahnaufhellung',
        experience: 'Jahre Erfahrung'
      },
      header: {
        title: 'Zahnaufhellung',
        description: 'Strahlende Lächeln mit professioneller Aufhellungsbehandlung'
      },
      responsibilities: {
        badge: 'Aufhellungsbehandlungen',
        title: 'Zahnaufhellungs-',
        highlight: 'Spezialisten',
        description: 'Erreichen Sie ein strahlenderes Lächeln mit professionellen Zahnaufhellungsbehandlungen.',
        imageAlt: 'Zahnaufhellungsspezialist',
        items: [
          'Aufhellung in der Praxis',
          'Aufhellungssets für zu Hause',
          'Zahnfarbanalyse',
          'Empfindlichkeitskontrolle',
          'Nachsorge nach der Aufhellung',
          'Regelmäßige Kontrollen'
        ]
      },
      advantages: {
        badge: 'Behandlungsvorteile',
        title: 'Vorteile der professionellen',
        highlight: 'Aufhellung',
        description: 'Mit unserer modernen Technologie und unserem Expertenteam hellen wir Ihre Zähne sicher auf und sorgen für ein strahlenderes Lächeln.',
        items: [
          'Optimale und schnelle Ergebnisse',
          'Personalisierte Behandlungen',
          'Langanhaltende Aufhellungseffekte',
          'Sicherheit und professionelle Überwachung',
          'Umfassende Fleckenentfernung',
          'Gesteigertes Selbstvertrauen'
        ]
      },
      suitable: {
        badge: 'Behandlungskriterien',
        title: 'Wer kann von',
        highlight: 'Zahnaufhellung profitieren?',
        description: 'Bedingungen, die für eine Zahnaufhellungsbehandlung geeignet sind',
        items: [
          'Menschen mit verfärbten oder verfärbten Zähnen durch Kaffee, Tee, Wein oder Rauchen',
          'Personen, die die natürlichen Alterseffekte auf die Zahnfarbe umkehren möchten',
          'Jeder, der eine kosmetische Verbesserung für einen besonderen Anlass oder zur persönlichen Zufriedenheit sucht',
          'Patienten mit guter Mundgesundheit, die ästhetische Zahnbehandlungen wünschen'
        ]
      },
      faq: {
        badge: 'Über Zahnaufhellung',
        title: 'Häufig Gestellte',
        highlight: 'Fragen',
        items: [
          {
            question: 'Welche der verschiedenen Aufhellungsmethoden sollte ich wählen?',
            answer: 'Die Antwort auf diese Frage variiert je nach Ausmaß der Vergilbung oder Verfärbung Ihrer Zähne. Ihr Zahnarzt wird nach einer Untersuchung entscheiden, welche Behandlung für Sie am effektivsten sein wird.'
          },
          {
            question: 'Wie viele Therapiesitzungen sind für eine erfolgreiche Zahnaufhellung erforderlich?',
            answer: 'Die Anzahl der Sitzungen kann je nach Größe des Verfärbungsproblems an Ihren Zähnen zu- oder abnehmen. Während einige Vergilbungs- oder Verfärbungsfälle in einer einzigen Sitzung behandelt werden können, können in einigen Fällen mehrere Sitzungen erforderlich sein.'
          },
          {
            question: 'Verursacht Aufhellung Empfindlichkeit in den Zähnen?',
            answer: 'Nein. Wenn jedoch bereits vor der Behandlung eine Beschwerde über Zahnempfindlichkeit besteht, kann dieser Zustand nach der Anwendung fortbestehen. Wenn bei der vor dem Aufhellungsverfahren durchgeführten Untersuchung durch den Experten Zahnempfindlichkeit festgestellt wird, sollte die Empfindlichkeit beseitigt werden, bevor mit der Anwendungsphase fortgefahren wird.'
          },
          {
            question: 'Wie sollte die Zahnpflege nach der Aufhellung sein?',
            answer: 'Es ist vorteilhaft, die Zähne unmittelbar nach der Zahnaufhellungsanwendung eine Weile etwas mehr als normal zu schützen. Es wäre richtig, für ein bis zwei Wochen auf Gewohnheiten zu verzichten, die zu Vergilbung oder Verfärbung führen können, wie Rauchen und Kaffee. Danach sollte die routinemäßige Zahnpflege fortgesetzt und die Mundhygiene für Zähne, die einer Aufhellungsbehandlung unterzogen wurden, wie für alle Zähne, niemals vernachlässigt werden. Auf diese Weise hält die Wirkung der Aufhellungsanwendung lange an.'
          },
          {
            question: 'Verlieren aufgehellte Zähne mit der Zeit ihre Farbe?',
            answer: 'Kein Zahnarzt kann behaupten, dass die Zahnaufhellungsbehandlung ein Leben lang hält. Es gibt verschiedene Faktoren, die bestimmen, wie lange der Aufhellungsprozess seine Wirkung auf die Zähne behält. Die vorhandene Struktur des Zahns, wie anfällig er für Vergilbung und Verfärbung ist, Essgewohnheiten und Gewohnheiten im Zusammenhang mit Mund- und Zahnpflege sind in dieser Hinsicht entscheidend. Mit guter Pflege und gesunden Essgewohnheiten können Zähne ihre Weiße lange beibehalten. Im Falle einer erneuten Verfärbung aus irgendeinem Grund kann unter Aufsicht eines Facharztes eine zusätzliche Aufhellungsbehandlung durchgeführt werden.'
          }
        ]
      }
    }
  },
  cta: {
    badge: 'Expertenteam',
    title: 'Lächeln Sie gesund',
    subtitle: 'Jeden Tag!',
    description: 'Beginnen Sie Ihren Tag mit einem Lächeln: Vereinbaren Sie einen Termin, um unser Expertenteam für Mund-, Zahn- und Kieferchirurgie kennenzulernen.',
    button: 'Termin Vereinbaren'
  },
  contact: {
    seo: {
      title: "Klinik Akademi | Kontakt",
      description: "Willkommen bei Klinik Akademi. Sie können uns für Termine, Informationen oder Fragen kontaktieren. Unser Expertenteam steht bereit, Ihnen zu helfen.",
      keywords: "kontakt, zahnklinik, termin, adresse, telefon, standort"
    },
    hero: {
      badge: "Kontaktieren Sie uns",
      title: "Klinik Akademi",
      highlight: "Kontaktzentrum",
      text: "Willkommen bei Klinik Akademi. Sie können uns für Termine, Informationen oder Fragen kontaktieren. Unser Expertenteam steht bereit, Ihnen zu helfen.",
      imageAlt: "Klinik Akademi Kontakt"
    },
    info: {
      phone: {
        title: "Telefon",
        content: "+90 553 973 64 80"
      },
      email: {
        title: "E-Mail",
        content: "info@klinikakademi.com"
      },
      openHours: {
        badge: "7 Tage die Woche geöffnet",
        content: "Jeden Tag 09:00 - 00:00"
      }
    },
    map: {
      badge: "Standortinformationen",
      titlePrefix: "Wie Sie",
      titleHighlight: "uns erreichen?",
      description: "Verwenden Sie die Karte oder folgen Sie den Anweisungen unten, um unsere Klinik zu erreichen",
      address: {
        title: "Adresse",
        value: "Orta, Yavuz Selim Cd. No:15-A Kartal/İstanbul"
      },
      phone: {
        title: "Telefon",
        value: "+90 553 973 64 80"
      },
      email: {
        title: "E-Mail",
        value: "info@klinikakademi.com"
      },
      hours: {
        title: "Geöffnet",
        value: "Jeden Tag 09:00 - 00:00"
      },
      markerTitle: "Klinik Akademi"
    },
    directions: {
      title: "Transportinformationen",
      car: {
        title: "Mit dem Privatfahrzeug",
        description: "Sie können uns über die E-5-Autobahn in Richtung Kartal oder über die Kartal-Samandira-Verbindungsstraße von TEM erreichen.",
        time1: "5 Minuten von E-5",
        time2: "10 Minuten von TEM",
        time3: "8 Minuten von der Küstenstraße"
      },
      public: {
        title: "Mit öffentlichen Verkehrsmitteln",
        description: "Sie können leicht mit U-Bahn-, Bus- und Minibuslinien anreisen.",
        option1: "Kartal U-Bahnstation - 5 Minuten zu Fuß",
        option2: "KM20, KM21, KM22 Minibuslinien",
        option3: "14K, 16KH, 17K Buslinien"
      },
      parking: {
        title: "Parkplatz",
        description: "Kostenlose Parkplätze sind rund um unsere Klinik verfügbar."
      },
      getDirections: "Wegbeschreibung erhalten"
    },
    form: {
      badge: "Kontaktinformationen",
      titlePrefix: "Nehmen Sie",
      titleHighlight: "Kontakt mit uns auf",
      description: "Kontaktieren Sie uns für Ihre Fragen, Vorschläge oder Terminanfragen",
      card: {
        title: "Nachricht senden",
        subtitle: "Sie können uns eine Nachricht senden, indem Sie das unten stehende Formular ausfüllen."
      },
      fields: {
        name: {
          label: "Ihr Vor- und Nachname",
          placeholder: "Ihr Vor- und Nachname"
        },
        phone: {
          label: "Ihre Telefonnummer",
          placeholder: "Ihre Telefonnummer"
        },
        email: {
          label: "Ihre E-Mail-Adresse",
          placeholder: "Ihre E-Mail-Adresse"
        },
        subject: {
          label: "Betreff",
          placeholder: "Betreff auswählen",
          options: {
            appointment: "Terminanfrage",
            info: "Informationsanfrage",
            price: "Preisinformation",
            other: "Sonstiges"
          }
        },
        message: {
          label: "Ihre Nachricht",
          placeholder: "Ihre Nachricht"
        },
        consent: {
          label: "Ich stimme der Verarbeitung meiner persönlichen Daten zu."
        }
      },
      buttons: {
        send: "Nachricht senden",
        sending: "Senden..."
      },
      messages: {
        success: "Ihre Nachricht wurde erfolgreich gesendet.",
        error: "Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten."
      }
    },
    details: {
      title: "Unsere Kontaktinformationen",
      subtitle: "Sie können uns über die folgenden Kanäle erreichen",
      address: {
        title: "Adresse",
        value: "Orta, Yavuz Selim Cd. No:15-A Kartal/İstanbul"
      },
      phone: {
        title: "Telefon",
        value: "+90 553 973 64 80"
      },
      email: {
        title: "E-Mail",
        value: "info@klinikakademi.com"
      },
      hours: {
        title: "Öffnungszeiten",
        value: "Jeden Tag: 09:00 - 00:00"
      },
      social: {
        title: "Soziale Medien"
      },
      formTitle: "Nehmen Sie Kontakt mit uns auf",
      formSubtitle: "Nachricht senden",
      formDescription: "Kontaktieren Sie uns für Ihre Fragen, Vorschläge oder Terminanfragen"
    }
  },
  appointment: {
    title: 'Online-Termin',
    description: 'Lassen Sie uns Ihren Termin zum günstigsten Zeitpunkt für Sie erstellen',
    form: {
      name: 'Vollständiger Name',
      namePlaceholder: 'Ihr Vor- und Nachname',
      phone: 'Telefon',
      phonePlaceholder: 'Ihre Telefonnummer',
      email: 'E-Mail',
      emailPlaceholder: 'Ihre E-Mail-Adresse',
      treatment: 'Behandlung',
      treatmentPlaceholder: 'Behandlung Auswählen',
      treatments: {
        implant: 'Implantat',
        rootcanal: 'Wurzelkanalbehandlung',
        orthodontics: 'Kieferorthopädie',
        invisalign: 'Invisalign',
        periodontics: 'Parodontologie',
        surgery: 'Mund-, Kiefer- und Gesichtschirurgie',
        aesthetics: 'Ästhetische Zahnheilkunde',
        whitening: 'Zahnaufhellung',
        prosthesis: 'Prothetische Behandlungen',
        pediatric: 'Kinderzahnheilkunde',
        general: 'Allgemeine Zahnheilkunde',
        emergency: 'Zahnärztliche Notfallbehandlung'
      },
      message: 'Ihre Nachricht',
      messagePlaceholder: 'Zusätzliche Anmerkungen, die Sie hinzufügen möchten...',
      sending: 'Wird Gesendet...',
      submit: 'Terminanfrage Erstellen',
      privacy: 'Ihre persönlichen Daten sind gemäß unserer Datenschutzrichtlinie geschützt',
      success: 'Ihre Terminanfrage wurde erfolgreich erstellt. Wir werden Sie so schnell wie möglich kontaktieren.',
      error: 'Bei der Erstellung Ihrer Terminanfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
    }
  },
  footer: {
    clinicName: 'Klinik Akademi',
    description: 'Wir sind mit unseren modernen Zahntechnologien und unserem Fachpersonal für gesunde Lächeln an Ihrer Seite.',
    quickLinks: 'Schnellzugriff',
    team: 'Unser Team',
    services: 'Unsere Leistungen',
    doctors: 'Unsere Ärzte',
    treatmentsTitle: 'Unsere Behandlungen',
    treatments: {
      implant: 'Zahnimplantate',
      whitening: 'Zahnaufhellung',
      orthodontics: 'Kieferorthopädie',
      periodontal: 'Parodontologische Behandlung',
      surgery: 'Mund-, Kiefer- und Gesichtschirurgie'
    },
    contactInfo: 'Kontaktinformationen',
    address: 'Yavuz Selim Str. Nr:15-A Kartal/Istanbul',
    copyright: 'Copyright © {year} Klinik Akademi. Alle Rechte vorbehalten.',
    designedBy: 'Entwickelt von ResRam Digitale Transformation',
    followUs: 'Folgen Sie Uns',
    legal: 'Rechtliches',
    privacy: 'Datenschutzrichtlinie',
    cookies: 'Cookie-Richtlinie'
  },
  team: {
    hero: {
      title: "Professionell",
      highlight: "Lernen Sie unser Team kennen",
      text1: "Unser spezialisiertes Team, geleitet von erfahrenen Zahnärzten und unterstützt von engagierten Fachleuten, hat sich der Bereitstellung einer personalisierten und erstklassigen Zahnpflege verschrieben. Wir priorisieren Ihren Komfort und Ihre Gesundheit, indem wir fortschrittliche Technologie mit einer sanften Berührung kombinieren, um ein positives Erlebnis zu schaffen.",
      text2: "Jedes Mitglied unseres Teams besteht aus Fachleuten, die Experten auf ihrem Gebiet sind, sich kontinuierlich weiterentwickeln und die höchste Stufe der Patientenzufriedenheit aufrechterhalten. Wir nutzen alle Möglichkeiten der modernen Zahnmedizin, verfolgen die neuesten technologischen Entwicklungen und aktualisieren kontinuierlich unsere Behandlungsprotokolle.",
      services: {
        expert: "Fachpersonal",
        patient: "Patientenorientiert",
        quality: "Qualitätsservice"
      },
      experience: "15+ Jahre Erfahrung"
    },
    section: {
      badge: "Professionelles Team",
      title: "Lernen Sie unser",
      highlight: "Expertenteam kennen",
      teamAlt: "Klinik Akademi Team"
    },
    join: {
      title1: "Auf Jobsuche?",
      title2: "Werden Sie Teil unseres Teams!",
      description: "Sind Sie eine leidenschaftliche und engagierte Person, die einen Unterschied in der Welt der Zahnmedizin machen möchte? Suchen Sie nicht weiter! Wir suchen talentierte Personen, die sich unserem wachsenden Team anschließen und uns helfen, unseren geschätzten Patienten außergewöhnliche Betreuung zu bieten.",
      button: "Auf eine Stelle bewerben",
      imageAlt: "Karrieremöglichkeiten"
    },
    members: {
      owner: "Zahnarzt und Inhaber",
      gm: "Geschäftsführer",
      bm: "Betriebsleiter",
      dentist: "Zahnarzt",
      chief: "Leitender Assistent"
    }
  },
  media: {
    hero: {
      badge: "Mediengalerie",
      title: "Klinik Akademi",
      highlight: "Medienzentrum",
      text: "Willkommen in unserer Mediengalerie mit den modernen Einrichtungen, erfolgreichen Behandlungsergebnissen und Veranstaltungen von Klinik Akademi. Entdecken Sie unsere visuellen Inhalte, die das Qualitätsserviceverständnis und den professionellen Ansatz unserer Klinik widerspiegeln.",
      photos: "Fotos",
      videos: "Videos",
      press: "Presse",
      facilities: "Unsere modernen Einrichtungen"
    },
    categories: {
      title: "Medienkategorien",
      heading: "Neueste Updates",
      highlight: "von Klinik Akademi",
      description: "Unsere Kliniknachrichten, Veranstaltungen und Updates",
      all: "Alle",
      photos: "Fotos",
      videos: "Videos",
      news: "Presse",
      social: "Soziale Medien"
    },
    actions: {
      loadMore: "Mehr Laden",
      loading: "Wird geladen...",
      close: "Schließen"
    },
    items: {
      clinic: "Unsere moderne Klinik",
      clinicDesc: "Moderne Behandlungseinheiten in unserer neuen Klinik in Soganlik",
      treatment: "Behandlungsräume",
      treatmentDesc: "Sterile Behandlungsumgebung, ausgestattet mit modernster Technologie",
      waiting: "Wartezimmer",
      waitingDesc: "Komfortabler und geräumiger Wartebereich",
      xray: "Digitales Röntgen",
      xrayDesc: "Fortschrittliche digitale Bildgebungssysteme",
      sterilization: "Unsere Sterilisationseinheit",
      sterilizationDesc: "Hohe Standards für Sterilisation und Hygiene"
    }
  },
  emergency: {
    title: 'Notfall-Zahnarzt',
    status: 'Aktiv - Service Verfügbar',
    call: 'Jetzt Anrufen',
    whatsapp: 'WhatsApp',
    instantResponse: 'Sofortige Antwort',
    services: {
      service1: {
        title: '7 Tage Service',
        description: '7 Tage die Woche geöffnet'
      },
      service2: {
        title: 'Schnelle Reaktion',
        description: 'Innerhalb von 30 Minuten'
      },
      service3: {
        title: 'Expertenteam',
        description: 'Erfahrenes Personal'
      },
      service4: {
        title: 'Notfallversorgung',
        description: 'Vorrangiger Service'
      }
    },
    form: {
      toggle: 'Notfall Melden',
      close: 'Formular Schließen',
      name: 'Vollständiger Name',
      namePlaceholder: 'Ihr Vor- und Nachname',
      phone: 'Telefon',
      phonePlaceholder: 'Ihre Telefonnummer',
      email: 'E-Mail',
      emailPlaceholder: 'Ihre E-Mail-Adresse',
      details: 'Notfall-Details',
      detailsPlaceholder: 'Beschreiben Sie kurz Ihren Notfall...',
      sending: 'Wird Gesendet...',
      submit: 'Notfall Melden',
      success: 'Ihre Notfallmeldung wurde erfolgreich gesendet. Wir werden Sie so schnell wie möglich kontaktieren.',
      error: 'Beim Senden Ihrer Notfallmeldung ist ein Fehler aufgetreten. Bitte rufen Sie uns direkt an.'
    }
  },
  notFound: {
    title: 'Seite nicht gefunden',
    subtitle: '404 Fehler',
    message: 'Die gesuchte Seite konnte nicht gefunden werden oder wurde möglicherweise verschoben.',
    button: 'Zurück zur Startseite',
    helpText: 'Brauchen Sie Hilfe?',
    contactUs: 'Kontaktieren Sie uns',
    suggestedPages: 'Vorgeschlagene Seiten:',
    home: 'Startseite',
    treatments: 'Behandlungen',
    contact: 'Kontakt'
  },
  cookieConsent: {
    title: 'Cookie-Hinweis',
    text: 'Unsere Website verwendet Cookies, um Ihre Erfahrung zu verbessern. Indem Sie unsere Website weiterhin nutzen, akzeptieren Sie unsere Cookie-Richtlinie.',
    detailButton: 'Weitere Informationen',
    acceptButton: 'Akzeptieren'
  },
  legal
} 