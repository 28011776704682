export default {
  // Hero Bölümü
  badge: 'Legal Information',
  title: {
    main: 'Privacy',
    highlight: 'Policy'
  },
  description: 'You can learn how your personal data is processed and protected when using our website.',
  
  meta: {
    title: 'Privacy Policy | Klinik Akademi Dental Polyclinic',
    description: 'Information about how your personal data is processed and protected when using the Klinik Akademi Dental Polyclinic website.'
  },
  
  // İçerik Bölümü
  intro: 'At Klinik Akademi Dental Polyclinic, protecting the privacy of our website visitors and ensuring the security of their information is among our priorities. This Privacy Policy contains explanations on how information collected when using our website is used, shared and protected.',
  
  // Bölüm 1
  section1: {
    title: '1. Information Collected',
    content: 'While using our website, we may collect the following information:',
    card1: {
      title: 'Automatically Collected Information',
      items: [
        'IP address',
        'Browser type and version',
        'Pages visited and dates',
        'Click data',
        'Device information'
      ]
    },
    card2: {
      title: 'Information You Provide',
      items: [
        'Name, surname, email, phone number shared through contact forms',
        'Health information shared through appointment forms',
        'Preferences regarding treatments and services'
      ]
    }
  },
  
  // Bölüm 2
  section2: {
    title: '2. Purpose of Using Information',
    content: 'We use the information we collect for the following purposes:',
    items: [
      'To provide you with better service',
      'To evaluate and respond to appointment requests',
      'To answer your questions and requests',
      'To improve our website and enhance user experience',
      'To fulfill legal obligations',
      'To conduct marketing activities (only if you give permission)'
    ]
  },
  
  // Bölüm 3
  section3: {
    title: '3. Sharing of Information',
    content: 'Your personal information is not shared with third parties except in the following cases:',
    items: [
      'When there is a legal obligation (court order, legal process, etc.)',
      'With our service providers (in this case, necessary measures are taken for the security of your information)',
      'When you give explicit permission'
    ]
  },
  
  // Bölüm 4
  section4: {
    title: '4. Security of Information',
    content: 'We implement technical and administrative measures to ensure the security of your personal information. These measures include:',
    items: [
      'Use of SSL encryption technology',
      'Limited access policies',
      'Regular security updates',
      'Data security training for our employees',
      'Firewalls and antivirus software'
    ],
    additionalContent: 'However, we would like to remind you that no data transmission over the internet is 100 percent secure. Therefore, we cannot guarantee absolute security.'
  },
  
  // Bölüm 5
  section5: {
    title: '5. Cookies',
    content: 'Our website uses cookies to improve user experience and analyze website performance. For detailed information about cookies, you can review our',
    cookiePolicyLink: 'Cookie Policy'
  },
  
  // Bölüm 6
  section6: {
    title: '6. Third Party Links',
    content: 'Our website may contain links to third-party websites. When you click on these links, you will leave our site. We have no control over and are not responsible for the privacy policies and practices of these third-party sites. We recommend that you review the privacy policy of every site you visit.'
  },
  
  // Bölüm 7
  section7: {
    title: '7. Children Privacy',
    content: 'Our website and services are not intended for children under the age of 18. We do not knowingly collect personal information from individuals under the age of 18. If you are under 18, you should not share any personal information without permission from your parents or guardians.'
  },
  
  // Bölüm 8
  section8: {
    title: '8. Your Rights',
    content: 'Regarding your personal data, you have the following rights:',
    category1: {
      items: [
        'Request access to your data',
        'Request correction of incorrect or incomplete information',
        'Request deletion of your data'
      ]
    },
    category2: {
      items: [
        'Object to the processing of your data',
        'Restrict the processing of your data',
        'Request portability of your data'
      ]
    },
    additionalContent: 'To exercise these rights or if you have any questions, you can contact us at: info@klinikakademi.com'
  },
  
  // Bölüm 9
  section9: {
    title: '9. Changes',
    content: 'We may update this Privacy Policy from time to time. When there is a change, we will publish the updated policy on our website. We recommend that you check the Privacy Policy every time you visit our website.'
  },
  
  // Bölüm 10
  section10: {
    title: '10. Contact',
    content: 'If you have questions or concerns about this Privacy Policy, please contact us through the following channels:',
    address: {
      label: 'Address',
      value: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/Istanbul'
    },
    email: {
      label: 'Email',
      value: 'info@klinikakademi.com'
    },
    phone: {
      label: 'Phone',
      value: '+90 553 973 64 80'
    },
    contactButton: 'Go to Contact Page'
  },
  
  // Footer
  lastUpdate: 'Last Update Date:'
} 