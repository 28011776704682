<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3" data-aos="fade-up">
            <div class="footer__info">
              <h3 class="footer__logo">{{ $t('footer.clinicName') }}</h3>
              <p class="footer__description">
                {{ $t('footer.description') }}
              </p>
              <div class="footer__social">
                <a href="https://www.facebook.com/KlinikAkademiAgizveDis/" class="footer__social-link" target="_blank">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="https://www.instagram.com/klinikakademi/" class="footer__social-link" target="_blank">
                  <i class="bi bi-instagram"></i>
                </a>
              </div>
            </div>
          </div>

          <div class="col-lg-2" data-aos="fade-up" data-aos-delay="100">
            <div class="footer__links">
              <h4 class="footer__title">{{ $t('footer.quickLinks') }}</h4>
              <ul class="footer__list">
                <li><router-link :to="getPageRoute('home')">{{ $t('nav.home') }}</router-link></li>
                <li><router-link :to="getPageRoute('about')">{{ $t('nav.aboutLink') }}</router-link></li>
                <li><router-link :to="getPageRoute('team')">{{ $t('footer.team') }}</router-link></li>
                <li><router-link :to="getPageRoute('services')">{{ $t('footer.services') }}</router-link></li>
                <li><router-link :to="getPageRoute('doctors')">{{ $t('footer.doctors') }}</router-link></li>
                <li><router-link :to="getPageRoute('contact')">{{ $t('nav.contact') }}</router-link></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-2" data-aos="fade-up" data-aos-delay="200">
            <div class="footer__links">
              <h4 class="footer__title">{{ $t('footer.treatmentsTitle') }}</h4>
              <ul class="footer__list">
                <li><router-link :to="getTreatmentLink('implant')">{{ $t('footer.treatments.implant') }}</router-link></li>
                <li><router-link :to="getTreatmentLink('whitening')">{{ $t('footer.treatments.whitening') }}</router-link></li>
                <li><router-link :to="getTreatmentLink('orthodontics')">{{ $t('footer.treatments.orthodontics') }}</router-link></li>
                <li><router-link :to="getTreatmentLink('periodontics')">{{ $t('footer.treatments.periodontal') }}</router-link></li>
                <li><router-link :to="getTreatmentLink('surgery')">{{ $t('footer.treatments.surgery') }}</router-link></li>
                <li><router-link :to="getTreatmentsPage()">{{ $t('treatments.viewAll') }}</router-link></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-2" data-aos="fade-up" data-aos-delay="250">
            <div class="footer__links">
              <h4 class="footer__title">{{ $t('footer.legal') || 'Yasal' }}</h4>
              <ul class="footer__list">
                <li><router-link :to="getLegalPageRoute('kvkk')">KVKK</router-link></li>
                <li><router-link :to="getLegalPageRoute('privacyPolicy')">{{ $t('footer.privacy') || 'Gizlilik Politikası' }}</router-link></li>
                <li><router-link :to="getLegalPageRoute('cookiePolicy')">{{ $t('footer.cookies') || 'Çerez Politikası' }}</router-link></li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3" data-aos="fade-up" data-aos-delay="300">
            <div class="footer__contact">
              <h4 class="footer__title">{{ $t('footer.contactInfo') }}</h4>
              <ul class="footer__list">
                <li>
                  <a href="https://g.co/kgs/GXdnnYr"><i class="bi bi-geo-alt"></i>{{ $t('footer.address') }}</a>
                </li>
                <li>
                  <a href="tel:+905539736480"><i class="bi bi-telephone"></i>0553 973 64 80</a>
                </li>
                <li>
                  <a href="mailto:info@klinikakademi.com"><i class="bi bi-envelope"></i>info@klinikakademi.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer__bottom">
      <div class="container">
        <div class="footer__copyright">
          <p>{{ $t('footer.copyright', { year: new Date().getFullYear() }) }} | 
            <a href="https://resram.com" target="_blank" rel="noopener noreferrer" class="designer-link resram-link">
              {{ $t('footer.designedBy') }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { getPageRoute, getTreatmentRoute, getTreatmentsBaseSlug } from '@/utils/slug';
import slugs from '@/translations/slugs';
import { getLocale } from '@/plugins/i18n';

export default {
  name: 'TheFooter',
  methods: {
    getTreatmentLink(treatmentKey) {
      return getTreatmentRoute(treatmentKey);
    },
    getTreatmentsPage() {
      return '/' + getTreatmentsBaseSlug();
    },
    getPageRoute(pageKey) {
      return getPageRoute(pageKey);
    },
    getLegalPageRoute(pageKey) {
      const locale = getLocale();
      return `/${slugs[locale].legal}/${slugs[locale][pageKey]}`;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables';
@import '@/assets/styles/_mixins';

.footer {
  background: linear-gradient(135deg, var(--primary-color) -10%, #1a1a1a 70%);
  color: $light-color;
  padding-top: 80px;
  position: relative;
  --link-hover: #{lighten($primary-color, 20%)};
  --link-transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  }

  &__top {
    padding-bottom: 60px;
  }

  &__logo {
    font-family: $font-family-heading;
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, #fff, #e6e6e6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__description {
    opacity: 0.9;
    margin-bottom: 2rem;
    line-height: 1.8;
    font-size: 1.05rem;
    color: rgba(255, 255, 255, 0.9);
  }

  &__social {
    display: flex;
    gap: 1.2rem;

    &-link {
      width: 42px;
      height: 42px;
      background: rgba($light-color, 0.08);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $light-color;
      font-size: 1.25rem;
      transition: all var(--link-transition);
      
      &:hover {
        transform: translateY(-5px);
        background: var(--primary-color);
        color: white;
        box-shadow: 0 5px 15px rgba(0, 164, 166, 0.3);
      }
    }
  }

  &__title {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1.8rem;
    position: relative;
    color: white;
    letter-spacing: 0.5px;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 35px;
      height: 2px;
      background: var(--primary-color);
      border-radius: 2px;
      box-shadow: 0 0 8px var(--primary-color);
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 1rem;

      a {
        color: rgba($light-color, 0.85);
        transition: all var(--link-transition);
        display: inline-flex;
        align-items: center;
        position: relative;
        padding: 0;
        background: none;
        border: none;
        font-weight: 500;
        font-size: 1.05rem;
        letter-spacing: 0.3px;
        text-decoration: none;

        &::after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 0;
          height: 1px;
          background: var(--primary-color);
          transition: width var(--link-transition);
          box-shadow: 0 0 5px var(--primary-color);
        }

        &:hover {
          color: white;
          transform: translateX(5px);
          
          &::after {
            width: 100%;
          }
          
          i {
            transform: translateX(3px);
            color: var(--primary-color);
            text-shadow: 0 0 8px var(--primary-color);
          }
        }
      }

      i {
        margin-right: 12px;
        color: var(--primary-color);
        font-size: 1.15rem;
        transition: all var(--link-transition);
        opacity: 0.9;
      }
    }
  }

  &__bottom {
    background: rgba(0, 0, 0, 0.3);
    padding: 22px 0;
    margin-top: 40px;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
    }
  }

  &__copyright {
    opacity: 0.9;
    font-size: 0.95rem;
    color: rgba(255, 255, 255, 0.8);

    p {
      margin: 0;
    }

    .resram-link {
      color: rgba(255, 255, 255, 0.8);
      text-decoration: none;
      transition: color 0.3s ease;
      
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .footer {
    padding-top: 60px;
    text-align: center;

    &__social {
      justify-content: center;
    }

    &__links, &__contact {
      margin-top: 45px;
    }

    &__title {
      &::after {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__list {
      li {
        a {
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
}
</style> 