<template>
  <div class="seo-head"></div>
</template>

<script>
import { getLocale } from '@/plugins/i18n'
import slugs from '@/translations/slugs'

export default {
  name: 'SeoHead',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    keywords: {
      type: String,
      required: true
    },
    mainSchemaType: {
      type: String,
      default: 'WebPage'
    },
    schemaData: {
      type: Object,
      default: () => ({})
    }
  },
  created() {
    // Mevcut dil kodunu al
    const currentLocale = getLocale();
    const localeMap = {
      'tr': 'tr_TR',
      'en': 'en_US',
      'de': 'de_DE'
    };
    
    // Sayfa başlığını güncelle
    document.title = this.title;
    
    // Meta description güncelle
    let descriptionMeta = document.querySelector('meta[name="description"]');
    if (!descriptionMeta) {
      descriptionMeta = document.createElement('meta');
      descriptionMeta.setAttribute('name', 'description');
      document.head.appendChild(descriptionMeta);
    }
    descriptionMeta.setAttribute('content', this.description);

    // Meta keywords güncelle
    let keywordsMeta = document.querySelector('meta[name="keywords"]');
    if (!keywordsMeta) {
      keywordsMeta = document.createElement('meta');
      keywordsMeta.setAttribute('name', 'keywords');
      document.head.appendChild(keywordsMeta);
    }
    keywordsMeta.setAttribute('content', this.keywords);

    // Canonical URL oluştur
    const currentPath = window.location.pathname;
    const hostname = window.location.origin;
    const canonicalUrl = hostname + currentPath;
    
    // Canonical URL meta tag'i
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.setAttribute('href', canonicalUrl);
    
    // Alternatif dil URL'leri için hreflang
    this.setHreflangTags(currentPath, hostname);
    
    // Open Graph meta etiketleri
    this.createOrUpdateMeta('property', 'og:title', this.title);
    this.createOrUpdateMeta('property', 'og:description', this.description);
    this.createOrUpdateMeta('property', 'og:type', 'website');
    this.createOrUpdateMeta('property', 'og:url', canonicalUrl);
    this.createOrUpdateMeta('property', 'og:locale', localeMap[currentLocale] || 'tr_TR');
    
    // Twitter Card meta etiketleri
    this.createOrUpdateMeta('name', 'twitter:card', 'summary_large_image');
    this.createOrUpdateMeta('name', 'twitter:title', this.title);
    this.createOrUpdateMeta('name', 'twitter:description', this.description);
    
    // Structured Data
    this.injectSchemaData();
  },
  beforeUnmount() {
    // Open Graph meta etiketlerini temizle
    document.querySelectorAll('meta[property^="og:"]').forEach(el => el.remove());
    document.querySelectorAll('meta[name^="twitter:"]').forEach(el => el.remove());
    
    // Hreflang etiketlerini temizle
    document.querySelectorAll('link[rel="alternate"][hreflang]').forEach(el => el.remove());
  },
  methods: {
    createOrUpdateMeta(attributeName, attributeValue, content) {
      let meta = document.querySelector(`meta[${attributeName}="${attributeValue}"]`);
      if (!meta) {
        meta = document.createElement('meta');
        meta.setAttribute(attributeName, attributeValue);
        document.head.appendChild(meta);
      }
      meta.setAttribute('content', content);
      return meta;
    },
    setHreflangTags(currentPath, hostname) {
      // Önce mevcut hreflang etiketlerini temizle
      document.querySelectorAll('link[rel="alternate"][hreflang]').forEach(el => el.remove());
      
      // Ana sayfa için basit kontrol
      if (currentPath === '/' || currentPath === '/en/' || currentPath === '/de/') {
        this.createHreflangTag(hostname + '/', 'tr');
        this.createHreflangTag(hostname + '/en/', 'en');
        this.createHreflangTag(hostname + '/de/', 'de');
        this.createHreflangTag(hostname + '/', 'x-default');
        return;
      }
      
      // Diğer sayfalar için içerik türünü ve anahtarını tespit etmeye çalış
      let pageKey = null;
      let isTreatment = false;
      
      // Tedavi sayfası mı kontrol et
      const treatmentMatch = currentPath.match(/\/([^/]+)\/([^/]+)$/);
      if (treatmentMatch) {
        const possibleBasePath = treatmentMatch[1];
        const possibleSlug = treatmentMatch[2];
        
        // Tedavi temeli mi kontrol et (tedaviler, treatments, zahnbehandlungen)
        for (const locale in slugs) {
          if (possibleBasePath === slugs[locale].treatments) {
            isTreatment = true;
            
            // Tedavi türünü bul
            for (const key in slugs[locale]) {
              if (key !== 'treatments' && key !== 'home' && slugs[locale][key] === possibleSlug) {
                pageKey = key;
                break;
              }
            }
            
            if (pageKey) break;
          }
        }
      } 
      
      // Tedavi sayfası değilse, normal sayfa mı kontrol et
      if (!isTreatment && !pageKey) {
        const pathWithoutLeadingSlash = currentPath.replace(/^\//, '');
        
        for (const locale in slugs) {
          for (const key in slugs[locale]) {
            if (key !== 'treatments' && key !== 'home' && slugs[locale][key] === pathWithoutLeadingSlash) {
              pageKey = key;
              break;
            }
          }
          if (pageKey) break;
        }
      }
      
      // Eğer sayfanın türünü tespit edebildiysek, hreflang etiketlerini ekle
      if (pageKey) {
        if (isTreatment) {
          // Tedavi sayfaları için
          for (const locale in slugs) {
            const basePath = slugs[locale].treatments;
            const slugPath = slugs[locale][pageKey];
            if (basePath && slugPath) {
              this.createHreflangTag(`${hostname}/${basePath}/${slugPath}`, locale);
            }
          }
        } else {
          // Diğer sayfalar için
          for (const locale in slugs) {
            const slugPath = slugs[locale][pageKey];
            if (slugPath) {
              this.createHreflangTag(`${hostname}/${slugPath}`, locale);
            }
          }
        }
        
        // x-default için Türkçe sayfayı kullan
        if (isTreatment) {
          this.createHreflangTag(`${hostname}/${slugs['tr'].treatments}/${slugs['tr'][pageKey]}`, 'x-default');
        } else {
          this.createHreflangTag(`${hostname}/${slugs['tr'][pageKey]}`, 'x-default');
        }
      }
    },
    createHreflangTag(href, hreflang) {
      const link = document.createElement('link');
      link.setAttribute('rel', 'alternate');
      link.setAttribute('hreflang', hreflang);
      link.setAttribute('href', href);
      document.head.appendChild(link);
    },
    injectSchemaData() {
      // Mevcut schema verilerini temizle
      document.querySelectorAll('script[type="application/ld+json"][data-seo-schema]').forEach(el => el.remove());
      
      // Temel schema verisi
      const baseSchema = {
        "@context": "https://schema.org",
        "@type": this.mainSchemaType,
        "url": window.location.href,
        "name": this.title,
        "description": this.description
      };
      
      // Kullanıcı tarafından sağlanan schema verilerini birleştir
      const schemaData = {...baseSchema, ...this.schemaData};
      
      // Schema verilerini sayfaya ekle
      const script = document.createElement('script');
      script.setAttribute('type', 'application/ld+json');
      script.setAttribute('data-seo-schema', 'true');
      script.textContent = JSON.stringify(schemaData);
      document.head.appendChild(script);
    }
  }
}
</script>

<style scoped>
.seo-head {
  display: none;
}
</style> 