export default {
  // Hero Bölümü
  badge: 'Yasal Bilgilendirme',
  title: {
    main: 'Gizlilik',
    highlight: 'Politikası'
  },
  description: 'Web sitemizi kullanırken kişisel verilerinizin nasıl işlendiği ve korunduğu hakkında bilgi edinebilirsiniz.',
  
  meta: {
    title: 'Gizlilik Politikası | Klinik Akademi Diş Polikliniği',
    description: 'Klinik Akademi Diş Polikliniği gizlilik politikası ve kişisel verilerin korunması hakkında detaylı bilgi.'
  },
  
  // İçerik Bölümü
  intro: 'Klinik Akademi Diş Polikliniği olarak, web sitemizi ziyaret eden kullanıcılarımızın gizliliğini korumak ve bilgilerinin güvenliğini sağlamak önceliklerimiz arasındadır. Bu Gizlilik Politikası, web sitemizi kullanırken toplanan bilgilerin nasıl kullanıldığı, paylaşıldığı ve korunduğuna dair açıklamaları içermektedir.',
  
  // Bölüm 1
  section1: {
    title: '1. Toplanan Bilgiler',
    content: 'Web sitemizi kullanırken, aşağıdaki bilgileri toplayabiliriz:',
    card1: {
      title: 'Otomatik Olarak Toplanan Bilgiler',
      items: [
        'IP adresi',
        'Tarayıcı türü ve versiyonu',
        'Ziyaret edilen sayfalar ve tarihler',
        'Tıklama verileri',
        'Cihaz bilgileri'
      ]
    },
    card2: {
      title: 'Sizin Sağladığınız Bilgiler',
      items: [
        'İletişim formları aracılığıyla paylaşılan ad, soyad, e-posta, telefon numarası',
        'Randevu formları aracılığıyla paylaşılan sağlık bilgileri',
        'Tedaviler ve hizmetlerle ilgili tercihler'
      ]
    }
  },
  
  // Bölüm 2
  section2: {
    title: '2. Bilgilerin Kullanılma Amacı',
    content: 'Topladığımız bilgileri aşağıdaki amaçlar doğrultusunda kullanmaktayız:',
    items: [
      'Size daha iyi hizmet sunmak için',
      'Randevu taleplerini değerlendirmek ve yanıtlamak için',
      'Sorularınızı ve taleplerinizi yanıtlamak için',
      'Web sitemizi geliştirmek ve kullanıcı deneyimini iyileştirmek için',
      'Yasal yükümlülükleri yerine getirmek için',
      'Pazarlama faaliyetleri yürütmek için (yalnızca izin verdiğiniz takdirde)'
    ]
  },
  
  // Bölüm 3
  section3: {
    title: '3. Bilgilerin Paylaşılması',
    content: 'Kişisel bilgileriniz, aşağıdaki durumlar dışında üçüncü taraflarla paylaşılmamaktadır:',
    items: [
      'Yasal bir zorunluluk olduğunda (mahkeme kararı, yasal süreç vb.)',
      'Hizmet sağlayıcılarımız ile (bu durumda bilgilerinizin güvenliği için gerekli önlemler alınır)',
      'Sizin açık izniniz olduğunda'
    ]
  },
  
  // Bölüm 4
  section4: {
    title: '4. Bilgilerin Güvenliği',
    content: 'Kişisel bilgilerinizin güvenliğini sağlamak adına teknik ve idari tedbirler uygulamaktayız. Bu tedbirler arasında:',
    items: [
      'SSL şifreleme teknolojisi kullanımı',
      'Sınırlı erişim politikaları',
      'Düzenli güvenlik güncellemeleri',
      'Çalışanlarımız için veri güvenliği eğitimleri',
      'Güvenlik duvarları ve antivirüs yazılımları'
    ],
    additionalContent: 'Ancak internet üzerinden hiçbir veri iletiminin yüzde 100 güvenli olmadığını hatırlatmak isteriz. Bu nedenle, mutlak güvenlik garantisi veremiyoruz.'
  },
  
  // Bölüm 5
  section5: {
    title: '5. Çerezler (Cookies)',
    content: 'Web sitemiz, çerezleri kullanıcı deneyimini geliştirmek ve web sitesi performansını analiz etmek için kullanmaktadır. Çerezler hakkında detaylı bilgi için',
    cookiePolicyLink: 'Çerez Politikamızı'
  },
  
  // Bölüm 6
  section6: {
    title: '6. Üçüncü Taraf Bağlantıları',
    content: 'Web sitemiz, üçüncü taraf web sitelerine bağlantılar içerebilir. Bu bağlantılara tıkladığınızda, bizim sitemizden ayrılmış olacaksınız. Bu üçüncü taraf sitelerin gizlilik politikaları ve uygulamaları üzerinde kontrolümüz yoktur ve bu nedenle sorumlu değiliz. Ziyaret ettiğiniz her sitenin gizlilik politikasını incelemenizi öneririz.'
  },
  
  // Bölüm 7
  section7: {
    title: '7. Çocukların Gizliliği',
    content: 'Web sitemiz ve hizmetlerimiz 18 yaşın altındaki çocuklara yönelik değildir. Bilerek 18 yaşın altındaki kişilerden kişisel bilgi toplamıyoruz. Eğer 18 yaşın altındaysanız, ebeveyn veya vasilerinizin izni olmadan herhangi bir kişisel bilgi paylaşmamalısınız.'
  },
  
  // Bölüm 8
  section8: {
    title: '8. Haklarınız',
    content: 'Kişisel verilerinizle ilgili olarak aşağıdaki haklara sahipsiniz:',
    category1: {
      items: [
        'Verilerinize erişim talep etme',
        'Yanlış veya eksik bilgilerin düzeltilmesini isteme',
        'Verilerinizin silinmesini talep etme'
      ]
    },
    category2: {
      items: [
        'Verilerinizin işlenmesine itiraz etme',
        'Verilerinizin işlenmesini kısıtlama',
        'Verilerinizin taşınabilirliğini talep etme'
      ]
    },
    additionalContent: 'Bu haklarınızı kullanmak veya herhangi bir sorunuz olması durumunda bizimle iletişime geçebilirsiniz:'
  },
  
  // Bölüm 9
  section9: {
    title: '9. Değişiklikler',
    content: 'Bu Gizlilik Politikasını zaman zaman güncelleyebiliriz. Herhangi bir değişiklik olduğunda, güncellenmiş politikayı web sitemizde yayınlayacağız. Web sitemizi her ziyaret ettiğinizde Gizlilik Politikasını kontrol etmenizi öneririz.'
  },
  
  // Bölüm 10
  section10: {
    title: '10. İletişim',
    content: 'Bu Gizlilik Politikası hakkında sorularınız veya endişeleriniz varsa, lütfen bizimle aşağıdaki kanallar üzerinden iletişime geçin:',
    address: {
      label: 'Adres',
      value: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/İstanbul'
    },
    email: {
      label: 'E-posta',
      value: 'info@klinikakademi.com'
    },
    phone: {
      label: 'Telefon',
      value: '+90 553 973 64 80'
    },
    contactButton: 'İletişim Sayfasına Git'
  },
  
  // Footer
  lastUpdate: 'Son Güncelleme Tarihi:'
} 