<template>
  <div v-if="showConsent" class="cookie-consent">
    <div class="cookie-content">
      <div class="cookie-icon">
        <i class="bi bi-shield-lock"></i>
      </div>
      <div class="cookie-text">
        <h3>{{ $t('cookieConsent.title') }}</h3>
        <p>{{ $t('cookieConsent.text') }}</p>
      </div>
      <div class="cookie-actions">
        <router-link :to="cookiePolicyRoute" class="btn-policy">
          {{ $t('cookieConsent.detailButton') }}
        </router-link>
        <button @click="acceptCookies" class="btn-accept">
          {{ $t('cookieConsent.acceptButton') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import slugs from '@/translations/slugs';
import { getLocale } from '@/plugins/i18n';

export default {
  name: 'CookieConsent',
  data() {
    return {
      showConsent: false
    }
  },
  computed: {
    cookiePolicyRoute() {
      const locale = getLocale();
      return '/' + slugs[locale].cookiePolicy;
    }
  },
  mounted() {
    this.checkCookieConsent();
  },
  methods: {
    checkCookieConsent() {
      const consent = localStorage.getItem('cookieConsent');
      this.showConsent = consent !== 'accepted';
    },
    acceptCookies() {
      localStorage.setItem('cookieConsent', 'accepted');
      this.showConsent = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie-consent {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 380px;
  max-width: 90vw;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
  z-index: 999;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 1px solid rgba(0, 164, 166, 0.1);
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.15);
  }
}

.cookie-content {
  padding: 1.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;
}

.cookie-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: rgba(0, 164, 166, 0.1);
  border-radius: 50%;
  margin-bottom: 0.5rem;
  transition: all 0.3s ease;
  
  i {
    font-size: 1.8rem;
    color: var(--primary-color);
    transition: transform 0.3s ease;
  }
  
  &:hover i {
    transform: scale(1.15);
  }
}

.cookie-text {
  h3 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: #333;
  }
  
  p {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #666;
    margin-bottom: 0.5rem;
  }
}

.cookie-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
  margin-top: 0.5rem;
}

.btn-policy {
  padding: 0.65rem 1.2rem;
  background: none;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 8px;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: rgba(0, 164, 166, 0.1);
    color: var(--primary-color);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 164, 166, 0.15);
  }
  
  &:active {
    transform: translateY(0);
  }
}

.btn-accept {
  padding: 0.65rem 1.5rem;
  background: linear-gradient(135deg, #00a4a6 0%, #007577 100%);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.95rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background: linear-gradient(135deg, #008385, #006668);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 164, 166, 0.25);
  }
  
  &:active {
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .cookie-consent {
    width: 360px;
    bottom: 15px;
    right: 15px;
  }
}

@media (max-width: 576px) {
  .cookie-consent {
    width: calc(100% - 30px);
    max-width: 360px;
    left: 0;
    right: 0;
    bottom: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .cookie-content {
    padding: 1.5rem;
  }
  
  .cookie-icon {
    width: 50px;
    height: 50px;
    
    i {
      font-size: 1.5rem;
    }
  }
  
  .cookie-text {
    h3 {
      font-size: 1.1rem;
    }
    
    p {
      font-size: 0.9rem;
    }
  }
  
  .cookie-actions {
    flex-direction: column-reverse;
    gap: 0.8rem;
  }
  
  .btn-policy, .btn-accept {
    width: 100%;
    text-align: center;
    padding: 0.6rem 1rem;
  }
}

@media (max-width: 360px) {
  .cookie-content {
    padding: 1.2rem;
  }
}
</style> 