export default {
  // Hero Bölümü
  badge: 'Yasal Bilgilendirme',
  title: {
    main: 'KVKK',
    highlight: 'Aydınlatma Metni'
  },
  description: 'Kişisel verilerinizin nasıl toplandığı, işlendiği ve korunduğu hakkında bilgi edinebilirsiniz.',
  
  meta: {
    title: 'KVKK Aydınlatma Metni',
    description: 'Klinik Akademi Diş Polikliniği\'nde kişisel verilerin korunması kanunu (KVKK) ve kişisel verilerin işlenmesi hakkında detaylı bilgi.',
  },
  
  // İçerik Bölümü
  intro: {
    content: 'Klinik Akademi Diş Polikliniği olarak, 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") kapsamında veri sorumlusu sıfatıyla, kişisel verilerinizi aşağıda açıklanan amaçlar doğrultusunda, kanuna ve dürüstlük kurallarına uygun olarak işleyebilir, kaydedebilir, saklayabilir, sınıflandırabilir, güncelleyebilir ve sınırlı, ölçülü ve kanunun izin verdiği durumlarda 3. kişilere açıklayabilir/aktarabiliriz.'
  },
  
  // Bölüm 1
  section1: {
    title: '1. Veri Sorumlusu',
    content: 'Klinik Akademi Diş Polikliniği, kişisel verilerinizin işlenmesi ve saklanması süreçlerinde veri sorumlusu olarak hareket etmektedir. KVKK uyarınca, kişisel verileriniz Klinik Akademi Diş Polikliniği tarafından veri sorumlusu olarak aşağıda açıklanan kapsamda toplanmakta ve işlenmektedir.'
  },
  
  // Bölüm 2
  section2: {
    title: '2. Kişisel Verilerin İşlenme Amacı',
    content: 'Kişisel verileriniz, Klinik Akademi Diş Polikliniği hizmetlerinden faydalanabilmeniz için gerekli operasyonların yürütülmesi, sağlık hizmetlerinin sunulması ve bunların planlanması ve takibi, kimliğinizin doğrulanması, randevu oluşturma ve takibi, tedavi süreçlerinin yönetimi, ödeme işlemlerinin gerçekleştirilmesi ve diğer dental operasyonlar dahil olmak üzere aşağıdaki amaçlar doğrultusunda işlenebilmektedir:<br><br><ul><li>Sağlık hizmetlerinin yürütülmesi ve geliştirilmesi</li><li>Hasta kaydı ve kabulünün gerçekleştirilmesi</li><li>Tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi</li><li>Sağlık hizmetleri finansmanının planlanması ve yönetimi</li><li>Randevu oluşturma ve hatırlatma hizmetlerinin sağlanması</li><li>Faturalama ve ödeme süreçlerinin yönetimi</li><li>Yasal ve düzenleyici gerekliliklerin yerine getirilmesi</li><li>Sağlık hizmetlerimiz hakkında analiz yapılması ve sağlık hizmetlerimizin geliştirilmesi</li></ul>'
  },
  
  // Bölüm 3
  section3: {
    title: '3. İşlenen Kişisel Veri Kategorileri',
    content: 'Kliniğimiz tarafından işlenen temel kişisel veri kategorileri aşağıdaki gibidir:<br><br><strong>Kimlik ve İletişim Bilgileri:</strong><br><ul><li>Kimlik bilgileri (ad, soyad, TC kimlik numarası, doğum tarihi, cinsiyet vb.)</li><li>İletişim bilgileri (telefon numarası, e-posta, adres vb.)</li><li>Finansal bilgiler (banka hesap bilgileri, fatura bilgileri vb.)</li></ul><br><strong>Sağlık ve Diğer Bilgiler:</strong><br><ul><li>Sağlık bilgileri (hastalık geçmişi, teşhis, tedavi bilgileri, röntgen ve görüntüleme sonuçları vb.)</li><li>Sigorta bilgileri (özel sağlık sigortası, SGK vb.)</li><li>Görsel ve işitsel kayıtlar (gerektiğinde tedavi öncesi ve sonrası fotoğraflar)</li></ul>'
  },
  
  // Bölüm 4
  section4: {
    title: '4. Kişisel Verilerin Aktarılması',
    content: 'Kişisel verileriniz, yukarıda belirtilen amaçlar doğrultusunda, kanun ve diğer mevzuat kapsamında aşağıda belirtilen yurt içi ve yurt dışı kuruluşlar ile paylaşılabilmektedir:<br><br><ul><li>Sağlık Bakanlığı ve bağlı alt birimleri</li><li>Özel sigorta şirketleri ve Sosyal Güvenlik Kurumu</li><li>Hukuki süreçlerin yürütülmesi amacıyla avukatlar ve yetkili kamu kurum ve kuruluşları</li><li>Düzenleyici ve denetleyici kurumlar ve resmi merciler</li><li>Hizmet alınan yurt içi/yurt dışı üçüncü kişiler (laboratuvarlar ve tıbbi cihaz tedarikçileri vb.)</li></ul><br>Kişisel verileriniz, Kanun\'un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir.'
  },
  
  // Bölüm 5
  section5: {
    title: '5. Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi',
    content: 'Kişisel verileriniz, Klinik Akademi Diş Polikliniği tarafından elektronik veya fiziksel olarak toplanabilmektedir. Bu kapsamda kişisel verileriniz aşağıdaki yöntemler ve hukuki sebeplerle toplanmaktadır:<br><br><ul><li>Hasta kabul, kayıt ve randevu oluşturma süreçlerinde paylaştığınız bilgiler</li><li>Tedavi sürecinde hekim tarafından yapılan muayene ve testler sonucunda elde edilen sağlık verileri</li><li>Web sitemiz, telefon, e-posta ve sosyal medya kanallarımız üzerinden sağladığınız bilgiler</li><li>Sağlık hizmetlerinin finansmanı ve planlanması amacıyla düzenleyici ve denetleyici kurumlarla yapılan bilgi paylaşımları</li><li>Hizmet alınan kurumlardan alınan bilgiler (özel sigorta, SGK vb.)</li></ul>',
    list: {
      item1: {
        title: 'Açık Rıza',
        content: 'Açıkça kanunlarda öngörülmeyen ve açık rıza gerektiren veri işleme faaliyetleri için, kişisel verileriniz açık rızanızın alınması suretiyle işlenmektedir.'
      },
      item2: {
        title: 'Kanunlarda Açıkça Öngörülmesi',
        content: 'Sağlık hizmetlerine ilişkin mevzuat kapsamında, verilerinizin işlenmesi ve ilgili kurumlarla paylaşılması yasal bir zorunluluktur.'
      },
      item3: {
        title: 'Sözleşmenin İfası',
        content: 'Klinik Akademi Diş Polikliniği olarak, sizinle aramızdaki sözleşmeden doğan hizmetlerin yerine getirilmesi ve tedavi ilişkisinin kurulması için kişisel verileriniz işlenmektedir.'
      }
    }
  },
  
  // Bölüm 6
  section6: {
    title: '6. Kişisel Veri Sahibinin Hakları',
    content: 'Kişisel veri sahibi olarak Kanunun 11. maddesi uyarınca aşağıdaki haklara sahip olduğunuzu bildiririz:',
    category1: {
      items: [
        'Kişisel verilerinizin işlenip işlenmediğini öğrenme',
        'Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme',
        'Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme',
        'Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme',
        'Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme'
      ]
    },
    category2: {
      items: [
        'Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini isteme',
        'Düzeltme, silme ve yok edilme taleplerinizin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme',
        'İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme',
        'Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme'
      ]
    }
  },
  
  // Bölüm 7
  section7: {
    title: '7. Talep ve Başvurular',
    content: 'Kişisel verilerinize ilişkin haklar kapsamındaki taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe göre Klinik Akademi Diş Polikliniğine iletebilirsiniz. Başvurularınızı aşağıdaki kanallardan birini kullanarak gerçekleştirebilirsiniz:',
    contactMethods: {
      inPerson: {
        clinic: 'Klinik Akademi Diş Polikliniği',
        address: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/İstanbul',
        method: 'Kimliğinizi tevsik edici belgeler ile şahsen başvuru'
      },
      mail: {
        address: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/İstanbul',
        method: 'Noter vasıtasıyla veya iadeli taahhütlü posta yoluyla yazılı başvuru'
      },
      email: {
        address: 'info@klinikakademi.com',
        method: 'Güvenli elektronik imzalı olarak veya sistemimizde kayıtlı e-posta adresiniz üzerinden başvuru'
      }
    },
    additionalContent: 'Başvurunuzda yer alan talepleriniz, talebin niteliğine göre en geç otuz gün içinde ücretsiz olarak sonuçlandırılacaktır.'
  },
  
  // Bölüm 8
  section8: {
    title: '8. Kişisel Verilerin Saklanma Süresi',
    content: 'Kişisel verileriniz, işlenme amaçlarının gerektirdiği süreler boyunca ve yasal yükümlülüklerimiz kapsamında saklanmaktadır. Yasal saklama sürelerinin dolması, işleme amaçlarının ortadan kalkması veya rızanızı geri almanız halinde ve Klinik Akademi Diş Polikliniğinin uygulamaları ve ticari yaşamının teamülleri uyarınca işlenmesini gerektiren süre ile sınırlı olarak işlenecek, daha sonra silinecek, yok edilecek veya anonim hale getirilecektir.'
  },
  
  // Footer
  lastUpdate: 'Son Güncelleme Tarihi:'
} 