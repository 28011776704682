export default {
  tr: {
    home: '',
    treatments: 'istanbul-kartal-soganlik-dis-tedavileri',
    implant: 'istanbul-kartal-soganlik-implant-tedavisi',
    orthodontics: 'istanbul-kartal-soganlik-ortodonti-tedavisi',
    whitening: 'istanbul-kartal-soganlik-dis-beyazlatma-tedavisi',
    rootCanal: 'istanbul-kartal-soganlik-kanal-tedavisi',
    prosthesis: 'istanbul-kartal-soganlik-protez-tedavisi',
    periodontics: 'istanbul-kartal-soganlik-dis-eti-tedavisi-periodonti',
    pediatric: 'istanbul-kartal-soganlik-cocuk-dis-hekimligi',
    surgery: 'istanbul-kartal-soganlik-agiz-dis-cene-cerrahisi',
    invisalign: 'istanbul-kartal-soganlik-invisalign-seffaf-plak-tedavisi',
    
    // Diğer Sayfalar
    about: 'istanbul-kartal-soganlik-dis-klinigi-hakkimizda',
    team: 'istanbul-kartal-soganlik-dis-hekimleri-ekibimiz',
    services: 'istanbul-kartal-soganlik-dis-klinigi-hizmetlerimiz',
    doctors: 'istanbul-kartal-soganlik-dis-doktorlarimiz',
    contact: 'istanbul-kartal-soganlik-dis-klinigi-iletisim',
    media: 'istanbul-kartal-soganlik-dis-klinigi-medya',
    clinic: 'istanbul-kartal-soganlik-dis-klinigi',
    notFound: 'sayfa-bulunamadi',
    
    // Yasal Sayfalar
    legal: 'yasal',
    kvkk: 'kvkk-aydinlatma-metni',
    privacyPolicy: 'gizlilik-politikasi',
    cookiePolicy: 'cerez-politikasi'
  },
  en: {
    home: '',
    treatments: 'istanbul-kartal-soganlik-dental-treatments',
    implant: 'istanbul-kartal-soganlik-dental-implant-treatment',
    orthodontics: 'istanbul-kartal-soganlik-orthodontic-treatment',
    whitening: 'istanbul-kartal-soganlik-teeth-whitening-treatment',
    rootCanal: 'istanbul-kartal-soganlik-root-canal-treatment',
    prosthesis: 'istanbul-kartal-soganlik-prosthetic-dental-treatment',
    periodontics: 'istanbul-kartal-soganlik-periodontal-gum-treatment',
    pediatric: 'istanbul-kartal-soganlik-pediatric-dentistry',
    surgery: 'istanbul-kartal-soganlik-oral-maxillofacial-surgery',
    invisalign: 'istanbul-kartal-soganlik-invisalign-clear-aligners',
    
    // Diğer Sayfalar
    about: 'istanbul-kartal-soganlik-dental-clinic-about-us',
    team: 'istanbul-kartal-soganlik-dental-clinic-our-team',
    services: 'istanbul-kartal-soganlik-dental-clinic-services',
    doctors: 'istanbul-kartal-soganlik-dental-clinic-our-doctors',
    contact: 'istanbul-kartal-soganlik-dental-clinic-contact',
    media: 'istanbul-kartal-soganlik-dental-clinic-media',
    clinic: 'istanbul-kartal-soganlik-dental-clinic',
    notFound: 'page-not-found',
    
    // Yasal Sayfalar
    legal: 'legal',
    kvkk: 'personal-data-protection-information',
    privacyPolicy: 'privacy-policy',
    cookiePolicy: 'cookie-policy'
  },
  de: {
    home: '',
    treatments: 'istanbul-kartal-soganlik-zahnbehandlungen',
    implant: 'istanbul-kartal-soganlik-zahnimplantat-behandlung',
    orthodontics: 'istanbul-kartal-soganlik-kieferorthopädie-behandlung',
    whitening: 'istanbul-kartal-soganlik-zahnaufhellung-behandlung',
    rootCanal: 'istanbul-kartal-soganlik-wurzelkanal-behandlung',
    prosthesis: 'istanbul-kartal-soganlik-zahnprothesen-behandlung',
    periodontics: 'istanbul-kartal-soganlik-zahnfleisch-parodontologie',
    pediatric: 'istanbul-kartal-soganlik-kinderzahnheilkunde',
    surgery: 'istanbul-kartal-soganlik-mund-kiefer-gesichtschirurgie',
    invisalign: 'istanbul-kartal-soganlik-invisalign-durchsichtige-zahnspangen',
    
    // Diğer Sayfalar
    about: 'istanbul-kartal-soganlik-zahnklinik-über-uns',
    team: 'istanbul-kartal-soganlik-zahnklinik-unser-team',
    services: 'istanbul-kartal-soganlik-zahnklinik-dienstleistungen',
    doctors: 'istanbul-kartal-soganlik-zahnklinik-unsere-zahnärzte',
    contact: 'istanbul-kartal-soganlik-zahnklinik-kontakt',
    media: 'istanbul-kartal-soganlik-zahnklinik-medien',
    clinic: 'istanbul-kartal-soganlik-zahnklinik',
    notFound: 'seite-nicht-gefunden',
    
    // Yasal Sayfalar
    legal: 'rechtliches',
    kvkk: 'datenschutz-informationen',
    privacyPolicy: 'datenschutzrichtlinie',
    cookiePolicy: 'cookie-richtlinie'
  }
} 