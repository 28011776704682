export default {
  // Hero Bölümü
  badge: 'Rechtliche Informationen',
  title: {
    main: 'Datenschutz',
    highlight: 'richtlinie'
  },
  description: 'Erfahren Sie, wie Ihre persönlichen Daten bei der Nutzung unserer Website verarbeitet und geschützt werden.',
  
  meta: {
    title: 'Datenschutzrichtlinie | Klinik Akademi Zahnklinik',
    description: 'Informationen darüber, wie Ihre persönlichen Daten bei der Nutzung der Website der Klinik Akademi Zahnklinik verarbeitet und geschützt werden.'
  },
  
  // İçerik Bölümü
  intro: 'Bei der Klinik Akademi Zahnklinik gehört der Schutz der Privatsphäre unserer Website-Besucher und die Sicherstellung der Sicherheit ihrer Informationen zu unseren Prioritäten. Diese Datenschutzrichtlinie enthält Erläuterungen darüber, wie bei der Nutzung unserer Website gesammelte Informationen verwendet, geteilt und geschützt werden.',
  
  // Bölüm 1
  section1: {
    title: '1. Gesammelte Informationen',
    content: 'Bei der Nutzung unserer Website können wir folgende Informationen sammeln:',
    card1: {
      title: 'Automatisch gesammelte Informationen',
      items: [
        'IP-Adresse',
        'Browser-Typ und -Version',
        'Besuchte Seiten und Daten',
        'Klickdaten',
        'Geräteinformationen'
      ]
    },
    card2: {
      title: 'Informationen, die Sie bereitstellen',
      items: [
        'Name, Nachname, E-Mail, Telefonnummer, die über Kontaktformulare geteilt werden',
        'Gesundheitsinformationen, die über Terminformulare geteilt werden',
        'Präferenzen bezüglich Behandlungen und Dienstleistungen'
      ]
    }
  },
  
  // Bölüm 2
  section2: {
    title: '2. Zweck der Verwendung von Informationen',
    content: 'Wir verwenden die von uns gesammelten Informationen für folgende Zwecke:',
    items: [
      'Um Ihnen einen besseren Service zu bieten',
      'Um Terminanfragen zu bewerten und zu beantworten',
      'Um Ihre Fragen und Anfragen zu beantworten',
      'Um unsere Website zu verbessern und die Benutzererfahrung zu verbessern',
      'Um gesetzliche Verpflichtungen zu erfüllen',
      'Um Marketingaktivitäten durchzuführen (nur wenn Sie die Erlaubnis erteilen)'
    ]
  },
  
  // Bölüm 3
  section3: {
    title: '3. Weitergabe von Informationen',
    content: 'Ihre persönlichen Informationen werden nicht an Dritte weitergegeben, außer in den folgenden Fällen:',
    items: [
      'Wenn eine gesetzliche Verpflichtung besteht (gerichtliche Anordnung, rechtliches Verfahren usw.)',
      'Mit unseren Dienstleistern (in diesem Fall werden notwendige Maßnahmen für die Sicherheit Ihrer Informationen getroffen)',
      'Wenn Sie ausdrücklich zustimmen'
    ]
  },
  
  // Bölüm 4
  section4: {
    title: '4. Sicherheit der Informationen',
    content: 'Wir implementieren technische und administrative Maßnahmen, um die Sicherheit Ihrer persönlichen Informationen zu gewährleisten. Zu diesen Maßnahmen gehören:',
    items: [
      'Verwendung von SSL-Verschlüsselungstechnologie',
      'Eingeschränkte Zugriffsrichtlinien',
      'Regelmäßige Sicherheitsupdates',
      'Datensicherheitsschulungen für unsere Mitarbeiter',
      'Firewalls und Antiviren-Software'
    ],
    additionalContent: 'Wir möchten Sie jedoch daran erinnern, dass keine Datenübertragung über das Internet zu 100 Prozent sicher ist. Daher können wir keine absolute Sicherheit garantieren.'
  },
  
  // Bölüm 5
  section5: {
    title: '5. Cookies',
    content: 'Unsere Website verwendet Cookies, um die Benutzererfahrung zu verbessern und die Website-Leistung zu analysieren. Für detaillierte Informationen über Cookies können Sie unsere',
    cookiePolicyLink: 'Cookie-Richtlinie'
  },
  
  // Bölüm 6
  section6: {
    title: '6. Links zu Drittanbietern',
    content: 'Unsere Website kann Links zu Websites von Drittanbietern enthalten. Wenn Sie auf diese Links klicken, verlassen Sie unsere Seite. Wir haben keine Kontrolle über und sind nicht verantwortlich für die Datenschutzrichtlinien und -praktiken dieser Websites von Drittanbietern. Wir empfehlen Ihnen, die Datenschutzrichtlinie jeder Website zu überprüfen, die Sie besuchen.'
  },
  
  // Bölüm 7
  section7: {
    title: '7. Datenschutz für Kinder',
    content: 'Unsere Website und Dienstleistungen sind nicht für Kinder unter 18 Jahren bestimmt. Wir sammeln wissentlich keine persönlichen Informationen von Personen unter 18 Jahren. Wenn Sie unter 18 Jahre alt sind, sollten Sie ohne Erlaubnis Ihrer Eltern oder Erziehungsberechtigten keine persönlichen Informationen teilen.'
  },
  
  // Bölüm 8
  section8: {
    title: '8. Ihre Rechte',
    content: 'In Bezug auf Ihre persönlichen Daten haben Sie die folgenden Rechte:',
    category1: {
      items: [
        'Zugriff auf Ihre Daten anfordern',
        'Korrektur falscher oder unvollständiger Informationen anfordern',
        'Löschung Ihrer Daten anfordern'
      ]
    },
    category2: {
      items: [
        'Der Verarbeitung Ihrer Daten widersprechen',
        'Die Verarbeitung Ihrer Daten einschränken',
        'Übertragbarkeit Ihrer Daten anfordern'
      ]
    },
    additionalContent: 'Um diese Rechte auszuüben oder wenn Sie Fragen haben, können Sie uns kontaktieren unter: info@klinikakademi.com'
  },
  
  // Bölüm 9
  section9: {
    title: '9. Änderungen',
    content: 'Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Bei Änderungen werden wir die aktualisierte Richtlinie auf unserer Website veröffentlichen. Wir empfehlen Ihnen, die Datenschutzrichtlinie bei jedem Besuch unserer Website zu überprüfen.'
  },
  
  // Bölüm 10
  section10: {
    title: '10. Kontakt',
    content: 'Wenn Sie Fragen oder Bedenken zu dieser Datenschutzrichtlinie haben, kontaktieren Sie uns bitte über die folgenden Kanäle:',
    address: {
      label: 'Adresse',
      value: 'Orta, Yavuz Selim Cd. No:15-A, 34880 Kartal/Istanbul'
    },
    email: {
      label: 'E-Mail',
      value: 'info@klinikakademi.com'
    },
    phone: {
      label: 'Telefon',
      value: '+90 553 973 64 80'
    },
    contactButton: 'Zur Kontaktseite'
  },
  
  // Footer
  lastUpdate: 'Letztes Aktualisierungsdatum:'
} 